<template>
  <div>
    <shop-breadcrumb style="margin: 0 auto;width: 1200px;" :breadcrumbList="breadcrumbList" :skip="true"></shop-breadcrumb>
    <div class="docx">
      <component :is="switchComponent"></component>
    </div>
  </div>
</template>

<script>
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import shopBreadcrumb from '@/components/layout/shopping/breadcrumb';
import privacyPolicyCN from "@/views/shopping/common/privacyPolicyCN";
import privacyPolicyUS from "@/views/shopping/common/privacyPolicyUS";


export default {
  name: 'legalDeclaration',
  mixins: [Mixin],
  components: {
    shopBreadcrumb,

  },
  data() {
    return {
      breadcrumbList: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005362'),
          path: '/index'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000040'),
          path: '/privacyPolicy'
        }
      ],
      switchComponent: privacyPolicyCN,
    };
  },
  props: {},
  watch: {
    languagesVal: {
      handler(val) {
        this.switchComponent = val === 'CN' ? privacyPolicyCN : privacyPolicyUS;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {}
};
</script>
<style scoped lang="less">
.docx {
  width: 1200px;
  margin: 0 auto 20px auto;
  padding: 38px;
  background-color: #ffffff;
  color: #000;
}
</style>

