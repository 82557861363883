<template>
  <Modal v-model="operationLogModal" :title="$t('key1000216')" width="1000" :transfer="false" :mask-closable="false"
    @on-visible-change="cahngeOperationLog">
    <div class="table_box">
      <Table
        highlight-row
        border
        max-height="550"
        :loading="tableLoading"
        :columns="tableColumns"
        :data="tableData"
      ></Table>
      <Page
        v-if="!tableLoading"
        :total="total"
        @on-change="changePage"
        show-total
        :page-size="pageParams.pageSize"
        show-elevator
        :current="curPage"
        show-sizer
        @on-page-size-change="changePageSize"
        placement="top"
        :page-size-opts="pageArray">
      </Page>
    </div>
    <template #footer></template>
  </Modal>
</template>

<script>
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import {isJSON} from "@/utils/common";

export default {
  name: "operationLogModal",
  mixins: [Mixin],
  props: {
    productId: {
      type: String,
      default: null
    },
    productGoodsId: {
      type: String,
      default: null
    },
    operationType: {
      type: String,
      default: 'spu'
    }
  },
  data() {
    let v = this;
    return {
      operationLogModal: false,
      pageParams: {
        pageNum: 1,
        pageSize: 15
      },
      tableColumns: [
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000450'),
          align: 'center',
          minWidth: 200,
          key: 'contentText',
          render: (h, params) => {
            let optionsContent = params.row.optionsContent;
            let changeTalg = params.row.changeTalg;
            let specialCharactersTalg = params.row.specialCharactersTalg;
            if (specialCharactersTalg) {
              return h('div', {class: 'mt4 mb4'}, [
                h('p', params.row.contenTitle),
                h('p', {
                  style: {
                    color: '#ED4014'
                  }
                }, params.row.contentText_0),
                changeTalg ? h('p', alias93cb5d9a0adb49f980c05a290e356da0.t('key1000451')) : '',
                changeTalg ? h('p', {
                  style: {
                    color: '#ED4014'
                  }
                }, params.row.contentText_1) : '',
              ]);
            } else {
              return h('div', {
                domProps: {
                  innerHTML: optionsContent
                },
                class: 'mt4 mb4'
              })
            }
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000452'),
          align: 'center',
          key: 'updatedTime',
          render: (h, params) => {
            return h('div', v.$uDate.dealTime(params.row.updatedTime, 'fulltime'));
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000222'),
          align: 'center',
          key: 'updatedBy',
          render: (h, params) => {
            let userName;
            if (v.userInfoList !== null && v.userInfoList[params.row.updatedBy] !== undefined) {
              userName = v.userInfoList[params.row.updatedBy].userName;
            } else {
              if (v.systemTalg) {
                userName = alias93cb5d9a0adb49f980c05a290e356da0.t('key1000453');
              } else {
                userName = alias93cb5d9a0adb49f980c05a290e356da0.t('key1000454');
              }
            }
            return h('div', userName);
          }
        }
      ],
      tableData: [],
      total: 0,
      curPage: 1,
      pageParamsStatus: false
    };
  },
  methods: {
    // 监听操作日志的弹窗
    cahngeOperationLog(value) {
      if (!value) {
        this.total = 0;
        this.curPage = 1;
        this.tableData = [];
      }
    },
    // 初始化数据
    initializeData(id) {
      this.getUserName().then(() => {
        this.getOperationLogData(id);
      });
    },
    // 获取操作日志的数据
    getOperationLogData(id) {
      let v = this;
      let obj = {};
      // spu 级别的操作日志
      if (v.operationType === 'spu') {
        obj.productId = id;
      }
      // sku级别的操作日志
      else if (v.operationType === 'sku') {
        obj.productGoodsId = id;
      }
      let query = Object.assign({}, v.pageParams, obj);
      v.tableLoading = true;
      v.axios.post(api.post_productGoodsOperationLog_query, query, {loading: true}).then(response => {
        v.tableLoading = false;
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            let list = data.list || [];
            v.handlerConter(list);
            v.total = data.total;
          }
          v.operationLogModal = true;
        }
      });
    },
    //操作日志内容展示的处理
    handlerConter(data) {
      if (data) {
        let reg = this.$regular.squareBrackets;
        let typeList = [
          'UPDATE_PRODUCT_GOODS_SUGGEST_PRICE_SPU',
          'UPDATE_PRODUCT_GOODS_SUGGEST_PRICE_SKU',
          'UPDATE_PRODUCT_GOODS_SUGGEST_PRICE_DETAIL_SKU'
        ];
        let newList = [
          {type: 'UPDATE_PRODUCT_GOODS_SUGGEST_PRICE_SPU', title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000455')},
          {type: 'UPDATE_PRODUCT_GOODS_SUGGEST_PRICE_SKU', title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000456')},
          {type: 'UPDATE_PRODUCT_GOODS_SUGGEST_PRICE_DETAIL_SKU', title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000457')},
        ];
        data.map((item) => {
          let text = item.operateContent;
          let operationType = item.operationType;
          item.specialCharactersTalg = false;
          if (typeList.includes(operationType)) {
            let name = newList.filter((talg) => {
              return talg.type === operationType;
            })[0].title;
            if (isJSON(text)) {
              let list = JSON.parse(text);
              let txt = '';
              let content = '';
              if (list && list.length > 0) {
                list.map((ele) => {
                  if (['UPDATE_PRODUCT_GOODS_SUGGEST_PRICE_DETAIL_SKU', 'UPDATE_PRODUCT_GOODS_SUGGEST_PRICE_SKU'].includes(operationType)) {
                    if(this.$route.path === '/viewSku') {
                      txt += `${ele.oldPrice} --> ${ele.newPrice}`;
                    } else {
                      let skuTxt = this.system === 'yms' ? 'ymsSKU' : 'SKU';
                      txt += `${ele[skuTxt]}：${ele.oldPrice} --> ${ele.newPrice} ${ele.currency};`;
                    }

                  } else {
                    txt += `${ele.oldPrice} --> ${ele.newPrice} ${ele.currency};`;
                  }
                })
              }
              if (operationType !== 'UPDATE_PRODUCT_GOODS_SUGGEST_PRICE_DETAIL_SKU') {
                content = `${name};${txt}`
              } else {
                content = `${name}：${txt}`
              }
              item.optionsContent = content.replace(/;/g, '<br/>');
            }
          } else {
            // 字符串内容包含有中括号
            if (text.includes('[') && text.includes(']')) {
              item.specialCharactersTalg = true;
              let list = text.split(':');
              if (list.length > 1) {
                item['contenTitle'] = list[0];
                let result = list[1].match(reg);
                item['changeTalg'] = !!(result && result.length > 1);
                if (result && result.length > 0) {
                  result.map((ele, index) => {
                    item['contentText_' + index] = result[index].substring(1, result[index].length - 1);
                  });
                }
              } else {
                item['contenTitle'] = list[0];
              }
            } else {
              item.optionsContent = text;
            }
          }
        });
        this.tableData = data;
      }
    },
  },
  watch: {
    // 监听参数变化更新数据
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        if (v.operationType === 'spu') {
          v.getOperationLogData(v.productId);
        } else if (v.operationType === 'sku') {
          v.getOperationLogData(v.productGoodsId);
        }
        v.pageParamsStatus = false;
      }
    },
  }
};
</script>

<style lang="less" scoped>

</style>
