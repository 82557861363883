<template>
  <div class="container_box">
    <!--头部筛选区域-->
    <div class="filter_box">
      <Form ref="pageParams" :model="pageParams" :label-width="labelWidth" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1005602')" prop="ymsWarehouseId">
              <Select v-model="pageParams.ymsWarehouseId" filterable>
                <Option v-for="item in warehouseList" :key="item.ymsWarehouseId" :value="item.ymsWarehouseId">{{ item.name }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1005863')" prop="outListNo">
              <Input v-model.trim="pageParams.outListNo" :placeholder="$t('key1002504')" clearable></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1002503')" prop="orderNo">
              <Input v-model.trim="pageParams.orderNo" :placeholder="$t('key1006126')" clearable></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1003499')" prop="outboundType">
              <Select v-model="pageParams.outboundType" filterable>
                <Option v-for="(item,index) in outboundTypeList" :key="index" :value="item.value" :label="item.name">
                  {{ item.name }}
                </Option>
              </Select>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1002333')" prop="source">
              <Select v-model="pageParams.source" filterable>
                <Option v-for="(item,index) in sourceList" :key="index" :value="item.enName" :label="item.enName">
                  {{ item.enName }}
                </Option>
              </Select>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-if="pageParams.packType === 'SS'">
            <FormItem :label="$t('key1006127')" prop="skuNumber">
              <Input v-model="pageParams.sku" style="width: 150px"/>
              <span> * </span>
              <Input style="width: 70px" v-model.trim="pageParams.skuNumber" clearable/>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-if="pageParams.packType === 'SS' && moreFilter">
            <FormItem :label="$t('key1006128')" prop="productBrandIds">
              <Select v-model="pageParams.productBrandIds" multiple filterable><Option
                v-for="(item,index) in productBrandList"
                :key="index"
                :class="{ productBrandRed : item.status === 0 }"
                :label="item.name"
                :value="item.productBrandId">{{ item.name }} </Option></Select>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1003404')">
              <Date-picker
                style="width:100%"
                type="datetimerange"
                @on-clear="resetDate"
                transfer
                @on-change="getDateValue"
                :clearable="clearAble"
                :options="dateOptions"
                format="yyyy-MM-dd HH:mm:ss"
                placement="bottom-end"
                :placeholder="$t('key1000269')"
                :value="payTimeArr">
              </Date-picker>
            </Form-item>
          </Col>
          <Col :xxl="sixIemCol" :xl="fourItemCol" :lg="threeItemCol" :md="twoItemCol" v-show="moreFilter">
            <FormItem :label="$t('key1006129')">
              <local-tabButton :tabButtonList="existDespatchWarnList" @selectStatus="existDespatchWarnBtn"></local-tabButton>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="fourItemCol" :lg="fourItemCol" :md="fourItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1002505')">
              <local-tabButton :tabButtonList="packTypeBtnData" @selectStatus="clickPackTypeBtnData"></local-tabButton>
            </Form-item>
          </Col>
          <Col :xxl="threeItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <FormItem :label="$t('key1003475')">
              <local-tabButton :tabButtonList="labelTypeList" @selectStatus="labelTypeStatus"></local-tabButton>
            </FormItem>
          </Col>
          <Col style="width: 200px;">
            <Button @click="reset" icon="md-refresh" class="mr10">{{ $t('key1000095') }}</Button>
            <Button type="primary" @click="searchData" icon="md-search">{{ $t('key1000270') }}</Button>
          </Col>
        </Row>
        <Row type="flex" justify="end" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" style="text-align: right;">
            <Button @click="toggleMoreFilter">{{ moreFilterMes }}
              <Icon :type="moreFilterIcon"></Icon>
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
    <div class="shipping_method">
      <!--邮件方式-->
      <div class="left_box">
        <div class="option_btn" v-if="!upOrDown" @click="switchBtn">
          <Icon size="20" type="ios-arrow-forward"/>
        </div>
        <shippingMethod
          :upOrDown="upOrDown"
          @switchOption="switchOption"
          @selectCheckBox="selectCheckBox"
          :showCheckbox="true"
          :treeData="treeData">
        </shippingMethod>
      </div>
      <div class="right_box" :style="upOrDown ? 'width: 77%' : 'width: 100%'">
        <!--功能按钮操作区域-->
        <div class="operation_box" :style="upOrDown ? '' : 'padding-left: 35px;'">
          <div class="mr15 sort_options_box">
            <span>{{ $t('key1002506') }}</span>
            <Button-group>
              <Button
                :type="item.checked ? 'primary' : 'default'"
                v-for="item in sortData"
                @click="clickSortButton(item)"
                :key="item.value">{{ item.label }}
                <Icon type="md-arrow-up" v-if="item.checked && !item.toogle"></Icon>
                <Icon type="md-arrow-down" v-if="item.checked && item.toogle"></Icon>
              </Button>
            </Button-group>
          </div>
          <Dropdown class="mr15" @on-click="clickAssignBatch"
            v-if="getPermission('wmsPickingGoods_create_batch') || getPermission('wmsPickingGoods_create_all')">
            <Button type="primary">
              <span>{{ $t('key1002507') }}</span>
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem name="select" v-if="getPermission('wmsPickingGoods_create_batch')">{{ $t('key1002508') }}</DropdownItem>
              <DropdownItem name="all" v-if="getPermission('wmsPickingGoods_create_all')">{{ $t('key1002509') }}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Icon @click="openSetting" class="setting-icon mr15" type="md-settings" size="20"></Icon>
        </div>
        <!--列表区域-->
        <div class="table_box">
          <Table
            border
            highlight-row
            ref="selection"
            :loading="tableLoading"
            max-height="490"
            :columns="columns"
            :data="data"
            @on-selection-change="tableChange">
          </Table>
          <Page
            v-if="!tableLoading"
            :total="tableItemTotal"
            :current="pageParams.pageNum"
            :page-size="pageParams.pageSize"
            :page-size-opts="pageArray"
            show-total
            show-sizer
            show-elevator
            @on-change="changePage"
            placement="top"
            @on-page-size-change="changePageSize">
          </Page>
        </div>
      </div>
    </div>
    <!--生成拣货单弹窗-->
    <createPickListModal
      ref="createPickList"
      :parameterObj="paramsFn()"
      @closeSuccess="closeSuccess"
      :countLabels=countLabels>
    </createPickListModal>
    <!--拣货单生成设置-->
    <Modal v-model="pickingListSettingsModal" @on-ok="savePickSetting" :title="$t('key1006130')" class-name="pickingListSettingsStyle">
      <h2 class="title_box">{{ $t('key1003764') }}</h2>
      <pickingRules :pickingSetInfo="pickingSetInfo" @updateRulesData="updateRulesData"></pickingRules>
      <h2 class="title_box">{{ $t('key1005945') }}</h2>
      <div class="labels_box">
        <Tag v-for="(item, index) in countLabels" :key="index" :name="item" closable @on-close="handleClose">{{ item }}</Tag>
        <Input v-model.trim="labels_txt" :placeholder="$t('key1001063')" clearable style="width: 80px" @on-enter="handleAdd"/>
      </div>
    </Modal>
  </div>
</template>

<script>
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import createPickListModal from "./createPickListModal";
import shippingMethod from "@/components/common/shippingMethod";
import {OUTBOUNDTYPELIST} from "@/utils/enum";
import pickingRules from "@/components/common/pickingRules";

export default {
  name: "generateOrderList",
  mixins: [Mixin],
  components: {
    createPickListModal,
    pickingRules,
    shippingMethod
  },
  data() {
    let v = this;
    return {
      pageParamsStatus: false,
      treeData: [],
      upOrDown: true,
      platformArr: [],
      pickingListSettingsModal: false,
      pickListSetting: null,
      indeterminateAll: false,
      wareId: v.getWarehouseId(),
      pageParams: {
        sku: '',
        skuNumber: '', // sku 数量
        productBrandIds: [], // 货品品牌流水号(品牌Id)
        outListNo: '',
        orderNo: '',
        logisticType: null,
        pageNum: 1,
        pageSize: 15,
        packType: '',
        packageUploadCarrierStatus: null,
        payStartTime: null,
        payEndTime: null,
        source: null,
        labelType: null,
        outboundType: 0,
        existDespatchWarn: null,
        ymsWarehouseId: null
      },
      assignList: [
        {
          label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002509'),
          value: '1',
          flagCode: ['1'],
          status: '1'
        }
      ],
      tableItemTotal: 0,
      pickingNo: '',
      workType: 'sellStock',
      cacheClickVal: 'CT', // 排序缓存值
      orderSeq: 'DESC',
      selectTableData: [],
      sortData: [
        {
          label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002510'),
          value: 'CT',
          checked: true,
          toogle: true
        }, {
          label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002511'),
          value: 'AS',
          checked: false,
          toogle: true
        }
      ],
      packTypeBtnData: [
        {
          selected: true,
          value: '',
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')
        },
        {
          selected: false,
          value: 'SS',
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000180'),

        },
        {
          selected: false,
          value: 'MM',
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000179'),
        }
      ],
      logisticsStatusList: [
        {
          txt: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096'),
          check: true,
          value: null
        }, {
          txt: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002483'),
          check: false,
          value: 0
        }, {
          txt: alias93cb5d9a0adb49f980c05a290e356da0.t('key1003549'),
          check: false,
          value: 3
        }, {
          txt: alias93cb5d9a0adb49f980c05a290e356da0.t('key1003550'),
          check: false,
          value: 4
        }
      ],
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center',
          fixed: 'left',
        }, {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004975'),
          key: 'pickingNo',
          align: 'center',
          minWidth: 160,
          fixed: 'left',
          render: (h, params) => {
            return h('Alink', {
              props: {
                linkName: params.row.pickingNo,
                AlinkShow: v.getPermission('wmsPicking_querySalesPickingDetail')
              },
              on: {
                AlinkBtn: () => {
                  v.pickingNo = params.row.pickingNo;
                  v.$router.push({
                    path: '/wms/generateOrderDetails',
                    query: {
                      pickingNo: v.pickingNo,
                      workType: v.workType
                    }
                  });
                }
              }
            });
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006131'),
          align: 'center',
          minWidth: 100,
          key: 'packageType',
          render: (h, params) => {
            if (params.row.packageType === 'MS' || params.row.packageType === 'MM') {
              return h('div', alias93cb5d9a0adb49f980c05a290e356da0.t('key1000179'));
            } else if (params.row.packageType === 'SS' || params.row.packageType === 'SM') {
              return h('div', alias93cb5d9a0adb49f980c05a290e356da0.t('key1000180'));
            }
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002981'),
          align: 'center',
          minWidth: 120,
          key: 'labelType',
          render: (h, params) => {
            let text = '';
            let status = params.row.labelType;
            v.labelTypeList.map((item) => {
              if (item.value === status) {
                if (status === 'null') {
                  text = '-';
                } else {
                  text = item.title;
                }
              }
            });
            return h('span', text);
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002514'),
          align: 'center',
          minWidth: 120,
          key: 'outboundType',
          render: (h, params) => {
            let text = '';
            let status = params.row.outboundType;
            v.outboundTypeList.map((item) => {
              if (item.value === status) {
                text = item.title;
              }
            });
            return h('span', text);
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000366'),
          minWidth: 120,
          align: 'center',
          key: 'orderNos',
          render: (h, params) => {
            let pos = [];
            if (params.row.packageOrderBoList !== null) {
              for (let i = 0; i < params.row.packageOrderBoList.length; i++) {
                let n = params.row.packageOrderBoList[i];
                if (n.accountCode) {
                  pos.push(h('div', n.accountCode + '-' + n.salesRecordNumber));
                } else {
                  pos.push(h('div', n.salesRecordNumber));
                }
              }
              return h('div', pos);
            }
          }
        }, {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001784'),
          width: 100,
          align: 'center',
          key: 'consigneeCountry'
        }, {
          title: 'SKU',
          minWidth: 140,
          align: 'center',
          render(h, params) {
            let skus = params.row.sku;
            if (skus && skus.length > 1) {
              skus.splice(1).push('...');
            }
            return skus ? h('div', {}, skus.map(i => {
              return h('p', {
                style: {
                  color: '#fff',
                  backgroundColor: '#999',
                  marginBottom: '5px'
                }
              }, i);
            })) : '';
          }
        }, {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002516'),
          minWidth: 110,
          align: 'center',
          key: 'goodsSkuNumber'
        }, {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002517'),
          align: 'center',
          minWidth: 100,
          key: 'goodsQuantityNumber'
        }, {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002993'),
          align: 'center',
          minWidth: 230,
          key: 'logisticsDealerName',
          render: (h, params) => {
            return h('div', params.row.logisticsDealerName + ' > ' + params.row.logisticsMailName);
          }
        }, {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006132'),
          align: 'center',
          minWidth: 120,
          key: 'warehouseBlockGroupNames',
          render: (h, params) => {
            if (params.row.warehouseBlockGroupNames) {
              return h('div', params.row.warehouseBlockGroupNames.toString());
            }
          }
        }, {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006133'),
          align: 'center',
          minWidth: 120,
          key: 'warehouseBlockNames',
          render: (h, params) => {
            if (params.row.warehouseBlockNames) {
              return h('div', params.row.warehouseBlockNames.toString());
            }
          }
        }, {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006052'),
          align: 'center',
          minWidth: 120,
          key: 'warehouseLocationNames',
          render: (h, params) => {
            if (params.row.warehouseLocationNames) {
              return h('div', params.row.warehouseLocationNames.toString());
            }
          }
        }, {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001600'),
          align: 'center',
          width: 170,
          key: 'createdTime',
          sortable: true,
          render: (h, params) => {
            return h('span', v.$uDate.getDataToLocalTime(params.row.createdTime, 'fulltime'));
          }
        }, {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000279'),
          key: 'opts',
          fixed: 'right',
          align: 'center',
          width: 120,
          render: (h, params) => {
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002507'),
                    show: v.getPermission('wmsPickingGoods_create_single'),
                    clickFn: () => {
                      v.$refs['createPickList'].initCreatePickListData([], 'single', params.row);
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    v.optionBtnWidth.push(value);
                    params.column.width = Math.max(...v.optionBtnWidth);
                    v.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ],
      loading2: false,
      data: [],
      recordNum: null,
      labels_txt: '',
      countLabels: [],
      clearAble: true,
      payTimeArr: [],
      sourceList: [],
      productBrandList: [],
      outboundTypeList: OUTBOUNDTYPELIST,
      existDespatchWarnList: [
        {
          selected: true,
          value: null,
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')
        },
        {
          selected: false,
          value: 1,
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004165')
        },
        {
          selected: false,
          value: 0,
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004164')
        }
      ],
      pickingSetInfo: {},
      warehouseList: [],
      preferredWarehouse: null,
      shippingMethodTalg: true,
    };
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
  },
  created() {
    let v = this;
    v.initFun();
  },
  methods: {
    // 初始化接口数据
    initFun() {
      let v = this;
      v.warehouseList = [];
      (async () => {
        await v.getBelongingWarehouseData(v.userInfo.merchantId).then((data) => {
          if (data && data.length > 0) {
            v.warehouseList = data;
            let list = data.filter((item) => {
              return item.isFirst === 1
            });
            if (list.length > 0) {
              v.pageParams.ymsWarehouseId = list[0].ymsWarehouseId;
              v.preferredWarehouse = list[0].ymsWarehouseId;
            } else {
              v.pageParams.ymsWarehouseId = v.warehouseList[0].ymsWarehouseId;
            }
          }
        });
        await v.getProductBrandList();
        await v.getAllShipMethod();
        await v.pickingGoodsTags();
        await v.getSourceList();
        await v.searchFn();
      })();
    },
    // 获取来源渠道的数据
    getSourceList() {
      let v = this;
      v.axios.get(api.get_organizationSource_query).then(response => {
        if (response.data.code === 0) {
          v.sourceList = response.data.datas;
        }
      });
    },
    // 获取品牌列表(筛选下拉)
    getProductBrandList() {
      let v = this;
      v.axios.get(api.get_productBrand_queryAll).then(function (response) {
        if (response.data.code === 0) {
          v.productBrandList = response.data.datas;
        }
      });
    },
    // 缓存拣货单生成设置
    savePickSetting() {
      let userId = this.userInfo.userId;
      localStorage.setItem('pickListSetting' + userId, JSON.stringify(this.pickListSetting));
      this.$Message.success(alias93cb5d9a0adb49f980c05a290e356da0.t('key1000103'));
    },
    // 获取拣货单生成规则数据
    updateRulesData(data) {
      this.pickListSetting = JSON.parse(JSON.stringify(data));
    },
    openSetting() {
      let v = this;
      // 获取缓存中拣货单规则设置
      let userId = this.userInfo.userId;
      let pickListSetting = localStorage.getItem('pickListSetting' + userId);
      if (pickListSetting) {
        v.pickingSetInfo = JSON.parse(pickListSetting);
      }
      this.pickingListSettingsModal = true;
    },
    getAllShipMethod() {
      let v = this;
      let obj = this.paramsFn();
      delete obj.pageSize;
      delete obj.pageNum;
      obj.getLabelStatus = 3;
      obj.pickingStatus = ['2'];
      v.treeData = [];
      v.axios.post(api.post_wmsPicking_querySalesPickingCarriers, obj).then(res => {
        if (res.data.code === 0 && res.data.datas) {
          let data = res.data.datas;
          data.forEach(val => {
            val.title = val.carrierName === null ? alias93cb5d9a0adb49f980c05a290e356da0.t('key1002521') : val.carrierName;
            val.code = val.carrierId;
            val.carrierId = val.carrierId;
            val.expand = true;
            val.pickingNumber = val.count;
            val.shippingMethods.forEach(val2 => {
              val2.logisticsDealerCode = val.logisticsDealerCode;
              val2.title = val2.shippingMethodName === null ? alias93cb5d9a0adb49f980c05a290e356da0.t('key1002522') : v.handleMultilingualFields(val2.shippingMethodName);
              val2.code = val2.shippingMethodId;
              val2.expand = true;
              val2.pickingNumber = val2.count;
              val2.logisticsMailCode = val2.shippingMethodId;
            });
            val.children = val.shippingMethods;
          });
          if (data.length !== 0) {
            v.treeData = [
              {
                title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096'),
                expand: true,
                pickingNumber: 0,
                children: []
              }
            ];
            this.treeData[0].pickingNumber = data.reduce((a, b) => {
              return a + b.count;
            }, 0);
            this.treeData[0].children = data;
          } else {
            v.treeData = [];
          }
        }
      });
    },
    closeSuccess(value) {
      this.$refs['selection'].selectAll(false);
      this.pageParams.logisticType = null;
      this.selectTableData = [];
      if (value == 'update') {
        this.getAllShipMethod();
        this.searchFn();
      }
    },
    // 生成拣货单
    clickAssignBatch(val) {
      // 勾选
      if (val === 'select') {
        if (this.selectTableData.length <= 0) {
          this.$Message.warning(alias93cb5d9a0adb49f980c05a290e356da0.t('key1002519'));
          return false;
        }
        this.$refs['createPickList'].initCreatePickListData(this.selectTableData, val);
      }
      // 所有
      else if (val === 'all') {
        if (this.data.length === 0) {
          this.$Message.warning(alias93cb5d9a0adb49f980c05a290e356da0.t('key1002520'));
          return false;
        }
        this.$refs['createPickList'].initCreatePickListData([], val);
      }
    },
    paramsFn() {
      return {
        orderBy: this.cacheClickVal === '' ? null : this.cacheClickVal,
        orderSeq: this.orderSeq,
        sku: this.pageParams.sku,
        skuNumber: this.pageParams.skuNumber ? Number(this.pageParams.skuNumber) : null,
        productBrandIds: this.pageParams.productBrandIds,
        packageType: this.pageParams.packType === '' || this.pageParams.packType[0] === '*' ? null : [this.pageParams.packType],
        pageNum: this.pageParams.pageNum,
        pageSize: this.pageParams.pageSize,
        pickingNo: this.pageParams.outListNo === '' ? null : this.sepCommasFn(this.pageParams.outListNo, [',', '，', '\n']), // 出库单编号
        pickingStatus: ['2'], // 出库单状态
        //                    pickingType: this.pageParams.outListType === '' || this.pageParams.outListType[0] === '*' ? ['S1'] : this.pageParams.outListType,
        //                    referenceNo: this.pageParams.referenceNo === '' ? null : this.sepCommasFn(this.pageParams.referenceNo),//参考编号
        warehouseId: [this.wareId],
        orderNos: this.pageParams.orderNo ? this.sepCommasFn(this.pageParams.orderNo, [',', '，', '\n']) : null,
        pickingExtendList: this.pageParams.logisticType,
        packageUploadCarrierStatus: this.pageParams.packageUploadCarrierStatus,
        payStartTime: this.pageParams.payStartTime,
        payEndTime: this.pageParams.payEndTime,
        source: this.pageParams.source,
        labelType: this.pageParams.labelType,
        outboundType: this.pageParams.outboundType === 0 ? null : this.pageParams.outboundType,
        existDespatchWarn: this.pageParams.existDespatchWarn,
        ymsWarehouseId: this.pageParams.ymsWarehouseId
      };
    },
    processData(listData) {
      let issueTypeList = [
        {value: 'S1', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001265')},
        {value: 'O3', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005875')},
        {value: 'O1', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000572')},
        {value: 'O2', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005876')},
      ];
      let progressStatusList = [
        {value: '0', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005888')},
        {value: '1', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005889')},
        {value: '2', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005890')},
        {value: '3', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001262')},
        {value: '4', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005891')},
        {value: '5', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005892')}
      ];
      let pickingStatusList = [
        {value: '0', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002490')},
        {value: '1', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001245')}
      ];
      listData.forEach(val => {
        issueTypeList.map((item) => {
          if (val.pickingType === item.value) {
            val.type = item.name;
          }
        });
        progressStatusList.map((ele) => {
          if (val.pickingStatus === ele.value) {
            val.status = ele.name;
          }
        });
        pickingStatusList.map((talg) => {
          if (talg.value === val.packageGoodsStatus) {
            val.pickStatus = talg.name;
          }
        });
      });
      return listData;
    },
    // 查询
    searchFn() {
      let v = this;
      this.data = [];
      this.tableItemTotal = 0;
      if (v.getPermission('wmsPicking_querySalesPicking_generateOrderList') ||
        this.getPermission('wmsPickingGoods_queryPickingGoodsTask')) {
        let obj = this.paramsFn();
        v.tableLoading = true;
        obj.getLabelStatus = 3;
        obj.pickingStatus = ['2'];
        v.axios.post(api.post_wmsPicking_querySalesPicking, obj).then(res => {
          v.shippingMethodTalg = true;
          if (res.data.code === 0 && res.data.datas && res.data.datas.list.length > 0) {
            let data = this.processData(res.data.datas.list);
            // 根据pickingNo去找订单前缀
            if (data.length === 0) return;
            let params = data.map(i => i.pickingId);
            v.axios.post(api.post_packageGoods_queryByPackageIds, params).then(response => {
              if (data.length !== 0) {
                data.forEach(item2 => {
                  item2.CopyPayTime = this.$uDate.getDateTime(item2.payTime, 'fulltime');
                  item2.CopyPayTime = this.$uDate.dealTime(item2.CopyPayTime);
                  item2.sku = [];
                  if (response.data.datas) {
                    response.data.datas.forEach(j => {
                      if (item2.pickingId === j.packageId) {
                        item2.sku.push(j.sku);
                      }
                    });
                  }
                });
              }
              this.data = data;
              this.tableItemTotal = res.data.datas.total;
              this.$nextTick(() => {
                this.tableLoading = false;
              });
            });
          } else {
            this.data = [];
            this.tableItemTotal = 0;
            v.tableLoading = false;
          }
        });
      } else {
        v.gotoError(); // 无权限
      }
    },
    // 选择品类
    clickPackTypeBtnData(index, value) {
      let v = this;
      v.pageParams.packType = value;
      v.packTypeBtnData.forEach((n, i) => {
        n.selected = i === index;
      });
    },
    // 查询按钮
    searchData(value) {
      if (this.pageParams.packType !== 'SS') {
        // 只有单品才展示sku sku数量 品牌
        this.pageParams.sku = null;
        this.pageParams.skuNumber = null;
        this.pageParams.productBrandIds = null;
      }
      this.pageParams.pageNum = 1;
      if (this.shippingMethodTalg) {
        this.getAllShipMethod();
      }
      this.searchFn();
    },
    // 点击排序按钮
    clickSortButton(data) {
      if (data.value === this.cacheClickVal) {
        data.toogle = !data.toogle;
      }
      this.cacheClickVal = data.value;
      if (data.toogle) {
        this.orderSeq = 'DESC'; // 降
      } else {
        this.orderSeq = 'ASC';
      }
      this.sortData.forEach(function (n) {
        n.checked = data.value === n.value;
      });
      this.searchFn();
      this.getAllShipMethod();
    },
    // 勾选列表数据
    tableChange(data) {
      this.selectTableData = data;
    },
    logisticsChange(value) {
      this.logisticsStatusList.forEach(it => {
        it.check = value === it.value;
      });
      this.pageParams.packageUploadCarrierStatus = value;
    },
    resetDate() {
      let v = this;
      v.pageParams.payStartTime = null;
      v.pageParams.payEndTime = null;
    },
    // 获取拣货单标签数据
    pickingGoodsTags() {
      let v = this;
      v.axios.get(api.get_wmsPickingGoods_pickingGoodsTags + `?warehouseId=${v.wareId}`).then((response) => {
        if (response.data.code === 0) {
          v.countLabels = response.data.datas;
        }
      });
    },
    // 添加标签
    handleAdd() {
      if (this.labels_txt !== '') {
        this.axios.post(api.post_wmsPickingGoods_pickingGoodsTags_save + `?tag=${this.labels_txt}&warehouseId=${this.wareId}`).then((response) => {
          if (response.data.code === 0) {
            this.pickingGoodsTags();
          }
        });
        this.labels_txt = '';
      } else {
        this.$Message.warning({
          content: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006134'),
          duration: 2
        });
      }
    },
    // 删除标签
    handleClose(event, name) {
      this.countLabels.map((item, index) => {
        if (name === item) {
          this.axios.delete(api.delete_wmsPickingGoods_pickingGoodsTag + `?tag=${name}&warehouseId=${this.wareId}`).then((response) => {
            if (response.data.code === 0) {
              this.pickingGoodsTags();
            }
          });
        }
      });
    },
    // 获取日期返回值
    getDateValue(value) {
      var v = this;
      if (value.length === 0 || !value[0]) {
        v.resetDate();
      } else {
        if (value[0] !== '') {
          let timeList = v.defaultTimePeriod(value);
          v.payTimeArr = v.defaultTimePeriod(value,false);
          v.pageParams.payStartTime = v.$uDate.getUniversalTime(new Date(timeList[0]).getTime(), 'fulltime');
          v.pageParams.payEndTime = v.$uDate.getUniversalTime(new Date(timeList[1]).getTime(), 'fulltime');
        }
      }
    },
    // 选中的邮寄方式
    selectCheckBox(arr) {
      let paramsArr = arr.filter(item => {
        if (item.logisticsMailCode) {
          return item;
        }
      }).map(item => {
        return {
          logisticsMailCode: item.logisticsMailCode
        };
      });
      this.pageParams.logisticType = paramsArr.length > 0 ? paramsArr : null;
      this.shippingMethodTalg = false;
      this.searchData();
    },
    // 展开与收起邮寄方式
    switchBtn() {
      this.upOrDown = !this.upOrDown;
    },
    switchOption(val) {
      this.upOrDown = val;
    },
    // 重置按钮
    reset() {
      this.clickPackTypeBtnData(0, '');
      this.payTimeArr = [];
      this.$refs['pageParams'].resetFields();
      this.labelTypeStatus(0, null);
      this.existDespatchWarnBtn(0, null);
      this.pageParams.ymsWarehouseId = this.preferredWarehouse || this.warehouseList[0].ymsWarehouseId;
      this.pageParams.logisticType = null;
      this.shippingMethodTalg = true;
      this.searchData();
    },
    // 选取面单类型
    labelTypeStatus(index, value) {
      let v = this;
      v.pageParams.labelType = value === 'null' ? null : value;
      v.labelTypeList.forEach((n, i) => {
        n.selected = i === index;
      });
    },
    // 选取发货提醒
    existDespatchWarnBtn(index, value) {
      let v = this;
      v.pageParams.existDespatchWarn = value;
      v.existDespatchWarnList.forEach((n, i) => {
        n.selected = i === index;
      });
    }
  },
  watch: {
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        v.searchFn();
        v.pageParamsStatus = false;
      }
    },
    // 监听是否是邮寄方式查询
    shippingMethodTalg: {
      handler(value) {
        if (value) {
          this.pageParams.logisticType = null;
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="less" scoped>
.setting-icon {
  cursor: pointer;
  position: relative;
  top: 6px;
}

.shipping_method {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-around;

  .left_box {
    position: relative;

    .option_btn {
      position: absolute;
      top: 0;
      left: 0;
      height: 56px;
      background-color: #2b85e4;
      color: #fff;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .right_box {
    min-width: 830px;
  }
}

/deep/ .pickingListSettingsStyle {
  .title_box {
    font-size: 15px;
    color: #333;
    font-weight: bold;
    margin-bottom: 8px;
  }
}
</style>
