<template>
  <div class="container_box">
    <!--头部筛选区域-->
    <div class="filter_box">
      <Form ref="pageParams" :model="pageParams" :label-width="labelWidth" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="twoItemCol" :xl="twoItemCol" :lg="twoItemCol" :md="twoItemCol">
            <FormItem :label="$t('key1002474')">
              <local-buttons :data="pickListStatusList" :value.sync="pageParams.pickingGoodsStatus" @btnClick="statusChange"></local-buttons>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <FormItem :label="$t('key1000184')">
              <local-tabButton :tabButtonList="pickListTypeList" @selectStatus="typeChange"></local-tabButton>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <FormItem :label="$t('key1002524')">
              <local-tabButton :tabButtonList="printStatusList" @selectStatus="printStatusChange"></local-tabButton>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1005602')" prop="ymsWarehouseId">
              <Select v-model="pageParams.ymsWarehouseId" filterable>
                <Option v-for="item in warehouseList" :key="item.ymsWarehouseId" :value="item.ymsWarehouseId">{{ item.name }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <FormItem :label="$t('key1002523')" prop="pickingGoodsNo">
              <Input v-model.trim="pageParams.pickingGoodsNo" clearable></Input>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <FormItem :label="$t('key1001512')" prop="pickingNo">
              <Input v-model.trim="pageParams.pickingNo" clearable></Input>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-if="pageParams.pickingGoodsStatus === '7'">
            <Form-item :label="$t('key1000888')">
              <Date-picker
                style="width: 100%"
                type="datetimerange"
                @on-clear="resetDate"
                transfer
                @on-change="getDateValue"
                :clearable="clearAble"
                :options="dateOptions"
                format="yyyy-MM-dd HH:mm:ss"
                placement="bottom-end"
                :placeholder="$t('key1000269')"
                :value="payTimeArr">
              </Date-picker>
            </Form-item>
          </Col>
          <Col style="width: 200px;" push="1">
            <Button @click="reset" icon="md-refresh" class="mr12">{{ $t('key1000095') }}</Button>
            <Button type="primary" @click="search" icon="md-search">{{ $t('key1000270') }}</Button>
          </Col>
        </Row>
      </Form>
    </div>
    <div class="shipping_method">
      <!--邮件方式-->
      <div class="left_box">
        <div class="option_btn" v-if="!upOrDown" @click="switchBtn">
          <Icon size="20" type="ios-arrow-forward"/>
        </div>
        <shippingMethod
          :upOrDown="upOrDown"
          @switchOption="switchOption"
          @selectChange="selectChange"
          :showCheckbox="false"
          :treeData="treeData">
        </shippingMethod>
      </div>
      <div class="right_box" :style="upOrDown ? 'width: 77%' : 'width: 100%'">
        <!--功能按钮操作区域-->
        <div class="operation_box" :style="upOrDown ? '' : 'padding-left: 35px;'">
          <div class="mr15 sort_options_box">
            <span>{{ $t('key1002506') }}</span>
            <Button-group>
              <Button
                :type="item.checked ? 'primary' : 'default'"
                v-for="item in sortData"
                @click="clickSortButton(item)"
                :key="item.value">{{ item.label }}
                <Icon type="md-arrow-up" v-if="item.checked && !item.toogle"></Icon>
                <Icon type="md-arrow-down" v-if="item.checked && item.toogle"></Icon>
              </Button>
            </Button-group>
          </div>
          <Dropdown @on-click="batchOption">
            <Button icon="ios-copy-outline">
              <span>{{ $t('key1002525') }}</span>
              <Icon type="md-arrow-dropdown"></Icon>
            </Button>
            <template #list>
              <DropdownMenu>
                <DropdownItem name="0" v-if="getPermission('wmsPickingGoods_print')">{{ $t('key1002476') }}</DropdownItem>
                <DropdownItem name="1" v-if="getPermission('wmsPickingGoods_modifyToPicking')">{{ $t('key1002526') }}</DropdownItem>
              </DropdownMenu>
            </template>
          </Dropdown>
        </div>
        <!--列表区域-->
        <div class="table_box">
          <Table
            highlight-row
            border
            ref="selection"
            :columns="tableColumns"
            :loading="tableLoading"
            :data="tableData"
            max-height="550"
            @on-selection-change="tableChange">
          </Table>
          <Page
            v-if="!tableLoading"
            :total="tableItemTotal"
            :current="pageParams.pageNum"
            :page-size="pageParams.pageSize"
            show-total
            show-sizer
            show-elevator
            placement="top"
            @on-change="changePage"
            @on-page-size-change="changePageSize"
            :page-size-opts="pageArray">
          </Page>
        </div>
      </div>
    </div>
    <!--订单取消提示-->
    <orderCancelPromptModal ref="orderCancelPrompt" @updateData="search"></orderCancelPromptModal>
  </div>
</template>

<script>
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import shippingMethod from "@/components/common/shippingMethod";
import orderCancelPromptModal from "@/components/common/orderCancelPromptModal";
import {supplierVerifyPicking} from "@/utils/common";

export default {
  name: "pickList",
  mixins: [Mixin],
  components: {
    shippingMethod,
    orderCancelPromptModal
  },
  data() {
    var v = this;
    let end = new Date();
    let start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
    return {
      pageParamsStatus: false,
      treeData: [],
      payTimeArr: [start, end],
      clearAble: true,
      upOrDown: true,
      checkAllShip: false,
      indeterminateAll: false,
      workShow: 'list',
      cacheClickVal: 'CT',
      orderSeq: 'DESC',
      tableItemTotal: 0,
      pickingGoodsNo: '', // 拣货单编号
      wareId: v.getWarehouseId(),
      pageParams: {
        merchantCarrierId: null,
        merchantShippingMethodId: null,
        pickingNo: null,
        createStartTime: null,
        createEndTime: null,
        pickingGoodsNo: null,
        pickListStatus: null,
        pickListType: null,
        pickingGoodsStatus: '1',
        pickingGoodsUploadCarrierStatus: null,
        printStatus: null,
        logisticType: null,
        pageNum: 1,
        pageSize: 15,
        ymsWarehouseId: 'null',
      },
      wareList: [],
      pickListStatusList: [
        {value: '1', label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002490')},
        {value: '2', label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005970')},
        {value: '3', label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005971')},
        {value: '4', label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005966')},
        {value: '5', label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005972')},
        {value: '6', label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005973')},
        {value: '7', label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005974')},
        {value: '8', label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001242')}
      ],
      pickListTypeList: [
        {selected: true, value: null, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')},
        {selected: false, value: 'SS', title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000180')},
        {selected: false, value: 'MM', title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000179')},
        {selected: false, value: 'MIX', title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005851')}
      ],
      printStatusList: [
        {selected: true, value: null, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')},
        {selected: false, value: 1, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002528')},
        {selected: false, value: 0, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002527')}
      ],
      sortData: [
        {label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002529'), value: 'CT', checked: true, toogle: true},
        {label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002530'), value: 'WS', checked: false, toogle: true}
      ],
      tableColumns: [],
      tableData: [],
      tableSltData: [],
      pickingStatus: '',
      batchPrintData: [],
      pickingGoodsNos: [],
      warehouseList: [],
    };
  },
  created() {
    this.initFun();
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
  },
  methods: {
    // 初始化接口数据
    initFun() {
      let v = this;
      v.warehouseList = [];
      (async () => {
        await v.getBelongingWarehouseData(v.userInfo.merchantId).then((data) => {
          if (data && data.length > 0) {
            v.warehouseList = data;
            let list = data.filter((item) => {
              return item.isFirst === 1
            });
            v.pageParams.ymsWarehouseId = list.length > 0 ? list[0].ymsWarehouseId : 'null';
            v.warehouseList.unshift({
              ymsWarehouseId: 'null',
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')
            })
          }
        });
        await v.getAllShipMethod();
        await v.searchFn();
      })();
    },
    // 处理列表字段
    handleColumns() {
      let v = this;
      v.optionBtnWidth = [];
      let columns = [
        {
          type: 'selection',
          width: 60,
          key: 'selection',
          fixed: 'left',
          align: 'center'
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002534'),
          key: 'pickingGoodsNo',
          align: 'center',
          fixed: 'left',
          minWidth: 140,
          render: (h, params) => {
            return h('Alink', {
              props: {
                linkName: params.row.pickingGoodsNo,
                AlinkShow: v.getPermission('wmsPickingGoods_detail')
              },
              on: {
                AlinkBtn: () => {
                  v.pickingGoodsNo = params.row.pickingGoodsNo;
                  v.pickingStatus = params.row.packageGoodsStatus;
                  v.$router.push({
                    path: '/wms/pickListDetails',
                    query: {
                      pickingGoodsNo: v.pickingGoodsNo,
                      pickingStatus: v.pickingStatus,
                      where: 'sell'
                    }
                  });
                }
              }
            });
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002535'),
          key: 'type',
          minWidth: 130,
          align: 'center'
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002981'),
          align: 'center',
          minWidth: 120,
          key: 'labelType',
          render: (h, params) => {
            let text = '';
            let status = params.row.labelType;
            v.labelTypeList.map((item) => {
              if (item.value === status) {
                if (item.value === 'null') {
                  text = '-';
                } else {
                  text = item.title;
                }
              }
            });
            return h('span', text);
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002536'),
          key: 'status',
          align: 'center',
          width: 100,
          render(h, params) {
            /*
             * packStatus：“2” 正在包装 不考虑 packageGoodsStatus 显示正在包装
             packStatus：“1” 已经包装 不考虑 packageGoodsStatus 显示已包装
             packStatus：“0” 等待包装 显示packageGoodsStatus 的状态
             packageGoodsStatus
             1未拣货，2部分拣货，3等待分拣，4正在分拣 */
            let str = params.row.printType === '0' ? alias93cb5d9a0adb49f980c05a290e356da0.t('key1000596') : params.row.printType === '1' ? alias93cb5d9a0adb49f980c05a290e356da0.t('key1005975') : alias93cb5d9a0adb49f980c05a290e356da0.t('key1005976');
            if (params.row.pickingGoodsStatus === '6' || params.row.pickingGoodsStatus === '7') {
              return h('div', {}, [h('p', params.row.status), h('p', str)]);
            } else {
              return h('div', params.row.status);
            }
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002537'),
          key: 'printStatus',
          align: 'center',
          width: 100,
          render: (h, params) => {
            return h('div', params.row.printStatus === '0' ? alias93cb5d9a0adb49f980c05a290e356da0.t('key1002527') : alias93cb5d9a0adb49f980c05a290e356da0.t('key1002528'));
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000199'),
          key: 'shippingMethodResults',
          width: 220,
          align: 'center',
          render(h, params) {
            let shippingMethodResults = params.row.shippingMethodResults;
            if (shippingMethodResults && shippingMethodResults.length > 0) {
              if (shippingMethodResults.length > 3) {
                shippingMethodResults = shippingMethodResults.splice(0, 3, {
                  carrierName: '...'
                });
              }
              return h('div', {}, params.row.shippingMethodResults.map(i => {
                let text = '';
                if (i.shippingMethodName) {
                  text = i.carrierName + '>' + i.shippingMethodName;
                } else {
                  text = i.carrierName;
                }
                return h('p', text);
              }));
            }
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005977'),
          key: 'remark',
          width: 180,
          align: 'center',
          render(h, params) {
            return v.checkContentOverflow(h, params.row.remark, 180, 2);
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000253'),
          key: 'warehouseName',
          minWidth: 130,
          align: 'center'
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1003705'),
          key: 'pickingNumber',
          align: 'center',
          minWidth: 110
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002538'),
          key: 'goodsSkuNumber',
          align: 'center',
          minWidth: 110,
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002539'),
          key: 'goodsQuantityNumber',
          align: 'center',
          minWidth: 110,
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002540'),
          key: 'finishTime',
          sortable: true,
          width: 160,
          align: 'center'
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001599'),
          key: 'createdByName',
          width: 140,
          align: 'center'
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001600'),
          key: 'createdTime',
          sortable: true,
          width: 160,
          align: 'center'
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000279'),
          key: 'options',
          fixed: 'right',
          width: 210,
          align: 'center',
          render: (h, params) => {
            let pickingGoodsStatus = params.row.pickingGoodsStatus;
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002476'),
                    show: v.getPermission('wmsPickingGoods_print'),
                    clickFn: () => {
                      let goto = v.$router.resolve({
                        path: '/printPickList',
                        query: {
                          data: params.row.pickingGoodsNo,
                          type: 'pickList',
                          where: 'sell'
                        }
                      });
                      window.open(goto.href, '_blank');
                    }
                  },
                  {
                    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000595'),
                    show: v.getPermission('wmsPickingGoods_exportSkuNumber'),
                    clickFn: () => {
                      v.exportSkuNumber(params.row.pickingGoodsId);
                    }
                  },
                  {
                    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002526'),
                    show: v.getPermission('wmsPickingGoods_modifyToPicking'),
                    clickFn: () => {
                      v.getSupplierWarehouseSetting().then((val) => {
                        if (val) {
                          v.markHasPicked([params.row.pickingGoodsNo]);
                        }
                      })
                    }
                  },
                  {
                    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000098'),
                    show: !['7', '8'].includes(pickingGoodsStatus) && v.getPermission('wmsPickingGoods_remove'),
                    clickFn: () => {
                      v.isDelModal(() => {
                        v.axios.post(api.post_wmsPickingGoods_remove + '?pickingGoodsNo=' +
                          params.row.pickingGoodsNo + '&packageGoodsStatus=' + params.row.packageGoodsStatus).then(res => {
                          if (res.data.code === 0) {
                            v.$Message.success(alias93cb5d9a0adb49f980c05a290e356da0.t('key1000331'));
                            v.pageParamsStatus = true;
                          }
                        });
                      }, null, alias93cb5d9a0adb49f980c05a290e356da0.t('key1005978'));
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    v.optionBtnWidth.push(value);
                    params.column.width = Math.max(...v.optionBtnWidth);
                    v.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ];
      if (v.pageParams.pickingGoodsStatus === '8') {
        v.tableColumns = columns.filter((item) => {
          if (item.key !== 'options') {
            return item;
          }
        })
      } else {
        v.tableColumns = columns;
      }
    },
    resetDate() {
      let v = this;
      v.pageParams.createStartTime = null;
      v.pageParams.createEndTime = null;
    },
    // 获取日期返回值
    getDateValue(value) {
      let v = this;
      if (value.length === 0 || !value[0]) {
        v.resetDate();
      } else {
        if (value[0] === null || value[1] === null) return false;
        let timeList = v.defaultTimePeriod(value);
        v.payTimeArr = v.defaultTimePeriod(value,false);
        v.pageParams.createStartTime = v.$uDate.getUniversalTime(new Date(timeList[0]).getTime(), 'fulltime');
        v.pageParams.createEndTime = v.$uDate.getUniversalTime(new Date(timeList[1]).getTime(), 'fulltime');
      }
    },
    // 选中的邮寄方式
    selectChange(data) {
      if (data[0]._ISALL) {
        this.pageParams.merchantCarrierId = null;
        this.pageParams.merchantShippingMethodId = null;
        this.searchFn();
        return;
      }
      if (data[0].shippingMethodId) {
        this.pageParams.merchantCarrierId = data[0].carrierId;
        this.pageParams.merchantShippingMethodId = data[0].shippingMethodId;
        this.searchFn();
      }
    },
    getAllShipMethod() {
      let v = this;
      let obj = this.paramsFn();
      delete obj.pageSize;
      delete obj.pageNum;
      v.axios.post(api.post_wmsPickingGoods_queryCarriersForPickingGoodsList, obj).then(res => {
        let newData = res.data.datas;
        if (res.data.code === 0 && newData && newData.length > 0) {
          newData.map(i => {
            i.title = i.carrierName;
            i.expand = true;
            if (i.shippingMethods) {
              i.children = i.shippingMethods.map(j => {
                j.carrierId = i.carrierId;
                j.title = j.shippingMethodName;
                return j;
              });
            }
            return i;
          });
          v.treeData = [
            {
              title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096'),
              expand: true,
              _ISALL: true,
              children: newData.filter(item => item.title !== null && item.title !== '')
            }
          ];
        } else {
          v.treeData = [];
        }
      });
    },
    // 已复核 默认按照创建时间最近一个月内的范围查询
    statusChange(item) {
      if (item.value === '7') {
        let end = new Date();
        let start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        let timer = [this.$uDate.getDateTime(start, 'fulltime'), this.$uDate.getDateTime(end, 'fulltime')];
        this.getDateValue(timer);
      } else {
        this.resetDate();
      }
    },
    // 选取拣货单类型
    typeChange(index, value) {
      let v = this;
      v.pageParams.pickListType = value;
      v.pickListTypeList.map((item, idx) => {
        item.selected = index === idx;
      });
    },
    // 选取打印状态
    printStatusChange(index, value) {
      let v = this;
      v.pageParams.printStatus = value;
      v.printStatusList.map((item, idx) => {
        item.selected = index === idx;
      });
    },
    // 排序按钮
    clickSortButton(data) {
      if (data.value === this.cacheClickVal) {
        data.toogle = !data.toogle;
      }
      this.cacheClickVal = data.value;
      if (data.toogle) {
        this.orderSeq = 'DESC'; // 降
      } else {
        this.orderSeq = 'ASC';
      }
      this.sortData.forEach(function (n) {
        n.checked = data.value === n.value;
      });
      this.searchFn();
    },
    // 查询按钮
    search() {
      if (this.pageParams.pickingGoodsStatus === '7') { // 已包装
        if (this.pageParams.createStartTime === null || this.pageParams.createEndTime === null) {
          this.$Message.warning({
            content: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002531'),
            duration: 4
          });
          return false;
        }
      }
      this.pageParams.pageNum = 1;
      this.searchFn();
      this.getAllShipMethod();
    },
    // 处理参数
    paramsFn() {
      return {
        warehouseId: this.wareId,
        merchantCarrierId: this.pageParams.merchantCarrierId,
        merchantShippingMethodId: this.pageParams.merchantShippingMethodId,
        packageGoodsType: this.pageParams.pickListType,
        pickingGoodsNo: this.pageParams.pickingGoodsNo === '' ? null : this.pageParams.pickingGoodsNo,
        pageNum: this.pageParams.pageNum,
        pageSize: this.pageParams.pageSize,
        orderSeq: this.orderSeq,
        pickingNo: this.pageParams.pickingNo,
        orderBy: this.cacheClickVal,
        pickingGoodsStatus: this.pageParams.pickingGoodsStatus,
        pickingGoodsUploadCarrierStatus: this.pageParams.pickingGoodsUploadCarrierStatus,
        printStatus: this.pageParams.printStatus,
        createStartTime: this.pageParams.createStartTime,
        createEndTime: this.pageParams.createEndTime,
        pickingType: 'S1',
        ymsWarehouseId: this.pageParams.ymsWarehouseId === 'null' ? null : this.pageParams.ymsWarehouseId
      };
    },
    // 查询函数
    searchFn() {
      this.tableData = [];
      this.tableItemTotal = 0;
      if (this.getPermission('wmsPickingGoods_list')) {
        let obj = this.paramsFn();
        this.tableLoading = true;
        this.axios.post(api.post_wmsPickingGoods_list, obj).then(res => {
          this.handleColumns();
          if (res.data.code === 0) {
            let data = res.data.datas;
            if (data) {
              let list = data.list || [];
              this.tableData = this.processResData(list);
              this.tableItemTotal = data.total;
              this.updateTableDom('selection');
            }
          } else {
            this.tableLoading = false;
          }
        });
      } else {
        this.gotoError(); // 无权限
      }
    },
    processResData(data) {
      // 中文匹配
      let v = this;
      /*
       * packStatus：“2” 正在包装 不考虑 package_goods_status 显示正在包装
       packStatus：“1” 已经包装 不考虑 package_goods_status 显示已包装
       packStatus：“0” 等待包装 显示package_goods_status 的状态
       package_goods_status
       1未拣货，2部分拣货，3等待分拣，4正在分拣
       * */

      data.forEach(val => {
        let obj = v.pickListTypeList.filter(i => i.value === val.packageGoodsType);
        val.type = obj ? obj[0].title : '';
        if (val.packStatus === '2') {
          val.pickingGoodsStatus = '6';
        }
        if (val.packStatus === '1') {
          val.pickingGoodsStatus = '7';
        }
        v.pickListStatusList.map((item) => {
          if (item.value === val.pickingGoodsStatus) {
            val.status = item.title;
          }
        })
        val.finishTime = val.finishTime ? this.$uDate.dealTime(val.finishTime) : '';
        val.createdTime = val.createdTime ? this.$uDate.dealTime(val.createdTime) : '';
      });
      return data;
    },
    // 标记为已发货 (标记之前要校验出库单中是否存在有已取消的订单，如果存在取消的订单则弹出取消订单提示窗)
    markHasPicked(params) {
      supplierVerifyPicking(api.put_wmsPickingGoods_checkPickingGoods, params).then((data) => {
        if (data.length > 0) {
          this.$refs['orderCancelPrompt'].basicData = data;
          this.$refs['orderCancelPrompt'].orderCancelPromptModal = true;
        } else {
          this.axios.post(api.post_wmsPickingGoods_modifyToPicking, {pickingGoodsNos: params},
            {loading: true, loadingText: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000213')}).then(res => {
            if (res.data.code === 0) {
              this.$Message.success(alias93cb5d9a0adb49f980c05a290e356da0.t('key1002532'));
              this.searchFn();
            }
          });
        }
      });
    },
    // 勾选列表数据
    tableChange(data) {
      this.tableSltData = data;
    },
    // 批量操作
    batchOption(val) {
      let v = this;
      if (this.tableSltData.length === 0) {
        this.$Message.warning({
          content: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002533'),
          duration: 3
        });
        return;
      }
      if (val === '1') {
        v.getSupplierWarehouseSetting().then((val) => {
          if (val) {
            let arr = this.tableSltData.map(val => {
              return val.pickingGoodsNo;
            });
            v.markHasPicked(arr);
          }
        })
      } else {
        // 批量打印拣货单
        let val = [];
        if (v.tableSltData.length > 0) {
          v.tableSltData.forEach((n, i) => {
            val.push(n.pickingGoodsNo);
          });
        }
        v.pickingGoodsNos = val;
        let goto = this.$router.resolve({
          path: '/printPickListBatch',
          query: {
            data: v.pickingGoodsNos.join(','),
            type: 'pickList',
            where: 'sell'
          }
        });
        window.open(goto.href, '_blank');
      }
    },
    // 展开与收起邮寄方式
    switchBtn() {
      this.upOrDown = !this.upOrDown;
    },
    switchOption(val) {
      this.upOrDown = val;
    },
    // 重置按钮
    reset() {
      let end = new Date();
      let start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      this.payTimeArr = [start, end];
      this.typeChange(0, null);
      this.printStatusChange(0, null);
      this.statusChange('1');
      this.$refs['pageParams'].resetFields();
      this.pageParams.pickingGoodsStatus = '1';
      this.search();
    },
    // 导出单个sku
    exportSkuNumber(pickingGoodsId) {
      this.axios.get(api.get_wmsPickingGoods_exportSkuNumber + '?pickingGoodsId=' + pickingGoodsId).then(res => {
        if (res.data.code === 0) {
          if (res.data.datas) {
            this.$Message.success(alias93cb5d9a0adb49f980c05a290e356da0.t('key1005979'));
            window.open(this.$store.state.erpConfig.filenodeViewTargetUrl + res.data.datas);
          }
        }
      });
    }
  },
  watch: {
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        v.searchFn();
        v.pageParamsStatus = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.shipping_method {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-around;

  .left_box {
    position: relative;

    .option_btn {
      position: absolute;
      top: 0;
      left: 0;
      height: 56px;
      background-color: #2b85e4;
      color: #fff;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .right_box {
    min-width: 830px;
  }
}
</style>
