<template>
  <Card class="tree_box_style" ref="tree_box_dom" dis-hover>
    <div class="all_style">
      <Input :placeholder="$t('key1000295')" clearable v-model.trim="treeKeywords" @on-change="changeValue" @on-enter="enterChange"></Input>
    </div>
    <template v-if="isAlibabaSite">
      <Tree
        :load-data="loadCategoryData"
        :class="customStyle ? 'distributor_tree_styles' : 'tree_styles'"
        ref="treeDataTalg"
        :data="treeData"
        @on-select-change="selectCategory">
      </Tree>
    </template>
    <template v-else>
      <Tree
        :class="customStyle ? 'distributor_tree_styles' : 'tree_styles'"
        ref="treeDataTalg"
        :data="treeData"
        @on-select-change="selectCategory">
      </Tree>
    </template>
  </Card>
</template>

<script>
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';
import {commonSessionStorage, getYmsSiteData, isAlibabaDistributor} from "@/utils/common";

export default {
  name: "commonTree",
  mixins: [Mixin],
  props: {
    // 是否自定义样式类名
    customStyle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      treeKeywords: '',
      originalData: [],
      treeData: [
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000296'),
          expand: true,
          ymsProductCategoryId: -1,
          render(h) {
            return h('div', {
              style: {
                width: '170px',
              },
              class: 'title_styles',
              on: {
                click: () => {

                }
              }
            }, alias93cb5d9a0adb49f980c05a290e356da0.t('key1000296'));
          },
          selected: true,
          children: []
        },
      ],
      nameToObject: {},
      timer: null,
    }
  },
  computed: {
    // 判断是否是阿里国际分销站点
    isAlibabaSite() {
      let obj = commonSessionStorage.getItem('erpConfig');
      let merchantType = obj ? obj.ymsSite ? obj.ymsSite.merchantType : null : null;
      return merchantType === 'ALIGJ'
    }
  },
  methods: {
    // 搜索分类关键词
    changeValue() {
      let v = this;
      if (v.treeKeywords) {
        if (!isAlibabaDistributor()) {
          let data = v.deepCopy(v.originalData).filter(i => i.name.includes(v.treeKeywords));
          v.processSearchClassificationData(data);
        }
      } else {
        clearTimeout(v.timer);
        v.setTreeData(v.deepCopy(v.originalData), null);
      }
    },
    // 阿里国际分销回车键搜索分类
    enterChange() {
      let v = this;
      if (isAlibabaDistributor()) {
        if (v.treeKeywords) {
          let obj = commonSessionStorage.getItem('erpConfig');
          let ymsSiteId = obj ? obj.ymsSite ? obj.ymsSite.ymsSiteId : null : null;
          let query = {
            name: v.treeKeywords,
            ymsSiteId: ymsSiteId
          }
          v.axios.post(api.post_ymsProductCategory_api_queryCategorysByName, query, {loading: true, loadingText: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000213')}).then((response) => {
            if (response.data.code === 0) {
              let data = response.data.datas || [];
              v.processSearchClassificationData(data);
            }
          });
        } else {
          clearTimeout(v.timer);
          v.setTreeData(v.deepCopy(v.originalData), null, 'aligjQuery');
        }
      }
    },
    // 处理搜索分类的数据
    processSearchClassificationData(data) {
      let v = this;
      if (data.length === 0) {
        v.treeData = [];
        return;
      }
      data.forEach(i => {
        let productCategoryNavigation = i.productCategoryNavigation.split('->');
        productCategoryNavigation.splice(-1, 1);
        if (productCategoryNavigation.length > 0) {
          // 拿到相关父级
          let hasNav = productCategoryNavigation.reduce((a, b) => {
            a.push(b);
            return a;
          }, []);
          v.originalData.forEach(k => {
            if (i.parentCode === k.productCategoryCode) {
              data.push(k);
            }
            hasNav.forEach(p => {
              if (k.name === p) {
                // 去重
                if (data.every(h => h.name !== p)) {
                  data.push(k);
                }
              }
            });
          });
        }
      });
      let newData = v.uniqueFunc(data, 'ymsProductCategoryId');
      if (newData.length > 0) {
        v.timer = setTimeout(() => {
          for (let i = 0; i < newData.length; i++) {
            let item = newData[i];
            let reg = new RegExp(v.treeKeywords, 'g');
            if (reg.test(item.name && v.treeKeywords)) {
              if (v.system === 'distributor') {
                item.name = item.name.replace(reg, '<span class="distributor_keywords_highlight">$&</span>');
              } else {
                item.name = item.name.replace(reg, '<span class="keywords_highlight">$&</span>');
              }
            }
          }
          v.setTreeData(v.deepCopy(newData), null);
        }, 500);
      }
    },
    // 获取所有分类的数据
    getClassificationData(value) {
      let v = this;
      if (value) {
        let query = {
          servicePlatform: v.system === 'yms' ? 'yms-core-service' :
            v.system === 'supplier' ? 'yms-supplier-service' :
              v.system === 'distributor' ? 'yms-distributor-service' : ''
        };
        v.treeData = [];
        v.originalData = [];
        v.treeKeywords = '';
        let url = '';
        let expandTalg = true;
        let obj = commonSessionStorage.getItem('erpConfig');
        let ymsSiteId = obj ? obj.ymsSite ? obj.ymsSite.ymsSiteId : null : null;
        if (v.system === 'yms') {
          url = api.post_ymsProductCategory_queryAll;
          getYmsSiteData().then((list) => {
            let newList = list.filter((item) => {
              return item.merchantType === 'YMS'
            });
            if (newList.length > 0) {
              ymsSiteId = newList[0].ymsSiteId;
              handleData(ymsSiteId);
            }
          })
        } else {
          url = api.post_ymsProductCategory_api_queryAll;
          // 是否是阿里国际分销商
          if (isAlibabaDistributor()) {
            expandTalg = false;
            query.type = 'firstLevel';
          }
          handleData(ymsSiteId)
        }

        function handleData(ymsSiteId) {
          v.getCloudTreeData(url, 'original', expandTalg, ymsSiteId, query).then((data) => {
            if (data && data.length > 0) {
              v.treeKeywords = '';
              v.originalData = v.deepCopy(JSON.parse(JSON.stringify(data)));
              v.setTreeData(data, null);
            }
          });
        }
      }
    },
    // 选中分类
    selectCategory(data) {
      this.$emit('selectCategory', data);
      this.$forceUpdate();
    },
    // 全部分类
    setTreeHead() {
      this.treeData = [
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000296'),
          expand: true,
          ymsProductCategoryId: -1,
          render(h) {
            return h('div', {
              style: {
                width: '170px',
              },
              class: 'title_styles'
            }, alias93cb5d9a0adb49f980c05a290e356da0.t('key1000296'));
          },
          selected: true,
          children: []
        }
      ];
    },
    // 组装树状数据
    setTreeData(data, selectedProductCategory, type) {
      let self = this;
      if (data) {
        data.forEach((item) => {
          self.$set(item, 'children', []);
          self.$set(item, 'selected', false);
          if (isAlibabaDistributor() && type !== 'aligjQuery') {
            self.$set(item, 'loading', false);
          }
        });
        data = data.sort(self.sortCategory);
        self.setTreeHead();
        let list = self.toTree(data, 'productCategoryNavigation', '->', selectedProductCategory);
        self.$set(self.treeData[0], 'children', list);
        self.treeData[0].selected = true;
        self.treeData[0].ymsProductCategoryId = -1;
        self.$forceUpdate();
      }
    },
    toTree(arr, key, Identification, selectedProductCategory) {
      let newArr = [];
      let v = this;
      v.nameToObject = {};
      arr.forEach(i => {
        i.selected = false;
        if (!isAlibabaDistributor()) {
          let matchName; // 最后一个name
          let name = i[key]; // 用来判断的值 =>分割
          if (name.lastIndexOf(Identification) > -1) {
            matchName = name.slice(0, name.lastIndexOf(Identification));
          } else {
            matchName = name;
            newArr.push(i);
          }
          if (!v.nameToObject[matchName]) {
            v.$set(v.nameToObject, matchName, {children: []});
          }
          v.nameToObject[matchName].children.push(i);
          v.$set(v.nameToObject, name, i);
          v.nameToObject[name].children = [];

          if (selectedProductCategory) {
            if (selectedProductCategory.ymsProductCategoryId === i.ymsProductCategoryId) {
              i.selected = true;
            }
          }
        } else {
          newArr.push(i);
        }
        i.render = (h, {root, node, data}) => {
          return h('div', {
            domProps: {
              innerHTML: data.name
            },
            attrs: {
              title: data.productCategoryNavigation,
            },
            class: 'title_styles'
          }, data.name);
        };
      });
      let newData = [];
      if (isAlibabaDistributor()) {
        newData = v.treeChangeExpand(newArr, v.treeKeywords ? true : false);
      } else {
        newData = v.treeChangeExpand(newArr, true);
      }
      return newData;
    },
    // 递归给树设置expand
    treeChangeExpand(treeData, flag) {
      let v = this;

      function handleData(treeData, flag) {
        for (let i = 0; i < treeData.length; i++) {
          v.$set(treeData[i], 'expand', flag);
          if (treeData[i].children) {
            treeData[i].children = v.treeChangeExpand(treeData[i].children, flag);
          }
        }
        return treeData;
      }

      if (isAlibabaDistributor()) {
        if (flag) {
          for (let i = 0; i < treeData.length; i++) {
            if (treeData[i].ymsProductCategoryId === '-1') {
              v.$set(treeData[i], 'expand', flag);
            } else {
              v.$set(treeData[i], 'expand', false);
            }
            if (treeData[i].children) {
              treeData[i].children = v.treeChangeExpand(treeData[i].children, flag);
            }
          }
          return treeData;
        } else {
          return handleData(treeData, flag);
        }
      } else {
        return handleData(treeData, flag);
      }
    },
    // 异步请求阿里国际分销的子类目数据
    loadCategoryData(item, callback) {
      let v = this;
      let ymsProductCategoryId = item.ymsProductCategoryId;
      if (ymsProductCategoryId) {
        v.axios.get(api.get_ymsProductCategory_api_querySubCategorysById + ymsProductCategoryId).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data.length > 0) {
              data.map((ele) => {
                ele.loading = false;
                v.$set(ele, 'children', []);
              });
              let newList = v.toTree(data, 'productCategoryNavigation', '->', null);
              callback(newList);
              v.$forceUpdate();
            } else {
              item.loading = false;
              delete item.loading;
            }
          }
        });
      } else {
        console.log('=======当前类目无分类id======');
      }
    }
  }
}
</script>

<style lang="less" scoped>
.tree_box_style {
  width: 21%;
  max-height: 624px;
  overflow: hidden;
  overflow-y: auto;

  /deep/ .ivu-card-body {
    padding: 0 !important;
  }

  /deep/ .ivu-tree {
    padding: 2px 8px 10px 8px;

    ul > li {
      margin: 4px 0;
    }

    .ivu-tree-arrow {
      position: relative;
      top: 4px;
    }

    .title_styles {
      max-width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: break-word;
      word-break: break-all;
      padding: 4px 0;
    }
  }

  // 运营系统、供应商系统侧边栏分类的选中样式
  /deep/ .tree_styles {
    .keywords_highlight {
      display: inline-block;
      background-color: #d5e8fc;
    }

    .ivu-tree-title {
      width: 90%;

      &:hover {
        background-color: #d5e8fc;
      }
    }

    .ivu-tree-title-selected {
      color: #2D8CF0;
      background-color: #d5e8fc;
    }
  }

  // 分销商系统侧边栏分类的选中样式
  /deep/ .distributor_tree_styles {
    .distributor_keywords_highlight {
      display: inline-block;
      background-color: #FFDDD3;
    }

    .ivu-tree-title {
      width: 90%;

      &:hover {
        background-color: rgba(255, 83, 16, 0.0588235294117647) !important;
      }
    }

    .ivu-tree-title-selected {
      color: #FF5310;
      background-color: rgba(255, 83, 16, 0.0588235294117647) !important;
    }
  }

  .all_style {
    background-color: #fff;
    position: sticky;
    left: 0;
    top: 0;
    height: 45px;
    line-height: 45px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.08);
    padding: 0 15px;
    z-index: 100;
  }
}

/deep/ .keywords_highlight {
  display: inline-block;
  background-color: #d5e8fc;
}

/deep/ .distributor_keywords_highlight {
  display: inline-block;
  background-color: #FFDDD3;
}
</style>