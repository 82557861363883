import Vue from 'vue';
import VueI18n from 'vue-i18n';
import local1961e0eda26544c1b6a02519d040c865 from './lang/en-US';
import local561894127f53449ca3529030b7d2eb51 from './lang/ko-KR';
import local789b8e5fe0f943d1bc4b6e0dc479e824 from './lang/zh-CN';

Vue.use(VueI18n);
function handleSystemLanguage() {
  let language = '';
  let defaultLanguage = window.navigator.language;
  if (defaultLanguage) {
    if (['zh', 'zh-CN'].includes(defaultLanguage)) {
      language = 'zh-CN';
    } else if (['en', 'en-US'].includes(defaultLanguage)) {
      language = 'en-US';
    } else {
      language = 'zh-CN';
    }
    return language;
  }
}
let lang = localStorage.getItem('lang')　|| handleSystemLanguage() || 'zh-CN';
localStorage.setItem('lang', lang);
Vue.config.lang = lang;

var localeMap = {"en-US":"English","ko-KR":"한국어","zh-CN":"简体中文"}
const messages = {'en-US':{...local1961e0eda26544c1b6a02519d040c865},'ko-KR':{...local561894127f53449ca3529030b7d2eb51},'zh-CN':{...local789b8e5fe0f943d1bc4b6e0dc479e824}};
export const i18n = new VueI18n({
				  locale: lang,
				  messages
				});
