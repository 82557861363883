<template>
  <Modal v-model="modal1" width="1200" :title="$t('key1000077')" @on-visible-change="modalChange">
    <Steps :current="current" class="getting">
      <Step :title="$t('key1001112')"></Step>
      <Step :title="$t('key1001113')"></Step>
      <Step :title="$t('key1001114')"></Step>
      <Step :title="$t('key1001115')"></Step>
      <Step :title="$t('key1001116')"></Step>
      <Step :title="$t('key1001117')"></Step>
      <Step :title="$t('key1001118')"></Step>
    </Steps>
    <div class="pt30 getting-content" ref="gettingContent">
      <div ref="gettingContentItem_0">
        <h4 class="title">
          <span class="text">1. {{ $t('key1001112') }}</span>
          <Button size="small" type="primary" class="btn" @click="gotoPage('/yms-shopping-service/login.html#/register')">{{ $t('key1001119') }}</Button>
        </h4>
        <p class="desc">{{ $t('key1001120') }}</p>
        <img src="../../assets/images/help/01.svg" width="100%" alt=""/>
      </div>
      <div ref="gettingContentItem_1">
        <h4 class="title">
          <span class="text">2. {{ $t('key1001113') }}</span>
          <Button size="small" type="primary" class="btn" @click="gotoPage('/yms-shopping-service/index.html#/index')">{{ $t('key1001121') }}</Button>
        </h4>
        <p class="desc">① {{ $t('key1001122') }}</p>
        <p class="desc">② {{ $t('key1001123') }}</p>
        <p class="desc">③ {{ $t('key1001124') }}</p>
        <img src="../../assets/images/help/02.svg" width="100%" alt=""/>
      </div>
      <div ref="gettingContentItem_2">
        <h4 class="title">
          <span class="text">3. {{ $t('key1001114') }}</span>
          <Button size="small" type="primary" class="btn" @click="gotoPage('/yms-shopping-service/index.html#/shopList?type=all')">{{ $t('key1001125') }}</Button>
        </h4>
        <p class="desc">① {{ $t('key1001126') }}</p>
        <img src="../../assets/images/help/03.svg" width="100%" alt=""/>
        <p class="desc">② {{ $t('key1001127') }}</p>
        <img src="../../assets/images/help/04.svg" width="100%" alt=""/>
      </div>
      <div ref="gettingContentItem_3">
        <h4 class="title">
          <span class="text">{{ $t('key1001128') }}</span>
          <!--<Button size="small" type="primary" class="btn"  @click="gotoPage('http://www.tongtool.com/')">去刊登</Button>--></h4>
        <p class="desc">① {{ $t('key1001129') }}</p>
        <p class="desc">{{ $t('key1001130') }}</p>
        <img src="../../assets/images/help/05.svg" width="100%" alt=""/>
        <p class="desc">② {{ $t('key1001131') }}</p>
        <p class="desc">{{ $t('key1001132') }}</p>
        <img src="../../assets/images/help/06.svg" width="100%" alt=""/>
        <img src="../../assets/images/help/07.svg" width="100%" alt=""/>
        <img src="../../assets/images/help/08.svg" width="100%" alt=""/>
      </div>
      <div ref="gettingContentItem_4">
        <h4 class="title">
          <span class="text">5. {{ $t('key1001116') }}</span>
        </h4>
        <p class="desc">5.1 {{ $t('key1001133') }} <!--<Button size="small" type="primary" class="btn ml10"  @click="gotoPage('http://www.tongtool.com/')">去设置</Button>--></p>
        <p class="desc">① {{ $t('key1001134') }}</p>
        <p class="desc">{{ $t('key1001135') }}</p>
        <img src="../../assets/images/help/09.svg" width="100%" alt=""/>
        <img src="../../assets/images/help/10.svg" width="100%" alt=""/>
        <p class="desc">② {{ $t('key1001136') }}</p>
        <p class="desc">{{ $t('key1001137') }}</p>
        <img src="../../assets/images/help/11.svg" width="100%" alt=""/>
        <p class="desc">③ {{ $t('key1001138') }}</p>
        <p class="desc">{{ $t('key1001139') }}</p>
        <img src="../../assets/images/help/12.svg" width="100%" alt=""/>
        <img src="../../assets/images/help/13.svg" width="100%" alt=""/>
        <p class="desc">④ {{ $t('key1001140') }}</p>
        <p class="desc">{{ $t('key1001141') }}</p>
        <img src="../../assets/images/help/14.svg" width="100%" alt=""/>
        <img src="../../assets/images/help/15.svg" width="100%" alt=""/>
        <p class="desc">5.2 {{ $t('key1001142') }}</p>
        <p class="desc">{{ $t('key1001143') }}</p>
        <img src="../../assets/images/help/16.svg" width="100%" alt=""/>
        <p class="desc">5.3 {{ $t('key1001116') }}</p>
        <p class="desc">{{ $t('key1001144') }}</p>
        <img src="../../assets/images/help/17.svg" width="100%" alt=""/>
      </div>
      <div ref="gettingContentItem_5">
        <h4 class="title">
          <span class="text">6. {{ $t('key1001117') }}</span>
          <Button size="small" type="primary" class="btn" @click="gotoPage('/yms-distributor-service/distributor.html#/myOrder')">{{ $t('key1001145') }}</Button>
        </h4>
        <p class="desc">{{ $t('key1001146') }}</p>
        <img src="../../assets/images/help/18.svg" width="100%" alt=""/>
        <img src="../../assets/images/help/19.svg" width="100%" alt=""/>
      </div>
      <div ref="gettingContentItem_6">
        <h4 class="title">
          <span class="text">7. {{ $t('key1001118') }}</span>
          <Button size="small" type="primary" class="btn" @click="gotoPage('/yms-distributor-service/distributor.html#/rechargeRecord')">{{ $t('key1001145') }}</Button>
        </h4>
        <p class="desc">{{ $t('key1001147') }}</p>
        <img src="../../assets/images/help/20.svg" width="100%" alt=""/>
      </div>
    </div>
    <template #footer>
      <Button @click="close">{{ $t('key1000854') }}</Button>
      <Button type="primary" @click="modal1= false">{{ $t('key1000610') }}</Button>
    </template>
  </Modal>
</template>

<script>
import {Button, Steps, Step} from 'view-design';

export default {
  name: 'gettingStarted',
  data() {
    return {
      modal1: false,
      current: 0,
      scrollTop: 0
    };
  },
  mounted() {
    let v = this;
    v.$nextTick(() => {
      v.$refs['gettingContent'].addEventListener('scroll', v.handleScroll);
    });
  },
  props: {},
  components: {
    Button,
    Steps,
    Step
  },
  methods: {
    close() {
      localStorage.setItem('noFirstEntry', '1');
      this.modal1 = false;
    },
    gotoPage(url) {
      window.open(url, '_blank');
    },
    open() {
      this.modal1 = true;
    },
    // 监听弹窗
    modalChange(value) {
      if (!value) {
        this.$refs['gettingContent'].scrollTop = 0;
        this.current = 0;
      }
    },
    handleScroll() {
      let v = this;
      let dom = v.$refs['gettingContent'];
      v.scrollTop = dom.scrollTop || dom.documentElement.scrollTop || dom.body.scrollTop;
      let heightList = [];
      for (let i = 0; i < 7; i++) {
        let height = v.$refs['gettingContentItem_' + i].offsetHeight;
        heightList.push(height);
      }

      function sumArr(arr) {
        return arr.reduce(function (prev, cur) {
          return prev + cur;
        }, 0);
      }

      let scrollTop = dom.scrollTop;
      let scrollHeight = dom.scrollHeight;
      let offsetHeight = dom.offsetHeight;
      if (v.scrollTop < sumArr(heightList.slice(0, 1))) {
        v.current = 0;
      } else if (v.scrollTop < sumArr(heightList.slice(0, 2))) {
        v.current = 1;
      } else if (v.scrollTop < sumArr(heightList.slice(0, 3))) {
        v.current = 2;
      } else if (v.scrollTop < sumArr(heightList.slice(0, 4))) {
        v.current = 3;
      } else if (v.scrollTop < sumArr(heightList.slice(0, 5))) {
        v.current = 4;
      } else if (v.scrollTop < sumArr(heightList.slice(0, 6))) {
        v.current = 5;
      }
      // 滚动到底部
      if ((offsetHeight + scrollTop) + 10 >= scrollHeight) {
        v.current = 6;
      }
    }
  }
};
</script>

<style lang="less">
.pt30 {
  padding-top: 30px;
}

.ml10 {
  margin-left: 10px;
}

.getting {
  display: block !important;
  margin-left: 38px;

  .ivu-steps-item {
    width: 156px;
    text-align: center;
    overflow: initial;
  }

  .ivu-steps-main {
    display: block !important;
  }

  .ivu-steps-tail {
    left: 88px;
  }

  .ivu-steps-item.ivu-steps-status-finish .ivu-steps-tail > i:after {
    position: absolute;
    left: 0;
  }
}

.getting-content {
  max-height: 600px;
  overflow-y: auto;

  .title {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    margin-bottom: 8px;

    .text {
      vertical-align: middle;
      display: inline-block;
      border-bottom: 1px solid #666;
    }

    .btn {
      margin-left: 20px;
    }
  }

  .desc {
    color: #333;
    margin-bottom: 2px;
  }
}
</style>
