<template>
  <div class="news_content" :class="newsList.length<1 ? 'hide_dropdown' : ''">
    <div v-if="userInfo" @click="changeSelect">
      <Dropdown placement="bottom-end">
        <Badge :count="limitMaxTotal" :offset="[-1, -10]" class="badge_box">
          <span class="iconfont news_icon">&#xe619;</span>
          <span class="news_box">{{ $t('key1000286') }}</span>
        </Badge>
        <template #list>
          <DropdownMenu v-if="newsList.length>=1">
            <DropdownItem v-for="(item, index) in newsList" :divided="index !==0" :name="item.title" :key="index"
              @click.native.stop="changeSelectItem(item)">
              <div class="notice_item" :title="item.title">
                <span>{{ item.title }}</span>
              </div>
            </DropdownItem>
            <DropdownItem name="all" v-if="newsList.length >=5" style="text-align: center; border-top: 1px solid #e8eaec;"
              @click.native.stop="changeSelectItem">
              {{ $t('key1000287') }}
            </DropdownItem>
          </DropdownMenu>
        </template>
      </Dropdown>
    </div>
    <div class="badge_box" v-else @click="toLogin">
      <span class="iconfont news_icon">&#xe619;</span>
      <span class="news_box">{{ $t('key1000286') }}</span>
    </div>
  </div>

</template>

<script>
import {pageJump} from "@/utils/common";
import api from '@/api/';
import Mixin from "@/components/mixin/common_mixin";

export default {
  name: "newsBox",
  mixins: [Mixin],
  props: {
    openType: {
      type: String,
      default: '_blank' // 默认打开新窗口
    },
    storeTalg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
    // 未读消息数量
    limitMaxTotal() {
      return this.$store.state.unreadTotal;
    },
    // 未读消息数据
    newsList() {
      return this.$store.state.unreadData;
    }
  },
  created() {
    this.receiveData();
  },
  methods: {
    // 接收websocket的数据
    receiveData() {
      let v = this;
      // 只有在登录的情况下，才能查看消息中心的数据
      if (v.userInfo) {
        v.getMessageNotificationData(api.get_ymsDistributorStationNotification_lastUnRead);
        v.$webSocket.socket.onmessage = function (e) {
          v.$webSocket.heartReset().heartStart();
          let receiveData = '';
          if (e) {
            try {
              receiveData = JSON.parse(e.data);
            } catch (e) {
              receiveData = null;
            }
            if (receiveData && receiveData.type === 'notice') {
              v.getMessageNotificationData(api.get_ymsDistributorStationNotification_lastUnRead);
            }
          }
        };
      }
    },
    // 进入消息中心
    changeSelect() {
      let informationType = null;
      if (this.storeTalg) {
        pageJump(`/distributor.html#/newsList?informationType=${informationType}`, '/yms-distributor-service', this.openType);
      } else {
        pageJump(`/distributor.html#/newsList?informationType=${informationType}`, '/yms-distributor-service', this.openType);
      }
    },

    // 点击对应的信息通知类型
    changeSelectItem(data) {
      let informationType = '';
      if (data.informationType) {
        informationType = String(data.informationType);
      } else {
        informationType = null;
      }
      if (this.storeTalg) {
        pageJump(`/distributor.html#/newsList?informationType=${informationType}`, '/yms-distributor-service', this.openType);
      } else {
        pageJump(`/distributor.html#/newsList?informationType=${informationType}`, '/yms-distributor-service', this.openType);
      }
    },

    // 没有登录的情况下，点击消息中心跳转到登录页面
    toLogin() {
      let url = '/login.html#/login';
      let service = '/yms-shopping-service';
      let type = '_self';
      let links = '?u=' + window.location.href;
      pageJump(url, service, type, links);
    },
  }
};
</script>

<style lang="less" scoped>
@import "src/assets/styles/shopping/common";

.news_content {
  /deep/ .ivu-select-dropdown {
    width: 350px;
    max-height: 320px;
    overflow-y: auto;

    .notice_item {
      width: 100%;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal;

      &:before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        top: 0;
        margin: 0 5px 0 0;
        background-color: @subject_color;
      }
    }
  }

  .badge_box {
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: relative;
    margin-left: 50px;

    .news_icon {
      font-size: 20px;
    }

    .news_box {
      margin-left: 8px;
      font-size: 15px;
      color: #333;
    }
  }
}

.hide_dropdown {
  /deep/ .ivu-select-dropdown {
    display: none;
  }
}
</style>
