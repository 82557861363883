
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';
import Vue from 'vue';
import axios from 'axios';
import {LoadingBar, Modal} from 'view-design';
import {removeToken, getToken} from '@/utils/cookie';
import MSG from './message/message/index';
import {
  showNoAuth,
  supplierDeactivationPrompt,
  auditSuppliers,
  becomeDistributor,
  noDomainNameSite
} from '@/utils/asist';
import {showLoadingModal, hideLoadingModal} from '@/utils/loading';
import {publicExceptionCodes} from "@/config/exceptionCode";
import '@/locale/method/locale';
import {pageJump, deleteTicket, commonSessionStorage} from "@/utils/common";
import store from '@/store/store';
import {dynamicErrorCodeFun} from "@/utils/dynamicErrorCode";
import {setConfigCharacters} from "@/utils/common";
import regular from "@/utils/tools/regular";

// axios 配置
let pending = []; // 声明一个数组用于存储每个ajax请求的取消函数和ajax标识
let CancelToken = axios.CancelToken;
let pendingTimer = null;
// 防止多次请求相同接口的处理方法
let removePending = (config, cancel) => {
  return new Promise(resolve => {
    if (config) {
      let params = '';
      clearTimeout(pendingTimer);
      if (config.data) {
        try {
          let obj = JSON.parse(config.data);
          params = JSON.stringify(obj);
        } catch (e) {
          params = JSON.stringify(config.data);
        }
      }
      if (config.url) {
        if (config.url.includes('fresh=KEEPNEW')) {
          for (let p in pending) {
            if (pending[p].u === config.url + '&' + config.method) {
              // 当当前请求在数组中存在时执行函数体
              let c = pending[p].cancel;
              c('cancelHttp');
              pending.splice(p, 1); // 把这条记录从数组中移除
              resolve(false);
              return;
            }
          }
        } else {
          for (let p in pending) {
            if (pending[p].u === config.url + '&' + config.method + '&' + params) {
              // 当当前请求在数组中存在时执行函数体
              cancel && cancel('cancelHttp');
              pending.splice(p, 1); // 把这条记录从数组中移除
              resolve(false);
              return;
            }
          }
        }
      }

      pendingTimer = setTimeout(() => {
        pending = [];
      }, 5000);
      resolve(true);
    }
  });
};
// 异常编码提示的方法
let showHttpErrorMessage = function (code) {
  let text = publicExceptionCodes(code, setConfigCharacters);
  /*let origin = window.location.origin;
  if (localStorage.getItem('setLanguages') === 'CN') {
    text = publicExceptionCodes(code, setConfigCharacters)
  } else {
    if (origin.includes('localhost')) {
      text = publicExceptionCodes(code, setConfigCharacters)
    } else {
      text = Vue.t(code);
    }
  }*/
  if (text) {
    setTimeout(() => {
      MSG.error({
        content: text,
        duration: 5,
        level: 1,
        closable: true
      });
    }, 300);
  }
};

axios.defaults.timeout = 180000;
axios.defaults.baseURL = '';
axios.defaults.responseType = 'json';
axios.defaults.headers.post['Accept'] = 'application/json;';
axios.defaults.headers.post['Content-Type'] = 'application/json;';
axios.defaults.headers.delete['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.delete['Accept'] = 'application/json;';
axios.defaults.headers.delete['Content-Type'] = 'application/json;';
axios.defaults.headers.put['Content-Type'] = 'application/json;';
axios.defaults.headers.put['Accept'] = 'application/json; ';
axios.defaults.headers.get['Content-Type'] = 'application/json;';
axios.defaults.headers.get['Accept'] = 'application/json; ';

var token = getToken() || commonSessionStorage.getItem('token'); // hack
// http request 拦截器
axios.jsonp = url => {
  if (!url) {
    return;
  }
  return new Promise((resolve, reject) => {
    window.printHandler = result => {
      resolve({
        data: result
      });
    };
    var JSONP = document.createElement('script');
    JSONP.type = 'text/javascript';
    JSONP.src = `${url}&callback=printHandler`;
    document.getElementsByTagName('head')[0].appendChild(JSONP);
    setTimeout(() => {
      document.getElementsByTagName('head')[0].removeChild(JSONP);
    }, 500);
  });
};
axios.interceptors.request.use(function (config) {
  config.cancelToken = new CancelToken(c => {
    removePending(config, c);
    let params = '';
    if (config) {
      if (config.data) {
        try {
          if (Object.prototype.toString.call(config.data) === '[object Array]') {
            if (config.data.length === 1) {
              params = JSON.stringify(config.data);
            }
          } else {
            var obj = JSON.parse(config.data);
            params = JSON.stringify(obj);
          }
        } catch (e) {
          params = JSON.stringify(config.data);
        }
      }
      if (config.url) {
        if (config.url.includes('fresh=KEEPNEW')) {
          pending.push({u: config.url + '&' + config.method, cancel: c});
        } else {
          pending.push({u: config.url + '&' + config.method + '&' + params, cancel: c});
        }
      }
    }
  });
  // 当请求中配置了loading：true，时，展示loading的遮罩层
  if (config.loading) {
    showLoadingModal(config.loadingText);
  } else {
    LoadingBar.start();
  }
  token = getToken() || commonSessionStorage.getItem('token');
  if (token) {
    config.headers.Authorization = token;
  } else {
    console.log('---token值已过期');
  }
  return config;
}, function (err) {
  return Promise.reject(err);
});


// http response 拦截器
axios.interceptors.response.use(function (response) {
  LoadingBar.finish();
  removePending(response.config);
  hideLoadingModal(response.status);
  if (response.data) {
    if (response.data.code === 999998) {
      // 999998 没有权限  999996 用户尚未登录
      if (response.config.headers['Authorization-Special'] === 'no-Login') {
        // 跳过
      } else {
        removeToken().then(() => {
          let path = window.location.href;
          sessionStorage.removeItem('token'); // hack
          path = deleteTicket(path);
          let loginUrl = response.data.others.loginUrl;
          let url = '';
          if (loginUrl) {
            let reg = regular.checkHttps; // 判断当前链接是否完整的https://、http://链接地址
            if (reg.test(loginUrl)) {
              url = loginUrl + encodeURIComponent(path);
            } else {
              let origin = window.location.origin;
              url = origin + loginUrl + encodeURIComponent(path);
            }
            window.location.href = url;
          }
        });
      }
    }
    // 供应商停用提示框
    else if (response.data.code === 999995) {
      let system = localStorage.getItem('system');
      if (system === 'supplier') {
        supplierDeactivationPrompt();
      } else {
        showNoAuth();
      }
    }
    // 展示后台的message
    else if (response.data.code === 999993) {
      MSG.error({
        content: response.data.message,
        duration: 5,
      });
    }
    // 没有yms权限的用户的通途用户访问
    else if (response.data.code === 999992) {
      showNoAuth();
    }
    // 当前登录分销商系统是通途商户，弹出是否要开通分销商的确认弹窗
    else if (response.data.code === 111314) {
      let message = response.data.message;
      localStorage.setItem('merchantId', message);
      becomeDistributor('tongTool');
    }
    // 分销商信息不全!
    else if (response.data.code === 999990) {
      let href = window.location.href;
      if (href.includes('/distributor.html')) {
        store.commit('showPerfectInformation', new Date().getTime());
      }
      return response;
    }
    // 当前登录商户不是分销商，弹出是否要开通分销商的确认弹窗
    else if (response.data.code === 111300) {
      let merchantId = response.data.message;
      localStorage.setItem('merchantId', merchantId)
      becomeDistributor('distributor');
      return response;
    }
    // 当前登录商户不是供应商，弹出是否要开通供应商的确认弹窗
    else if (response.data.code === 1112793) {
      let merchantId = response.data.message;
      localStorage.setItem('merchantId', merchantId)
      becomeDistributor('supplier');
      return response;
    }
    // 校验当前登录的供应商是否已经审核通过
    else if (response.data.code === 210034) {
      auditSuppliers(response.data.message);
    }
    // 当前商户没有开通域名站点
    else if (response.data.code === 1112832) {
      let message = response.data.message;
      noDomainNameSite(message);
    }
    // 系统级错误
    else if (response.data.code === 999999) {
      showHttpErrorMessage(response.data.code);
      /*return Promise.reject(new Error('接口报错了，请在控制台查看接口错误'));*/
    } else if (response.data.code === 0) {
      return response;
    } else if (response.data.code === 10555555) {
      // 取消
    } else if (response.data.code === -1 || response.data.code === -2 || response.data.code === -3 || response.data.code === -4
      || response.data.code === -5 || response.data.code === -6 || response.data.code === -7 || response.data.code === -8
      || response.data.code === -9 || response.data.code === -10 || response.data.code === 1111 || response.data.code === -999
      || response.data.code === 1) {
      // 错误码 (-1.*,-10) 错误走之前流程
      return response;
    }
    // yms商品不存在--重定向跳转到商城首页
    else if (response.data.code === 122006) {
      pageJump('/index.html#/index', '/yms-shopping-service', '_self');
    } else {
      // 异常码处理
      let text = dynamicErrorCodeFun(response.data);
      console.log('=======text==========', text)
      if (text) {
        setTimeout(() => {
          MSG.error({
            content: text,
            duration: 5,
            level: 1,
            closable: true
          });
        }, 300);
      } else {
        showHttpErrorMessage(response.data.code);
      }
      return response;
    }
  }
}, (error) => {
  hideLoadingModal(200);
  if (error.response) {
    if (error.response.status === 500) {
      showHttpErrorMessage(error.response.data.code);
      return error.response;
    } else if (error.response.status === 504) {
      MSG.error({
        content: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001202'),
        duration: 5,
        level: 1,
        closable: true
      });
    }
  } else if (error.message === 'cancelHttp') {
    // 取消
    return {
      data: {
        code: 10555555
      }
    };
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log('Error', error.message);
  }
  removePending(error.config);
});



