<template>
  <publicExport :exportTypeList="exportTypeList"></publicExport>
</template>
<script>
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';

import publicExport from "@/components/common/publicExport";
import {setConfigCharacters} from "@/utils/common";

export default {
  name: "exportTask",
  data() {
    return {
      exportTypeList: [
        {value: 'productExport', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000694')},
        {value: 'inventoryExport', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000727')},
        {value: 'inventoryLogExport', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005720')},
        {value: 'packageExport', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002542')},
        {value: 'ymsInventoryExport', name: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1005719')}`},
        {value: 'supplierBillExport', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005721')},
        {value: 'wmsPickupOrderExport', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005722')},
        {value: 'packageInfoOrderExport', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001406')},
        {value: 'packageInfoStockExport', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001303')},
      ]
    };
  },
  components: {
    publicExport
  }
};
</script>

<style scoped>

</style>
