import { render, staticRenderFns } from "./productBrand.vue?vue&type=template&id=ccfe5292&scoped=true&"
import script from "./productBrand.vue?vue&type=script&lang=js&"
export * from "./productBrand.vue?vue&type=script&lang=js&"
import style0 from "./productBrand.vue?vue&type=style&index=0&id=ccfe5292&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ccfe5292",
  null
  
)

export default component.exports