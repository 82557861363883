<template>
  <div class="container_box details_box_styles">
    <div v-if="type !=='details'">
      <!--头部筛选区域-->
      <div class="filter_box">
        <Form ref="pageParams" :model="pageParams" :label-width="labelWidth" @submit.native.prevent>
          <Row type="flex" :gutter="gutterItem">
            <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
              <FormItem :label="$t('key1001102')" prop="status">
                <Select v-model="pageParams.status" transfer filterable>
                  <Option v-for="item in statusList" :key="item.value" :value="item.value">{{ item.title }}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
              <Form-item :label="$t('key1000888')">
                <Date-picker
                  style="width:100%"
                  type="datetimerange"
                  :transfer="true"
                  @on-clear="resetDate"
                  @on-change="getDateValue"
                  :clearable="true"
                  :options="dateOptions"
                  format="yyyy-MM-dd HH:mm:ss"
                  placement="bottom-end"
                  :placeholder="$t('key1000269')"
                  :value="timeValue">
                </Date-picker>
              </Form-item>
            </Col>
            <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
              <Form-item :label="$t('key1003282')" prop="wmsPickupOrderNumber">
                <Input :placeholder="$t('key1005996')" clearable v-model.trim="pageParams.wmsPickupOrderNumber"></Input>
              </Form-item>
            </Col>
            <Col style="width: 200px;">
              <Button @click="reset" v-once icon="md-refresh" class="mr10">{{ $t('key1000095') }}</Button>
              <Button type="primary" @click="search" icon="ios-search">{{ $t('key1000270') }}</Button>
            </Col>
          </Row>
        </Form>
      </div>
      <!--功能按钮操作区域-->
      <div class="operation_box">
        <Button type="primary" @click="addPackingBtn" class="mr15"
          :disabled="!getPermission('wmsPickupOrder_insert')">{{ $t('key1006000') }}</Button>
        <Dropdown @on-click="changeExportBtn" v-if="getPermission('wmsPickupOrder_export')">
          <Button type="primary">
            <span>{{ $t('key1005999') }}</span>
            <Icon type="ios-arrow-down"></Icon>
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="1">{{ $t('key1002314') }}</DropdownItem>
            <DropdownItem name="2">{{ $t('key1002315') }}</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <!--列表区域-->
      <div class="table_box">
        <Table
          ref="selection"
          highlight-row
          border
          :loading="tableLoading"
          max-height="490"
          :columns="tableColumns"
          :data="tableData"
          @on-selection-change="tableChange"
          @on-sort-change="sortChange">
        </Table>
        <Page
          v-if="!tableLoading"
          :total="total"
          @on-change="changePage"
          show-total
          :page-size="pageParams.pageSize"
          show-elevator
          :current="pageParams.pageNum"
          show-sizer
          @on-page-size-change="changePageSize"
          placement="top"
          :page-size-opts="pageArray">
        </Page>
      </div>
    </div>
    <!--查看箱子详情信息-->
    <div v-else>
      <!--返回按钮-->
      <div class="go_back" @click="backPage">
        <Icon class="back_icon" type="ios-arrow-back"/>
        <span class="back_txt">{{ $t('key1001890') }}</span>
      </div>
      <!--基本信息-->
      <div class="information_box">
        <Card :bordered="false" dis-hover>
          <template #title><h2 class="title_box">{{ $t('key1000613') }}</h2></template>
          <Row type="flex" :gutter="gutterItem">
            <Col :span="ItemCol" class="basic_item">
              <span class="basic_title">{{ $t('key1003282') }}</span>
              <span class="basic_txt">{{ detailsObj.pickupOrderNumber }}</span>
            </Col>
            <Col :span="ItemCol" class="basic_item">
              <span class="basic_title">{{ $t('key1001102') }}</span>
              <span class="basic_txt">{{ detailsObj.statusText }}</span>
            </Col>
            <Col :span="ItemCol" class="basic_item">
              <span class="basic_title">{{ $t('key1000888') }}</span>
              <span class="basic_txt">{{ detailsObj.createdTime }}</span>
            </Col>
            <Col :span="ItemCol" class="basic_item">
              <span class="basic_title">{{ $t('key1003226') }}</span>
              <span class="basic_txt">{{ detailsObj.pickupTime }}</span>
            </Col>
          </Row>
        </Card>
      </div>
      <!--明细信息-->
      <div class="information_box">
        <Card :bordered="false" dis-hover>
          <template #title>
            <h2 class="title_box">{{ $t('key1003248') }}</h2>
          </template>
          <Button type="primary" v-if="detailsObj.status === 0" style="margin-bottom: 15px;" @click="deliveryOrderModal = true">{{ $t('key1005997') }}</Button>
          <Table
            border
            max-height="650"
            :columns="detailsTableColumns"
            :data="detailsTableData"
            :span-method="handleSpan">
          </Table>
        </Card>
      </div>
    </div>
    <!--批量添加出库单-->
    <Modal v-model="deliveryOrderModal"
      class-name="deliveryOrderStyle"
      :title="$t('key1005997')"
      width="600"
      :transfer="false"
      :mask-closable="false"
      @on-visible-change="deliveryOrderModalChange">
      <div class="deliveryOrderBox">
        <span>{{ $t('key1000934') }}</span>
        <Input v-model="oddNumberList" clearable type="textarea" :placeholder="$t('key1005998')" :rows="3" style="width: 90%;"/>
      </div>
      <template #footer>
        <div style="text-align: center;">
          <Button @click="deliveryOrderModal = false">{{ $t('key1000098') }}</Button>
          <Button type="primary" @click="deliveryOrderBtn">{{ $t('key1000097') }}</Button>
        </div>
      </template>
    </Modal>
    <!--打印箱唛-->
    <printCaseMarkModal
      ref="printTalg"
      :apiUrl="apiUrl"
      :service="service"
      typeTalg="packingManage">
    </printCaseMarkModal>
    <!--批量添加出库单的提示弹窗-->
    <Modal v-model="tipsTalg" class-name="tips_style">
      <div class="tips_box">
        <div class="tips_center" v-for="(item,index) in tipsList" :key="index">
          <h3 class="title">{{ item.text + '：' }}</h3>
          <div class="tips_item_box">
            <span v-for="(ele, ids) in item.data" :key="ids">{{ ele.packageCode }}</span>
          </div>
        </div>
      </div>
      <template #footer>
        <div style="text-align: center;">
          <Button @click="tipsTalg = false">{{ $t('key1000205') }}</Button>
        </div>
      </template>
    </Modal>
    <!--导出数据字段弹窗-->
    <exportFieldsModal
      ref="exportFields"
      :exportFieldsTitle="$t('key1005999')"
      paramKeyColums="supplierWmsPickupOrderColumns"
      :customFieldExportList="customFieldExportList"
      @exportFieldsDataBtn="exportFieldsDataBtn">
    </exportFieldsModal>
    <!--修改物流信息弹窗-->
    <logisticsInfoModal ref="logisticsInfo" @updateData="pageParamsStatus = true"></logisticsInfoModal>
  </div>
</template>

<script>
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from '@/components/mixin/common_mixin';
import printCaseMarkModal from "@/components/common/printCaseMarkModal";
import exportFieldsModal from "@/components/common/exportFieldsModal.vue";
import logisticsInfoModal from "@/components/common/logisticsInfoModal.vue";
import {getUserNameByUserId, jumpToTheCourierInquiry, pageJump, setConfigCharacters} from "@/utils/common";

export default {
  name: 'packingList',
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      tipsTalg: false,
      tipsList: [],
      total: 0,
      timeValue: [],
      pageParamsStatus: false,
      apiUrl: api.put_wmsPickupOrder_printWmsPickupOrder,
      service: 'yms-supplier-service',
      statusList: [
        {value: 'null', title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')},
        {value: 0, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005590')},
        {value: 1, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001249')},
        {value: 2, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001223')},
        {value: 3, title: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1003628')}`},
        {value: 99, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001252')},
      ],
      type: 'case',
      pageParams: {
        status: 'null', // 0:正在装箱，1:等待揽收，2:已揽收，3.YMS已收货
        wmsPickupOrderNumber: null,
        createdStartTime: null,
        createdEndTime: null,
        upDown: 'down',
        orderBy: 'createdTime',
        pageNum: 1,
        pageSize: 15
      },
      tableData: [],
      tableColumns: [
        {
          type: 'selection',
          width: 60,
          fixed: 'left',
          align: 'center'
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002847'),
          key: 'pickupOrderNumber',
          align: 'center',
          fixed: 'left',
          minWidth: 160,
          render: function (h, params) {
            return h('Alink', {
              props: {
                linkName: params.row.pickupOrderNumber,
                styles: {
                  justifyContent: 'center'
                }
              },
              on: {
                AlinkBtn: () => {
                  v.type = 'details';
                  v.pickupOrderNumber = params.row.pickupOrderNumber;
                  v.getPickupOrderData(params.row.pickupOrderNumber);
                }
              }
            });
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000515'),
          key: 'status',
          align: 'center',
          minWidth: 120,
          render: (h, params) => {
            let statusTxt = '-';
            let status = params.row.status;
            let content = params.row.invalidReason;
            v.statusList.map((item) => {
              if (item.value === status) {
                statusTxt = item.title;
              }
            });
            return h('div', {class: 'flex align-items-center'}, [
              h('span', statusTxt),
              status === 99 ? h('Tooltip', {
                props: {
                  transfer: true,
                  content: content,
                  maxWidth: 300,
                  placement: 'top'
                }
              }, [
                h('Icon', {
                  class: 'ml3 cursor',
                  props: {
                    type: 'md-help-circle',
                    size: 17,
                    color: '#7F7F7F'
                  },
                })
              ]) : ''
            ]);
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1003021'),
          key: 'deliveryMethod',
          align: 'center',
          minWidth: 170,
          render(h, params) {
            let deliveryType = params.row.deliveryType; // 发货方式{1YMS物流 2自有物流}
            let transportType = params.row.transportType; // 运输方式{1快递 2上门揽收}
            let text = '-';
            if (deliveryType === 1) {
              text = `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1002992')}` + ' ' + '-' + ' ' + (transportType === 1 ? alias93cb5d9a0adb49f980c05a290e356da0.t('key1001156') : alias93cb5d9a0adb49f980c05a290e356da0.t('key1003006'));
            } else if (deliveryType === 2) {
              text = alias93cb5d9a0adb49f980c05a290e356da0.t('key1001155')
            }
            return h('span', text);
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001169'),
          key: 'logisticsInfo',
          align: 'center',
          minWidth: 180,
          render(h, params) {
            let deliveryType = params.row.deliveryType; // 发货方式{1YMS物流 2自有物流}
            let transportType = params.row.transportType; // 运输方式{1快递 2上门揽收}
            let expressageNo = params.row.expressageNo;
            let expressageCode = params.row.expressageCode;
            let trackingNumber = params.row.trackingNumber;
            return h('div', [
              deliveryType === 1 && transportType === 2 ? h('span', `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1003006')}`) :
                h('div', [
                  h('Alink', {
                    props: {
                      linkName: deliveryType === 2 ? expressageNo : trackingNumber,
                      Aicon: '&#xe6e7;',
                      AlinkShow: false,
                      AiconSize: '22px',
                      AiconShow: (trackingNumber || expressageNo) ? true : false,
                      AiconColor: '#2D8CF0',
                      styles: {
                        justifyContent: 'center'
                      }
                    },
                    on: {
                      AIconBtn: () => {
                        jumpToTheCourierInquiry(expressageCode, expressageNo, trackingNumber);
                      }
                    }
                  })
                ])
            ]);
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002838'),
          key: 'logisticsCostCurrency',
          align: 'center',
          minWidth: 140,
          render(h, params) {
            let logisticsCostCurrency = params.row.logisticsCostCurrency;
            let logisticsCost = params.row.logisticsCost || '-';
            let deliveryType = params.row.deliveryType; // 发货方式{1YMS物流 2自有物流}
            let transportType = params.row.transportType; // 运输方式{1快递 2上门揽收}
            let text = '-';
            if (deliveryType === 1 && transportType === 1) {
              if (logisticsCost && logisticsCostCurrency) {
                text = logisticsCost + ' ' + logisticsCostCurrency;
              }
            }
            return h('span', text);
          }
        },
        {
          title: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001276')}`,
          key: 'subsidyAmount',
          align: 'center',
          minWidth: 140,
          render(h, params) {
            let text = '-';
            let ruleName = params.row.ruleName;
            let auditStatus = params.row.auditStatus; // 1为待审核
            let subsidyAmount = params.row.subsidyAmount;
            let content = '';
            if (auditStatus === 1) {
              content = `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1005578')}`;
              text = '-';
            } else {
              if (subsidyAmount) {
                text = subsidyAmount + v.currencyState;
                if (ruleName) {
                  let list = ruleName.split(',');
                  if (list && list.length > 0) {
                    list.map((item) => {
                      item = `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1005579')}${item}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1002994')}`;
                    });
                    content = list.join('、');
                  }
                }
              } else {
                content = `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1005580')}${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1005581')}`;
              }
            }
            return h('div', {class: 'flex justify-content-center align-items-center'}, [
              h('span', text),
              h('Tooltip', {
                  props: {
                    transfer: true,
                    maxWidth: 250,
                    placement: 'top',
                    content: content
                  },
                  style: {
                    marginLeft: '5px'
                  }
                },
                [
                  h('Icon', {
                    props: {
                      type: 'md-help-circle',
                      size: 17
                    },
                    style: {
                      cursor: 'pointer',
                      color: '#7F7F7F'
                    }
                  })
                ])
            ])
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1003230'),
          key: 'trackingMessage',
          align: 'center',
          minWidth: 150,
          render(h, params) {
            let deliveryType = params.row.deliveryType; // 发货方式{1YMS物流 2自有物流}
            let transportType = params.row.transportType; // 运输方式{1快递 2上门揽收}
            let trackingMessage = '-';
            if (deliveryType === 1 && transportType === 1) {
              trackingMessage = params.row.trackingMessage || '-'
            }
            return h('span', trackingMessage)
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001739'),
          key: 'warehouseId',
          align: 'center',
          minWidth: 140,
          render(h, params) {
            return h('span', v.getWarehouseName(params.row.warehouseId));
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1003932'),
          key: 'totalQuantities',
          minWidth: 130,
          align: 'center'
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005583'),
          key: 'totalActualSortingNumber',
          minWidth: 150,
          align: 'center',
          render(h, params) {
            let totalQuantities = params.row.totalQuantities;
            let totalActualSortingNumber = params.row.totalActualSortingNumber;
            let talg = false;
            if (totalQuantities && totalActualSortingNumber && totalActualSortingNumber !== totalQuantities) {
              talg = true;
            }
            return h('span', {class: talg ? 'redColor' : ''}, totalActualSortingNumber)
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005584'),
          key: 'totalSupplierOverSortingNumber',
          minWidth: 140,
          align: 'center',
          render(h, params) {
            let totalSupplierOverSortingNumber = params.row.totalSupplierOverSortingNumber || '-';
            return h('span', {class: totalSupplierOverSortingNumber !== '-' ? 'redColor' : ''}, totalSupplierOverSortingNumber);
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1003234'),
          key: 'abnormalStatus',
          align: 'center',
          minWidth: 160,
          render(h, params) {
            let text = '-';
            let talg = false;
            let status = params.row.status;
            let hasStockPackage = params.row.hasStockPackage; // 是否有多发补单 0 无 1 有
            let pickupOrderNumber = params.row.pickupOrderNumber;
            let ymsSortQuantities = params.row.ymsSortQuantities || 0;
            let totalQuantities = params.row.totalQuantities;
            if ((totalQuantities > 0 && totalQuantities !== ymsSortQuantities)
              || hasStockPackage === 1 || totalQuantities !== ymsSortQuantities) {
              text = alias93cb5d9a0adb49f980c05a290e356da0.t('key1003235');
              talg = true;
            }
            return status === 3 ? h('Alink', {
              props: {
                linkName: text,
                AlinkShow: talg,
                defineColor: talg ? '#FF3300' : '',
                styles: {
                  justifyContent: 'center'
                }
              },
              on: {
                AlinkBtn: () => {
                  v.type = 'details';
                  v.pickupOrderNumber = pickupOrderNumber;
                  v.getPickupOrderData(pickupOrderNumber);
                }
              }
            }) : h('span', '-')
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001600'),
          key: 'createdTime',
          sortable: 'custom',
          sortType: 'desc',
          minWidth: 170,
          align: 'center',
          render: (h, params) => {
            return h('div', v.$uDate.getDataToLocalTime(params.row.createdTime, 'fulltime') || '-');
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002849'),
          key: 'pickupTime',
          sortable: 'custom',
          align: 'center',
          minWidth: 170,
          render: (h, params) => {
            return h('div', v.$uDate.getDataToLocalTime(params.row.pickupTime, 'fulltime') || '-');
          }
        },
        {
          title: ` ${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1005582')}`,
          key: 'ymsPickingDate',
          sortable: 'custom',
          align: 'center',
          minWidth: 170,
          render: (h, params) => {
            return h('div', v.$uDate.getDataToLocalTime(params.row.ymsPickingDate, 'fulltime') || '-');
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001599'),
          key: 'createdBy',
          align: 'center',
          minWidth: 120,
          render: (h, params) => {
            return h('div', getUserNameByUserId(params.row.createdBy, v));
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000279'),
          key: 'action',
          align: 'center',
          width: 200,
          fixed: 'right',
          render: (h, params) => {
            let deliveryType = params.row.deliveryType; // 发货方式{1YMS物流 2自有物流}
            let status = params.row.status;
            let wmsPickupOrderId = params.row.wmsPickupOrderId;
            let pickupOrderNumber = params.row.pickupOrderNumber;
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000739'),
                    show: v.getPermission('wmsPickupOrder_detail'),
                    clickFn: () => {
                      v.type = 'details';
                      v.pickupOrderNumber = pickupOrderNumber;
                      v.getPickupOrderData(pickupOrderNumber);
                    }
                  },
                  {
                    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006001'),
                    show: params.row.status === 0 && v.getPermission('wmsPickupOrder_insertPackage'),
                    clickFn: () => {
                      v.getSupplierWarehouseSetting().then((val) => {
                        if (val) {
                          v.deliveryOrderModal = true;
                          v.pickupOrderNumber = pickupOrderNumber;
                          v.wmsPickupOrderId = wmsPickupOrderId;
                        }
                      })
                    }
                  },
                  {
                    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1003181'),
                    show: params.row.status === 0 && v.getPermission('wmsPickupOrder_continuePickupOrder'), // 正在装箱的状态，才能继续装箱,
                    clickFn: () => {
                      v.getSupplierWarehouseSetting().then((val) => {
                        if (val) {
                          let obj = {
                            value: 'scanPacking',
                            pickupOrderNumber: pickupOrderNumber,
                            type: 'continue'
                          };
                          v.$emit('changeTabs', obj);
                        }
                      })
                    }
                  },
                  {
                    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000887'),
                    show: v.getPermission('wmsPickupOrder_printWmsPickupOrder'),
                    clickFn: () => {
                      v.$refs['printTalg'].getPrintData(wmsPickupOrderId)
                    }
                  },
                  {
                    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001171'), // 物流方式为自有物流且等待揽收状态
                    show: status === 1 && deliveryType === 2 && v.getPermission('wmsPickupOrder_updateLogistics'),
                    clickFn: () => {
                      v.$refs['logisticsInfo'].initLogisticsInfo(wmsPickupOrderId, 'boxManagement');
                    }
                  },
                  {
                    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005587'),
                    show: [0, 1].includes(status),
                    clickFn: () => {
                      v.$Modal.confirm({
                        title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005588'),
                        content: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005589'),
                        okText: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005587'),
                        cancelText: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000098'),
                        onOk: () => {
                          v.cancelBillBtn(wmsPickupOrderId);
                        },
                        onCancel: () => {
                        }
                      });
                    }
                  },
                  {
                    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000092'),
                    show: params.row.status === 0 && v.getPermission('wmsPickupOrder_delete'),
                    clickFn: () => {
                      v.deletePackage(wmsPickupOrderId);
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    v.optionBtnWidth.push(value);
                    params.column.width = Math.max(...v.optionBtnWidth);
                    v.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ],
      ItemCol: 6,
      gutterItem: 16, // 栅格间距，单位 px，左右平分
      detailsObj: {},
      deliveryOrderModal: false,
      detailsTableColumns: [
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000934'),
          key: 'packageCode',
          minWidth: 160,
          align: 'left'
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002868'),
          key: 'trackingNumber',
          minWidth: 170,
          align: 'left'
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000311'),
          minWidth: 230,
          align: 'left',
          render: (h, params) => {
            let values = params.row.valueList || [];
            let imgPath = '';
            let skuImgPath = params.row._productInfo.primaryImage;
            let spuImgPath = params.row._productInfo._spuInfo.primaryImage
            imgPath = skuImgPath ? skuImgPath : spuImgPath;
            return h('div', {
              class: 'flex mt5 mb5'
            }, [
              v.tableImg(h, params, null, imgPath),
              h('div', {class: 'ml10 flex flex-direction'}, [
                h('p', {class: 'mt5'}, alias93cb5d9a0adb49f980c05a290e356da0.t('key1000412') + params.row.name),
                h('p', {class: 'mt5'}, alias93cb5d9a0adb49f980c05a290e356da0.t('key1000207') + values.join('、'))
              ])
            ])
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005678'),
          key: 'info',
          align: 'left',
          minWidth: 180,
          render(h, params) {
            return h('div', {
              class: 'flex flex-direction mt5'
            }, [
              h('p', {class: 'mb5'}, 'SPU：' + (params.row.spu ? params.row.spu : '')),
              h('p', {class: 'mb5'}, 'SKU：' + (params.row.sku ? params.row.sku : '')),
              h('p', {class: 'mb5'}, `${setConfigCharacters}SKU：` + (params.row.ymsSku ? params.row.ymsSku : ''))
            ]);
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000367'),
          key: 'quantity',
          minWidth: 120,
          align: 'left',
          render(h, params) {
            return h('p', params.row.quantity)
          }
        },
        {
          title: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1003297')}`,
          key: 'actualSortingNumber',
          align: 'left',
          minWidth: 140,
          render(h, params) {
            let quantity = params.row.quantity ? Number(params.row.quantity) : params.row.quantity;
            let actualSortingNumber = params.row.actualSortingNumber;
            return h('Alink', {
              props: {
                AlinkShow: false,
                linkName: actualSortingNumber,
                textAlign: 'left',
                styles: {
                  color: actualSortingNumber !== quantity ? '#D9001B' : '#333'
                }
              },
            })
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1003463'),
          key: 'isInvalid',
          minWidth: 140,
          align: 'left',
          render(h, params) {
            let isInvalid = params.row.isInvalid; // 是否作废 0:未作废 1:已作废
            let text = isInvalid === 1 ? alias93cb5d9a0adb49f980c05a290e356da0.t('key1001252') : isInvalid === 0 ? alias93cb5d9a0adb49f980c05a290e356da0.t('key1003464') : '-';
            return h('p', text);
          }
        },
      ],
      detailsTableData: [],
      oddNumberList: '',
      wmsPickupOrderId: null,
      pickupOrderNumber: null,
      selectTableData: [],
      exportType: '1',
      customFieldExportList: [
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000613'),
          titleKey: 'basicInfoColums',
          selectList: [], // 选中导出字段的key
          checkBoxList: [
            {paramKey: 'pickupOrderNumber', paramValue: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001338')},
            {paramKey: 'status', paramValue: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000515')},
            {paramKey: 'deliveryType', paramValue: alias93cb5d9a0adb49f980c05a290e356da0.t('key1003021')},
            {paramKey: 'carrierId', paramValue: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000197')},
            {paramKey: 'carrierCode', paramValue: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005592')},
            {paramKey: 'logisticsCost', paramValue: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002838')},
            {paramKey: 'subsidyAmount', paramValue: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001276')}`},
            {paramKey: 'ymsWarehouse', paramValue: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001739')},
            {paramKey: 'totalQuantities', paramValue: alias93cb5d9a0adb49f980c05a290e356da0.t('key1003932')},
            {paramKey: 'totalActualSortingNumber', paramValue: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005583')},
            {paramKey: 'totalSupplierOverSortingNumber', paramValue: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005584')},
            {paramKey: 'errorStatus', paramValue: alias93cb5d9a0adb49f980c05a290e356da0.t('key1003234')},
            {paramKey: 'createdTime', paramValue: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001600')},
            {paramKey: 'pickupTime', paramValue: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002849')},
            {paramKey: 'ymsPickingDate', paramValue: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1005582')}`},
            {paramKey: 'createdBy', paramValue: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001599')},
          ]
        }
      ],
    };
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    }
  },
  created() {
    this.setDefaultTimerParams('three_months');
    this.initFun();
  },
  methods: {
    // 初始化接口数据
    initFun() {
      (async () => {
        await this.getBelongingWarehouseData(this.userInfo.merchantId).then((data) => {
          if (data && data.length > 0) {
            this.warehouseList = data;
          }
        });
        await this.getUserName();
        await this.getList();
      })();
    },
    // 获取创建时间
    getDateValue(value) {
      let v = this;
      if (value.length === 0) {
        v.$Message.error(alias93cb5d9a0adb49f980c05a290e356da0.t('key1000285'));
        v.resetDate();
      } else {
        if (value[0] !== '') {
          let timeList = v.defaultTimePeriod(value);
          v.timeValue = v.defaultTimePeriod(value,false);
          v.pageParams.createdStartTime = v.$uDate.getUniversalTime(new Date(timeList[0]).getTime(), 'fulltime');
          v.pageParams.createdEndTime = v.$uDate.getUniversalTime(new Date(timeList[1]).getTime(), 'fulltime');
        }
      }
    },
    // 重置创建时间
    resetDate() {
      let v = this;
      v.pageParams.createdStartTime = null;
      v.pageParams.createdEndTime = null;
    },
    // 查询按钮
    search() {
      let v = this;
      v.pageParams.pageNum = 1;
      v.pageParamsStatus = true;
    },
    // 获取列表的数据
    getList() {
      let v = this;
      v.optionBtnWidth = [];
      v.tableData = [];
      v.total = 0;
      v.selectTableData = [];
      if (v.getPermission('wmsPickupOrder_query')) {
        v.tableLoading = true;
        v.axios.post(api.post_wmsPickupOrder_query, v.handleParameter()).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            v.tableData = data.list || [];
            v.total = data.total;
            v.$refs['selection'].selectAll(false);
            v.updateTableDom('selection');
          } else {
            v.tableLoading = false;
          }
        });
      }
    },
    // 新增箱/袋
    addPackingBtn() {
      this.getSupplierWarehouseSetting().then((val) => {
        if (val) {
          let obj = {
            value: 'scanPacking',
            type: 'adding'
          };
          this.$emit('changeTabs', obj);
        }
      });
    },
    // 删除包裹
    deletePackage(wmsPickupOrderId) {
      let v = this;
      v.$Modal.confirm({
        title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006002'),
        onOk: () => {
          v.axios.delete(api.delete_wmsPickupOrder + `${wmsPickupOrderId}`).then(response => {
            if (response.data.code === 0) {
              v.$Message.success(alias93cb5d9a0adb49f980c05a290e356da0.t('key1000332'));
              v.pageParamsStatus = true;
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    // 创建时间排序
    sortChange(data) {
      let obj = {
        desc: 'down',
        asc: 'up'
      }
      this.pageParams.upDown = obj[data.order];
      this.pageParams.orderBy = data.key;
      this.pageParamsStatus = true;
    },
    // 重置数据
    reset() {
      let v = this;
      v.$refs['pageParams'].resetFields();
      v.timeValue = [];
      v.resetDate();
      v.pageParams = {
        status: 'null',
        wmsPickupOrderNumber: null,
        createdStartTime: null,
        createdEndTime: null,
        upDown: 'down',
        orderBy: 'createdTime',
        pageNum: 1,
        pageSize: 15
      };
      v.setDefaultTimerParams('three_months');
      v.search();
    },
    // 关闭详情页面
    backPage() {
      this.type = 'case';
      this.pageParamsStatus = true;
    },
    // 获取查看装箱详情的数据
    getPickupOrderData(pickupOrderNumber) {
      let v = this;
      v.tableLoading = true;
      let newList = [];
      v.detailsTableData = [];
      v.axios.get(api.get_wmsPickupOrder + `${pickupOrderNumber}`).then(response => {
        v.tableLoading = false;
        if (response.data.code === 0) {
          let data = response.data.datas;
          v.statusList.map((item) => {
            if (item.value === data.status) {
              data.statusText = item.title;
            }
          });
          data.createdTime = v.$uDate.getDataToLocalTime(data.createdTime, 'fulltime');
          data.pickupTime = v.$uDate.getDataToLocalTime(data.pickupTime, 'fulltime');
          v.detailsObj = data;
          v.wmsPickupOrderId = data.wmsPickupOrderId;
          if (data.wmsPickupOrderDetails && data.wmsPickupOrderDetails.length > 0) {
            data.wmsPickupOrderDetails.map((item, idx) => {
              let packageGoods = item.packageGoods || [];
              let wmsPickGoodsBo = item.wmsPickGoodsBo || [];
              if (wmsPickGoodsBo && wmsPickGoodsBo.goodsDetailBos) {
                wmsPickGoodsBo.goodsDetailBos.map((ele, index) => {
                  let length = wmsPickGoodsBo.goodsDetailBos.length;
                  ele['length'] = length;
                  ele['currentTalg'] = (length > 1 && index === 0);
                  ele['packageCode'] = item.packageCode;
                  ele['trackingNumber'] = item.trackingNumber;
                  ele['wmsPickupOrderDetailId'] = item.wmsPickupOrderDetailId;
                  ele['path'] = ele.productGoodsImages ? ele.productGoodsImages[0].path : '';
                  ele['valueList'] = [];
                  ele['isInvalid'] = item.isInvalid;
                  if (packageGoods.length > 0) {
                    packageGoods.map((j) => {
                      if (ele.productGoodsId === j.productGoodsId) {
                        ele['actualSortingNumber'] = j.actualSortingNumber;
                      }
                    })
                  }
                  if (ele.productGoodsSpecifications && ele.productGoodsSpecifications.length > 0) {
                    ele.productGoodsSpecifications.map((talg) => {
                      ele['valueList'].push(talg.value);
                    });
                    ele['valueList'] = v.uniqueFunc(ele['valueList']);
                  }
                  newList.push(ele);
                });
              }
            });
            if (newList.length > 0) {
              let productGoodsIds = [];
              newList.map((item, index) => {
                item['tableLength'] = newList.length;
                if (item.currentTalg) {
                  item['currentIndex'] = index;
                }
                productGoodsIds.push(item.productGoodsId);
              });
              let list = v.uniqueFunc(productGoodsIds);
              if (list.length > 0) {
                (async () => {
                  v.detailsTableData = await v.getProductInfoByIds(list, newList,
                    false, false, false, api.post_productInfo_queryByProductGoodsIds, 'supplier');
                })();
              }
            }
          }
        }
      });
    },
    // 关闭弹窗重置数据
    deliveryOrderModalChange(value) {
      if (!value) {
        this.oddNumberList = '';
      }
    },
    // 批量添加出库单弹窗的确定按钮
    deliveryOrderBtn() {
      let v = this;
      let obj = {};
      if (v.oddNumberList) {
        obj.packageCodes = v.sepCommasFn(v.oddNumberList, [',', '，', '\n']);
        obj.wmsPickupOrderId = v.wmsPickupOrderId;
      } else {
        v.$Message.warning(alias93cb5d9a0adb49f980c05a290e356da0.t('key1006003'));
        return false;
      }
      v.axios.post(api.post_wmsPickupOrderDetail, obj).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          let abnormalList = [
            {code: 111004, content: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006004')},
            {code: 310027, content: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006005')},
            {code: 310029, content: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006006')}
          ];
          if (data.length > 0) {
            data.map((item) => {
              abnormalList.map((ele) => {
                if (item.code === ele.code) {
                  item['text'] = ele.content;
                }
              });
            });
            v.tipsList = v.handerGrouping(data, function (item) {
              return [item.text];
            }, 'text');
            v.tipsTalg = true;
          } else {
            v.$Message.success(alias93cb5d9a0adb49f980c05a290e356da0.t('key1000332'));
          }
          this.deliveryOrderModal = false;
          if (v.type === 'case') {
            v.pageParamsStatus = true;
          } else {
            v.getPickupOrderData(v.pickupOrderNumber);
          }
        }
      });
    },
    // 合并单元格
    handleSpan({row, column, rowIndex, columnIndex}) {
      let columnList = [0, 1, 6];
      if (columnList.includes(columnIndex)) {
        if (row.length > 1) {
          if (rowIndex === row.currentIndex) {
            return [row.length, 1]
          } else {
            for (let i = 0; i <= row.tableLength; i++) {
              if (rowIndex === i) {
                return [0, 0]
              }
            }
          }
        }
      }
    },
    // 参数处理
    handleParameter() {
      let v = this;
      let params = Object.assign({}, v.pageParams);
      params.status = v.pageParams.status === 'null' ? null : v.pageParams.status;
      return params;
    },
    // 勾选列表数据
    tableChange(data) {
      this.selectTableData = [];
      data.map((item) => {
        if (item.pickupOrderNumber) {
          this.selectTableData.push(item.pickupOrderNumber);
        }
      });
    },
    // 选择导出数据类型  1 按选中导出 2 按查询结果导出
    changeExportBtn(value) {
      this.exportType = value;
      if (value === '1') {
        if (this.selectTableData.length <= 0) {
          this.$Message.warning(alias93cb5d9a0adb49f980c05a290e356da0.t('key1002316'));
          return false;
        }
      } else {
        if (this.tableData.length <= 0) {
          this.$Message.warning(alias93cb5d9a0adb49f980c05a290e356da0.t('key1002317'));
          return false;
        }
      }
      this.$refs['exportFields'].getExportFieldsSetting();
    },
    // 导出数据
    exportFieldsDataBtn() {
      let v = this;
      let query = v.handleParameter();
      if (v.exportType === '1') {
        query.wmsPickupOrderNumbers = v.selectTableData;
      } else {
        query.wmsPickupOrderNumbers = [];
      }
      v.$refs['exportFields'].saveExportFieldsSetting().then((value) => {
        if (value) {
          v.axios.post(api.post_wmsPickupOrder_export, query,
            {loading: true, loadingText: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000213')}).then(res => {
            if (res.data.code === 0) {
              v.$refs['exportFields'].exportFieldsModal = false;
              let taskNumber = res.data.datas;
              setTimeout(() => {
                pageJump('/supplier.html#/exportTask?operateCode=' + taskNumber, '/yms-supplier-service', '_blank');
              }, 30)
            }
          });
        }
      });
    },
    // 作废提单
    cancelBillBtn(wmsPickupOrderId) {
      let v = this;
      v.axios.post(api.post_wmsPickupOrder_invalid + wmsPickupOrderId, null, {
        loading: true,
        loadingText: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000213')
      }).then(res => {
        if (res.data.code === 0) {
          v.$Message.success(alias93cb5d9a0adb49f980c05a290e356da0.t('key1005594'));
          v.pageParamsStatus = true;
        }
      });
    }
  },
  watch: {
    pageParamsStatus(n) {
      let v = this;
      if (n) {
        v.getList();
        v.pageParamsStatus = false;
      }
    }
  },
  components: {
    logisticsInfoModal,
    printCaseMarkModal,
    exportFieldsModal
  }
};
</script>

<style lang="less" scoped>
.details_box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .back_icon {
    font-size: 17px;
    color: #2d8cf0;
  }

  a {
    display: inline-block;
  }
}

.information_box {
  margin-top: 10px;

  .title_box {
    color: #333;
    font-size: 17px;
    font-weight: bold;
    background-color: #fff;
  }

  .basic_item {
    line-height: 35px;
    display: flex;
  }
}

/deep/ .deliveryOrderStyle {
  .deliveryOrderBox {
    display: flex;

    span {
      display: inline-block;
      width: 60px;
      margin: 5px 8px 0 0;
    }
  }
}

/deep/ .tips_style {
  .tips_box {
    display: flex;
    flex-direction: column;

    .tips_center {
      margin-bottom: 15px;

      .title {
        font-size: 15px;
        color: #333;
      }

      .tips_item_box {
        display: flex;
        flex-wrap: wrap;

        span {
          padding: 10px;
        }
      }
    }
  }
}

.table_box /deep/ .link_box_style {
  a {
    width: inherit !important;
  }
}
</style>
