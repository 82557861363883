
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 菜单
 * @author gan
 * @date: 2020/12/17 11:09
 * @attribute name 名称
 * @attribute path 路径
 * @attribute icon 图标
 * @attribute children 子菜单
 * @attribute menuKey 菜单key(需要唯一，用于获取权限)
 */
import {setConfigCharacters} from "@/utils/common";

export default {
  menu: [
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005362'),
      icon: '&#xe67b;',
      path: '/statistics',
      menuKey: 'yms_statistics',
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005683'),
      icon: '&#xe749;',
      type: 'productList',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002166'),
          path: '/productList',
          menuKey: 'yms_productList',
          children: [
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002608'),
              path: '/viewSku',
              menuKey: 'yms_viewSku',
              menuHide: true // 菜单不展示
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006780'),
              path: '/productDetails',
              menuKey: 'yms_productDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002758'),
          path: '/category',
          menuKey: 'yms_category',
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006781'),
          path: '/sizeTemplateManage',
          menuKey: 'yms_sizeTemplateManage',
          children: [
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006782'),
              path: '/updateSizeTemplate',
              menuKey: 'yms_updateSizeTemplate',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006783'),
          path: '/approvalList',
          menuKey: 'yms_approvalList',
          children: [
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006784'),
              path: '/approvalDetails',
              menuKey: 'yms_approvalDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002611'),
              path: '/findSimilarProducts',
              menuKey: 'yms_findSimilarProducts',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002177'),
          path: '/itemAttributes',
          menuKey: 'yms_itemAttributes'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002603'),
          path: '/productLabel',
          menuKey: 'yms_productLabel'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006785'),
          path: '/productSettings',
          menuKey: 'yms_productSettings',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在权限设置中不展示此菜单
        },
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001117'),
      icon: '&#xe665;',
      type: 'orderList',
      children: [
        {
          name: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001406')}`,
          path: '/orderList',
          menuKey: 'yms_orderList',
          children: [
            {
              name: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1004969')}`,
              path: '/orderListDetails',
              menuKey: 'yms_orderListDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006786'),
              path: '/supplierOrderDetails',
              menuKey: 'yms_supplierOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006787'),
          path: '/distributorStockList',
          menuKey: 'yms_distributorStockList'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006788'),
          path: '/supplierStockList',
          menuKey: 'yms_supplierStockList',
          children: [
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006789'),
              path: '/supplierStockOrderDetails',
              menuKey: 'yms_supplierStockOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002176'),
          path: '/supplierOrders',
          menuKey: 'yms_supplierOrders'
        },
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000018'),
      icon: '&#xe679;',
      type: 'wms',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002168'),
          path: '/wms/inventoryLevels',
          menuKey: 'yms_wms_inventoryLevels'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006790'),
          children: [
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006791'),
              path: '/billLadingManage',
              menuKey: 'yms_billLadingManage',
              children: [
                {
                  name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006792'),
                  path: '/billLadingManageDetails',
                  menuKey: 'yms_billLadingManageDetails',
                  menuHide: true // 菜单不展示
                }
              ]
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001906'),
              path: '/billWarehousing',
              menuKey: 'yms_billWarehousing'
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001907'),
              path: '/commoditySorting',
              menuKey: 'yms_commoditySorting'
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002167'),
              path: '/commoditySticker',
              menuKey: 'yms_commoditySticker'
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001910'),
              path: '/commodityShelves',
              menuKey: 'yms_commodityShelves'
            }
          ]
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005876'),
          children: [
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002507'),
              path: '/assembleGeneratePickingList',
              menuKey: 'yms_assembleGeneratePickingList'
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006793'),
              path: '/assembleGeneratePickingDetails',
              menuKey: 'yms_assembleGeneratePickingDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006786'),
              path: '/assembleSupplierOrderDetails',
              menuKey: 'yms_assembleSupplierOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006794'),
              path: '/assemblePickingList',
              menuKey: 'yms_assemblePickingList'
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002472'),
              path: '/assemblePickingListDetails',
              menuKey: 'yms_assemblePickingListDetails',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001306'),
          children: [
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002507'),
              path: '/wms/generateOrderList',
              menuKey: 'yms_wms_generateOrderList'
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006793'),
              path: '/generateOrderDetails',
              menuKey: 'yms_generateOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006786'),
              path: '/generateSupplierOrderDetails',
              menuKey: 'yms_generateSupplierOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006794'),
              path: '/wms/pickList',
              menuKey: 'yms_wms_pickList'
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002472'),
              path: '/wms/pickListDetails',
              menuKey: 'yms_wms_pickListDetails',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005976'),
          children: [
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006795'),
              path: '/wms/sortOrderSetting',
              menuKey: 'yms_wms_sortOrderSetting'
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001911'),
              path: '/wms/sorting',
              menuKey: 'yms_wms_sorting'
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001913'),
              path: '/wms/packWorking',
              menuKey: 'yms_wms_packWorking'
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001914'),
              path: '/sortingOut',
              menuKey: 'yms_sortingOut'
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006796'),
              path: '/handoverSheetManage',
              menuKey: 'yms_handoverSheetManage',
              children: [
                {
                  name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006797'),
                  path: '/handoverSheetManageDetails',
                  menuKey: 'yms_handoverSheetManageDetails',
                  menuHide: true // 菜单不展示
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006798'),
      icon: '&#xe668;',
      type: 'supplierList',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006799'),
          path: '/supplierList',
          menuKey: 'yms_supplierList'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006800'),
          path: '/supplierAuditList',
          menuKey: 'yms_supplierAuditList'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006801'),
          path: '/supplierLevel',
          menuKey: 'yms_supplierLevel'
        }
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006802'),
      icon: '&#xe699;',
      type: 'distributorList',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006803'),
          path: '/customerList',
          menuKey: 'yms_customerList',
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002414'),
          path: '/customerLabel',
          menuKey: 'yms_customerLabel',
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002413'),
          path: '/customerGroups',
          menuKey: 'yms_customerGroups',
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002415'),
          path: '/customerGrade',
          menuKey: 'yms_customerGrade'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006804'),
          path: '/tuokeLink',
          menuKey: 'yms_tuokeLink'
        }
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001118'),
      icon: '&#xe668;',
      type: 'supplierBillManage',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006805'),
          path: '/supplierBillManage',
          menuKey: 'yms_supplierBillManage',
          children: [
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006806'),
              path: '/supplierBillDetails',
              menuKey: 'yms_supplierBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002178'),
          path: '/customerBill',
          menuKey: 'yms_customerBill',
          children: [
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006807'),
              path: '/customerBillDetails',
              menuKey: 'yms_customerBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002179'),
          path: '/logisticsBill',
          menuKey: 'yms_logisticsBill',
          children: [
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006808'),
              path: '/logisticsBillDetails',
              menuKey: 'yms_logisticsBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002180'),
          path: '/warehouseBill',
          menuKey: 'yms_warehouseBill',
          children: [
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006809'),
              path: '/warehouseBillDetails',
              menuKey: 'yms_warehouseBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002822'),
          path: '/financialJournal',
          menuKey: 'yms_financialJournal'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002173'),
          path: '/rechargeRecord',
          menuKey: 'yms_rechargeRecord'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006810'),
          path: '/passwordManage',
          menuKey: 'yms_passwordManage'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002172'),
          path: '/subsidyReview',
          menuKey: 'yms_subsidyReview'
        }
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006811'),
      icon: '&#xe68e;',
      type: 'logisticsManage',
      children: [
        {
          name: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1002992')}`,
          path: '/ymsLogistics',
          menuKey: 'yms_ymsLogistics'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006812'),
          path: '/basicLogistics',
          menuKey: 'yms_basicLogistics'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006813'),
          path: '/smartLogisticsRule',
          menuKey: 'yms_smartLogisticsRule'
        },
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001133'),
      icon: '&#xe68f;',
      type: 'warehouseManage',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006814'),
          path: '/warehouseManage',
          menuKey: 'yms_warehouseManage'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006815'),
          path: '/reservoirManage',
          menuKey: 'yms_reservoirManage'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006816'),
          path: '/locationManage',
          menuKey: 'yms_locationManage'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006817'),
          path: '/messageNotification',
          menuKey: 'yms_messageNotification'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001646'),
          path: '/exchangeRateConfig',
          menuKey: 'yms_exchangeRateConfig'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006818'),
          path: '/containerManage',
          menuKey: 'yms_containerManage'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006819'),
          path: '/containerSettings',
          menuKey: 'yms_containerSettings'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006820'),
          path: '/addressLibraryManage',
          menuKey: 'yms_addressLibraryManage'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006821'),
          path: '/processConfiguration',
          menuKey: 'yms_processConfiguration'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006822'),
          path: '/iossTaxRateSetting',
          menuKey: 'yms_iossTaxRateSetting'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000969'),
          children: [
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006823'),
              path: '/declarationRules',
              menuKey: 'yms_declarationRules'
            }
          ]
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001117'),
          children: [
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006824'),
              path: '/automaticDistributionRules',
              menuKey: 'yms_automaticDistributionRules'
            }
          ]
        }
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006825'),
      icon: '&#xe68b;',
      type: 'helpCenter',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000036'),
          path: '/helpCenter',
          menuKey: 'yms_helpCenter',
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006826'),
          path: '/questionnaireInvestigation',
          menuKey: 'yms_questionnaireInvestigation',
          children: [
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002114'),
              path: '/questionnaireStatistics',
              menuKey: 'yms_questionnaireStatistics',
              menuHide: true // 菜单不展示
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002207'),
              path: '/addQuestionnaire',
              menuKey: 'yms_addQuestionnaire',
              menuHide: true // 菜单不展示
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006827'),
              path: '/editQuestionnaire',
              menuKey: 'yms_editQuestionnaire',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006828'),
          path: '/operatingInstructions',
          menuKey: 'yms_operatingInstructions',
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000853'),
          id: 'toSystemAnnouncement',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在权限设置中不展示此菜单
          externalLink: true, // 是否跳转到外部系统的标识
          menuKey: 'yms_systemAnnouncement',
        },
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006829'),
      icon: '&#xe691;',
      type: 'dataCenter',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002170'),
          path: '/deliveryDetails',
          menuKey: 'yms_deliveryDetails',
        }
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006830'),
      icon: '&#xe66a;',
      type: 'activityManage',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006831'),
          path: '/siteManage',
          menuKey: 'yms_siteManage',
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006832'),
          path: '/activityManage',
          menuKey: 'yms_activityManage',
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006833'),
          path: '/editActivity',
          menuKey: 'yms_editActivity',
          menuHide: true // 菜单不展示
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006834'),
          path: '/advertisingManage',
          menuKey: 'yms_advertisingManage',
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004028'),
          path: '/rotationChart',
          menuKey: 'yms_rotationChart',
          menuHide: true // 菜单不展示
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1003995'),
          path: '/vajraDistrict',
          menuKey: 'yms_vajraDistrict',
          menuHide: true // 菜单不展示
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004029'),
          path: '/topAdvertisingSpace',
          menuKey: 'yms_topAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004030'),
          path: '/sideAdvertisingSpace',
          menuKey: 'yms_sideAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004031'),
          path: '/infoAdvertisingSpace',
          menuKey: 'yms_infoAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002994'),
          path: '/subsidyRules',
          menuKey: 'yms_subsidyRules',
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006835'),
          path: '/distributorFeeAllocation',
          menuKey: 'yms_distributorFeeAllocation',
        },
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000286'),
      path: '/messageCenter',
      icon: '&#xe8be;',
      menuKey: 'yms_messageCenter',
      jurisdictionHide: true, // 不需要权限判断
      type: 'messageCenter',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006836'),
          path: '/messageMailboxes',
          menuKey: 'yms_messageMailboxes',
          menuHide: true // 菜单不展示
        },
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006837'),
      icon: '&#xe693;',
      path: '/employeeList',
      menuKey: 'yms_employeeList',
      type: 'employeeList',
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006838'),
      icon: '&#xe664;',
      type: 'importTask',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006839'),
          path: '/importTask',
          menuKey: 'yms_importTask'
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006840'),
          path: '/exportTask',
          menuKey: 'yms_exportTask'
        }
      ]
    }
  ]
};
