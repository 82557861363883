<template>
  <div class="link_box_style" :class="className" :style="styles">
    <div v-if="AlinkShow">
      <a v-if="showDefaultTitle" :title="linkName" :style="{color: linkColor, textAlign: textAlign}" :class="AlinkClass" @click="AlinkBtn">
        <p style="color: #515a6e; display: inline-block;">{{ $t('key1000086') }}</p>
        {{ linkName }}
      </a>
      <a v-else :title="linkName" :style="{color: defineColor || linkColor, textAlign: textAlign}"
        :class="AlinkClass" @click="AlinkBtn">{{ linkName }}</a>
    </div>
    <p v-else :style="styles" :title="linkName" :class="AlinkClass">{{ linkName }}</p>
    <span class="icon iconfont" :style="{color: AiconColor, fontSize: AiconSize}" :class="resetIconClass"
      v-if="AiconShow" v-html="Aicon" @click="AIconBtn"></span>
    <Icon style="margin-left: 10px;" v-if="ViconShow" :color="linkColor" :type="Aicon" :size="ViconSize"/>
  </div>
</template>

<script>
import {isAlibabaDistributor} from "@/utils/common";

export default {
  name: "aLink",
  props: {
    // 是否展示商品名称的默认文字
    showDefaultTitle: {
      type: Boolean,
      default: false
    },
    // a标签是否可以点击(默认可以点击)
    AlinkShow: {
      type: Boolean,
      default: true
    },
    // 文本内容
    linkName: {
      type: [String, Number],
      default: null
    },
    // 阿里巴巴图标
    Aicon: {
      type: String,
      default: ''
    },
    // 是否展示阿里巴巴图标(默认不展示)
    AiconShow: {
      type: Boolean,
      default: false
    },
    // 阿里巴巴图标的大小
    AiconSize: {
      type: String,
      default: '16px'
    },
    // 阿里巴巴图标颜色
    AiconColor: {
      type: String,
      default: null
    },
    // iview的图标
    Vicon: {
      type: String,
      default: ''
    },
    // 是否展示iview的图标（默认不展示）
    ViconShow: {
      type: Boolean,
      default: false
    },
    // 阿里巴巴图标的大小
    ViconSize: {
      type: String,
      default: '16px'
    },
    // 文本的对齐方式
    textAlign: {
      type: String,
      default: 'center'
    },
    // 样式类名
    className: {
      type: String,
      default: ''
    },
    // 自定义样式
    styles: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 自定义颜色
    defineColor: {
      type: String,
      default: () => {
        return ''
      }
    },
    // 链接的自定义class类名
    AlinkClass: {
      type: String,
      default: ''
    },
    // icon的类名
    resetIconClass: {
      type: String,
      default: ''
    },
  },
  computed: {
    linkColor() {
      let textColor = '';
      let pathname = window.location.pathname;
      let clorList = [
        {pathname: '/yms.html', themeColor: '#2D8CF0'},
        {pathname: '/supplier.html', themeColor: '#2D8CF0'},
        {pathname: '/distributor.html', themeColor: '#FD5425'}
      ];
      clorList.map((item) => {
        if (pathname.includes(item.pathname)) {
          if(isAlibabaDistributor()) {
            textColor = clorList[0].themeColor;
          } else {
            textColor = item.themeColor;
          }
        }
      });
      return textColor
    }
  },
  methods: {
    // a标签的点击事件
    AlinkBtn() {
      this.$emit('AlinkBtn')
    },
    // 阿里巴巴图标的点击事件
    AIconBtn() {
      this.$emit('AIconBtn')
    }
  }
}
</script>

<style lang="less" scoped>
.link_box_style {
  display: flex;
  align-items: center;
  //justify-content: center;

  a {
    cursor: pointer;
    display: inline-block;
    width: 100%;
  }

  span {
    margin-left: 10px;
    display: inline-block;
    cursor: pointer;
  }

}
</style>
