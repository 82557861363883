/**
 * @Description: 商城公共状态的存储
 * @author jing
 * @date 2021/1/27 10:25
 * @params:
 */

export default {
  state: {
    classificationTalg: true, // 所有分类菜单的展示与隐藏
    showHover: false, // 移入移出是否展示所有分类菜单
    classificationData: [], // 所有分类的数据
    system: '', // 系统标识
    baseInfo: {}, // 缓存进入商品中心的基本信息
    headerTalg: true, // true：商城默认展示的头部，false：展示店铺列表的头部
    supplierInfo: {}, // 供应商的基本信息
    scrollPosition: 0, // 记录消息中心列表滚动的位置
    shoppingInfo: null, // 商城信息
    distributorInfo: null, // 分销商地址 商城地址
    warehouseList: null, // 仓库
    showPerfectInformation: null, // 展示绑定手机号
    ongoingActivitiesData: [], // 商城正在进行中活动的数据
    navBarFixed: false, // 是否固定商城顶部
    mallAdvertising: [], // 商城已经启用的广告数据
    fromListing: false,
    categoryByTableOpen: {}, // 商品分类是否展开数据
    productSettings: null, // 商品设置
    updateSellingCountry: null, // 监听售卖国值
  },
  mutations: {
    showPerfectInformation(state, data) {
      state.showPerfectInformation = data;
    },
    warehouseList(state, data) {
      state.warehouseList = data;
    },
    classificationTalg(state, data) {
      state.classificationTalg = data;
    },
    showHover(state, data) {
      state.showHover = data;
    },
    classificationData(state, data) {
      state.classificationData = data;
    },
    system(state, data) {
      state.system = data;
    },
    baseInfo(state, data) {
      state.baseInfo = data;
    },
    headerTalg(state, data) {
      state.headerTalg = data;
    },
    supplierInfo(state, data) {
      state.supplierInfo = data;
    },
    scrollPosition(state, data) {
      state.scrollPosition = data;
    },
    shoppingInfo(state, data) {
      state.shoppingInfo = data;
    },
    distributorInfo(state, data) {
      state.distributorInfo = data;
    },
    ongoingActivitiesData(state, data) {
      state.ongoingActivitiesData = data;
    },
    navBarFixed(state, data) {
      state.navBarFixed = data;
    },
    mallAdvertising(state, data) {
      state.mallAdvertising = data;
    },
    fromListing(state, data) {
      state.fromListing = data;
    },
    categoryByTableOpen(state, data) {
      state.categoryByTableOpen = data;
    },
    productSettings(state, data) {
      state.productSettings = data;
    },
    updateSellingCountry(state, data) {
      state.updateSellingCountry = data;
    }
  },
  actions: {}
};
