<template>
  <div class="container_box">
    <!--头部筛选区域-->
    <div class="filter_box">
      <!--<Button type="primary" @click="gotoScanEx" icon="md-swap" class="scan_warehouse"
        v-if="getPermission('packageInfo_markPackageDelivery')">切换为扫描出库 </Button>-->
      <Form ref="pageParams" :model="pageParams" :label-width="labelWidth" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1005602')" prop="ymsWarehouseId">
              <Select v-model="pageParams.ymsWarehouseId" filterable>
                <Option v-for="item in warehouseList" :key="item.ymsWarehouseId" :value="item.ymsWarehouseId">{{ item.name }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <FormItem :label="$t('key1003523')">
              <Cascader
                v-model="shippingMethodModel"
                :load-data="loadData"
                @on-change="changeShippingMethod"
                :data="shippingMethodData"
                filterable
                change-on-select>
              </Cascader>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1006009')">
              <Date-picker
                style="width:80%"
                type="date"
                @on-clear="resetDate"
                @on-change="getDateValue"
                :clearable="clearAble"
                transfer
                format="yyyy-MM-dd HH:mm:ss"
                placement="bottom-end"
                :placeholder="$t('key1000269')"
                :value="printTimeArr"></Date-picker>
              <span style="margin-left: 10px;">{{ $t('key1006022') }}</span>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1001926')" prop="buyerCountryCodeList">
              <Select v-model="pageParams.buyerCountryCodeList" @on-change="countryChange" filterable multiple :placeholder="$t('key1000984')">
                <Option
                  v-for="(item, index) in countrysData"
                  :key="index"
                  :value="item.twoCode">{{ item.enName }}
                </Option>
              </Select>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1002473')" prop="pickingGoodsNo">
              <Input :placeholder="$t('key1006010')" v-model.trim="pageParams.pickingGoodsNo"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1001101')" prop="trackingNumber">
              <Input :placeholder="$t('key1006011')" v-model.trim="pageParams.trackingNumber"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1001512')" prop="packageCode">
              <Input :placeholder="$t('key1006012')" v-model.trim="pageParams.packageCode"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1002503')" prop="orderNo">
              <Input :placeholder="$t('key1006013')" v-model.trim="pageParams.orderNo"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1004957')" prop="buyerName">
              <Input :placeholder="$t('key1006014')" v-model.trim="pageParams.buyerName"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1005009')" prop="buyerAccountId">
              <Input :placeholder="$t('key1006015')" v-model.trim="pageParams.buyerAccountId"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1002073')" prop="searchValue">
              <Input :placeholder="$t('key1006016')" v-model.trim="pageParams.searchValue"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item label="SKU：" prop="webstoreSku">
              <Input :placeholder="$t('key1006017')" type="textarea" v-model.trim="pageParams.webstoreSku"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1006018')" prop="buyerName">
              <local-tabButton :tabButtonList="tabsData" @selectStatus="selectTabsData"></local-tabButton>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <div class="filterItem max_filterItem">
              <Form-item :label="$t('key1006019')" prop="buyerName">
                <local-tabButton :tabButtonList="tabsData1" @selectStatus="selectTabsData1"></local-tabButton>
              </Form-item>
            </div>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <div class="filterItem max_filterItem">
              <Form-item :label="$t('key1006020')" prop="buyerName">
                <local-tabButton :tabButtonList="tabsData2" @selectStatus="selectTabsData2"></local-tabButton>
              </Form-item>
            </div>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter" style="max-width: 20%">
            <div class="filterItem max_filterItem">
              <Form-item :label="$t('key1006021')">
                <local-tabButton :tabButtonList="tabsData3" @selectStatus="selectTabsData3"></local-tabButton>
              </Form-item>
            </div>
          </Col>
          <Col :xxl="threeItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter" style="max-width: 30%;">
            <FormItem :label="$t('key1003475')">
              <local-tabButton :tabButtonList="labelTypeList" @selectStatus="labelTypeStatus"></local-tabButton>
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" justify="space-between" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" :offset="offsetCol">
            <Button @click="reset" v-once icon="md-refresh" class="mr10">{{ $t('key1000095') }}</Button>
            <Button type="primary" @click="search" icon="ios-search">{{ $t('key1000270') }}</Button>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" style="text-align: right;">
            <Button @click="toggleMoreFilter">{{ moreFilterMes }}
              <Icon :type="moreFilterIcon"></Icon>
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
    <!--功能按钮操作区域-->
    <div class="operation_box">
      <div class="mr15 sort_options_box">
        <span>{{ $t('key1002260') }}</span>
        <Button-group>
          <template v-for="(item, index) in buttonGroupModel">
            <Button v-if="item.selected" type="primary" @click="modifyTheSort(index, item.status)" :key="index">
              {{ item.title }}
              <Icon type="md-arrow-up" v-if="item.status && item.selected"></Icon>
              <Icon type="md-arrow-down" v-if="!item.status && item.selected"></Icon>
            </Button>
            <Button v-if="!item.selected" @click="modifyTheSort(index, item.status)" :key="index">
              {{ item.title }}
              <Icon type="md-arrow-up" v-if="item.status && item.selected"></Icon>
              <Icon type="md-arrow-down" v-if="!item.status && item.selected"></Icon>
            </Button>
          </template>
        </Button-group>
      </div>
      <!--<Buttons type="primary" transfer class="mr15" trigger="click" @on-click="markOutboundHasCondition">
        <Button type="primary" class="iconbuttons" @click="MarkOutbound('moreSelect')" :disabled="
        !getPermission('packageInfo_batchMarkPackageDelivery_batch')">
          <Icon type="md-exit"></Icon>
          标出库
        </Button>
        <Buttons-menu slot="list">
          <Buttons-item name="1" v-if="getPermission('packageInfo_batchMarkPackageDelivery_all')">标出库（所有结果集）</Buttons-item>
        </Buttons-menu>
      </Buttons>-->
    </div>
    <!--列表区域-->
    <div class="table_box">
      <Table
        highlight-row
        border
        :loading="tableLoading"
        max-height="550"
        :columns="orderColumn"
        :data="printData"
        @on-selection-change="getSelectValue">
      </Table>
      <Page
        v-if="!tableLoading"
        :total="total"
        @on-change="changePage"
        show-total
        :page-size="pageParams.pageSize"
        show-elevator
        :current="pageParams.pageNum"
        show-sizer
        @on-page-size-change="changePageSize"
        placement="top"
        :page-size-opts="pageArray">
      </Page>
    </div>
    <!--标出库弹窗-->
    <Modal v-model="scanModel.status" @on-ok="setOut">
      <div class="ivu-modal-confirm">
        <div class="ivu-modal-confirm-head">
          <div class="ivu-modal-confirm-head-title">
            {{ scanModel.title }}
          </div>
        </div>
        <div class="ivu-modal-confirm-body ivu-modal-confirm-body-render">
          <div class="normalTop">
            <Row>
              <Col :span="1">
                <Icon type="md-information-circle" color="#2b85e4" style="font-size:36px"></Icon>
              </Col>
              <Col :span="21" :offset="2">
                <p>
                  {{ scanModel.content }} </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Modal>
    <!--标记缺货弹窗-->
    <Modal v-model="stockModel.status" :loading="setStockoutLoading" @on-ok="setStockoutIt" width="600">
      <div class="ivu-modal-confirm">
        <div class="ivu-modal-confirm-head">
          <div class="ivu-modal-confirm-head-title">
            {{ $t('key1000370') }}
          </div>
        </div>
        <div class="ivu-modal-confirm-body ivu-modal-confirm-body-render">
          <div class="normalTop">
            <Row>
              <Col :span="1">
                <Icon type="md-information-circle" color="#2b85e4" style="font-size:36px"></Icon>
              </Col>
              <Col :span="21" :offset="2">
                <p>
                  {{ $t('key1006023') }}</p>
              </Col>
            </Row>
          </div>
          <div class="normalTop">
            <Table
              highlight-row
              border
              :columns="selectProductColumn"
              :loading="tableLoading"
              :data="selectProductData"
              size="small"
              @on-selection-change="getSelectValue1">
            </Table>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';

export default {
  name: "exwarehouse",
  mixins: [Mixin],
  data() {
    let self = this;
    return {
      // 标发货提示参数
      tabsData: [
        {selected: true, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096'), value: '-1'},
        {title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004165'), selected: false, value: '1'},
        {title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004164'), selected: false, value: '0'}
      ],
      tabsData1: [
        {selected: true, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096'), value: '-1'},
        {title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004165'), selected: false, value: '1'},
        {title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004164'), selected: false, value: '0'}
      ],
      tabsData2: [
        {selected: true, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096'), value: '-1'},
        {title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004165'), selected: false, value: '1'},
        {title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004164'), selected: false, value: '0'}
      ],
      tabsData3: [
        {selected: true, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096'), value: '-1'},
        {title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004165'), selected: false, value: '1'},
        {title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004164'), selected: false, value: '0'}
      ],
      packageModel: false,
      packageDetailsStatus: true,
      packageId: null,
      timestamp: null,
      setStockoutLoading: true,
      stockModel: {
        status: false
      },
      selectProductColumn: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          key: 'pictureUrl',
          width: 120,
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000161'),
          render: (h, params) => {
            return this.tableImg(h, params, 'pictureUrl');
          }
        },
        {
          key: 'sku',
          width: 120,
          title: 'sku'
        },
        {
          key: 'title',
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000193')
        }
      ],
      selectProductData: [],
      scanModel: {
        status: false,
        title: '',
        content: '',
        type: '',
        pid: null,
        batchNo: null
      },
      packageIds: [],
      stockoutArr: [],
      buttonGroupModel: [
        {type: 'printTime', selected: true, status: true, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006024')},
        {type: 'payTime', selected: false, status: true, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006025')},
        {type: 'packageCode', selected: false, status: true, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006026')}
      ],
      pageParamsPrototype: null, // 重置查询条件
      pageParams: {
        pageNum: 1,
        pageSize: 15,
        status: 0,
        hasTrackingNumber: '-1',
        hasThirdPartyNo: '-1',
        hasCarrierWeight: '-1',
        hasPostage: '-1',
        webstoreSku: null,
        getLabelStatus: null,
        platFormId: null,
        saleAccountIds: [],
        buyerCountryCodeList: [],
        packageCode: null,
        trackingNumber: null,
        searchValue: null, // 搜索内容
        pickingGoodsNo: null, // 拣货单号
        warehouseId: self.getWarehouseId(),
        labelType: null,
        ymsWarehouseId: 'null'
      },
      platformArr: [],
      pageParamsStatus: false, // 每次更新完pageParms都要设置成true触发刷新
      totalPage: 0,
      total: 0,
      clearAble: true,
      resetStatus: true,
      printTimeArr: '',
      printData: [],
      orderColumn: [
        {
          type: 'selection',
          width: 60,
          fixed: 'left',
          align: 'center'
        },
        {
          title: 'NO',
          key: 'index',
          fixed: 'left',
          align: 'center',
          width: 70
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000934'),
          key: 'packageCode',
          fixed: 'left',
          align: 'center',
          width: 160,
          render: (h, params) => {
            let packageCode = params.row.packageCode;
            if (this.getPermission('wmsPicking_querySalesPickingDetail')) {
              return h('span', {
                class: 'blueColor cursor underline',
                on: {
                  click: () => {
                    const {href} = self.$router.resolve({
                      path: '/wms/searchPackageDetails',
                      query: {
                        pickingNo: packageCode,
                        workType: "sellStock"
                      }
                    });
                    window.open(href, '_blank');
                  }
                }
              }, packageCode);
            } else {
              return h('div', packageCode);
            }
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1003298'),
          key: 'warehouseName',
          minWidth: 130,
          align: 'center',
          render(h, params) {
            return h('span', self.getWarehouseName(params.row.ymsWarehouseId));
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1003703'),
          key: 'pickingGoodsNo',
          width: 180,
          align: 'center'
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000366'),
          key: 'salesRecordNumber',
          align: 'center',
          width: 160,
          render: (h, params) => {
            let pos = [];
            let packageOrderBoList = params.row.packageOrderBoList || [];
            if (packageOrderBoList.length > 0) {
              for (let i = 0; i < packageOrderBoList.length; i++) {
                let n = packageOrderBoList[i];
                if (n.accountCode) {
                  pos.push(h('div', n.accountCode + '-' + n.salesRecordNumber));
                } else {
                  pos.push(h('div', n.salesRecordNumber));
                }
              }
              return h('div', pos);
            }
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006027'),
          key: 'buyerName',
          align: 'center',
          width: 140,
          render: (h, params) => {
            return h('div', [
              h('div', {
                style: {
                  color: '#0054A6'
                }
              }, params.row.buyerAccountId),
              h('div', {
                style: {
                  color: '#ff3300'
                }
              }, params.row.buyerName)
            ]);
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001784'),
          align: 'center',
          key: 'buyerCountryCode',
          width: 110
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002993'),
          align: 'center',
          key: 'carrierShippingMethodName',
          width: 200
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002868'),
          align: 'center',
          key: 'trackingNumber',
          width: 200,
          render(h, params) {
            return h('div', [h('p', params.row.trackingNumber), h('p', params.row.virtualTrackingNumber)]);
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002516'),
          align: 'center',
          key: 'skuQuantity',
          width: 120
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002517'),
          align: 'center',
          key: 'productQuantity',
          width: 120
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1003594'),
          align: 'center',
          sortable: true,
          width: 175,
          key: 'payTime',
          render: (h, params) => {
            return self.$uDate.getDataToLocalTime(params.row.payTime, 'fulltime');
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000279'),
          key: 'operate',
          align: 'center',
          width: 140,
          fixed: 'right',
          render: (h, params) => {
            // 必须是允许供应商可直接发货 的才可以标记为已发货
            let talg = params.row.isSupplierSupportDirectDelivery === 1 && self.getPermission('packageInfo_markPackageDelivery');
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006028'),
                    show: talg,
                    clickFn: () => {
                      self.MarkOutbound('single', params.row.packageId, params.row.deliveryBatchNo);
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    self.optionBtnWidth.push(value);
                    params.column.width = Math.max(...self.optionBtnWidth);
                    self.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ],
      warehouseList: [],
    };
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
  },
  created() {
    let v = this;
    v.initFun();
  },
  methods: {
    // 初始化接口数据
    initFun() {
      let v = this;
      v.warehouseList = [];
      (async () => {
        await v.getBelongingWarehouseData(v.userInfo.merchantId).then((data) => {
          if (data && data.length > 0) {
            v.warehouseList = data;
            let list = data.filter((item) => {
              return item.isFirst === 1
            });
            v.pageParams.ymsWarehouseId = list.length > 0 ? list[0].ymsWarehouseId : 'null';
            v.warehouseList.unshift({
              ymsWarehouseId: 'null',
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')
            })
          }
        });
        await v.getCountrysData();
        await v.getShippingList();
        await v.search();
      })();
      v.pageParamsPrototype = v.deepCopy(v.pageParams);
    },
    getShippingList() {
      let v = this;
      v.axios.get(api.get_carrier_queryForEnableCarriers + '?warehouseId=' + v.getWarehouseId() + '&isFilter=true' + '&time=' + new Date().getTime()).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          if (data.length > 0) {
            v.carrierList = data;
            let pos = [];
            for (let i = 0; i < data.length; i++) {
              pos.push({
                value: data[i].carrierId,
                label: data[i].carrierName,
                children: [],
                loading: false,
                carrierAccounts: data[i].carrierAccounts,
                notAssociated: false
              });
              if (i === data.length - 1) {
                v.shippingMethodData = pos;
              }
            }
          }
        }
      });
    },
    getList() {
      let v = this;
      v.printData = [];
      v.total = 0;
      if (v.getPermission('packageInfo_queryForOutWarehouse')) {
        v.tableLoading = true;
        let query = JSON.parse(JSON.stringify(v.pageParams));
        let config = {
          headers: {
            'Language': v.languagesVal
          }
        }
        query.ymsWarehouseId = this.pageParams.ymsWarehouseId === 'null' ? null : this.pageParams.ymsWarehouseId;
        v.axios.post(api.post_packageInfo_queryForOutWarehouse, query).then(response => {
          let saleAccountIdPos = [];
          let merchantShippingMethodIdPos = [];
          if (response.data.code === 0) {
            let data = response.data.datas.list;
            if (data !== null && data.length > 0) {
              data.forEach((n, i) => {
                n.index = i + 1;
                saleAccountIdPos.push(n.saleAccountId);
                merchantShippingMethodIdPos.push(n.merchantShippingMethodId);
              });
              const getAllShippingMethods = new Promise((resolve, reject) => {
                v.axios.get(api.get_carrierShippingMethod_queryByIds + '?carrierShippingMethodIdList=' + merchantShippingMethodIdPos, config).then(response1 => {
                  let shippingData = response1.data.datas;
                  shippingData.forEach((m, t) => {
                    data.forEach((n, i) => {
                      if (n.merchantShippingMethodId === m.shippingMethodId) {
                        data[i].carrierShippingMethodName = m.carrierShippingMethodName;
                      }
                    });
                  });
                  resolve(true);
                });
              });
              Promise.all([getAllShippingMethods]).then(result => {
                if (result[0]) {
                  v.$nextTick(function () {
                    v.total = Number(response.data.datas.total);
                    v.totalPage = Number(response.data.datas.pages);
                    v.printData = data;
                    v.tableLoading = false;
                  });
                } else {
                  v.printData = [];
                  v.$nextTick(function () {
                    v.total = 0;
                    v.totalPage = 0;
                    v.tableLoading = false;
                  });
                }
              }).catch(error => {
                console.log(error);
              });
            } else {
              v.printData = [];
              v.$nextTick(function () {
                v.total = 0;
                v.totalPage = 0;
                v.tableLoading = false;
              });
            }
          } else {
            v.tableLoading = false;
          }
        });
      } else {
        v.gotoError()
      }
    },
    reset() {
      let v = this;
      v.printTimeArr = null;
      v.resetDate();
      v.shippingMethodModel = [];
      v.$refs['pageParams'].resetFields();
      v.selectTabsData(0, '-1');
      v.selectTabsData1(0, '-1');
      v.selectTabsData2(0, '-1');
      v.pageParams.webstoreSku = null;
      v.selectTabsData3(0, '-1');
      v.labelTypeStatus(0, null);
      v.pageParams.merchantCarrierId = null;
      v.pageParams.merchantShippingMethodId = null;
      v.search();
    },
    search() {
      let v = this;
      v.pageParams.pageNum = 1;
      v.pageParamsStatus = true;
    },
    changeShippingMethod(value, data) {
      let v = this;
      if (value.length === 0) {
        v.pageParams.merchantCarrierId = null;
        v.pageParams.merchantShippingMethodId = null;
      } else if (value.length === 1) {
        v.pageParams.merchantCarrierId = value[0];
        v.pageParams.merchantShippingMethodId = null;
      } else if (value.length === 2) {
        v.pageParams.merchantCarrierId = value[0];
        v.pageParams.merchantShippingMethodId = value[1][0];
      }
    },
    resetDate() {
      let v = this;
      v.pageParams.printEndTime = '';
    },
    // 获取日期返回值
    getDateValue(value) {
      let v = this;
      if (!value) {
        v.resetDate();
      } else {
        v.pageParams.printEndTime = v.$uDate.getUniversalTime(new Date(value).getTime(), 'fulltime');
      }
    },
    MarkOutbound(type, pid, batchNo) {
      let v = this;
      if (type !== 'all' && type !== 'single') {
        if (v.packageIds.length < 1) {
          v.$Message.error(alias93cb5d9a0adb49f980c05a290e356da0.t('key1006029'));
          return false;
        }
        v.scanModel.title = alias93cb5d9a0adb49f980c05a290e356da0.t('key1006030');
        v.scanModel.content = alias93cb5d9a0adb49f980c05a290e356da0.t('key1006031');
        v.scanModel.type = 'moreSelect';
        v.scanModel.status = true;
      } else if (type === 'all') {
        v.scanModel.type = 'all';
        v.scanModel.status = true;
      } else if (type === 'single') {
        // v.scanModel.title = '标记为已发货';
        // v.scanModel.content = '标记为已发货，系统将符合您选择的包裹标记为已发货。';
        v.scanModel.type = 'single';
        v.scanModel.pid = pid;
        v.scanModel.batchNo = batchNo;
        v.$Modal.confirm({
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006032'),
          onOk: () => {
            v.setOut();
          },
          onCancel: () => {
          }
        });
      }
    },
    setOut(flag) {
      let v = this;
      let obj;
      let url = api.put_packageInfo_batchMarkPackageDelivery;
      if (v.scanModel.type === 'all') {
        obj = v.deepCopy(v.pageParams);
      } else if (v.scanModel.type === 'moreSelect') {
        obj = {
          packageIds: v.packageIds
        };
      } else {
        obj = {
          packageId: v.scanModel.pid,
          deliveryBatchNo: v.scanModel.batchNo,
          warehouseId: v.getWarehouseId()
        };
        url = api.put_packageInfo_markPackageDelivery;
      }
      v.axios.put(url, JSON.stringify(obj), {loading: true}).then(response => {
        if (response.data.code === 0) {
          v.$Message.success(alias93cb5d9a0adb49f980c05a290e356da0.t('key1000131'));
          v.pageParamsStatus = true;
        }
      });
    },
    keepOut() {
      this.setOut(1);
    },
    goCancel() {
      this.$router.push('cancelPackage');
    },
    markOutboundHasCondition() {
      let v = this;
      v.scanModel.title = alias93cb5d9a0adb49f980c05a290e356da0.t('key1006033');
      v.scanModel.content = alias93cb5d9a0adb49f980c05a290e356da0.t('key1006034');
      v.MarkOutbound('all');
    },
    markOutOfStock() {
      let v = this;
      if (v.packageIds.length < 1) {
        v.$Message.error(alias93cb5d9a0adb49f980c05a290e356da0.t('key1006029'));
        return false;
      }
      v.stockModel.status = true;
      v.tableLoading = true;
      v.axios.post(api.get_packageProductList, JSON.stringify(v.packageIds)).then(response => {
        v.tableLoading = false;
        if (response.data.code === 0) {
          v.selectProductData = response.data.datas;
        }
      });
    },
    // table获取所选值
    getSelectValue(value) {
      let v = this;
      let pos = [];
      Promise.resolve(value.forEach((n, i) => {
        pos.push(n.packageId);
      })).then(() => {
        v.packageIds = pos;
      });
    },
    getSelectValue1(value) {
      let v = this;
      let pos = [];
      Promise.resolve(value.forEach((n, i) => {
        pos.push({
          packageGoodsId: n.packageGoodsId
        });
      })).then(() => {
        v.stockoutArr = pos;
      });
    },
    gotoScanEx() {
      this.$router.push('/wms/scanEx');
    },
    setStockoutIt() {
      let v = this;
      if (v.stockoutArr.length === 0) {
        v.$Message.error(alias93cb5d9a0adb49f980c05a290e356da0.t('key1006035'));
        v.resetStockOutStatus();
        return false;
      }
      let obj = {
        packageIds: v.packageIds,
        markPackageGoodsMissingDetailBos: v.stockoutArr
      };
      v.axios.put(api.set_markPackageGoodsMissing, JSON.stringify(obj)).then(response => {
        if (response.data.code === 0) {
          v.$Message.success(alias93cb5d9a0adb49f980c05a290e356da0.t('key1000131'));
          v.resetStockOutStatus();
          v.pageParamsStatus = true;
          v.stockModel.status = false;
        }
      });
    },
    resetStockOutStatus() {
      let v = this;
      v.setStockoutLoading = false;
      v.$nextTick(function () {
        v.setStockoutLoading = true;
      });
    },
    // 运单号
    selectTabsData(index, value) {
      let v = this;
      v.pageParams.hasTrackingNumber = value;
      v.tabsData.map((item, idx) => {
        item.selected = index === idx;
      });
    },
    // 是否有物流商号
    selectTabsData1(index, value) {
      let v = this;
      v.pageParams.hasThirdPartyNo = value;
      v.tabsData1.map((item, idx) => {
        item.selected = index === idx;
      });
    },
    // 是否有物流商重量
    selectTabsData2(index, value) {
      let v = this;
      v.pageParams.hasCarrierWeight = value;
      v.tabsData2.map((item, idx) => {
        item.selected = index === idx;
      });
    },
    // 是否有物流商运费
    selectTabsData3(index, value) {
      let v = this;
      v.pageParams.hasPostage = value;
      v.tabsData3.map((item, idx) => {
        item.selected = index === idx;
      });
    },
    // 选取面单类型
    labelTypeStatus(index, value) {
      let v = this;
      v.pageParams.labelType = value === 'null' ? null : value;
      v.labelTypeList.forEach((n, i) => {
        n.selected = i === index;
      });
    },
  },
  watch: {
    pageParamsStatus(n) {
      var v = this;
      if (n) {
        v.getList();
        v.pageParamsStatus = false;
      }
    }
  },
};
</script>

<style lang="less" scoped>
.filter_box {
  position: relative;

  .scan_warehouse {
    position: absolute;
    top: -52px;
    right: 0;
    z-index: 999;
  }

  .max_filterItem {
    /deep/ label {
      width: 140px !important;
    }
  }
}
</style>
