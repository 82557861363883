
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';
// 拣货状态
import {setConfigCharacters} from "@/utils/common";

export const YMS_STATUS_LIST = [
  {value: 1, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001235')},
  {value: 2, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001236')},
  {value: 3, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001237')},
  {value: 4, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001238')},
  {value: 5, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001239')},
  {value: 6, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001240')},
  {value: 7, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001241')},
  {value: 8, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001242')},
];

// 出库单状态
export const YMS_PACKAGE_INFO_STATUS = [
  {value: 1, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001243')},
  {value: 2, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001244')},
  {value: 3, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001235')},
  {value: 4, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001245')},
  {value: 5, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001246')},
  {value: 6, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001247')},
  {value: 7, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001248')},
  {value: 8, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001242')}
];

export const PICK_STATUS_LIST = [
  {
    value: 1,
    title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001249')
  },
  {
    value: 2,
    title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001223')
  },
  {
    value: 3,
    title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001238')
  },
  {
    value: 4,
    title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001224')
  },
  {
    value: 5,
    title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001250')
  },
  {
    value: 21,
    title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001251')
  },
  {
    value: 99,
    title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001252')
  }
];

export const YMS_PACKAGE_STATUS = [
  {
    value: 0,
    title: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001222')}`
  },
  {
    value: 9,
    title: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001223')}`
  },
  {
    value: 10,
    title: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001224')}`
  },
  {
    value: 11,
    title: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001225')}`
  },
  {
    value: 12,
    title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001253')
  }
];

export const YMS_SUBPACKAGE_STATUS = [
  {value: 3, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001235')},
  {value: 4, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001245')},
  {value: 5, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001254')},
  {value: 6, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001247')},
  {value: 7, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001225')},
  {value: 8, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001242')}
];

// listing inputType
export const LISTING_INPUT_TYPE = [
  {
    value: '1',
    label: 'text'
  }, {
    value: '2',
    label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000474')
  }, {
    value: '3',
    label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001255')
  }, {
    value: '4',
    label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000473')
  }, {
    value: '5',
    label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001256')
  }
];


// 规则是否类型
export const RULE_TYPE_IS = ['order-service-A044', 'order-service-A045', 'order-service-A042', 'order-service-A040', 'order-service-A035', 'order-service-A036', 'order-service-A037', 'order-service-A038', 'order-service-A047', 'order-service-A048', 'order-service-A046',
  'CORE-YCCR-045', 'DISTRIBUTOR-DCCR-A024', 'DISTRIBUTOR-DCCR-A028'
];

// 规则单个input 类型
export const RULE_TYPE_INPUT = ['CORE-YCCR-003', 'CORE-YCCR-004', 'DISTRIBUTOR-DCCR-A005', 'DISTRIBUTOR-DCCR-A004'];

// 规则 两个checkbox input 类型
export const RULE_TYPE_TCI = [
  'CORE-YCCR-014', 'CORE-YCCR-015', 'CORE-YCCR-016', 'CORE-YCCR-017', 'CORE-YCCR-018', 'CORE-YCCR-019', 'CORE-YPDR-008', 'CORE-YPDR-006', 'CORE-YPDR-009', 'CORE-YCCR-010', 'CORE-YCCR-013', 'DISTRIBUTOR-DCCR-A012', 'DISTRIBUTOR-DCCR-A016', 'DISTRIBUTOR-DCCR-A017', 'DISTRIBUTOR-DCCR-A018', 'DISTRIBUTOR-DCCR-A019', 'DISTRIBUTOR-DCCR-A020', 'DISTRIBUTOR-DCCR-A021', 'DISTRIBUTOR-DCCR-A022'];

// 平台订单状态
export const PLATFORM_ORDER_STATUS = [
  {label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096'), value: 'null'},
  {label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001243'), value: 1},
  {label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001257'), value: 2},
  {label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001258'), value: 3},
  {label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001259'), value: 4},
  {label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001248'), value: 5},
  {label: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001242'), value: 6},
];

// 三方平台订单状态
export const THIRH_PARTY_PLATFORM_ORDER_STATUS = [
  {value: 0, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001260')},
  {value: 1, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001261')},
  {value: 2, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001248')},
  {value: 21, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001262')},
  {value: 3, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001108')},
  {value: 31, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001263')},
  {value: 4, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001242')},
  {value: 41, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001264')}
];

// 供应商--出库单类型
export const OUTBOUNDTYPELIST = [ // 1:销售出库 2:云卖中心仓备货 3:三方仓备货 null:全部
  {value: 0, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')},
  {value: 1, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001265')},
  {value: 2, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001266')},
  {value: 3, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001267')},
  {value: 4, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001268')},
];

// 云卖订单状态
export const CLOUDSALESORDERSTATUS = [
  {selected: true, value: 0, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')},
  {selected: false, value: 1, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001243')},
  {selected: false, value: 2, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001244')},
  {selected: false, value: 3, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001235')},
  {selected: false, value: 4, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001245')},
  {selected: false, value: 5, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001246')},
  {selected: false, value: 6, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001247')},
  {selected: false, value: 7, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001248')},
  {selected: false, value: 8, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001242')}
];

// 销售状态
export const SALESSTATUS = [
  {selected: true, value: null, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')},
  {selected: false, value: 1, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000417')},
  {selected: false, value: 2, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000360')},
  {selected: false, value: 0, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000416')},
];

// 审核状态
export const AUDITSTATUS = [
  {selected: true, value: 'null', title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')},
  {selected: false, value: 0, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001269')},
  {selected: false, value: 1, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001270')},
  {selected: false, value: 2, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001271')},
  {selected: false, value: 3, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000674')}
];

// 费用类型
export const EXPENSETYPE = [
  {selected: true, value: null, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')},
  {selected: false, value: 1, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001272')},
  {selected: false, value: 2, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001273')},
  {selected: false, value: 3, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001274')},
  {selected: false, value: 4, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001275')},
  {selected: false, value: 5, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001276')},
  {selected: false, value: 6, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001277')},
  {selected: false, value: 7, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001278')},
  {selected: false, value: 8, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001279')}
];

// 启用停用状态
export const ENABLEDISABLESTATUS = [
  {selected: true, value: null, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')},
  {selected: false, value: 1, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000516')},
  {selected: false, value: 0, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000517')}
];

//面单类型
export const EACESHEETTYPE = [
  {selected: true, value: null, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')},
  {selected: false, value: 'LOGIS', title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000109')},
  {selected: false, value: 'YMS', title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000110')},
  {selected: false, value: 'NONE', title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000111')},
  {selected: false, value: 'MIX', title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000112')}
];

// 异常状态
export const ABNORMALSTATE = [
  {selected: true, value: 0, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')},
  {selected: false, value: 1, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001280')},
  {selected: false, value: 2, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000357')}
];

// 商品状态
export const COMMODITYSTATUS = [
  {selected: true, value: null, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')},
  {selected: false, value: 0, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000360')},
  {selected: false, value: 1, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000417')},
  {selected: false, value: 2, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000848')},
  {selected: false, value: 3, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000849')}
];

// 费用流水状态
export const EXPENSEFLOWSTATUS = [
  {selected: true, value: null, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')},
  {selected: false, value: 1, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001281')},
  {selected: false, value: 0, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001282')}
];

// 注册来源
export const REGISTER_SOURCE = [
  {value: 0, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')},
  {value: 1, title: 'TONGTOOL'},
  {value: 3, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001283')},
  {value: 4, title: 'Shopify'},
  {value: 5, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001284')},
  {value: 6, title: 'H5'},
  {value: 7, title: 'MIAOSHOU'},
];

// 结算状态(供应商系统) 1 待审核状态 2 待确认 3 等待收款/付款 4 已收款/已付款 5 作废
export const SUPPLIER_SETTLEMENTSTATUS = [
  {selected: true, value: 2, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001285')},
  {selected: false, value: 3, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001286')},
  {selected: false, value: 4, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001287')}
];
// 结算状态(运营系统)
export const YMS_SETTLEMENTSTATUS = [
  {selected: false, value: 'null', title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')},
  {selected: false, value: 1, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001270')},
  {selected: true, value: 2, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001288')},
  {selected: false, value: 3, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001289')},
  {selected: false, value: 4, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001290')}
];
// 语种对应
export const mappingLang = {
  EN: 'usName',  // 英文
  FRA: 'frName', // 法文
  GER: 'deName', // 德文
  SPN: 'esName', //西班牙
  NL: 'nlName',  // 荷兰语
  PLN: 'plName', // 波兰
  PT: 'ptName',  // 葡萄牙
  IT: 'itName',  // 意大利
  CN: 'cnName'   // 中文
};

const fixPath = 'https://www.yms.com/yms-distributor-service/filenode/s'; // 固定写死
export const videos = {
  1: fixPath + alias93cb5d9a0adb49f980c05a290e356da0.t('key1001291'),
  2: fixPath + alias93cb5d9a0adb49f980c05a290e356da0.t('key1001292'),
  3: fixPath + alias93cb5d9a0adb49f980c05a290e356da0.t('key1001293'),
  4: fixPath + alias93cb5d9a0adb49f980c05a290e356da0.t('key1001294'),
  5: fixPath + alias93cb5d9a0adb49f980c05a290e356da0.t('key1001295'),
  6: fixPath + alias93cb5d9a0adb49f980c05a290e356da0.t('key1001296'),
};
export const DELIVERYORDERTYPE = [
  // {value: 1, title: '销售出库'},
  {value: 2, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001266')},
  {value: 3, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001267')},
  {value: 4, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001268')}
];

// 多语种名称
export const MULTILINGUALNAMESLIST = {
  CN: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001297'),
  US: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001298'),
// {type: 'frName', name: '法语'},
// {type: 'deName', name: '德语'},
// {type: 'esName', name: '西班牙语'},
// {type: 'nlName', name: '荷兰语'},
// {type: 'plName', name: '波兰语'},
// {type: 'ptName', name: '葡萄牙语'},
// {type: 'itName', name: '意大利语'}
}

// 消息模板
export const INFORMATIONTYPELIST = [
  {
    value: 1,
    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000357'),
    name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001299'),
    triggerConditionTxt: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001300'),
    dynamicParamList: [alias93cb5d9a0adb49f980c05a290e356da0.t('key1001301')], // 动态参数
    messageTemplate: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001302')
  },
  {
    value: 2,
    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001303'),
    name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001304'),
    dynamicParamList: ['X'], // 动态参数
    messageTemplate: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001305')
  },
  {
    value: 3,
    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001306'),
    name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001307'),
    dynamicParamList: ['X'],
    messageTemplate: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001308'),
  },
  {
    value: 4,
    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001309'),
    name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001310'),
    dynamicParamList: ['X'],
    messageTemplate: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001311')
  },
  {
    value: 5,
    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001312'),
    name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001313'),
    dynamicParamList: ['X'],
    messageTemplate: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001314')
  },
  {
    value: 6,
    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001315'),
    name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001316'),
    dynamicParamList: ['X'],
    messageTemplate: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001317')
  },
  {
    value: 7,
    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000098'),
    name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001318'),
    triggerConditionTxt: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001319'),
    dynamicParamList: [alias93cb5d9a0adb49f980c05a290e356da0.t('key1001301')],
    messageTemplate: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001320')
  },
  {
    value: 8,
    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000360'),
    name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001321'),
    triggerConditionTxt: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001322'),
    dynamicParamList: [alias93cb5d9a0adb49f980c05a290e356da0.t('key1000308'), 'SPU/SKU'],
    messageTemplate: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001323')
  },
  {
    value: 9,
    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001324'),
    name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001325'),
    title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001326'),
    triggerConditionTxt: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001324'),
    dynamicParamList: ['SPU'],
    messageTemplate: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001327')
  },
  {
    value: 10,
    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001328'),
    name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001329'),
    title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001330'),
    triggerConditionTxt: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001328'),
    dynamicParamList: ['SPU'],
    messageTemplate: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001331')
  },
  {
    value: 11,
    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001332'),  // 触发条件的文案
    name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001333'), // 消息类型名称
    title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001334'), // 消息的标题
    triggerConditionTxt: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001332'),
    dynamicParamList: [alias93cb5d9a0adb49f980c05a290e356da0.t('key1000836')],
    messageTemplate: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001335')
  },
  {
    value: 12,
    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001336'),  // 触发条件的文案
    name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001337'), // 消息类型名称
    title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001337'), // 消息的标题
    triggerConditionTxt: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001336'),
    dynamicParamList: [alias93cb5d9a0adb49f980c05a290e356da0.t('key1001338')],
    messageTemplate: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001226')}<span class="highlight_color">${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001227')}</span>${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001228')}${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001229')}`
  },
  {
    value: 13,
    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001339'),
    name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001340'),
    triggerConditionTxt: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001339'),
    dynamicParamList: ['SPU'],
    messageTemplate: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001341')
  },
  {
    value: 14,
    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001342'),
    name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001343'),
    triggerConditionTxt: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001342'),
    dynamicParamList: ['SPU'],
    messageTemplate: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001344')
  },
  {
    value: 15,
    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001345'),
    name: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001230')}`,
    triggerConditionTxt: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001345'),
    dynamicParamList: [`${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000366')}`],
    messageTemplate: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001231')}<span class="highlight_color">【${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001232')}</span>${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001233')}`
  },
  {
    value: 16,
    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001346'),  // 触发条件的文案
    name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001347'), // 消息类型名称
    title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001348'), // 消息的标题
    triggerConditionTxt: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001346'),
    dynamicParamList: [alias93cb5d9a0adb49f980c05a290e356da0.t('key1001349'), alias93cb5d9a0adb49f980c05a290e356da0.t('key1000366')],
    messageTemplate: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001350')
  },
  {
    value: 17,
    text: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001351'),
    name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001352'),
    title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001352'), // 消息的标题
    triggerConditionTxt: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001351'),
    dynamicParamList: ['SPU'],
    messageTemplate: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001353')
  },
];

// 供应商--订单列表--出库单状态
export const SUPPLIEROUTPACKAGEORDERSTATUS = [
  {value: 1, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001235')},
  {value: 2, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001239')},
  {value: 3, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001354')},
  {value: 4, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001225')},
  {value: 5, title: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001234')}`},
  {value: 6, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001355')},
  {value: 7, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001252')},
  {value: 8, title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001247')}
]

// 平台订单类型
export const PLATFORMORDERTYPE = [
  {value: 'null', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')},
  {value: 'GENERAL', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001356')},
  {value: 'TEMU_S', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001357')},
  {value: 'TEMU_P', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001358')},
  {value: 'ONE_STOP_SERVICE', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001359')},
]

// IOSS 类型
export const IOSSTYPE = [
  {value: 1, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001360')},
  {value: 2, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001361')},
  {value: 3, name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001362')},
]

// 翻译之后的云卖订单状态(订单轨迹)
export const YMSORDERSTATUSLIST = [
  {sort: 0, value: 'null', key: 'null', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000096')},
  {sort: 1, value: 0, key: 'createdTime', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001243')},
  {sort: 2, value: 14, key: 'createdTime', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001363')},
  {sort: 3, value: 13, key: 'createdTime', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001364')},
  {sort: 4, value: 1, key: 'printTime', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001365')},
  {sort: 5, value: 2, key: 'pickingFinishTime', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001366')},
  {sort: 6, value: 3, key: 'packingTime', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001367')},
  {sort: 7, value: 4, key: 'supperBoxedTime', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001368')},
  {sort: 8, value: 5, key: 'despatchTime', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001369')},
  {sort: 9, value: 11, key: 'allottedTime', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001370')},
  {sort: 10, value: 6, key: 'allottedTime', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001235')},
  {sort: 11, value: 7, key: 'pickedTime', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001239')},
  {sort: 12, value: 8, key: 'changedLabelTime', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001354')},
  {sort: 13, value: 9, key: 'boxedTime', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001371')},
  {sort: 14, value: 10, key: 'shipedTime', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001372')},
  {sort: 15, value: 12, key: 'cancelledTime', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001242')},
];

export const ATTRIBUTEMULTILINGUALNAME = [
  {key: 'cnName', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000485')},
  {key: 'usName', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001373')},
  {key: 'ukName', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001374')},
  {key: 'auName', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001375')},
  {key: 'euName', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001376')},
  {key: 'frName', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000490')},
  {key: 'deName', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000491')},
  {key: 'esName', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000492')},
  {key: 'nlName', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000493')},
  {key: 'plName', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000494')},
  {key: 'ptName', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000495')},
  {key: 'itName', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000496')},
  {key: 'roName', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000497')},
];

export const ATTRIBUTEVALUEDATA = [
  {key: 'cnValue', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000485')},
  {key: 'usValue', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001373')},
  {key: 'ukValue', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001374')},
  {key: 'auValue', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001375')},
  {key: 'euValue', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001376')},
  {key: 'frValue', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000490')},
  {key: 'deValue', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000491')},
  {key: 'esValue', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000492')},
  {key: 'nlValue', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000493')},
  {key: 'plValue', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000494')},
  {key: 'ptValue', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000495')},
  {key: 'itValue', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000496')},
  {key: 'roValue', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000497')},
];

export const PRODUCTTYPES = [
  {title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000637'), value: 1},
  {title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000639'), value: 2},
  {title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000638'), value: 3},
  {title: 'POD', value: 4},
];

export const VISIBLEAFTERLOGIN = alias93cb5d9a0adb49f980c05a290e356da0.t('key1001377');

// 无站点的平台
export const NOSITEPLATFORMLIST = [
  'wish', 'joom', '1688', 'aliexpress', 'fruugo', 'ozon', 'shopify', 'temu', 'tiktok',
  'walmart', 'aliexpressChoice', 'alibaba', 'temu_choice'
];

