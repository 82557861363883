<template>
  <Button-group class="more_platforms_box">
    <template v-for="(item, index) in platformGroup" v-if="index < defaultPlatformNumber">
      <Button v-if="item.selected" type="primary" @click="getPlatformData(item.platformId, index)" :key="index">
        <template v-if="showNumericalValue">
          {{ item.orderCount !== null ? item.name + item.orderCount : item.name }}
        </template>
        <template v-else>
          {{ item.name }}
        </template>
      </Button>
      <span class="pseudoButton" v-else @click="getPlatformData(item.platformId, index)">
        <template v-if="showNumericalValue">
          {{ item.orderCount !== null ? item.name + item.orderCount : item.name }}
        </template>
        <template v-else>
          {{ item.name }}
        </template>
      </span>
    </template>
    <template v-if="platformGroup.length > defaultPlatformNumber">
      <Dropdown style="margin-left: 10px">
        <Button>{{ $t('key1001059') }}
          <Icon type="md-arrow-dropdown"/>
        </Button>
        <template #list>
          <Dropdown-menu>
            <Dropdown-item v-for="(item, index) in platformGroup" v-if="index >= defaultPlatformNumber" :key="index">
              <div @click="getPlatformData(item.platformId, index)">{{ item.name }}</div>
            </Dropdown-item>
          </Dropdown-menu>
        </template>
      </Dropdown>
    </template>
  </Button-group>
</template>

<script>
import Mixin from "@/components/mixin/common_mixin";

export default {
  name: "morePlatforms",
  mixins: [Mixin],
  props: {
    // 默认展示平台数
    defaultPlatformNumber: {
      type: Number,
      default: 15
    },
    // 数据
    platformList: {
      type: Array,
      default: () => []
    },
    // 默认选中的平台
    defaultPlatform: {
      type: String,
      default: ''
    },
    // 是否从其他地方获取数据源
    dataSource: {
      type: Boolean,
      default: false
    },
    // 对应平台的待处理总数
    pendingData: {
      type: Array,
      default: () => []
    },
    // 是否展示待处理数值 (默认不展示)
    showNumericalValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      platformGroup: []
    };
  },
  created() {
    let v = this;
    if (!v.dataSource) {
      v.getEnablePlatform().then((data) => {
        v.$emit('handlePlatformData', data);
        v.handleData(data);
      });
    } else {
      if (v.showNumericalValue) {
        v.handleData(v.platformList);
      }
    }
  },
  methods: {
    // 处理数据
    handleData(data) {
      let v = this;
      if (data && data.length > 0) {
        data.map((item, index) => {
          // 默认选中shopify平台
          item.selected = false;
          item.orderCount = null;
          if (v.defaultPlatform) {
            if (item.platformId === v.defaultPlatform) {
              item.selected = true;
            }
          } else {
            if (index === 0) {
              item.selected = true;
            }
          }
          if (v.pendingData.length > 0) {
            v.pendingData.map((ele) => {
              if (ele.platformId === item.platformId) {
                item.orderCount = ele.orderCount > 999 ? '(999+)' : `(${ele.orderCount})`;
              }
            })
          }
        });

        v.platformGroup = data;
        localStorage.setItem('platformIdList', JSON.stringify(data));
      }
    },
    // 选取平台
    getPlatformData(platformId, index, platformGroup) {
      let v = this;
      v.platformGroup = platformGroup || v.platformGroup;
      v.platformGroup.map((item) => {
        item.selected = platformId === item.platformId;
      });
      let currentNum = v.defaultPlatformNumber - 1;
      if (index > currentNum) {
        [v.platformGroup[currentNum], v.platformGroup[index]] = [v.platformGroup[index], v.platformGroup[currentNum]];
      }
      v.$emit('selectPlatform', platformId);
      v.$forceUpdate();
    }
  },
  watch: {
    platformList: {
      handler(list) {
        let v = this;
        if (list && list.length > 0) {
          list.map((item) => {
            item.selected = item.platformId === v.defaultPlatform;
          })
          v.platformGroup = JSON.parse(JSON.stringify(list))
        }
      },
      deep: true,
      immediate: true
    },
    pendingData: {
      handler(data) {
        if (this.platformGroup.length > 0) {
          this.platformGroup.map((item) => {
            item.orderCount = null;
            if (data.length > 0) {
              data.map((ele) => {
                if (ele.platformId === item.platformId) {
                  item.orderCount = ele.orderCount > 999 ? '(999+)' : `(${ele.orderCount})`;
                }
              })
            }
          });
          this.$forceUpdate();
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="less" scoped>
.more_platforms_box {
  .pseudoButton {
    position: relative;
    float: left;
    margin-left: -1px;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    line-height: 1.5;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 6px 15px;
    font-size: 12px;
    border-radius: 4px;
    transition: color 0.2s linear, background-color 0.2s linear, border 0.2s linear;
    color: #495060;
    background-color: #f7f7f7;
    border: 1px solid #dddee1;
  }

  .pseudoButton:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  .ivu-btn-group:not(.ivu-btn-group-vertical) > .pseudoButton:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}
</style>
