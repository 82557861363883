<template>
  <publicImport :importTypeList="importTypeList"></publicImport>
</template>

<script>
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';

import publicImport from "@/components/common/publicImport";

export default {
  name: "importTask",
  data() {
    return {
      importTypeList: [
        {value: 'productImport', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000694')},
        {value: 'inventoryImport', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000727')},
        {value: 'productGoodsSuggestPriceImport', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000760')},
        {value: 'productGoodsStatusImport', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005723')},
        {value: 'importSupplierStockOrder', name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004517')},
      ]
    };
  },
  components: {
    publicImport
  }
};
</script>
<style scoped>

</style>
