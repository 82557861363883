<template>
    <Modal v-model="model" :title="$t('key1000604')" width="850" class="product-detail-upload-modal">
      <div class="box">
        <div class="font-weight-bold">1. {{ $t('key1000605') }}</div>
        <p>{{ $t('key1000606') }}</p>
        <div class="text-align-center">
          <img src="@/assets/images/example01.png" />
        </div>
        <div class="font-weight-bold mt10">2. {{ $t('key1000607') }}</div>
        <p>{{ $t('key1000608') }}</p>
        <p>{{ $t('key1000609') }}</p>
        <div class="text-align-center">
          <img src="@/assets/images/example02.png" />
        </div>
      </div>
      <template #footer>
        <Button type="primary" @click="model = false">{{ $t('key1000610') }}</Button>
      </template>
    </Modal>
</template>

<script>
import Mixin from '@/components/mixin/common_mixin';

export default {
  name: 'productDetailUploadStandard',
  mixins: [Mixin],
  data () {
    return {
      model: false
    };
  },
  created () {

  },
  props: {},
  watch: {},
  methods: {
    open () {
      this.model = true
    }
  }
};
</script>

<style scoped>
.box {
  color: #333333;
}
</style>

<style>
.product-detail-upload-modal .ivu-modal-body {
  height: 500px;
  overflow-y: auto;
}
</style>
