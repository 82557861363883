<template>
  <Modal v-model="dailyRecordModal" :title="$t('key1001812')" width="700" :transfer="false" @on-visible-change="dailyRecordChange">
    <h2 class="mb10">{{ packageCode + $t('key1002385') }}</h2>
    <div class="table_box">
      <Table
        ref="tableDom"
        highlight-row
        border
        max-height="500"
        :loading="tableLoading"
        :columns="tableColumns"
        :data="tableData">
      </Table>
    </div>
    <template #footer>
      <div style="text-align: right;">
        <Button type="primary" @click="dailyRecordModal = false">{{ $t('key1000139') }}</Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';
import {getUserNameByUserId} from "@/utils/common";

export default {
  name: "dailyRecordModal",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      dailyRecordModal: false,
      pageParams: {
        pickingId: null,
        nextToken: null,
        pageSize: 15,
        showNotes: 1
      },
      tableColumns: [
        {
          key: 'logContentDesc',
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000220'), // 操作内容描述
          minWidth: 300,
          render(h, params) {
            let content = params.row.logContentDesc;
            content = content.replace(/;/g, '<br/>');
            return h('div', {
              domProps: {
                innerHTML: content
              }
            })
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000222'),
          minWidth: 150,
          key: 'updatedBy',
          align: 'center',
          render(h, params) {
            let name = getUserNameByUserId(params.row.updatedBy, v) || alias93cb5d9a0adb49f980c05a290e356da0.t('key1005595');
            return h('div', name);
          }
        },
        {
          title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000221'),
          key: 'updatedTime',
          align: 'center',
          sortable: true,
          sortType: 'desc',
          minWidth: 180,
          render: (h, params) => {
            return h('div', v.$uDate.dealTime(params.row.updatedTime, 'fulltime'));
          }
        },
      ],
      tableData: [],
      packageCode: null
    }
  },
  mounted() {
    const tableEl = this.$refs.tableDom.$el.querySelector('.ivu-table-body')
    tableEl.addEventListener('scroll', this.handleTableScroll)
  },
  methods: {
    // 初始化数据
    initDailyRecordData(data) {
      let v = this;
      v.packageCode = data.packageCode;
      v.tableData = [];
      (async () => {
        await v.getUserName();
        await v.getLogData(data.packageId);
      })();
    },
    // 获取操作日志的数据
    getLogData(packageId) {
      let v = this;
      v.tableLoading = true;
      v.pageParams.pickingId = packageId;
      v.axios.post(api.get_wmsPickingLog, v.pageParams).then(response => {
        v.tableLoading = false;
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            let list = data.result || [];
            v.pageParams.nextToken = data.nextToken;
            v.tableData = [...v.tableData, ...list];
          }
          v.dailyRecordModal = true;
        }
      });
    },
    // 滚动加载更多日志数据
    handleTableScroll() {
      if (this.pageParams.nextToken) {
        const tableEl = this.$refs.tableDom.$el.querySelector('.ivu-table-body')
        const scrollTop = tableEl.scrollTop
        const clientHeight = tableEl.clientHeight
        const scrollHeight = tableEl.scrollHeight
        if (scrollHeight - scrollTop - clientHeight < 100) {
          this.getLogData(this.pageParams.pickingId);
        }
      }
    },
    // 监听弹窗
    dailyRecordChange(value) {
      if (!value) {
        this.tableData = [];
        this.packageCode = null;
        this.pageParams = {
          pickingId: null,
          nextToken: null,
          pageSize: 15,
          showNotes: 1
        }
      }
    }
  },
  beforeDestroy() {
    if (this.$refs.tableDom) {
      const tableEl = this.$refs.tableDom.$el.querySelector('.ivu-table-body')
      tableEl.removeEventListener('scroll', this.handleTableScroll)
    }
  },
}
</script>

<style scoped>

</style>