import Vue from 'vue';
import Router from 'vue-router';
import config from '@/config';
import store from '@/store/store';
import {
  refreshToken,
  getDistributorErpConfig,
  getAccessToken,
  getTokenByListing,
  getQueryMenu,
  getSinglePageRoleCommon,
  getConfigureLanguage
} from '@/utils/user';
import {getToken} from "@/utils/cookie";
import {
  getCurrencyData,
  getMultiSpecificationAttributeMerchantConfig,
  deleteTicket,
  openClothingCategories,
  getYmsInfoConfig
} from "@/utils/common";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
Vue.use(Router);
let countTime = config.curTime;
const routes = [
  {
    path: '/',
    redirect: '/index',
    component: () => import('@/components/layout/shopping/main'), // 引入主文件
    children: [
      {
        path: '/index', // 商城--首页
        component: () => import('@/views/shopping/index/components/index')
      },
      {
        path: '/shopList', // 商城--商品列表页
        component: () => import('@/views/shopping/common/shopList')
      },
      {
        path: '/shopDetails/:id', // 商城--商品列表详情页
        component: () => import('@/views/shopping/index/components/shopDetails'),
        name: '/shopDetails'
      },
      {
        path: '/legalDeclaration', // 法律声明
        component: () => import('@/views/shopping/index/components/legalDeclaration')
      },
      {
        path: '/privacyPolicy', // 隐私政策
        component: () => import('@/views/shopping/index/components/privacyPolicy')
      },
      {
        path: '/termsOfService', // 服务条款
        component: () => import('@/views/shopping/index/components/termsOfService')
      },
      {
        path: '/introduce', // YMS商城介绍
        component: () => import('@/views/shopping/index/components/introduce')
      },
      {
        path: '/introduce', // YMS商城介绍
        component: () => import('@/views/shopping/index/components/introduce')
      },
      {
        path: '/introduce', // YMS商城介绍
        component: () => import('@/views/shopping/index/components/introduce')
      },
      {
        path: '/businessIntroduce', // 平台业务介绍
        component: () => import('@/views/shopping/index/components/businessIntroduce')
      },
      {
        path: '/contactUs', // 平台业务介绍
        component: () => import('@/views/shopping/index/components/contactUs')
      }
    ]
  },
  {
    path: '/help', // 商城--首页
    component: () => import('@/views/shopping/index/components/help')
  },
  {
    path: '/supplierEntryGuidelines', // 商城--供应商入驻指南
    component: () => import('@/views/shopping/index/components/supplierEntryGuidelines')
  },
  {
    path: '*',
    redirect: '/index'
  }
];
const router = new Router({
  mode: 'hash',
  routes
});
router.beforeEach((to, from, next) => {
  localStorage.setItem('system', 'shopping');
  const localUrl = window.location.href;
  const token = getToken();
  getYmsInfoConfig().then(() => {
    if (localUrl.includes('from=listing')) {
      store.commit('fromListing', true);
      getConfigureLanguage().then(() => {
        getTokenByListing().then(() => {
          Promise.all([getDistributorErpConfig(), getMultiSpecificationAttributeMerchantConfig()]).then(result => {
            if (result[0]) {
              next();
            }
          });
        });
      });
      return;
    }
    if (localUrl.indexOf('ticket') > 0) {
      getAccessToken(localUrl, true).then((result) => {
        if (result) {
          window.location.href = deleteTicket(localUrl);
          window.location.reload();
        }
      }).catch(() => {
        window.location.href = deleteTicket(localUrl);
        window.location.reload();
      });
    } else {
      refreshToken(countTime, true);
      if (token) {
        getQueryMenu('yms-distributor-service').then(() => {
          openClothingCategories();
          getConfigureLanguage().then(() => {
            Promise.all([getDistributorErpConfig(), getSinglePageRoleCommon('shop_shopList', 'yms-distributor-service'),
              getMultiSpecificationAttributeMerchantConfig(), getCurrencyData()]).then(result => {
              if (result[0] && result[1]) {
                next();
              }
            });
          });
        });
      } else {
        getConfigureLanguage().then(() => {
          Promise.all([getDistributorErpConfig(), getMultiSpecificationAttributeMerchantConfig()]).then(result => {
            if (result[0]) {
              next();
            }
          });
        });
      }
    }
  });
});
export default router;
