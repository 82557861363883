export default {
  key1000000: `复制`,
  key1000001: `标题：`,
  key1000002: `链接地址：`,
  key1000003: `内容已被停用或删除！`,
  key1000004: `复制成功！`,
  key1000005: `请输入菜单关键词`,
  key1000006: `请输入正确的关键词`,
  key1000007: `请输入关键词`,
  key1000008: `上架工具`,
  key1000009: `通途Listing`,
  key1000010: `YMS商城`,
  key1000011: `请输入关键字`,
  key1000012: `搜索`,
  key1000013: `全部商品类目`,
  key1000014: `当前有 `,
  key1000015: ` 条订单超时未发，`,
  key1000016: `请点击处理`,
  key1000017: `运营管理`,
  key1000018: `仓储管理`,
  key1000019: `查看更多`,
  key1000020: `时区：`,
  key1000021: `您当前的时间：`,
  key1000022: `世界标准时间：`,
  key1000023: `登出`,
  key1000024: `运营系统`,
  key1000025: `供应商系统`,
  key1000026: `分销商系统`,
  key1000027: `商户编号：`,
  key1000028: `通途客户编号：`,
  key1000029: `正在退出...`,
  key1000030: `成为分销商`,
  key1000031: `立即注册`,
  key1000032: `成为供应商`,
  key1000033: `供应商入驻`,
  key1000034: `入驻指南`,
  key1000035: `供应商登录`,
  key1000036: `帮助中心`,
  key1000037: `常见问题`,
  key1000038: `法律声明`,
  key1000039: `服务条款`,
  key1000040: `隐私政策`,
  key1000041: `关于我们`,
  key1000042: `YMS商城介绍`,
  key1000043: `平台业务`,
  key1000044: `联系我们`,
  key1000045: `业务咨询`,
  key1000046: `分销商在线服务`,
  key1000047: `深圳云卖供应链管理有限公司`,
  key1000048: `备案号：粤ICP备2020140415号-1`,
  key1000049: `多`,
  key1000050: `海量优质货源`,
  key1000051: `20w+SKU，每日上新500+`,
  key1000052: `快`,
  key1000053: `一键上传全平台`,
  key1000054: `开店必备，5分钟开好店铺`,
  key1000055: `好`,
  key1000056: `专业团队品质保障`,
  key1000057: `专业拣货、分拣、打包、发货等服务`,
  key1000058: `省`,
  key1000059: `0库存开店，无资金压力`,
  key1000060: `无需囤货备货，先出单后付款`,
  key1000061: `请输入要搜索的关键词`,
  key1000062: `上传图片搜索`,
  key1000063: `一站式跨境服装分销`,
  key1000064: `上传文件格式有误,`,
  key1000065: `请选择jpg、jpeg、png格式文件`,
  key1000066: `文件大小受限,文件`,
  key1000067: `太大,`,
  key1000068: `不能超过20M`,
  key1000069: `全部商品`,
  key1000070: `已停售`,
  key1000071: `售卖国：`,
  key1000072: `商户号：`,
  key1000073: `退出`,
  key1000074: `请登录`,
  key1000075: `免费注册`,
  key1000076: `消息`,
  key1000077: `入门指引`,
  key1000078: `个人中心`,
  key1000079: `我的收藏`,
  key1000080: `我的订单`,
  key1000081: `Hi，欢迎来到TONGTOOL！`,
  key1000082: `Hi，欢迎来到YMS商城！`,
  key1000083: `搜全站`,
  key1000084: `搜本店`,
  key1000085: `操作成功!`,
  key1000086: `商品名称：`,
  key1000087: `请选择`,
  key1000088: `无匹配数据`,
  key1000089: `加载中`,
  key1000090: `暂无数据`,
  key1000091: `更多`,
  key1000092: `删除`,
  key1000093: `暂无筛选结果`,
  key1000094: `筛选`,
  key1000095: `重置`,
  key1000096: `全部`,
  key1000097: `确定`,
  key1000098: `取消`,
  key1000099: `自定义列展示`,
  key1000100: `自定义列显示`,
  key1000101: `恢复默认`,
  key1000102: `保存为默认`,
  key1000103: `保存成功`,
  key1000104: `更多筛选项`,
  key1000105: `最近一周`,
  key1000106: `最近一个月`,
  key1000107: `最近三个月`,
  key1000108: `现货一件代发`,
  key1000109: `物流面单`,
  key1000110: `云仓面单`,
  key1000111: `无面单`,
  key1000112: `混合面单`,
  key1000113: `文件大小超出限制，最大为10M`,
  key1000114: `系统繁忙，上传文件失败！`,
  key1000115: `文件格式有误`,
  key1000116: `收起`,
  key1000117: `没有相关记录`,
  key1000118: `系统繁忙，请重新尝试`,
  key1000119: `密码不能为空`,
  key1000120: `密码不能包含中文`,
  key1000121: `请填写邮件地址`,
  key1000122: `不是有效的邮箱或者邮箱格式不正确`,
  key1000123: `手机号码不能为空`,
  key1000124: `手机号码格式不正确`,
  key1000125: `只允许输入数字格式号码`,
  key1000126: `固定电话号码格式不正确`,
  key1000127: `省市区地址不能为空`,
  key1000128: `请选择完整的省市区地址`,
  key1000129: `确认是否删除`,
  key1000130: `温馨提示`,
  key1000131: `操作成功`,
  key1000132: `包裹号`,
  key1000133: `资料覆盖提醒`,
  key1000134: `新的售卖产品资料将覆盖已经生成过的售卖产品资料，生成后不可恢复，是否生成？`,
  key1000135: `立即生成`,
  key1000136: `请先勾选要生成售卖资料的数据！`,
  key1000137: `任务生成成功，请至生成售卖资料模块中查看结果。`,
  key1000138: `当前用户没有未开启仓储管理设置`,
  key1000139: `知道了`,
  key1000140: `当前用户已开启仓储管理设置`,
  key1000141: `调价`,
  key1000142: `跳转失败！`,
  key1000143: `普货`,
  key1000144: `纯电池`,
  key1000145: `配套电池`,
  key1000146: `液体`,
  key1000147: `药品`,
  key1000148: `粉末`,
  key1000149: `危险品`,
  key1000150: `内置电池`,
  key1000151: `纽扣电池`,
  key1000152: `带磁`,
  key1000153: `1天`,
  key1000154: `2天`,
  key1000155: `3天`,
  key1000156: `4天`,
  key1000157: `5天`,
  key1000158: `6天`,
  key1000159: `7天`,
  key1000160: ` 相同`,
  key1000161: `图片`,
  key1000162: `未匹配`,
  key1000163: `指定产品`,
  key1000164: `指定站点`,
  key1000165: `指定帐号`,
  key1000166: `并且`,
  key1000167: `或者`,
  key1000168: `以`,
  key1000169: `不以`,
  key1000170: `运输类型为空`,
  key1000171: `添加成功`,
  key1000172: `操作失败，请重新尝试`,
  key1000173: `删除失败,请重新尝试`,
  key1000174: `指定店铺`,
  key1000175: `指定国家`,
  key1000176: `指定分类`,
  key1000177: `请将邮编段填写完整`,
  key1000178: `指定基础物流`,
  key1000179: `多品`,
  key1000180: `单品`,
  key1000181: `拣货单:`,
  key1000182: `拣货仓库：`,
  key1000183: `物流商:`,
  key1000184: `拣货单类型：`,
  key1000185: `出库单数量：`,
  key1000186: `创建时间：：`,
  key1000187: `SKU总数：`,
  key1000188: `拣货人员：`,
  key1000189: `SKU种类：`,
  key1000190: `所在库区`,
  key1000191: `所在库位`,
  key1000192: `需拣货数量`,
  key1000193: `中文描述`,
  key1000194: `产品规格`,
  key1000195: `英文描述`,
  key1000196: `批次号`,
  key1000197: `物流商`,
  key1000198: `等`,
  key1000199: `物流渠道`,
  key1000200: `多种物流渠道`,
  key1000201: `选择引用的渠道`,
  key1000202: `请选择物流商：`,
  key1000203: `请选择物流渠道：`,
  key1000204: `物流渠道名称不能为空`,
  key1000205: `关闭`,
  key1000206: `SKU信息`,
  key1000207: `规格：`,
  key1000208: `分配数量`,
  key1000209: `可用数量`,
  key1000210: `可用数量必须是大于等于0的整数`,
  key1000211: `供应商库存详情`,
  key1000212: `中心仓库存详情`,
  key1000213: `处理中...`,
  key1000214: `选择类目`,
  key1000215: `已选类名：`,
  key1000216: `操作日志`,
  key1000217: `属性`,
  key1000218: `属性值`,
  key1000219: `操作类型`,
  key1000220: `操作内容描述`,
  key1000221: `操作时间`,
  key1000222: `操作人`,
  key1000223: `分销商操作`,
  key1000224: `供应商`,
  key1000225: `原值：`,
  key1000226: `修改后：`,
  key1000227: `编辑属性`,
  key1000228: `必填`,
  key1000229: `非必填`,
  key1000230: `推荐填写`,
  key1000231: `规格属性`,
  key1000232: `普通属性`,
  key1000233: `属性值多选`,
  key1000234: `属性值单选`,
  key1000235: `是尺码属性`,
  key1000236: `不是尺码属性`,
  key1000237: `允许自定义属性值`,
  key1000238: `不允许自定义属性值`,
  key1000239: `编辑属性值`,
  key1000240: `菜单设置`,
  key1000241: `快捷搜索侧边栏菜单`,
  key1000242: `仓库：`,
  key1000243: `上传文件：`,
  key1000244: `文件名称：`,
  key1000245: `导入的数据已存在时：`,
  key1000246: `请选择文件`,
  key1000247: `模板下载`,
  key1000248: `忽略`,
  key1000249: `更新`,
  key1000250: `覆盖`,
  key1000251: `追加`,
  key1000252: `导入成功，当前导入的ymsSku不存在：`,
  key1000253: `仓库`,
  key1000254: `导入`,
  key1000255: `上传文件失败，请重新尝试`,
  key1000256: `提示`,
  key1000257: `上传成功`,
  key1000258: `库位所属库区不匹配：`,
  key1000259: `所选仓库内无此库区，请核对后重试`,
  key1000260: `所选仓库内无此库位，请核对后重试`,
  key1000261: `存在重复数据，请确认后重试`,
  key1000262: `导入新增库存货主角色存在异常，请核对后重试`,
  key1000263: `导入新增库存货品供应商商户错误`,
  key1000264: `下载文件模板失败！`,
  key1000265: `请先选择上传文件`,
  key1000266: `导出类型：`,
  key1000267: `任务编号：`,
  key1000268: `导出时间：`,
  key1000269: `选择日期`,
  key1000270: `查询`,
  key1000271: `序号`,
  key1000272: `任务编号`,
  key1000273: `导出类型`,
  key1000274: `导出时间`,
  key1000275: `导出状态`,
  key1000276: `正在导出`,
  key1000277: `系统操作`,
  key1000278: `备注`,
  key1000279: `操作`,
  key1000280: `文件保留7天，超过7天将不能下载！`,
  key1000281: `下载文件`,
  key1000282: `导出中`,
  key1000283: `导出完成`,
  key1000284: `导出失败`,
  key1000285: `日期格式有误`,
  key1000286: `消息中心`,
  key1000287: `查看全部通知`,
  key1000288: `请输入`,
  key1000289: `已选`,
  key1000290: `项`,
  key1000291: `提交`,
  key1000292: `跳过`,
  key1000293: `其他`,
  key1000294: `必填项不能为空！`,
  key1000295: `请输入类目关键词`,
  key1000296: `全部分类`,
  key1000297: `商品可售卖国:`,
  key1000298: `商品售卖国设置`,
  key1000299: `商品售卖国修改`,
  key1000300: `设置售卖国成功！`,
  key1000301: `编辑售卖国成功！`,
  key1000302: `主料选择`,
  key1000303: `工厂：`,
  key1000304: `工厂SPU`,
  key1000305: `请输入工厂SPU`,
  key1000306: `工厂SKU`,
  key1000307: `请输入工厂SKU`,
  key1000308: `商品名称`,
  key1000309: `请输入商品名称`,
  key1000310: `工厂不能为空`,
  key1000311: `商品信息`,
  key1000312: `工厂SPU：`,
  key1000313: `工厂SKU：`,
  key1000314: `所属分类`,
  key1000315: `POD平台未绑定`,
  key1000316: `暂未绑定POD平台，请绑定后重试。`,
  key1000317: `前往绑定`,
  key1000318: `请输入承运商名称`,
  key1000319: `承运商名称：`,
  key1000320: `承运人`,
  key1000321: `承运商名称`,
  key1000322: `承运人电话`,
  key1000323: `是否可用`,
  key1000324: `可用`,
  key1000325: `不可用`,
  key1000326: `选取`,
  key1000327: `承运商的名称不能为空!`,
  key1000328: `稍后提醒`,
  key1000329: `审核成功，系统将在指定时间发送公告`,
  key1000330: `退回成功`,
  key1000331: `取消成功`,
  key1000332: `操作成功！`,
  key1000333: `添加多张图片`,
  key1000334: `已选择上传图片：`,
  key1000335: `图片要求：`,
  key1000336: `建议上传800*800像素以上正方形实拍大图，比如：800*800px，1000*1000px，1500*1500px，最大不超过5MB，，若不符合该尺寸则会导致上传失败。`,
  key1000337: `图片清晰无水印；`,
  key1000338: `包含商品整体款式和商品细节，比如：自拍模特图、海报图、白底图、3D图、细节图等。`,
  key1000339: `4.点击图片可使用系统提供的裁切工具助您更好的展示商品的特点！`,
  key1000340: `点击裁剪`,
  key1000341: `删除图片`,
  key1000342: `上传图片`,
  key1000343: `确定上传`,
  key1000344: `图片加载失败`,
  key1000345: `文件大小超出限制，最大为5M！`,
  key1000346: `上传图片格式错误！系统仅支持JPG、JPEG、PNG、WEBP、BMP格式图片上传`,
  key1000347: `上传图片中...`,
  key1000348: `已阅读并接受`,
  key1000349: `和`,
  key1000350: `开通成功！`,
  key1000351: `需阅读并接受服务条款和隐私政策`,
  key1000352: `缺货数量不能为空`,
  key1000353: `预计到货时间不能为空`,
  key1000354: `请选择预计到货时间`,
  key1000355: `按订单查看`,
  key1000356: `按SKU查看`,
  key1000357: `缺货`,
  key1000358: `不缺货`,
  key1000359: `补货`,
  key1000360: `停售`,
  key1000361: `系统将自动将此SKU进行停售`,
  key1000362: `弹窗内单次最多可处理100行订单数据`,
  key1000363: `稍后处理`,
  key1000364: `确认`,
  key1000365: `超时订单处理`,
  key1000366: `订单号`,
  key1000367: `数量`,
  key1000368: `缺货数量`,
  key1000369: `预计到货时间`,
  key1000370: `标记缺货`,
  key1000371: `以下订单已超时，请更新预计发货时间。`,
  key1000372: `所选订单商品信息如下：`,
  key1000373: `预计到货时间要大于今天的日期！`,
  key1000374: `标记成功！`,
  key1000375: `导入运费模板`,
  key1000376: `计费方式：`,
  key1000377: `计重方式：`,
  key1000378: `选择文件：`,
  key1000379: `导入处理：`,
  key1000380: `（材积重：`,
  key1000381: `批量上传`,
  key1000382: `下载`,
  key1000383: `保存`,
  key1000384: `普通计费`,
  key1000385: `首续重计费`,
  key1000386: `固定收费`,
  key1000387: `实重`,
  key1000388: `抛重`,
  key1000389: `两者比较`,
  key1000390: `覆盖原有数据`,
  key1000391: `国家相同覆盖，不同追加`,
  key1000392: `选择上传文件`,
  key1000393: `选择指定异常`,
  key1000394: `本条件用于筛选异常状况，以下条件符合任何一项，即认为符合本条件。`,
  key1000395: `姓名字符中空格数小于`,
  key1000396: `俄罗斯邮政要求收件人地址为全名，此处可输入2`,
  key1000397: `姓名字符数小于`,
  key1000398: `输入1时，相当于收件人姓名为空`,
  key1000399: `地址字符数小于`,
  key1000400: `地址1+地址2的总字符长度`,
  key1000401: `城市名字字符数小于`,
  key1000402: `输入1时，相当于城市名称为空`,
  key1000403: `省/州名字字符数小于`,
  key1000404: `输入1时，相当于省州名称为空`,
  key1000405: `邮编字符数小于`,
  key1000406: `输入1时，相当于邮编为空`,
  key1000407: `电话号码数字字符个数小于`,
  key1000408: `电话、手机两个号码必须都小于该值才认为该条件成立`,
  key1000409: `审核通过后，自动生成`,
  key1000410: `分类：`,
  key1000411: `商品状态：`,
  key1000412: `中文标题：`,
  key1000413: `供应商SPU：`,
  key1000414: `SKU数量：`,
  key1000415: `商品总数量：`,
  key1000416: `暂存`,
  key1000417: `在售`,
  key1000418: `供应商SKU`,
  key1000419: `规格`,
  key1000420: `商品状态`,
  key1000421: `供货价`,
  key1000422: `上架`,
  key1000423: `打印条码`,
  key1000424: `打印二维码`,
  key1000425: `是否确认删除？`,
  key1000426: `打印提示`,
  key1000427: `SKU中包含中文，暂不支持条形码打印，请使用二维码打印!`,
  key1000428: `驳回原因`,
  key1000429: `大量现货`,
  key1000430: `放心推广`,
  key1000431: `图片裁剪`,
  key1000432: `裁切须知`,
  key1000433: `1.为了保证您的商品可以更好的展示给分销商并带来更多的订单，上传的商品图片长宽尺码必须≥800。`,
  key1000434: `2.若原始上传的图片尺码长宽≤800，则需要重新上传图片。`,
  key1000435: `3.商户可通过裁切，调整已上传图片的展示比例，突出商品的特点。`,
  key1000436: `图片宽度：`,
  key1000437: `图片高度：`,
  key1000438: `预览`,
  key1000439: `+重新上传图片`,
  key1000440: `图片尺寸至少要≥`,
  key1000441: `且`,
  key1000442: `文件大小超出限制，最大为`,
  key1000443: `加载图片中...`,
  key1000444: `裁剪图片.jpeg`,
  key1000445: `退回`,
  key1000446: `退回说明：`,
  key1000447: `请输入退回说明`,
  key1000448: `退回后此公告将流转为待修改状态，请确认是否退回修改？`,
  key1000449: `退回说明不能为空`,
  key1000450: `日志内容`,
  key1000451: `改为`,
  key1000452: `操作日期`,
  key1000453: `运营系统操作`,
  key1000454: `供应商系统操作`,
  key1000455: `SPU价格调整`,
  key1000456: `部分SKU价格调整`,
  key1000457: `SKU价格调整`,
  key1000458: `允许不同库区组的出库单进入同一张拣货单？`,
  key1000459: `允许不同库区的出库单进入同一张拣货单？`,
  key1000460: `允许不同物流商的包裹进入同一张拣货单？`,
  key1000461: `允许相同物流商不同邮寄方式的包裹进入同一张拣货单？`,
  key1000462: `每张常规拣货单最大包裹数`,
  key1000463: `每张备货拣货单最大包裹数`,
  key1000464: `允许`,
  key1000465: `不允许`,
  key1000466: `所属站点：`,
  key1000467: `规格/普通属性：`,
  key1000468: `属性值单选/多选：`,
  key1000469: `填写要求：`,
  key1000470: `是否尺码属性：`,
  key1000471: `是否需填写比例：`,
  key1000472: `普通`,
  key1000473: `多选`,
  key1000474: `单选`,
  key1000475: `否`,
  key1000476: `是`,
  key1000477: `属性名称`,
  key1000478: `可选值`,
  key1000479: `允许自定义`,
  key1000480: `尺码组名：`,
  key1000481: `添加属性值`,
  key1000482: `属性已被使用，不可进行修改。`,
  key1000483: `属性值已被使用，不可进行修改。`,
  key1000484: `新增属性`,
  key1000485: `中文名称`,
  key1000486: `英文-US`,
  key1000487: `英文-UK`,
  key1000488: `英文-AU`,
  key1000489: `英文-EU`,
  key1000490: `法语名称`,
  key1000491: `德语名称`,
  key1000492: `西班牙语名称`,
  key1000493: `荷兰语名称`,
  key1000494: `波兰语名称`,
  key1000495: `葡萄牙语名称`,
  key1000496: `意大利语名称`,
  key1000497: `罗马尼亚语名称`,
  key1000498: `法码`,
  key1000499: `德码`,
  key1000500: `西班牙码`,
  key1000501: `荷兰码`,
  key1000502: `波兰码`,
  key1000503: `葡萄牙码`,
  key1000504: `意大利码`,
  key1000505: `罗马尼亚码`,
  key1000506: `已存在相同名称的属性，请修改属性名称后再提交！`,
  key1000507: `中文、英文-US属性名称不能为空!`,
  key1000508: `同语种的属性值都要必填!`,
  key1000509: `同一个语种下的属性值不能相同！`,
  key1000510: `新增成功`,
  key1000511: `上传失败`,
  key1000512: `是否取消该尺码组别？`,
  key1000513: `是否确认取消该尺码组别？确认后该尺码组别不会显示在属性值里，所填写的数据也会清空。`,
  key1000514: `确定取消`,
  key1000515: `状态`,
  key1000516: `启用`,
  key1000517: `停用`,
  key1000518: `参考图片`,
  key1000519: `简介`,
  key1000520: `请填写国家尺码信息`,
  key1000521: `同语种下属性值不能相同`,
  key1000522: `选择要刊登的店铺`,
  key1000523: `站点：`,
  key1000524: `刊登店铺：`,
  key1000525: `刊登模版：`,
  key1000526: `设置`,
  key1000527: `请选择刊登店铺`,
  key1000528: `请选择刊登模版`,
  key1000529: `刊登草稿失败`,
  key1000530: `消息通知`,
  key1000531: `没有更多数据了...`,
  key1000532: `稍后再看`,
  key1000533: `您的商品：`,
  key1000534: `，审核未通过，驳回原因：`,
  key1000535: `处理中`,
  key1000536: `已标记已读！`,
  key1000537: `不能为空`,
  key1000538: `供应商币种`,
  key1000539: `添加供应商币种`,
  key1000540: `添加分销商币种`,
  key1000541: `更换币种`,
  key1000542: `分销商币种`,
  key1000543: `币种`,
  key1000544: `库存事务明细`,
  key1000545: `库存变化类型：`,
  key1000546: `操作时间：`,
  key1000547: `选择开始日期`,
  key1000548: `选择结束日期`,
  key1000549: `到`,
  key1000550: `excel导出`,
  key1000551: `可用库存变化`,
  key1000552: `已分配库存变化`,
  key1000553: `事务类型`,
  key1000554: `配货`,
  key1000555: `取消配货`,
  key1000556: `出库`,
  key1000557: `盘点`,
  key1000558: `分拣撤回`,
  key1000559: `加工入库`,
  key1000560: `加工出库`,
  key1000561: `可用库存结存`,
  key1000562: `分配库存变化`,
  key1000563: `分配库存结存`,
  key1000564: `相关单据号`,
  key1000565: `收货`,
  key1000566: `分配`,
  key1000567: `取消分配`,
  key1000568: `库存移动`,
  key1000569: `库存冻结`,
  key1000570: `取消冻结`,
  key1000571: `库存调整`,
  key1000572: `调拨出库`,
  key1000573: `收货上架`,
  key1000574: `调整顺序`,
  key1000575: `取消出库`,
  key1000576: `同步库存`,
  key1000577: `取消收货`,
  key1000578: `回收库存`,
  key1000579: `归库`,
  key1000580: `导入库存`,
  key1000581: `更新库存`,
  key1000582: `获取列表数据失败`,
  key1000583: `选择日期时间`,
  key1000584: `请输入模板名称`,
  key1000585: `已生成的报表文件，系统最多保留7天，超过7天不支持下载。`,
  key1000586: `查看导出列表`,
  key1000587: `导出模板：`,
  key1000588: `管理导出模板`,
  key1000589: `返回`,
  key1000590: `时间范围：`,
  key1000591: `请选择导出维度：`,
  key1000592: `请勾选需导出的字段：`,
  key1000593: `全选`,
  key1000594: `存为新模板`,
  key1000595: `导出`,
  key1000596: `未选择`,
  key1000597: `新增成功！`,
  key1000598: `模板名称不能为空！`,
  key1000599: `是否要删除当前模板？`,
  key1000600: `删除成功`,
  key1000601: `选择邮寄方式`,
  key1000602: `全部收起`,
  key1000603: `全部展开`,
  key1000604: `图片上传规范`,
  key1000605: `轮播图上传规范`,
  key1000606: `推荐按以下顺序上传图片，合理清晰的图片顺序方便查看商品。`,
  key1000607: `单张图片尺寸`,
  key1000608: `尺寸建议800*800px以上（800*800px，1000*1000px，1500*1500px）最大不超过5MB。`,
  key1000609: `比例为正方形，合适的尺寸能看清楚细节。`,
  key1000610: `我知道了`,
  key1000611: `enter可以输入自定义属性`,
  key1000612: `请选择规格`,
  key1000613: `基本信息`,
  key1000614: `类型：`,
  key1000615: `商品类目：`,
  key1000616: `商品货号/SPU：`,
  key1000617: `请输入商品货号/SPU`,
  key1000618: `选择主料：`,
  key1000619: `商品轮播图：`,
  key1000620: `批量编辑组装信息：`,
  key1000621: `图片类型：`,
  key1000622: `商品标题组成:商品特征+商品品类即可，不需要加年份、eBay、 亚马逊等字眼`,
  key1000623: `商品属性`,
  key1000624: `请输入属性值`,
  key1000625: `价格库存`,
  key1000626: `服务与承诺`,
  key1000627: `商品服务：`,
  key1000628: `发货时效：`,
  key1000629: `商品销售建议`,
  key1000630: `查看示例`,
  key1000631: `组装信息`,
  key1000632: `、主属性图片不能为空`,
  key1000633: `供货价格`,
  key1000634: `设置此商品在`,
  key1000635: `累计销售达到一定数量后进行优惠的规则，此优惠将直接给到分销商，但分销商无法直接查看设置的优惠规则，仅在分销商达到某一分层的数量后才能查看下一分层优惠，请放心设置。`,
  key1000636: `提交审批`,
  key1000637: `普通商品`,
  key1000638: `组装商品`,
  key1000639: `原材料`,
  key1000640: `选择商品类目`,
  key1000641: `修改类目`,
  key1000642: `准确选择类目有利于商品曝光，促进转化。`,
  key1000643: `为了方便管理，可以自定义商品编码，比如：货号，发布成功后生效。`,
  key1000644: `选择工厂主料`,
  key1000645: `重新选择`,
  key1000646: `首图`,
  key1000647: `上传网络图片`,
  key1000648: `首图优先带背景模特拼图，没有拼图时选择模特正面图，带背景模特图按一定顺序排列。`,
  key1000649: `查看规范`,
  key1000650: `图片要求`,
  key1000651: `建议上传800*800像素以上正方形实拍大图，比如：800*800px，1000*1000px，1500*1500px，最大不超过5MB，若不符合该尺寸则会导致上传失败。`,
  key1000652: `推荐JPG、JPEG格式图片，但平台支持PNG、WEBP、BMP格式图片上传（受限平台限制会将非JPG、JPEG格式都转化为JPEG格式，但会导致图片会有略微失真）`,
  key1000653: `编辑`,
  key1000654: `自拍模特图`,
  key1000655: `网红图`,
  key1000656: `实物摆拍图`,
  key1000657: `自动生成`,
  key1000658: `Listing商品属性信息：`,
  key1000659: `展开填写更多属性`,
  key1000660: `以下属性需填写属性值比例：`,
  key1000661: `请先选择商品类目`,
  key1000662: `请准确填写属性，更多的商品属性将有利于提高下单转化率。`,
  key1000663: `商品规格`,
  key1000664: `仅支持第一组规格设置图片，套装首图要选择套装图，每个规格最多上传5张图片，建议尺寸：800*800px。`,
  key1000665: `多规格排序`,
  key1000666: `规格明细`,
  key1000667: `自定义列`,
  key1000668: `尺码表`,
  key1000669: `请先添加商品规格`,
  key1000670: `固定金额`,
  key1000671: `折扣`,
  key1000672: `添加分层`,
  key1000673: `提交审核后暂不上架`,
  key1000674: `审核通过`,
  key1000675: `驳回`,
  key1000676: `该商品类目成功匹配YMS商品类目信息`,
  key1000677: `“，确认选择该主料后将会替换当前商品类目信息，商品规格等设置信息会被新类目对应的信息覆盖。`,
  key1000678: `商品分类不能为空`,
  key1000679: `商品货号/SPU不能为空`,
  key1000680: `商品轮播图不能为空`,
  key1000681: `图片类型不能为空`,
  key1000682: `累计购买数量`,
  key1000683: `至`,
  key1000684: `不可小于等于左区间`,
  key1000685: `优惠金额`,
  key1000686: `折扣系数`,
  key1000687: `请输入优惠金额,如：1.00`,
  key1000688: `请输入折扣系数,如：0.1`,
  key1000689: `分层价格参考`,
  key1000690: `原价：`,
  key1000691: `优惠价：`,
  key1000692: `无限`,
  key1000693: `删除分层`,
  key1000694: `商品`,
  key1000695: `属性值已被停用，请更换为相近的属性值`,
  key1000696: `属性值异常，请更换属性值`,
  key1000697: `基础属性的可选值最多只能选取5个!`,
  key1000698: `此属性已存在，请输入其他属性名。`,
  key1000699: `更新成功`,
  key1000700: `选项未填写完整`,
  key1000701: `商品中文名称不能为空！`,
  key1000702: `属性值比例不能为空`,
  key1000703: `属性值比例必须是大于0的正整数`,
  key1000704: `每个属性的属性值比例总和要等于100%`,
  key1000705: `规格属性存在已停用属性值，更换为相近的属性值后重试。`,
  key1000706: `规格属性属性值异常，请更换属性值后重试。`,
  key1000707: `基础属性的属性值异常，请更换属性值后重试。`,
  key1000708: `审核成功!`,
  key1000709: `均码`,
  key1000710: `、输入值后enter`,
  key1000711: ` 自动生成`,
  key1000712: `自动生成sku规则：商品货号/SPU + 规格属性`,
  key1000713: `包装尺寸（长，宽，高cm）`,
  key1000714: `包装长宽高各边最长不超过40cm，泳装除外，春夏款式高度最高不超过3cm，最低不低于1cm`,
  key1000715: `主料尺寸（长、宽、高cm）：`,
  key1000716: `长`,
  key1000717: `宽`,
  key1000718: `高`,
  key1000719: `长宽高`,
  key1000720: `重量`,
  key1000721: `重量(g)`,
  key1000722: `重量填写最大码克重`,
  key1000723: `主料重量（g）：`,
  key1000724: `供货价（CNY）`,
  key1000725: `供货价（`,
  key1000726: `建议供货价小于或等于1688价格`,
  key1000727: `库存`,
  key1000728: `库存为整数`,
  key1000729: `是否组装`,
  key1000730: `主料SKU信息`,
  key1000731: `加工主料未选择`,
  key1000732: `点击选择加工主料`,
  key1000733: `辅料工艺`,
  key1000734: `请先选择主料信息`,
  key1000735: `点击选择辅料工艺`,
  key1000736: `辅料工艺未选择`,
  key1000737: `位置&素材`,
  key1000738: `设置第一列'位置&素材'信息后，勾选可使拥有相同可用打印位置的商品获得相同'位置&素材'设置，但您需自行判断，不同规格的商品，是否存在信息需要微调。`,
  key1000739: `查看`,
  key1000740: `位置&素材未设置`,
  key1000741: `(属性值已被停用)`,
  key1000742: `预览图`,
  key1000743: `活动价`,
  key1000744: `活动价（`,
  key1000745: `销售价配置`,
  key1000746: `百分比加价数值不能为空`,
  key1000747: `固定加价数值不能为空`,
  key1000748: `仅支持输入数字及小数点`,
  key1000749: `减价不能大于等于供货价`,
  key1000750: `下浮不能大于等于100%`,
  key1000751: `最多只允许输入四位小数`,
  key1000752: `上浮`,
  key1000753: `下浮`,
  key1000754: `加价`,
  key1000755: `减价`,
  key1000756: `百分比加价`,
  key1000757: `固定加价`,
  key1000758: `请输入浮动比例`,
  key1000759: `请输入数值`,
  key1000760: `销售价`,
  key1000761: `销售价（`,
  key1000762: `商品货号/SPU不能为空!`,
  key1000763: `尺码`,
  key1000764: `单个规格最多上传5张图片`,
  key1000765: `关闭提示`,
  key1000766: `内容未保存提醒`,
  key1000767: `未保存的内容将丢失，请确认是否退出？`,
  key1000768: `确认退出`,
  key1000769: `标题不能为空`,
  key1000770: `选择主料确认`,
  key1000771: `该主料不可用`,
  key1000772: `该主料商品类目不属于YMS商品类目表中类目内容，请更换选择主料，或手动绑定工厂加工商品。`,
  key1000773: `切换主料SKU确认`,
  key1000774: `切换主料SKU后，部位&素材设置信息需要重新填写。请确认是否切换主料SKU?`,
  key1000775: `确认切换`,
  key1000776: `填充的数据不能为0`,
  key1000777: `填充`,
  key1000778: `计费模型编辑`,
  key1000779: `支持中文、英文、二字码搜索`,
  key1000780: `材积(kg)：`,
  key1000781: `材积重：`,
  key1000782: `（材积(kg) = 长(cm) × 宽(cm) × 高(cm) ÷`,
  key1000783: `验证运费是否正确：重量(`,
  key1000784: `已选中国家：`,
  key1000785: `清空`,
  key1000786: `设置邮编`,
  key1000787: `查看邮编`,
  key1000788: `新增重量区间`,
  key1000789: `验证运费`,
  key1000790: `测试运费：`,
  key1000791: `运费 = (重量 * 单价 + 挂号费 + 操作费) * (1 + 附加费)`,
  key1000792: `重量小于等于首重，运费=首重运费*（1+附加费）`,
  key1000793: `重量大于首重，运费={首重运费 +向上取整(总重量-首重)/续重单位重量) * 续重单价 + 挂号费 + 操作费}*（1+附加费）`,
  key1000794: `运费 = (单价 + 挂号费 + 操作费) * (1 + 附加费)`,
  key1000795: `不在重量区间之内`,
  key1000796: `起始重量`,
  key1000797: `截止重量`,
  key1000798: `挂号费`,
  key1000799: `操作费`,
  key1000800: `附加费（%）`,
  key1000801: `价格`,
  key1000802: `首重（`,
  key1000803: `首重运费(`,
  key1000804: `续重单位重量（`,
  key1000805: `续重单价(`,
  key1000806: `截止重量要大于起始重量`,
  key1000807: `请选择国家`,
  key1000808: `价格不能为空`,
  key1000809: `首重不能为空`,
  key1000810: `首重运费不能为空`,
  key1000811: `续重单位重量不能为空`,
  key1000812: `续重单价不能为空`,
  key1000813: `材积重不能为空`,
  key1000814: `订阅状态：`,
  key1000815: `选择确认`,
  key1000816: `创建后若后续平台订单中存在此商品都无需手动匹配YMS商品，可直接通过此关联关系进行此YMS商品匹配。提示：已创建的关联关系可通过路径“商品管理-收藏的商品-操作项[设置关联关系]”进行查看/编辑;（未收藏的商品也可创建关联关系，可在收藏后对其进行查看/编辑）`,
  key1000817: `条`,
  key1000818: `已选择：`,
  key1000819: `设为主料`,
  key1000820: `是否确认选择该YMS商品进行订单商品匹配？`,
  key1000821: `创建关联关系`,
  key1000822: `SPU,支持多个按行分隔`,
  key1000823: `SKU,支持多个按行分隔`,
  key1000824: `中心仓库存`,
  key1000825: `添加商品`,
  key1000826: `已收藏`,
  key1000827: `未收藏`,
  key1000828: `供应商SPU`,
  key1000829: `请输入供应商SPU,支持多个按行分隔`,
  key1000830: `请输入SPU,支持多个逗号和按行分隔`,
  key1000831: `请输入SKU,支持多个逗号和按行分隔`,
  key1000832: `请输入商品名称,支持多个逗号和按行分隔`,
  key1000833: `供应商商户号`,
  key1000834: `商品标题`,
  key1000835: `请输入商品标题`,
  key1000836: `供应商名称`,
  key1000837: `供应商库存`,
  key1000838: `商品SPU：`,
  key1000839: `商品SKU：`,
  key1000840: `可用库存`,
  key1000841: `近30天销量`,
  key1000842: `选择`,
  key1000843: `我的库存`,
  key1000844: `在途库存：`,
  key1000845: `可用库存：`,
  key1000846: `单价`,
  key1000847: `长宽高(cm)`,
  key1000848: `试卖`,
  key1000849: `清仓`,
  key1000850: `商品数量不能为空!`,
  key1000851: `请先选择商品！`,
  key1000852: `该商品现已存在关联关系！`,
  key1000853: `系统公告`,
  key1000854: `不再提醒`,
  key1000855: `商品搜索：`,
  key1000856: `主料`,
  key1000857: `辅料`,
  key1000858: `请输入完整SPU或商品名称`,
  key1000859: `请输入完整SPU / SKU或商品名称`,
  key1000860: `辅料的数量不能为空！`,
  key1000861: `主料的数量不能为空！`,
  key1000862: `请先选择主料数据！`,
  key1000863: `你没有访问该页面的权限`,
  key1000864: `返回上一页`,
  key1000865: `抱歉，您访问的页面不存在！`,
  key1000866: `返回系统首页`,
  key1000867: `返回有权限菜单`,
  key1000868: `请先勾选需要展示的自定义列表字段！`,
  key1000869: `介质材料`,
  key1000870: `标签大小`,
  key1000871: `起止信息`,
  key1000872: `最大支持100mm`,
  key1000873: `字体`,
  key1000874: `每pt约占0.35mm宽`,
  key1000875: `*注:打印内容可拖动改变位置`,
  key1000876: `打印`,
  key1000877: `不打印`,
  key1000878: `条码`,
  key1000879: `自定义内容`,
  key1000880: `产品库位`,
  key1000881: `产品库位预览占位`,
  key1000882: `产品绑定库区`,
  key1000883: `产品绑定库区预览占位`,
  key1000884: `参考编号`,
  key1000885: `参考编号预览占位`,
  key1000886: `通过拖拽调整规格排序`,
  key1000887: `打印箱唛`,
  key1000888: `创建时间：`,
  key1000889: `打印数量：`,
  key1000890: `打印YMS面单`,
  key1000891: `打印数量必须大于0`,
  key1000892: `无可用选项`,
  key1000893: `分类检索`,
  key1000894: `最近使用：`,
  key1000895: `已选类目：`,
  key1000896: `切换类目确认`,
  key1000897: `切换类目后，商品属性及规格等信息需要重新填写。请确认是否切换分类?`,
  key1000898: `请选择分类`,
  key1000899: `请输入手机号码`,
  key1000900: `请输入图形验证码`,
  key1000901: `验证码图片`,
  key1000902: `请输入验证码`,
  key1000903: `请输入密码`,
  key1000904: `请再次确认密码`,
  key1000905: `邮箱（选填）`,
  key1000906: `完善账号信息`,
  key1000907: `温馨提示:为了你的账号安全，请绑定手机号码，绑定后可通过手机号码和密码登录。`,
  key1000908: `绑定手机号码`,
  key1000909: `暂不绑定`,
  key1000910: `发送验证码`,
  key1000911: `图形验证码不能为空`,
  key1000912: `验证码不能为空`,
  key1000913: `重新发送`,
  key1000914: `手机验证码已发送！`,
  key1000915: `确认新密码不能为空`,
  key1000916: `密码与确认密码不一致`,
  key1000917: `绑定手机成功`,
  key1000918: `请先勾选已阅读并接受`,
  key1000919: `编辑模板`,
  key1000920: `模板名称：`,
  key1000921: `编辑成功！`,
  key1000922: `支持批量输入，请换行分隔`,
  key1000923: `截取邮编前`,
  key1000924: `位`,
  key1000925: `邮编前`,
  key1000926: `位：`,
  key1000927: `邮编：`,
  key1000928: `邮编不能为空`,
  key1000929: `邮编格式只能是数字或者字母`,
  key1000930: `订单取消提示`,
  key1000931: `本次作业中存在已取消的订单，订单所含出库单详情信息如下。`,
  key1000932: `继续分拣`,
  key1000933: `移除已取消的出库单`,
  key1000934: `出库单号`,
  key1000935: `打印配置`,
  key1000936: `常规订单面单`,
  key1000937: `长：`,
  key1000938: `宽：`,
  key1000939: `速卖通全托管货品标签`,
  key1000940: `速卖通全托管物流面单`,
  key1000941: `常规订单面单长度和宽度不能为空！`,
  key1000942: `速卖通全托管货品标签长度和宽度不能为空！`,
  key1000943: `速卖通全托管物流面单长度和宽度不能为空！`,
  key1000944: `保存成功！`,
  key1000945: `商品驳回通知`,
  key1000946: `以下商品审核被驳回，请修改后重新提交审核:`,
  key1000947: `下次提醒`,
  key1000948: `已复制！`,
  key1000949: `选择要刊登的平台`,
  key1000950: `此类目暂未维护平台映射，如有需要请咨询客服。`,
  key1000951: `暂无可支持刊登的平台！`,
  key1000952: `未绑定店铺`,
  key1000953: `你的账号暂时未绑定店铺，或绑定的店铺已过期。`,
  key1000954: `从地址添加图片`,
  key1000955: `图片地址：`,
  key1000956: `请填写图片地址`,
  key1000957: `链接地址格式不正确`,
  key1000958: `获取上传图片失败！`,
  key1000959: `新品`,
  key1000960: `已授权列表`,
  key1000961: `可输入代号、账户名进行搜索`,
  key1000962: `代号`,
  key1000963: `账户名`,
  key1000964: `有效`,
  key1000965: `无效`,
  key1000966: `更新店铺`,
  key1000967: `添加店铺`,
  key1000968: `关键字`,
  key1000969: `规则`,
  key1000970: `可输入多个，中间用逗号或换行隔开`,
  key1000971: `可输入多个，中间用英文逗号隔开`,
  key1000972: `请输入关键字...`,
  key1000973: `多邮编换行请求半角输入逗号隔开`,
  key1000974: `请选择平台`,
  key1000975: `请输入运输类型名称搜索`,
  key1000976: `新增运输类型`,
  key1000977: `您确定要删除吗？`,
  key1000978: `美元(USD)`,
  key1000979: `或欧元(EUR)`,
  key1000980: `或英镑(GBP)`,
  key1000981: `或澳元(AUD)`,
  key1000982: `或人民币(CNY)`,
  key1000983: `请输入SKU，多个SKU用逗号或换行隔开`,
  key1000984: `请选择或输入所在地`,
  key1000985: `请选择店铺，可输入搜索`,
  key1000986: `请选择物流`,
  key1000987: `十日达订单即X日达订单，如5日达、10日达`,
  key1000988: `指定范围`,
  key1000989: `指定类型`,
  key1000990: `开头`,
  key1000991: `结尾`,
  key1000992: `指定规律`,
  key1000993: `指定SKU`,
  key1000994: `指定仓库`,
  key1000995: `或`,
  key1000996: `指定平台`,
  key1000997: `已选条件`,
  key1000998: `订单包含货品:`,
  key1000999: `体积重计重因子`,
  key1001000: `包裹货品总数量满足以下选中的条件：`,
  key1001001: `订单重量满足以下全部条件：`,
  key1001002: `订单货品长度满足以下全部条件：`,
  key1001003: `订单货品宽度满足以下全部条件：`,
  key1001004: `订单货品高度满足以下全部条件：`,
  key1001005: `订单货品体积满足以下全部条件`,
  key1001006: `订单货品总数量满足以下选中的条件：`,
  key1001007: `订单收货邮编字符长度满足以下选中的条件:`,
  key1001008: `订单包含货品：`,
  key1001009: `并且上述货品的数量总和满足以下选中的所有条件：`,
  key1001010: `与体积重取重对比取重的作为订单重量计算`,
  key1001011: `提示：订单中每件货品在商品管理中记录的最长边为a1、中间值为b1、最短边为c，所有`,
  key1001012: `a1中的最大值为a，所有b1中的最大值为b，订单中所有货品的短边c相加为d，则a、b、`,
  key1001013: `d中的最大值为订单货品长度，中间值为订单货品宽度，最小值为订单货品高度。体积`,
  key1001014: `重(g)=(a*b*d)/计重因子*1000。`,
  key1001015: `提示：订单中每件货品在商品管理中记录的最长边为a1、中间值为b1、最短边为c，`,
  key1001016: `所有a1中的最大值为a，所有b1中的最大值为b，订单中所有货品的短边c相加为d，`,
  key1001017: `则a、b、d中的最大值为订单货品长度，中间值为订单货品宽度，最小值为订单货品高度。`,
  key1001018: `则a、b、d中的最大值为订单货品长度，中间值为订单货品宽度，最小值为订单货品`,
  key1001019: `高度，a*b*d即订单货品体积。`,
  key1001020: `订单包含多个交易（且运输类型完全相同`,
  key1001021: `订单仅包含一个交易`,
  key1001022: `订单包含多个交易（且运输类型不完全相同`,
  key1001023: `指定条件`,
  key1001024: `订单收件人电话符合以下选中的条件：`,
  key1001025: `移动电话可读字符长度≤`,
  key1001026: `固定电话可读字符长度≤`,
  key1001027: `移动电话前缀`,
  key1001028: `指定字符串`,
  key1001029: `指定要在地址(仅街道1+街道2，不含国家/地区省市信息)中查找的字符：`,
  key1001030: `新增`,
  key1001031: `指定长度`,
  key1001032: `订单地址信息字符长度小于：`,
  key1001033: `*此处地址信息由街道1与街道2合并组成，不包含国家/地区、省州、城市信息。`,
  key1001034: `,并且`,
  key1001035: `,或者`,
  key1001036: `包含`,
  key1001037: `指定格式`,
  key1001038: `订单邮编至少符合以下选中的条件中的任意一个条件(输入内容仅限数字、英文字母、中划线和空隔)：`,
  key1001039: `指定异常`,
  key1001040: `且邮编在`,
  key1001041: `邮编段`,
  key1001042: `邮编列表`,
  key1001043: `已选择`,
  key1001044: `注：其他币种转化成美元,所有选项都必须填写`,
  key1001045: `买家支付的运费转换为币种：`,
  key1001046: `之后满足以下全部条件：`,
  key1001047: `指定的商品状态`,
  key1001048: `并且为`,
  key1001049: `指定货品`,
  key1001050: `指定邮寄方式`,
  key1001051: `包裹总金额包含多订单合并到一包裹中的所有订单的金额，不同币种的订单的金额将被统一按汇率转换为如下设置中的币种。`,
  key1001052: `包裹总金额转换为币种：`,
  key1001053: `指定要在买家ID中查找的字符：`,
  key1001054: `规则名称：`,
  key1001055: `可选条件`,
  key1001056: `请指定`,
  key1001057: `未选择规则`,
  key1001058: `规则名称不能为空`,
  key1001059: `更多平台`,
  key1001060: `批量配置标签`,
  key1001061: `标签：`,
  key1001062: `请选择标签`,
  key1001063: `添加标签`,
  key1001064: `删除标签`,
  key1001065: `导入类型：`,
  key1001066: `请输入任务编号`,
  key1001067: `导入时间：`,
  key1001068: `导入中`,
  key1001069: `导入完成`,
  key1001070: `导入失败`,
  key1001071: `导入类型`,
  key1001072: `导入文件`,
  key1001073: `导入时间`,
  key1001074: `导入状态`,
  key1001075: `成功/失败`,
  key1001076: `成功`,
  key1001077: `失败`,
  key1001078: `位置&素材设置`,
  key1001079: `点击选择部位`,
  key1001080: `点击输入`,
  key1001081: `部位不能为空`,
  key1001082: `请先选择部位`,
  key1001083: `可印区域不能为空`,
  key1001084: `不能超过可印区域长度`,
  key1001085: `不能超过可印区域宽度`,
  key1001086: `请先输入印刷尺寸`,
  key1001087: `添加`,
  key1001088: `位置`,
  key1001089: `部位示意图`,
  key1001090: `可印区域（长、宽cm）`,
  key1001091: `印刷尺寸（长、宽cm）`,
  key1001092: `素材`,
  key1001093: `素材编码`,
  key1001094: `供应商用于标识素材的信息`,
  key1001095: `成品效果图`,
  key1001096: `切换部位确认`,
  key1001097: `切换部位后，印刷尺寸、素材等信息需要重新输入、请确认是否切换部位？`,
  key1001098: `文件大小超出限制，最大为20M！`,
  key1001099: `有必填项未填！`,
  key1001100: `跟踪信息`,
  key1001101: `运单号：`,
  key1001102: `状态：`,
  key1001103: `待查询`,
  key1001104: `查询中`,
  key1001105: `未查到`,
  key1001106: `运输中`,
  key1001107: `等待领取`,
  key1001108: `已签收`,
  key1001109: `投递失败`,
  key1001110: `运输过久`,
  key1001111: `可能异常`,
  key1001112: `申请成为分销商`,
  key1001113: `寻找商品`,
  key1001114: `收藏商品`,
  key1001115: `商品刊登&下载资料包`,
  key1001116: `下单发货`,
  key1001117: `订单管理`,
  key1001118: `财务管理`,
  key1001119: `去注册`,
  key1001120: `点击商城首页右上角“成为分销商”，使用手机号注册或使用通途账号授权登录。`,
  key1001121: `去浏览`,
  key1001122: `搜索关键字，找到想要分销的商品。`,
  key1001123: `在分类导航，找到想要分销的商品。`,
  key1001124: `在首页和核心页面，找到想要分销的商品。`,
  key1001125: `去收藏`,
  key1001126: `在商品列表页，找到商品后，点击“收藏”按钮收藏商品，以便刊登时使用。`,
  key1001127: `在商品详情页，点击“立即收藏”按钮收藏商品，以便刊登时使用。`,
  key1001128: `4.商品刊登&下载资料包`,
  key1001129: `商品刊登`,
  key1001130: `使用通途Listing，将商品一键刊登到ebay、Aliexpress、Wish、Amazon等海外电商平台。`,
  key1001131: `下载资料包`,
  key1001132: `您也可以在“商品列表”、“商品详情页”或“商品管理”页面，免费下载资料包，再到第三方销售平台商家后台上架商品。`,
  key1001133: `基础设置`,
  key1001134: `启用物流`,
  key1001135: `在通途ERP2.0，启用第三方物流，通途系统已内置了行业内用户使用数较多，口碑较好的一些物流商，您可以根据需要选择并启用。`,
  key1001136: `启用邮寄方式`,
  key1001137: `选择希望启用哪种邮寄方式，启用后会提示“邮寄方式启用成功”。`,
  key1001138: `仓库设置`,
  key1001139: `在第三方仓库中选择“云卖供应链“启用。`,
  key1001140: `绑定物流渠道`,
  key1001141: `选择“云卖供应链“仓库，在右侧点击”绑定物流渠道“，选择物流方式后，点“确认”按钮。`,
  key1001142: `生成通途SKU`,
  key1001143: `在通途ERP2.0的“商品管理-云卖分销商品”模块，点击已收藏的商品，在商品详情中，点击“生成商品资料”。`,
  key1001144: `在通途ERP2.0中，选择“发货管理-托管仓库-等待配货”模块，仓库选择“云卖供应链”，点击“云卖下单”，余额不足时，可联系客服充值。`,
  key1001145: `去查看`,
  key1001146: `在“订单管理-我的订单“模块，进行订单查询，进入“订单详情页”可以查看发货情况。`,
  key1001147: `在“财务管理”模块，可以查询充值记录和查看费用明细。`,
  key1001148: `发货方式：`,
  key1001149: `运输方式：`,
  key1001150: `物流方案：`,
  key1001151: `发货地址：`,
  key1001152: `物流商：`,
  key1001153: `物流单号：`,
  key1001154: `YMS物流`,
  key1001155: `自有物流`,
  key1001156: `快递`,
  key1001157: `YMS上门揽收`,
  key1001158: `请在`,
  key1001159: `账户管理-基本信息`,
  key1001160: `中维护发货地址信息`,
  key1001161: `请务必提前与YMS进行线下确认，是否可进行上门揽收！！`,
  key1001162: `发货时货品需贴YMS商品条码，大包外需粘贴箱唛在显眼处！`,
  key1001163: `结束装箱`,
  key1001164: `标记发货成功，提单号：`,
  key1001165: `请选择物流方案`,
  key1001166: `请选择发货地址`,
  key1001167: `请选择物流商`,
  key1001168: `物流单号不能为空`,
  key1001169: `物流信息`,
  key1001170: `标记发货`,
  key1001171: `修改物流信息`,
  key1001172: `中国`,
  key1001173: `您的浏览器不支持websocket！`,
  key1001174: `分钟前`,
  key1001175: `小时前`,
  key1001176: `天前`,
  key1001177: `周前`,
  key1001178: `个月前`,
  key1001179: `年前`,
  key1001180: `周日`,
  key1001181: `周一`,
  key1001182: `周二`,
  key1001183: `周三`,
  key1001184: `周四`,
  key1001185: `周五`,
  key1001186: `周六`,
  key1001187: `天`,
  key1001188: `小时`,
  key1001189: `分钟`,
  key1001190: `1分钟`,
  key1001191: `刚刚`,
  key1001192: `供应商操作`,
  key1001193: `该功能需使用通途打印控件，请下载安装`,
  key1001194: `点击下载`,
  key1001195: `如果已安装，请开启通途打印控件`,
  key1001196: `当前浏览器 Not support websocket`,
  key1001197: `连接菜鸟打印控件失败,请确认是否安装或启用菜鸟打印控件`,
  key1001198: `账户余额不足`,
  key1001199: `你的账户余额不足，请充值足够的金额后，再提交订单！`,
  key1001200: `去充值`,
  key1001201: `加载中...`,
  key1001202: `请求超时`,
  key1001203: `账户已被停用，若需重新启用账户，请联系`,
  key1001204: `运营人员。`,
  key1001205: `您已有`,
  key1001206: `账号，可直接使用此`,
  key1001207: `账号作为供应商进行入驻。`,
  key1001208: `账号开通分销商账户。`,
  key1001209: `没有权限访问该系统`,
  key1001210: `亲爱的通途用户，您没有权限访问该系统!`,
  key1001211: `请联系，系统管理员！`,
  key1001212: `账单待确认提示`,
  key1001213: `存在未确认账单，请及时确认，若账单生成后14天内未确认账单系统将默认账单无误，自动确认账单。`,
  key1001214: `查看账单`,
  key1001215: `稍后确认`,
  key1001216: `账户停用提示`,
  key1001217: `成为供应商确认`,
  key1001218: `前往入驻`,
  key1001219: `成为分销商确认`,
  key1001220: `确认开通`,
  key1001221: `您已有通途账号，可直接使用此账号开通分销商账户。`,
  key1001222: `未揽收`,
  key1001223: `已揽收`,
  key1001224: `已分拣`,
  key1001225: `已出库`,
  key1001226: `提单，`,
  key1001227: `【提单号】`,
  key1001228: `，存在货品多发，已转为备货库存存放于`,
  key1001229: `中心仓。`,
  key1001230: `订单自动取消通知`,
  key1001231: `您的订单`,
  key1001232: `订单号】`,
  key1001233: `，已超时自动取消，请及时关注。`,
  key1001234: `直接出库`,
  key1001235: `待拣货`,
  key1001236: `拣货中`,
  key1001237: `待分拣`,
  key1001238: `分拣中`,
  key1001239: `待包装`,
  key1001240: `包装中`,
  key1001241: `包装完成`,
  key1001242: `已取消`,
  key1001243: `已创建`,
  key1001244: `备货中`,
  key1001245: `已拣货`,
  key1001246: `已换单`,
  key1001247: `已装箱`,
  key1001248: `已发货`,
  key1001249: `等待揽收`,
  key1001250: `已上架`,
  key1001251: `已入库`,
  key1001252: `已作废`,
  key1001253: `揽收分拣异常`,
  key1001254: `已打单`,
  key1001255: `单选可自定值`,
  key1001256: `多选可自定义值`,
  key1001257: `已匹配商品`,
  key1001258: `已匹配物流`,
  key1001259: `已下单`,
  key1001260: `核查中`,
  key1001261: `待发货`,
  key1001262: `部分发货`,
  key1001263: `部分签收`,
  key1001264: `部分取消`,
  key1001265: `销售出库`,
  key1001266: `自有仓备货`,
  key1001267: `三方仓备货`,
  key1001268: `多发补单`,
  key1001269: `编辑中`,
  key1001270: `待审核`,
  key1001271: `审核驳回`,
  key1001272: `货品金额`,
  key1001273: `包裹处理费`,
  key1001274: `退费`,
  key1001275: `物流处理费`,
  key1001276: `补贴金额`,
  key1001277: `国内快递费`,
  key1001278: `清关费`,
  key1001279: `订单取消处理费`,
  key1001280: `正常`,
  key1001281: `完成扣款`,
  key1001282: `预扣款`,
  key1001283: `商城注册`,
  key1001284: `人工添加`,
  key1001285: `待确认`,
  key1001286: `待收款`,
  key1001287: `已收款`,
  key1001288: `待供应商确认`,
  key1001289: `待打款`,
  key1001290: `已打款`,
  key1001291: `/yms-distributor-service/video/YMS功能介绍.mp4`,
  key1001292: `/yms-distributor-service/video/店铺授权.mp4`,
  key1001293: `/yms-distributor-service/video/刊登模板设置.mp4`,
  key1001294: `/yms-distributor-service/video/一键刊登（YMS).mp4`,
  key1001295: `/yms-distributor-service/video/一键刊登规则.mp4`,
  key1001296: `/yms-distributor-service/video/注册云卖供应链.mp4`,
  key1001297: `中文`,
  key1001298: `英文`,
  key1001299: `订单缺货提醒`,
  key1001300: `订单状态标记为缺货时`,
  key1001301: `订单编号`,
  key1001302: `你的订单：<span class="highlight_color">【订单编号】</span>缺货，请及时关注！`,
  key1001303: `备货`,
  key1001304: `订单备货提醒`,
  key1001305: `你有<span class="highlight_color">【X】</span>笔订单，备货已超时，请及时备货！`,
  key1001306: `拣货`,
  key1001307: `订单拣货提醒`,
  key1001308: `你有<span class="highlight_color">【X】</span>笔订单，拣货已超时，请及时拣货！`,
  key1001309: `换单`,
  key1001310: `订单换单提醒`,
  key1001311: `你有<span class="highlight_color">【X】</span>笔订单，换单已超时，请及时换单！`,
  key1001312: `装箱`,
  key1001313: `订单装箱提醒`,
  key1001314: `你有<span class="highlight_color">【X】</span>笔订单，装箱已超时，请及时装箱！`,
  key1001315: `发货`,
  key1001316: `订单发货提醒`,
  key1001317: `你有<span class="highlight_color">【X】</span>笔订单，发货已超时，请及时发货！`,
  key1001318: `订单取消提醒`,
  key1001319: `订单状态标记为取消时`,
  key1001320: `分销商已取消订单：<span class="highlight_color">【订单编号】</span>，请及时关注发货情况`,
  key1001321: `商品停售通知`,
  key1001322: `供应商标记SPU或SKU停售时`,
  key1001323: `尊敬的分销商您好：您收藏的：<span class="highlight_color">【商品名称】</span>，商品编码：<span class="highlight_color">【SPU/SKU】</span>已停售，如果您已上架该商品，请及时下架。`,
  key1001324: `商品描述或图片被修改时`,
  key1001325: `商品信息修改通知`,
  key1001326: `商品<span class="highlight_color">【SPU】</span>信息修改通知`,
  key1001327: `尊敬的分销商您好：您收藏的商品 <span class="highlight_color">【SPU】</span>，描述/图片已修改，如果您已上架该商品，请及时更新成最新的产品信息。`,
  key1001328: `商品价格被修改时`,
  key1001329: `商品调价通知`,
  key1001330: `商品<span class="highlight_color">【SPU】</span>调价通知`,
  key1001331: `尊敬的分销商您好：由于成本变动，您收藏的商品 <span class="highlight_color">【SPU】</span>，价格有调整，请及时关注。`,
  key1001332: `关注的供应商上新商品通过审核时`,
  key1001333: `商品上新通知`,
  key1001334: `您关注的供应商有商品上新`,
  key1001335: `您关注的供应商：<span class="highlight_color">【供应商名称】</span>有商品上新。`,
  key1001336: `入库分拣结束时，提单内存在多收货品时`,
  key1001337: `货品多发通知`,
  key1001338: `提单号`,
  key1001339: `通过分销商配置的自动规则调整库存或上架工具状态时`,
  key1001340: `商品状态自动同步通知`,
  key1001341: `您订阅的商品<span class="highlight_color">【SPU】</span>，已停售，已根据规则自动调整相关信息，请及时关注。`,
  key1001342: `通过分销商配置的自动规则调整在线刊登价格时`,
  key1001343: `在线刊登自动调价通知`,
  key1001344: `您订阅的商品<span class="highlight_color">【SPU】</span>，供货价格有调整，在线刊登价格根据规则自动调整，请及时关注。`,
  key1001345: `通过分销商配置的超时时间自动取消订单时`,
  key1001346: `通过平台订单自动下单由于余额不足导致下单失败时`,
  key1001347: `余额不足下单失败通知`,
  key1001348: `余额不足通知`,
  key1001349: `平台`,
  key1001350: `您的余额不足，<span class="highlight_color">【平台】</span>订单<span class="highlight_color">【订单号】</span>自动下单失败，请及时充值。`,
  key1001351: `商品审核被驳回时`,
  key1001352: `商品审核驳回通知`,
  key1001353: `您的商品：<span class="highlight_color">【SPU】</span>，审核未通过，驳回原因：XXXXXXXXX。`,
  key1001354: `待装箱`,
  key1001355: `缺货中`,
  key1001356: `常规单`,
  key1001357: `Temu样品订单`,
  key1001358: `Temu货品订单`,
  key1001359: `速卖通全托管订单`,
  key1001360: `使用已有IOSS`,
  key1001361: `物流代缴`,
  key1001362: `手动录入`,
  key1001363: `待下单`,
  key1001364: `等待在途库存`,
  key1001365: `待商家拣货`,
  key1001366: `待商家复核`,
  key1001367: `待商家打包`,
  key1001368: `快递运输中`,
  key1001369: `到达中心仓`,
  key1001370: `中心仓已到货`,
  key1001371: `待物流商上门取件`,
  key1001372: `已交付物流商`,
  key1001373: `英文-US名称`,
  key1001374: `英文-UK名称`,
  key1001375: `英文-AU名称`,
  key1001376: `英文-EU名称`,
  key1001377: `登录后可见`,
  key1001378: `原收件名称`,
  key1001379: `原收件邮箱`,
  key1001380: `原收件电话`,
  key1001381: `原收件用户`,
  key1001382: `原收国家编码`,
  key1001383: `原收件人手机`,
  key1001384: `原收件收货地址1`,
  key1001385: `原收件收货地址2`,
  key1001386: `原收件城市`,
  key1001387: `原收件人州/省`,
  key1001388: `原收件人邮编`,
  key1001389: `原收件人护照编码`,
  key1001390: `订单[`,
  key1001391: `]，修改地址成功:`,
  key1001392: `付款，实付金额：`,
  key1001393: `分销商已付款，实付金额：`,
  key1001394: `调价;货品原价总金额：`,
  key1001395: `;分销商应付金额：`,
  key1001396: `;供应商应收金额：`,
  key1001397: `已到仓`,
  key1001398: `标记为非`,
  key1001399: `订单,清空商品&物流信息`,
  key1001400: `自动匹配订单商品或物流失败`,
  key1001401: `原始单订单取消`,
  key1001402: `未匹配到任何物流`,
  key1001403: `匹配基础物流失败`,
  key1001404: `取消订单成功`,
  key1001405: `自动匹配物流成功：`,
  key1001406: `订单`,
  key1001407: `自动匹配物流成功:`,
  key1001408: `]，匹配物流特殊规则：[`,
  key1001409: `]，匹配适用范围:`,
  key1001410: `匹配方案规则:`,
  key1001411: `手工匹配商品成功：平台SKU：`,
  key1001412: `，匹配`,
  key1001413: `手工匹配商品失败：平台SKU：`,
  key1001414: `，未匹配`,
  key1001415: `自动匹配商品成功：平台SKU：`,
  key1001416: `自动匹配商品失败：平台SKU：`,
  key1001417: `SKU失败，SKU已停售`,
  key1001418: `下单失败，分销商余额不足`,
  key1001419: `变更物流信息，原物流方式：`,
  key1001420: `原运单号：`,
  key1001421: `原物流商单号：`,
  key1001422: `标发货失败，原因：`,
  key1001423: `标发货成功，运单号：`,
  key1001424: `取消订单失败，原因：`,
  key1001425: `下单失败`,
  key1001426: `下单成功`,
  key1001427: `自动匹配物流失败，未找到符合条件的物流方式`,
  key1001428: `(未找到符合基本条件的物流`,
  key1001429: `修改地址信息失败`,
  key1001430: `]，修改物流成功:`,
  key1001431: `原物流`,
  key1001432: `改成`,
  key1001433: `]，修改物流信息失败`,
  key1001434: `添加授权`,
  key1001435: `更新授权`,
  key1001436: `授权失效`,
  key1001437: `手动标记平台订单标发货成功`,
  key1001438: `编辑IOSS;IOSS名称：`,
  key1001439: `;IOSS编号：`,
  key1001440: `添加IOSS;IOSS名称：`,
  key1001441: `添加规则;规则名称：`,
  key1001442: `;使用的IOSS：`,
  key1001443: `;适用平台：`,
  key1001444: `;适用店铺：`,
  key1001445: `编辑规则;规则名称：`,
  key1001446: `取消交易，取消金额：`,
  key1001447: `部分取消，取消金额：`,
  key1001448: `创建备货单`,
  key1001449: `确认接单，接受调价`,
  key1001450: `拒绝调价`,
  key1001451: `未到货部分已退款，退款金额`,
  key1001452: `，币种：`,
  key1001453: `子单全部到仓，已完结`,
  key1001454: `下单`,
  key1001455: `货品缺货,补货中：;预计到货时间：`,
  key1001456: `;缺货内容如下;`,
  key1001457: `货品缺货,已停售：;缺货内容如下;`,
  key1001458: `请补充邮编信息后重试`,
  key1001459: `添加属性`,
  key1001460: `启用属性`,
  key1001461: `停用属性`,
  key1001462: `出库成功`,
  key1001463: `下发物流成功`,
  key1001464: `变更物流信息;原物流方式：`,
  key1001465: `;原运单号：`,
  key1001466: `;原物流商单号：`,
  key1001467: `支付成功`,
  key1001468: `作废订单成功`,
  key1001469: `生成账单`,
  key1001470: `更新仓储账单`,
  key1001471: `生成客户账单`,
  key1001472: `物流分拣完成`,
  key1001473: `新增费率：`,
  key1001474: `编辑费率：`,
  key1001475: `生成一条供应商账单`,
  key1001476: `生成一条分销商账单`,
  key1001477: `换单失败退回到生成拣货单列表`,
  key1001478: `从拣货单【`,
  key1001479: `】移除`,
  key1001480: `重置分拣`,
  key1001481: `标记已拣货`,
  key1001482: `已生成拣货单，拣货单编号:【`,
  key1001483: `换单失败退回到拣货单列表`,
  key1001484: `提单已揽收`,
  key1001485: `提单已创建`,
  key1001486: `提单已入库`,
  key1001487: `提单已上架`,
  key1001488: `提单已分拣`,
  key1001489: `有多余包裹，请联系供应商`,
  key1001490: `审核不通过的原因：`,
  key1001491: `由审核通过`,
  key1001492: `更改为`,
  key1001493: `审核不通过：`,
  key1001494: `由审核不通过`,
  key1001495: `审核不通过`,
  key1001496: `调整补贴`,
  key1001497: `由`,
  key1001498: `调整为`,
  key1001499: `;调整说明：`,
  key1001500: `运营人工添加供应商`,
  key1001501: `停用供应商`,
  key1001502: `启用供应商`,
  key1001503: `编辑供应商`,
  key1001504: `商城入驻注册供应商`,
  key1001505: `商城入驻完善供应商企业信息`,
  key1001506: `审核驳回，驳回原因：`,
  key1001507: `新增配置`,
  key1001508: `编辑配置`,
  key1001509: `已分配库存`,
  key1001510: `匹配智能物流规则：`,
  key1001511: `创建订单`,
  key1001512: `出库单号：`,
  key1001513: `自动匹配仓库成功;发货仓库：`,
  key1001514: `已取消订单`,
  key1001515: `取消订单，取消原因：`,
  key1001516: `物流[`,
  key1001517: `]修改为[`,
  key1001518: `;特殊规则：`,
  key1001519: `,适用范围`,
  key1001520: `,运费/时效优先级`,
  key1001521: `上传包裹前获取包裹状态异常`,
  key1001522: `申请运单号失败`,
  key1001523: `获取运单号失败`,
  key1001524: `获取速卖通货品标签异常`,
  key1001525: `申请运单号成功`,
  key1001526: `(运单号：`,
  key1001527: `(失败原因:：`,
  key1001528: `edis重新上传包裹异常异常`,
  key1001529: `手动修改：更新仓储账单【单据费用】`,
  key1001530: `手动修改：更新仓储账单【其他费用】`,
  key1001531: `手动修改：新增其他费用【`,
  key1001532: `添加备注`,
  key1001533: `手动修改`,
  key1001534: `币种修改`,
  key1001535: `导入账单更新`,
  key1001536: `确认结算`,
  key1001537: `人工标记标记允许取消订单`,
  key1001538: `由于地址修改，当前物流无法送达，原订单已取消，请重新匹配物流后进行下单。`,
  key1001539: `收货中`,
  key1001540: `由于地址修改，原订单已取消。`,
  key1001541: `作废提单，原因：`,
  key1001542: `手动标记付款成功`,
  key1001543: `付款成功`,
  key1001544: `买家修改收货地址，更新订单收货地址`,
  key1001545: `缴费金额：`,
  key1001546: `，时长：`,
  key1001547: `个月`,
  key1001548: `备货单首次分配`,
  key1001549: `商户工商信息变更，驳回原因：`,
  key1001550: `供应商修改了:`,
  key1001551: `商户工商信息变更，审核通过`,
  key1001552: `订单新增标签：`,
  key1001553: `订单删除标签：`,
  key1001554: `存在备货单尚未到货，等待在途库存。`,
  key1001555: `订单新增备注：`,
  key1001556: `美西仓库存不足，等待下次分配`,
  key1001557: `审核账单`,
  key1001558: `确认账单`,
  key1001559: `确认打款`,
  key1001560: `停售商品：`,
  key1001561: `停售货品：`,
  key1001562: `，生成货品标签异常;`,
  key1001563: `[ 运费最低 ]`,
  key1001564: `[ 时效最快 ]`,
  key1001565: `无法备货至`,
  key1001566: `仓`,
  key1001567: `当前最多可下单`,
  key1001568: `个`,
  key1001569: `存在重复，请修改`,
  key1001570: `尺码值语种：`,
  key1001571: `不可为空`,
  key1001572: `出库单:`,
  key1001573: `不是等待拣货状态，请移除有再完成追加`,
  key1001574: `速卖通预约交货失败`,
  key1001575: `数量超出限制，请核对数量`,
  key1001576: `当前容器`,
  key1001577: `正在被占用`,
  key1001578: `已存在`,
  key1001579: `运营下发物流异常：不同仓库`,
  key1001580: `订单不可同时发货`,
  key1001581: `运营下发物流异常：店铺地址不匹配`,
  key1001582: `已预约，揽收单号：`,
  key1001583: `供应商系统出库异常，提单单号：`,
  key1001584: `交接单号：`,
  key1001585: `预约交货异常`,
  key1001586: `非此订单所需货品，请确认货品所属订单`,
  key1001587: `提单：`,
  key1001588: `不存在，请输入正确提单号`,
  key1001589: `存在已下发的云卖订单`,
  key1001590: `启用状态：`,
  key1001591: `请输入规则名称`,
  key1001592: `新增规则`,
  key1001593: `优先级`,
  key1001594: `置底`,
  key1001595: `置顶`,
  key1001596: `移动`,
  key1001597: `规则名称`,
  key1001598: `启用状态`,
  key1001599: `创建人`,
  key1001600: `创建时间`,
  key1001601: `最后更新时间`,
  key1001602: `修改成功`,
  key1001603: `删除提示`,
  key1001604: `规则删除后，将不可恢复，是否删除？`,
  key1001605: `仓库不能为空`,
  key1001606: `动作类型：`,
  key1001607: `选择仓库：`,
  key1001608: `指定履约方`,
  key1001609: `设定动作`,
  key1001610: `分配发货仓库`,
  key1001611: `所分配仓库缺货时支持继续匹配其他分配仓库规则`,
  key1001612: `禁用`,
  key1001613: `阿里国际`,
  key1001614: `查看规则`,
  key1001615: `编辑规则`,
  key1001616: `复制规则`,
  key1001617: `请先选择规则项！`,
  key1001618: `复制成功`,
  key1001619: `新增容器类型`,
  key1001620: `容器类型：`,
  key1001621: `请输入容器类型，比如：周转箱`,
  key1001622: `重量(kg)：`,
  key1001623: `体积(cm³)：`,
  key1001624: `长(cm)：`,
  key1001625: `宽(cm)：`,
  key1001626: `高(cm)：`,
  key1001627: `备注：`,
  key1001628: `容器类型`,
  key1001629: `重量(kg)`,
  key1001630: `体积(cm³)`,
  key1001631: `长(cm)`,
  key1001632: `宽(cm)`,
  key1001633: `高(cm)`,
  key1001634: `容器类型不能为空`,
  key1001635: `容器重量不能为空`,
  key1001636: `容器体积不能为空`,
  key1001637: `容器长度不能为空`,
  key1001638: `容器宽度不能为空`,
  key1001639: `容器高度不能为空`,
  key1001640: `平台币种：`,
  key1001641: `供应商币种：`,
  key1001642: `添加支持币种`,
  key1001643: `分销商币种：`,
  key1001644: `平台币种`,
  key1001645: `交易币种`,
  key1001646: `汇率配置`,
  key1001647: `固定汇率`,
  key1001648: `实时汇率浮动`,
  key1001649: `浮动比例`,
  key1001650: `请输入固定汇率`,
  key1001651: `汇率展示`,
  key1001652: `实时汇率：`,
  key1001653: `系统汇率：`,
  key1001654: `固定汇率不能为空！`,
  key1001655: `固定汇率必须是大于等于0且最多允许4位小数`,
  key1001656: `实时汇率浮动不能为空！`,
  key1001657: `实时汇率浮动必须是大于等于0且最多允许4位小数`,
  key1001658: `打印容器条码`,
  key1001659: `介质材料：`,
  key1001660: `标签大小：`,
  key1001661: `自定义内容：`,
  key1001662: `自定义内容字体`,
  key1001663: `条码字体`,
  key1001664: `每pt约占0.35mm宽，请确保最长的SKU字符数*左侧pt值*0.35小于介质尺寸`,
  key1001665: `不打印自定义内容`,
  key1001666: `打印自定义内容`,
  key1001667: `增加`,
  key1001668: `统一设置打印数量`,
  key1001669: `注:打印内容可拖动改变位置`,
  key1001670: `请输入自定义内容`,
  key1001671: `新增消息模板`,
  key1001672: `消息名称`,
  key1001673: `消息类型`,
  key1001674: `通知渠道`,
  key1001675: `通知方式`,
  key1001676: `站内信`,
  key1001677: `邮箱->短信`,
  key1001678: `短信->邮箱`,
  key1001679: `删除消息模板后，将不再发送提醒消息，你确定要删除？`,
  key1001680: `消息名称：`,
  key1001681: `请输入消息名称，最多输入20个汉字`,
  key1001682: `消息类型：`,
  key1001683: `触发条件：`,
  key1001684: `通知渠道：`,
  key1001685: `通知方式：`,
  key1001686: `是否弹窗：`,
  key1001687: `提醒时间：`,
  key1001688: `提醒时间段为：6:00-23:00，其他时间段不提醒`,
  key1001689: `请选择时间`,
  key1001690: `状态停留时间超过`,
  key1001691: `及时提醒`,
  key1001692: `固定时间`,
  key1001693: `每`,
  key1001694: `提醒一次`,
  key1001695: `1)可从右侧拖拽添加动态字段。`,
  key1001696: `2)蓝色字体文字为动态参数，请勿修改。`,
  key1001697: `3)自定义内容仅支持站内信与邮件，短信按默认内容发送。`,
  key1001698: `动态参数`,
  key1001699: `标题不能为空！`,
  key1001700: `内容不能为空！`,
  key1001701: `消息类型不能为空`,
  key1001702: `内容：`,
  key1001703: `编辑消息模板`,
  key1001704: `创建成功`,
  key1001705: `编辑成功`,
  key1001706: `提醒时间类型为固定时间且单位为天时，具体的提醒时间段不能为空！`,
  key1001707: `触发条件的频率次数不能为空！`,
  key1001708: `提醒类型为固定时间的频率次数不能为空！`,
  key1001709: `库区名称：`,
  key1001710: `库区代码：`,
  key1001711: `库区状态：`,
  key1001712: `仓库名称：`,
  key1001713: `打印库区条码`,
  key1001714: `库区代码`,
  key1001715: `库区`,
  key1001716: `新建库区`,
  key1001717: `批量打印条码`,
  key1001718: `库区名称`,
  key1001719: `仓库名称不能为空`,
  key1001720: `库区名称不能为空`,
  key1001721: `库区代码不能为空`,
  key1001722: `状态不能为空`,
  key1001723: `打印数量`,
  key1001724: `查看库区`,
  key1001725: `编辑库区`,
  key1001726: `请先勾选需要打印的数据！`,
  key1001727: `所属仓库：`,
  key1001728: `容器编号：`,
  key1001729: `请入输入容器编号`,
  key1001730: `容器颜色：`,
  key1001731: `作业状态：`,
  key1001732: `容器数量：`,
  key1001733: `请输入颜色名称`,
  key1001734: `批量删除`,
  key1001735: `上传音频`,
  key1001736: `容器编号`,
  key1001737: `容器颜色`,
  key1001738: `作业状态`,
  key1001739: `所属仓库`,
  key1001740: `最后一次占用时间`,
  key1001741: `编辑容器颜色`,
  key1001742: `所属仓库不能为空`,
  key1001743: `空闲`,
  key1001744: `货品分拣完成`,
  key1001745: `质检中`,
  key1001746: `待贴单`,
  key1001747: `贴单中`,
  key1001748: `待上架`,
  key1001749: `上架中`,
  key1001750: `待多品分拣`,
  key1001751: `多品分拣中`,
  key1001752: `物流分拣中`,
  key1001753: `新增容器`,
  key1001754: `请选择数据`,
  key1001755: `容器数量不能为空`,
  key1001756: `容器数量必须是大于0的正整数`,
  key1001757: `上传音频失败，请重新尝试`,
  key1001758: `模板选择不能为空`,
  key1001759: `仓库编码：`,
  key1001760: `仓库类型：`,
  key1001761: `模板选择：`,
  key1001762: `常规订单处理费：`,
  key1001763: `常规多品订单续件：`,
  key1001764: `常规订单耗材费：`,
  key1001765: `备货类订单处理费：`,
  key1001766: `仓库租金：`,
  key1001767: ` / 件`,
  key1001768: ` / 单`,
  key1001769: ` / 月`,
  key1001770: `仓库处理费`,
  key1001771: `+添加分层价`,
  key1001772: `每日`,
  key1001773: `时`,
  key1001774: `新增仓库`,
  key1001775: `仓库编码不能为空`,
  key1001776: `仓库类型不能为空`,
  key1001777: `常规订单处理费不能为空`,
  key1001778: `常规多品订单续件不能为空`,
  key1001779: `备货类订单处理费不能为空`,
  key1001780: `仓库发货地址`,
  key1001781: `仓库收货地址`,
  key1001782: `仓库退货地址`,
  key1001783: `姓名`,
  key1001784: `国家/地区`,
  key1001785: `省/州`,
  key1001786: `城市`,
  key1001787: `区县`,
  key1001788: `街道地址`,
  key1001789: `手机号`,
  key1001790: `邮政编码`,
  key1001791: `公司名称`,
  key1001792: `邮箱`,
  key1001793: `名称：`,
  key1001794: `编辑仓库`,
  key1001795: `查看仓库`,
  key1001796: `名称不能为空`,
  key1001797: `左区间不能为空！`,
  key1001798: `右区间不能为空！`,
  key1001799: `分层价不能为空！`,
  key1001800: `左区间不能大于等于右区间`,
  key1001801: `标记为已读`,
  key1001802: `共`,
  key1001803: `条消息，其中未读消息`,
  key1001804: `点击查看`,
  key1001805: `消息标题`,
  key1001806: `时间`,
  key1001807: `请先勾选要标记已读的数据！`,
  key1001808: `目的国`,
  key1001809: `清关费率`,
  key1001810: `更新人`,
  key1001811: `更新时间`,
  key1001812: `日志`,
  key1001813: `启用确认`,
  key1001814: `停用确认`,
  key1001815: `启用后，此目的国将校验IOSS编号，请确认是否启用？`,
  key1001816: `停用后，此目的国将不再校验IOSS编号，请确认是否停用？`,
  key1001817: `确认启用`,
  key1001818: `确认停用`,
  key1001819: `启用成功`,
  key1001820: `停用成功`,
  key1001821: `目的国：`,
  key1001822: `清关费率：`,
  key1001823: `目的国不能为空`,
  key1001824: `清关费率不能为空`,
  key1001825: `最多支持两位小数，需大于等于0`,
  key1001826: `添加税率设置`,
  key1001827: `编辑税率设置`,
  key1001828: `清关费率不能为空！`,
  key1001829: `申报的SKU品种数`,
  key1001830: `每个申报的SKU品种申报的数量`,
  key1001831: `包裹包含多SKU时选择申报的货品信息`,
  key1001832: `申报重量的计算方法`,
  key1001833: `申报价格的计算方法`,
  key1001834: `默认申报设置`,
  key1001835: `全部申报`,
  key1001836: `限制最多申报品种数`,
  key1001837: `实际数量`,
  key1001838: `限制最多申报数量`,
  key1001839: `本项设置仅为确定申报所使用的品名及数量，对报关重量和价格无影响`,
  key1001840: `使用固定的总重量申报`,
  key1001841: `固定包裹总重量为`,
  key1001842: `如果申报时各品种需要详细的申报重量，系统将自动按照申报的品种的真实货品重量加权平均计算`,
  key1001843: `使用货品真实重量申报`,
  key1001844: `设置包裹封顶重量为`,
  key1001845: `申报币种`,
  key1001846: `使用固定价格申报`,
  key1001847: `固定申报价格`,
  key1001848: `本项设置仅录入金额，币种取本申报币种`,
  key1001849: `使用价格比例申报`,
  key1001850: `使用包裹`,
  key1001851: `的`,
  key1001852: `设置最低价格`,
  key1001853: `设置封顶价格`,
  key1001854: `设置为本选项后，系统将根据申报币种自动做汇率转换`,
  key1001855: `总金额`,
  key1001856: `商品总金额`,
  key1001857: `按照货品成本单价由高到低顺序选择`,
  key1001858: `按照货品成本单价X数量由高到低顺序选择`,
  key1001859: `按照货品单品体积由大到小顺序选择`,
  key1001860: `按照货品单品体积X数量由大到小顺序选择`,
  key1001861: `按照货品单品重量由大到小顺序选择`,
  key1001862: `按照货品单品重量X数量由大到小顺序选择`,
  key1001863: `封顶价格要大于最低价格`,
  key1001864: `请输入设置最低价格`,
  key1001865: `请输入设置封顶价格`,
  key1001866: `请输入设置包裹封顶重量`,
  key1001867: `仓库名称`,
  key1001868: `仓库代码`,
  key1001869: `仓库类型`,
  key1001870: `国家`,
  key1001871: `自营`,
  key1001872: `请先选择所属仓库`,
  key1001873: `所属库区名称：`,
  key1001874: `库位代码：`,
  key1001875: `库位状态：`,
  key1001876: `打印库位条码`,
  key1001877: `库位代码`,
  key1001878: `库位`,
  key1001879: `导入库位`,
  key1001880: `新建库位`,
  key1001881: `提示：`,
  key1001882: `1.支持扩展名:`,
  key1001883: `2.当导入数据存在异常时，数据导入失败;`,
  key1001884: `3.导入操作后，请稍后在“导入/导出任务-导入查看”中查看导入结果。`,
  key1001885: `所属库区名称`,
  key1001886: `所属库区代码`,
  key1001887: `库位代码不能为空`,
  key1001888: `查看库位`,
  key1001889: `编辑库位`,
  key1001890: `返回列表`,
  key1001891: `请入输入模板名称`,
  key1001892: `停用地址`,
  key1001893: `新增地址`,
  key1001894: `需要选择新的地址替换被停用的地址，请选择要替换的地址模板`,
  key1001895: `模板名称`,
  key1001896: `手机号码`,
  key1001897: `已启用`,
  key1001898: `已停用`,
  key1001899: `停用成功！`,
  key1001900: `入库分拣设置`,
  key1001901: `拣货容器：`,
  key1001902: `包装容器：`,
  key1001903: `切换仓库`,
  key1001904: `提单揽收`,
  key1001905: `流程状态：启用`,
  key1001906: `提单入库`,
  key1001907: `入库分拣`,
  key1001908: `极速出库模式`,
  key1001909: `分拣时进行贴单`,
  key1001910: `商品上架`,
  key1001911: `多品分拣`,
  key1001912: `单品任务跳过此流程`,
  key1001913: `包装作业`,
  key1001914: `物流分拣`,
  key1001915: `上架容器`,
  key1001916: `贴单+上架容器`,
  key1001917: `出库容器`,
  key1001918: `包装容器`,
  key1001919: `贴单+包装容器`,
  key1001920: `拣货容器`,
  key1001921: `贴单+拣货容器`,
  key1001922: `分拣容器出库单数量限制`,
  key1001923: `暂无仓库数据权限，请配置仓库权限后重试。`,
  key1001924: `存在进行中的入库分拣任务，请完成后再进行修改。`,
  key1001925: `姓名：`,
  key1001926: `国家/地区：`,
  key1001927: `省/州：`,
  key1001928: `城市：`,
  key1001929: `区县：`,
  key1001930: `街道地址：`,
  key1001931: `手机号：`,
  key1001932: `邮政编码：`,
  key1001933: `公司名称：`,
  key1001934: `邮箱：`,
  key1001935: `模板名称不能为空`,
  key1001936: `姓名不能为空`,
  key1001937: `国家/地区不能为空`,
  key1001938: `省州不能为空`,
  key1001939: `城市不能为空`,
  key1001940: `区县不能为空`,
  key1001941: `街道地址不能为空`,
  key1001942: `邮政编码不能为空`,
  key1001943: `编辑地址`,
  key1001944: `查看地址`,
  key1001945: `特殊申报规则`,
  key1001946: `添加时间`,
  key1001947: `订单自动规则--编辑`,
  key1001948: `等级名称：`,
  key1001949: `被展示分销商：`,
  key1001950: `新增供应商等级`,
  key1001951: `所有（包含未登录）`,
  key1001952: `部分`,
  key1001953: `等级名称不能为空`,
  key1001954: `等级名称`,
  key1001955: `被展示分销商`,
  key1001956: `查看供应商等级`,
  key1001957: `编辑供应商等级`,
  key1001958: `是否要删除当前供应商等级？`,
  key1001959: `请选择分销等级！`,
  key1001960: `手机号码：`,
  key1001961: `请填写邮箱`,
  key1001962: `营业执照：`,
  key1001963: `法定代表人身份证照：`,
  key1001964: `经营规模：`,
  key1001965: `联系人姓名：`,
  key1001966: `请填写联系人姓名`,
  key1001967: `联系人手机号码：`,
  key1001968: `请填联系人手机号码`,
  key1001969: `固定电话：`,
  key1001970: `请填写固定电话号码`,
  key1001971: `店铺名称：`,
  key1001972: `请填写店铺名称`,
  key1001973: `店铺Logo：`,
  key1001974: `经营模式：`,
  key1001975: `计划经营类目：`,
  key1001976: `预计上架商品数：`,
  key1001977: `商品发布频率：`,
  key1001978: `请填写具体数量`,
  key1001979: `营业地址：`,
  key1001980: `请填写详细地址`,
  key1001981: `归属仓库为供应商发货的目的仓库，至少选择1个。`,
  key1001982: `每月的X日生成上月账单，不含当月账单`,
  key1001983: `供应商等级：`,
  key1001984: `是否允许打印物流面单：`,
  key1001985: `是否允许直接发货：`,
  key1001986: `申请时间：`,
  key1001987: `驳回原因：`,
  key1001988: `账号信息`,
  key1001989: `密码：`,
  key1001990: `重新生成`,
  key1001991: `企业信息`,
  key1001992: `仅支持JPG、JPEG、PNG格式。`,
  key1001993: `人面像`,
  key1001994: `国徽面`,
  key1001995: `店铺信息`,
  key1001996: `尺寸：300*300px，仅支持JPG、JPEG、PNG格式。`,
  key1001997: `款`,
  key1001998: `归属仓库：`,
  key1001999: `首选仓库`,
  key1002000: `设为首选仓库`,
  key1002001: `账期结算：`,
  key1002002: `每月`,
  key1002003: `日出账单`,
  key1002004: `收款信息`,
  key1002005: `审核结果`,
  key1002006: `上传`,
  key1002007: `上传文件格式有误,文件`,
  key1002008: `格式错误,`,
  key1002009: `请选择[jpg,jpeg,png]`,
  key1002010: `添加供应商`,
  key1002011: `请选择经营规模`,
  key1002012: `联系人不能为空`,
  key1002013: `店铺名称不能为空`,
  key1002014: `请选择经营模式`,
  key1002015: `请选择计划经营类目`,
  key1002016: `请选择预计上架商品数`,
  key1002017: `详细地址不能为空`,
  key1002018: `供应商等级不能为空`,
  key1002019: `月结`,
  key1002020: `半月结`,
  key1002021: `周结`,
  key1002022: `10人以下`,
  key1002023: `10~30人`,
  key1002024: `30~50人`,
  key1002025: `50~100人`,
  key1002026: `100人以上`,
  key1002027: `自有工厂`,
  key1002028: `渠道代理`,
  key1002029: `经销批发`,
  key1002030: `女装`,
  key1002031: `男装`,
  key1002032: `男童`,
  key1002033: `女童`,
  key1002034: `婴童`,
  key1002035: `10以下`,
  key1002036: `100以上`,
  key1002037: `待定`,
  key1002038: `每天发布`,
  key1002039: `每周发布`,
  key1002040: `每月发布`,
  key1002041: `收款方式：`,
  key1002042: `开户人姓名：`,
  key1002043: `开户行：`,
  key1002044: `银行卡号：`,
  key1002045: `身份证号：`,
  key1002046: `支付宝账户：`,
  key1002047: `开户人证件号：`,
  key1002048: `公司`,
  key1002049: `入驻审核状态：`,
  key1002050: `工商信息变更审核状态：`,
  key1002051: `供应商详情`,
  key1002052: `编辑供应商信息`,
  key1002053: `审核供应商`,
  key1002054: `审核供应商工商信息变更`,
  key1002055: `银行卡`,
  key1002056: `支付宝`,
  key1002057: `营业执照`,
  key1002058: `法定代表人身份证国徽面`,
  key1002059: `法定代表人身份证人像面`,
  key1002060: `店铺logo`,
  key1002061: `请先上传营业执照`,
  key1002062: `请先上传法定代表人身份证国徽面`,
  key1002063: `请先上传法定代表人身份证人像面`,
  key1002064: `请先上传店铺logo`,
  key1002065: `请先选择商品发布频率类型！`,
  key1002066: `账期结算不能为空！`,
  key1002067: `审核成功！`,
  key1002068: `商品发布数不能为空`,
  key1002069: `商品发布数必须是大于0的正整数`,
  key1002070: `营业地址不能为空`,
  key1002071: `请选择完整的省市区营业地址`,
  key1002072: `提交时间：`,
  key1002073: `搜索字符：`,
  key1002074: `供应商名称/供应商商户号/联系人/手机/邮箱`,
  key1002075: `新增供应商`,
  key1002076: `供应商编号`,
  key1002077: `请输入供应商编号，支持多个逗号或换行分隔`,
  key1002078: `请输入手机号，支持多个逗号或换行分隔`,
  key1002079: `请输入邮箱，支持多个逗号或换行分隔`,
  key1002080: `已认证供应商`,
  key1002081: `黑名单供应商`,
  key1002082: `启用成功！`,
  key1002083: `联系人`,
  key1002084: `手机`,
  key1002085: `电话`,
  key1002086: `SKU总数量`,
  key1002087: `注册时间`,
  key1002088: `停用时间`,
  key1002089: `是否启用该供应商？启用后该供应商的账号可正常登录平台进行操作。`,
  key1002090: `名称`,
  key1002091: `停用原因：`,
  key1002092: `请输入停用原因`,
  key1002093: `停用后供应商将无法登录系统，无法进行发货等任何操作，请谨慎操作！`,
  key1002094: `供应商信息是否允许通过审核？`,
  key1002095: `供应商状态`,
  key1002096: `初审`,
  key1002097: `信息变更`,
  key1002098: `法人代表身份证`,
  key1002099: `经营规模`,
  key1002100: `联系人姓名`,
  key1002101: `联系人手机号码`,
  key1002102: `固定电话`,
  key1002103: `提交审核时间`,
  key1002104: `驳回时间`,
  key1002105: `审核`,
  key1002106: `通过审核`,
  key1002107: `入驻审核`,
  key1002108: `工商信息变更审核`,
  key1002109: `驳回时间：`,
  key1002110: `确认通过`,
  key1002111: `驳回申请`,
  key1002112: `请输入驳回原因`,
  key1002113: `驳回成功！`,
  key1002114: `统计`,
  key1002115: `订单状态为已出库的累计订单总金额。`,
  key1002116: `订单状态为已出库的累计订单总数量。`,
  key1002117: `商城SKU状态为在售的总数量。`,
  key1002118: `统计所有分销商订阅商品总量，同一个商品多个分销商订阅只计算1次。`,
  key1002119: `统计所有分销商已上架到平台的商品总数量，同一个商品不同的Item只计算一次。`,
  key1002120: `统计所有分销商发布刊登的总数量，按Item数量统计。`,
  key1002121: `销售额`,
  key1002122: `订单量`,
  key1002123: `供应商订单排名`,
  key1002124: `分销商订单排名`,
  key1002125: `总销售额(`,
  key1002126: `本月销售额`,
  key1002127: `昨日销售额`,
  key1002128: `今日销售额`,
  key1002129: `订单总数（单）`,
  key1002130: `本月订单数`,
  key1002131: `昨日订单数`,
  key1002132: `今日订单数`,
  key1002133: `在售商品数（SKU）`,
  key1002134: `本月上新`,
  key1002135: `昨日上新`,
  key1002136: `今日上新`,
  key1002137: `商品订阅数（SPU）`,
  key1002138: `本月订阅`,
  key1002139: `昨日订阅`,
  key1002140: `今日订阅`,
  key1002141: `上架商品数（SPU）`,
  key1002142: `本月上架数`,
  key1002143: `昨日上架数`,
  key1002144: `今日上架数`,
  key1002145: `发布刊登数（Item）`,
  key1002146: `本月刊登数`,
  key1002147: `昨日刊登数`,
  key1002148: `今日刊登数`,
  key1002149: `供应商总数`,
  key1002150: `本月新增`,
  key1002151: `昨日新增`,
  key1002152: `今日新增`,
  key1002153: `分销商总数`,
  key1002154: `订单状态`,
  key1002155: `待换单`,
  key1002156: `本周`,
  key1002157: `本月`,
  key1002158: `本年`,
  key1002159: `月`,
  key1002160: `时间不能跨度超过一年`,
  key1002161: `排名`,
  key1002162: `较上月`,
  key1002163: `较上周`,
  key1002164: `分销商商户号`,
  key1002165: `分销商名称`,
  key1002166: `제품 목록`,
  key1002167: `商品贴单`,
  key1002168: `库存余量`,
  key1002169: `提单列表`,
  key1002170: `出库明细`,
  key1002171: `问卷统计`,
  key1002172: `补贴审核`,
  key1002173: `充值记录`,
  key1002174: `供应商总账单`,
  key1002175: `供应商明细账单`,
  key1002176: `供应商订单`,
  key1002177: `物品属性池`,
  key1002178: `客户账单`,
  key1002179: `物流账单`,
  key1002180: `仓储账单`,
  key1002181: `库存明细`,
  key1002182: `物品属性`,
  key1002183: `商品资料`,
  key1002184: `类目映射`,
  key1002185: `通知对象：`,
  key1002186: `创建人：`,
  key1002187: `发送时间：`,
  key1002188: `创建公告`,
  key1002189: `草稿`,
  key1002190: `待修改`,
  key1002191: `待发送`,
  key1002192: `已发送`,
  key1002193: `公告名称`,
  key1002194: `发送状态`,
  key1002195: `通知对象`,
  key1002196: `发送时间`,
  key1002197: `（预定）`,
  key1002198: `审核人`,
  key1002199: `审核通过提示`,
  key1002200: `审核通过后系统将按指定时间发送公告，请确认是否审核通过？`,
  key1002201: `删除公告提示`,
  key1002202: `公告删除后不可恢复，请确认是否删除？`,
  key1002203: `删除成功！`,
  key1002204: `取消发送`,
  key1002205: `取消发送提示`,
  key1002206: `取消后此公告将流转为草稿状态，请确认是否取消发送？`,
  key1002207: `新建问卷`,
  key1002208: `问卷名称`,
  key1002209: `问卷状态`,
  key1002210: `已暂停`,
  key1002211: `答题中`,
  key1002212: `浏览量`,
  key1002213: `回收率`,
  key1002214: `最后修改时间`,
  key1002215: `停用后不可答题`,
  key1002216: `停用后，问卷答题将暂停，你确定停用？`,
  key1002217: `问卷标题`,
  key1002218: `问卷说明`,
  key1002219: `请输入题目标题`,
  key1002220: `请输入选项`,
  key1002221: `投放渠道`,
  key1002222: `显示方式`,
  key1002223: `触发事件`,
  key1002224: `选择题型`,
  key1002225: `选填`,
  key1002226: `添加选项`,
  key1002227: `添加其他`,
  key1002228: `问卷设置`,
  key1002229: `文本`,
  key1002230: `分销商端`,
  key1002231: `弹窗`,
  key1002232: `不显示【跳过】按钮`,
  key1002233: `显示【跳过】按钮`,
  key1002234: `首次登录`,
  key1002235: `选项`,
  key1002236: `其他____`,
  key1002237: `是否保存问卷内容`,
  key1002238: `你有未保存的问卷，是否需要保存？`,
  key1002239: `不保存`,
  key1002240: `新建成功！`,
  key1002241: `题目标题不能为空！`,
  key1002242: `题目标题不能重复！`,
  key1002243: `新增指引`,
  key1002244: `PDF文件`,
  key1002245: `在线内容`,
  key1002246: `指引标题`,
  key1002247: `所属模块`,
  key1002248: `指引类型`,
  key1002249: `排序`,
  key1002250: `停用提醒`,
  key1002251: `停用后，用户将无法查看使用教程，`,
  key1002252: `你确定要停用吗？`,
  key1002253: `删除提醒`,
  key1002254: `你即将永久删除，删除后将无法恢复，`,
  key1002255: `你确定要删除吗？`,
  key1002256: `目录名称`,
  key1002257: `内容标题：`,
  key1002258: `请输入内容标题`,
  key1002259: `正文内容：`,
  key1002260: `排序：`,
  key1002261: `展示状态：`,
  key1002262: `复制文章标题`,
  key1002263: `最多可输入28个中文字符`,
  key1002264: `数字越大，越靠后`,
  key1002265: `开`,
  key1002266: `关`,
  key1002267: `添加内容`,
  key1002268: `是否确认删除【`,
  key1002269: `】分类？`,
  key1002270: `标题`,
  key1002271: `发布日期`,
  key1002272: `最后更新日期`,
  key1002273: `展示状态`,
  key1002274: `首页推荐`,
  key1002275: `新增目录`,
  key1002276: `文章标题不能为空`,
  key1002277: `目录`,
  key1002278: `首页最多推荐5条帮助文档，请先关闭其他推荐，再开启！`,
  key1002279: `是否确认删除当前文章？`,
  key1002280: `编辑目录`,
  key1002281: `目录分类最多只能有三个层级关系，且只允许同级拖动排序！`,
  key1002282: `内容`,
  key1002283: `在新的标签页打开图片`,
  key1002284: `1:1显示图片`,
  key1002285: `问题汇总`,
  key1002286: `鼠标滚轮缩放图片`,
  key1002287: `指引标题：`,
  key1002288: `请输入指引标题`,
  key1002289: `所属模块：`,
  key1002290: `显示位置：`,
  key1002291: `指引类型：`,
  key1002292: `提示:`,
  key1002293: `1.仅支持扩展名为PDF的文件。`,
  key1002294: `2.文件大小不超过10Mb。`,
  key1002295: `指引标题不能为空`,
  key1002296: `所属模块不能为空`,
  key1002297: `显示位置不能为空`,
  key1002298: `面包屑右侧`,
  key1002299: `编辑指引`,
  key1002300: `上传文件成功`,
  key1002301: `请先上传PDF文件！`,
  key1002302: `正文内容不能为空！`,
  key1002303: `公告名称：`,
  key1002304: `若审核通过时已超出发送时间，公告将在审核通过后立即发送。`,
  key1002305: `存为草稿`,
  key1002306: `提交审核`,
  key1002307: `请输入公告名称`,
  key1002308: `请选择通知对象`,
  key1002309: `编辑公告`,
  key1002310: `提交审核成功`,
  key1002311: `请输入正文内容`,
  key1002312: `请选择发送时间`,
  key1002313: `导出数据`,
  key1002314: `按选中导出`,
  key1002315: `按查询结果导出`,
  key1002316: `请先勾选需要导出的数据！`,
  key1002317: `当前筛选结果暂无数据，无法导出！`,
  key1002318: `商户号`,
  key1002319: `开始答题时间`,
  key1002320: `结束答题时间`,
  key1002321: `客户名称：`,
  key1002322: `联系人：`,
  key1002323: `手机：`,
  key1002324: `请输入手机号`,
  key1002325: `客户等级：`,
  key1002326: `仓库权限：`,
  key1002327: `客户类型：`,
  key1002328: `分销客户`,
  key1002329: `阿里国际分销`,
  key1002330: `客户等级不能为空`,
  key1002331: `链接详情`,
  key1002332: `链接名称：`,
  key1002333: `来源渠道：`,
  key1002334: `推广人：`,
  key1002335: `推广方式：`,
  key1002336: `推广内容：`,
  key1002337: `推广组：`,
  key1002338: `备注信息`,
  key1002339: `链接基本信息`,
  key1002340: `链接数据`,
  key1002341: `点击总人数`,
  key1002342: `注册总人数`,
  key1002343: `注册转换率`,
  key1002344: `请填写链接名称`,
  key1002345: `分时数据`,
  key1002346: `点击人数`,
  key1002347: `注册人数`,
  key1002348: `创建链接`,
  key1002349: `请填写链接地址`,
  key1002350: `创建成功！`,
  key1002351: `客户详情`,
  key1002352: `客户信息`,
  key1002353: `编号：`,
  key1002354: `客户来源：`,
  key1002355: `客户币种：`,
  key1002356: `关联商户：`,
  key1002357: `客户负责人：`,
  key1002358: `请填写备注`,
  key1002359: `余额：`,
  key1002360: `预扣金额：`,
  key1002361: `总额度：`,
  key1002362: `已使用额度：`,
  key1002363: `请输入姓名`,
  key1002364: `职务：`,
  key1002365: `请输入职务`,
  key1002366: `请输入邮箱`,
  key1002367: `请输入固定电话`,
  key1002368: `微信号：`,
  key1002369: `请输入微信号`,
  key1002370: `QQ号：`,
  key1002371: `请输入微QQ号`,
  key1002372: `客户旅程`,
  key1002373: `主联系人`,
  key1002374: `下次联系时间：`,
  key1002375: `操作人：`,
  key1002376: `客户基本信息`,
  key1002377: `客户财务信息`,
  key1002378: `客户仓库权限`,
  key1002379: `客户API权限`,
  key1002380: `添加联系人`,
  key1002381: `设为主要联系人`,
  key1002382: `暂无旅程数据`,
  key1002383: `联系记录`,
  key1002384: `开通产品记录`,
  key1002385: `日志信息`,
  key1002386: `不是有效的邮箱或者邮箱格式不正确！`,
  key1002387: `信息至少要填写其中一个！`,
  key1002388: `创建分组`,
  key1002389: `分组名称`,
  key1002390: ` 分组客户数量`,
  key1002391: `分组备注`,
  key1002392: `删除分组提示`,
  key1002393: `正在操作删除分组，请确认是否删除？`,
  key1002394: `删除分组`,
  key1002395: `注册时间：`,
  key1002396: `注册来源：`,
  key1002397: `请选择注册来源`,
  key1002398: `客户标签：`,
  key1002399: `请选择客户标签`,
  key1002400: `请选择客户等级`,
  key1002401: `客户名称/客户编号/联系人/手机/邮箱/备注`,
  key1002402: `新增客户`,
  key1002403: `移动分组`,
  key1002404: `批量审核`,
  key1002405: `客户编号`,
  key1002406: `请输入客户编号，支持多个逗号或换行分隔`,
  key1002407: `是否确认启用？`,
  key1002408: `是否确认停用？`,
  key1002409: `仅待审核客户可进行审核，请重新选择!`,
  key1002410: `请先勾选要审核的数据`,
  key1002411: `未分组`,
  key1002412: `客户名称`,
  key1002413: `客户分组`,
  key1002414: `客户标签`,
  key1002415: `客户等级`,
  key1002416: `客户币种`,
  key1002417: `余额`,
  key1002418: `预扣金额`,
  key1002419: `总额度`,
  key1002420: `已使用额度`,
  key1002421: `注册来源`,
  key1002422: `客户备注`,
  key1002423: `请先选择客户`,
  key1002424: `标签名称：`,
  key1002425: `请输入标签名称`,
  key1002426: `标签备注：`,
  key1002427: `请填写标签名称`,
  key1002428: `创建标签`,
  key1002429: `编辑标签`,
  key1002430: `指定分组：`,
  key1002431: `请选择分组`,
  key1002432: `本次操作中存在删除分组行为，请为分组下的客户重新进行分组`,
  key1002433: `非通途客户`,
  key1002434: `通途客户`,
  key1002435: `通途商户号不能为空`,
  key1002436: `查看客户`,
  key1002437: `标签名称`,
  key1002438: `标签客户数量`,
  key1002439: `标签备注`,
  key1002440: `删除标签提示`,
  key1002441: `删除标签后，此标签将同步从客户上去除，请确认是否删除？`,
  key1002442: `标签详情`,
  key1002443: `标签基本信息`,
  key1002444: `标签类型：`,
  key1002445: `标签数据`,
  key1002446: `统计时间：`,
  key1002447: `搜索链接名称`,
  key1002448: `请选择标签类型`,
  key1002449: `搜索标签名称`,
  key1002450: `链接名称`,
  key1002451: `链接类型`,
  key1002452: `查看二维码`,
  key1002453: `复制链接`,
  key1002454: `分组名称：`,
  key1002455: `请输入分组名称`,
  key1002456: `分组备注：`,
  key1002457: `请填写分组名称`,
  key1002458: `最多输入20个字符`,
  key1002459: `编辑分组`,
  key1002460: `新增客户等级`,
  key1002461: `等级`,
  key1002462: `查看客户等级`,
  key1002463: `编辑客户等级`,
  key1002464: `是否要删除当前客户等级？`,
  key1002465: `注册时默认等级`,
  key1002466: `默认`,
  key1002467: `设为默认`,
  key1002468: `更换注册默认等级`,
  key1002469: `当前操作将会影响商城客户注册时的默认等级，是否确认更换默认等级？`,
  key1002470: `输入出库单号查找`,
  key1002471: `删除确认`,
  key1002472: `拣货单详情`,
  key1002473: `拣货单号：`,
  key1002474: `拣货单状态：`,
  key1002475: `是否打印：`,
  key1002476: `打印拣货单`,
  key1002477: `创建人员：`,
  key1002478: `是否删除最后一条？如果删除该拣货单也会删除！`,
  key1002479: `按出库单查看`,
  key1002480: `按商品查看`,
  key1002481: `出库单状态`,
  key1002482: `物流下发状态`,
  key1002483: `未下发`,
  key1002484: `已下发`,
  key1002485: `从拣货单移除`,
  key1002486: `所在仓库`,
  key1002487: `产品图片`,
  key1002488: `已拣货数量`,
  key1002489: `体积`,
  key1002490: `未拣货`,
  key1002491: `成品`,
  key1002492: `原料`,
  key1002493: `拣货单：`,
  key1002494: `物料类型`,
  key1002495: `原料SPU`,
  key1002496: `原料SKU`,
  key1002497: `制单人`,
  key1002498: `（签字/日期）`,
  key1002499: `拣货人`,
  key1002500: `（签字日期）`,
  key1002501: `取消打印`,
  key1002502: `标记打印成功`,
  key1002503: `订单号：`,
  key1002504: `多个出库单请用逗号分隔`,
  key1002505: `品类：`,
  key1002506: `排序方式：`,
  key1002507: `生成拣货单`,
  key1002508: `按选中生成拣货单`,
  key1002509: `生成拣货单（所有结果集）`,
  key1002510: `按创建时间`,
  key1002511: `按物流方式`,
  key1002512: `Temu 样`,
  key1002513: `Temu 货`,
  key1002514: `出库单类型`,
  key1002515: `分销商订单号`,
  key1002516: `SKU数量`,
  key1002517: `物品数量`,
  key1002518: `查看详情`,
  key1002519: `请先勾选需要生成拣货单的数据！`,
  key1002520: `当前筛选结果暂无数据，无法生成拣货单！`,
  key1002521: `未指定物流商`,
  key1002522: `未指定邮寄方式`,
  key1002523: `拣货单编号：`,
  key1002524: `打印状态：`,
  key1002525: `批量操作`,
  key1002526: `标记为已拣货`,
  key1002527: `未打印`,
  key1002528: `已打印`,
  key1002529: `按添加时间`,
  key1002530: `按拣货单状态`,
  key1002531: `查询已包装的拣货单，创建时间不能为空！`,
  key1002532: `标记成功`,
  key1002533: `请选择操作数据`,
  key1002534: `拣货单编号`,
  key1002535: `拣货单类型`,
  key1002536: `拣货状态`,
  key1002537: `打印状态`,
  key1002538: `SKU数`,
  key1002539: `货品数`,
  key1002540: `拣货完成时间`,
  key1002541: `拣货单来源`,
  key1002542: `出库单`,
  key1002543: `分拣容器`,
  key1002544: `打印组装标签`,
  key1002545: `获取打印组装标签链接地址失败!`,
  key1002546: `属性名称：`,
  key1002547: `请输入属性名称，支持多语种搜索`,
  key1002548: `是否自定义：`,
  key1002549: `单选/多选：`,
  key1002550: `物品属性导入`,
  key1002551: `导出属性`,
  key1002552: `2.当导入数据存在异常时，数据导入失败。`,
  key1002553: `编号`,
  key1002554: `属性中文名称`,
  key1002555: `规格/普通属性`,
  key1002556: `是否尺码属性`,
  key1002557: `填写要求`,
  key1002558: `是否自定义`,
  key1002559: `单选/多选`,
  key1002560: `规格/普通属性(规格、普通)`,
  key1002561: `是否自定义(是、否)`,
  key1002562: `属性值单选/多选(多选、单选)`,
  key1002563: `是否尺码属性(是、否)`,
  key1002564: `填写要求(必填、非必填、推荐填写)`,
  key1002565: `属性信息`,
  key1002566: `中文-简体`,
  key1002567: `法语`,
  key1002568: `德语`,
  key1002569: `西班牙语`,
  key1002570: `荷兰语`,
  key1002571: `波兰语`,
  key1002572: `葡萄牙语`,
  key1002573: `意大利语`,
  key1002574: `属性状态`,
  key1002575: `属性值信息`,
  key1002576: `属性值状态`,
  key1002577: `属性删除提示`,
  key1002578: `本次修改中存在删除属性行为，删除后现有商品信息中该属性字段将一并删除，请确认是否保存此次修改？`,
  key1002579: `停用属性后，供应商发布新商品和修改已发布的商品时，此属性将不可使用，你确定要停用?`,
  key1002580: `立即停用`,
  key1002581: `新增模板`,
  key1002582: `部位`,
  key1002583: `单位`,
  key1002584: `是否要删除当前尺码模板？`,
  key1002585: `标签商品数量`,
  key1002586: `标签名称不能为空`,
  key1002587: `标签`,
  key1002588: `删除标签后，此标签将同步从商品上去除，请确认是否删除？`,
  key1002589: `请输入中文标题,支持多个按行分隔`,
  key1002590: `供应商：`,
  key1002591: `商品类型：`,
  key1002592: `商品标签：`,
  key1002593: `请选择商品标签`,
  key1002594: `商品可售卖国：`,
  key1002595: `导出商品列表`,
  key1002596: `批量停售`,
  key1002597: `按选中停售`,
  key1002598: `按查询结果停售`,
  key1002599: `取消选择`,
  key1002600: `面单`,
  key1002601: `供应商信息`,
  key1002602: `分类`,
  key1002603: `商品标签`,
  key1002604: `商品可售卖国`,
  key1002605: `最近停售时间`,
  key1002606: `首次上架时间`,
  key1002607: `首次提交审核时间`,
  key1002608: `查看SKU`,
  key1002609: `查看中心仓库存`,
  key1002610: `查看供应商库存`,
  key1002611: `1688找同款`,
  key1002612: `请输入供应商SKU,支持多个按行分隔`,
  key1002613: `商品类目`,
  key1002614: `销售状态`,
  key1002615: `最后上架时间`,
  key1002616: `中心仓分配库存`,
  key1002617: `供应商分配库存`,
  key1002618: `商品类型`,
  key1002619: `以SPU维度导出`,
  key1002620: `(报表中每个SPU一行)`,
  key1002621: `以SKU维度导出`,
  key1002622: `(报表中每个SKU一行)`,
  key1002623: `最小值`,
  key1002624: `最大值`,
  key1002625: `供应商仓库存`,
  key1002626: `商品上架时间`,
  key1002627: `请先勾选需要停售的数据！`,
  key1002628: `当前筛选结果暂无数据，无法操作停售！`,
  key1002629: `库存最小值不可大于最大值，请修改条件后再查询。`,
  key1002630: `请先选择商品`,
  key1002631: `1. 选择平台`,
  key1002632: `2. 选择站点`,
  key1002633: `4. 选择Product Type`,
  key1002634: `4. 选择Family Type`,
  key1002635: `5. 商品规格映射`,
  key1002636: `6. 商品属性映射`,
  key1002637: `自定义值`,
  key1002638: `平台属性变更，原值：`,
  key1002639: `选择站点`,
  key1002640: `类目不能为空`,
  key1002641: `商品规格映射`,
  key1002642: `属性:`,
  key1002643: `已映射:`,
  key1002644: `待映射:`,
  key1002645: `属性值:`,
  key1002646: `属性值已映射:`,
  key1002647: `属性值待映射:`,
  key1002648: `自动匹配`,
  key1002649: `正在匹配中，请稍后...`,
  key1002650: `自动匹配完成，已匹配属性：`,
  key1002651: `已匹配属性值：`,
  key1002652: `查看更多可选值`,
  key1002653: `无数据`,
  key1002654: `商品属性映射`,
  key1002655: `属性值类型`,
  key1002656: `添加平台`,
  key1002657: `平台不能为空`,
  key1002658: `站点不能为空`,
  key1002659: `自定义`,
  key1002660: `平台属性`,
  key1002661: `平台属性值类型`,
  key1002662: `编辑平台`,
  key1002663: `查看平台`,
  key1002664: `（已映射）`,
  key1002665: `Product Type不能为空`,
  key1002666: `FamiltType不能为空`,
  key1002667: `开始匹配`,
  key1002668: `正在匹配中...`,
  key1002669: `匹配数据获取完成`,
  key1002670: `数据匹配中`,
  key1002671: `接口请求错误，可能导致数据匹配不全`,
  key1002672: `名称数据匹配`,
  key1002673: `平台映射表`,
  key1002674: `电商平台：`,
  key1002675: `站点名称：`,
  key1002676: `分类名称：`,
  key1002677: `自定义属性值`,
  key1002678: `正在搜索`,
  key1002679: `输入文字进行搜索`,
  key1002680: `平台标签属性`,
  key1002681: `映射属性`,
  key1002682: `listing关键词的生成规则`,
  key1002683: `随机`,
  key1002684: `不选`,
  key1002685: `切换Product Type会清空列表数据`,
  key1002686: `请先选择Product Type`,
  key1002687: `平台属性不能重复`,
  key1002688: `未查询到平台类目`,
  key1002689: `价格区间(CNY)：`,
  key1002690: `综合评分：`,
  key1002691: `24小时揽收率：`,
  key1002692: `48小时揽收率：`,
  key1002693: `搜索关键词：`,
  key1002694: ` 起`,
  key1002695: `起批`,
  key1002696: `分`,
  key1002697: `近30天成交：`,
  key1002698: `不限`,
  key1002699: `5星`,
  key1002700: `4.5星-5.0星`,
  key1002701: `4星-4.5星`,
  key1002702: `4星以下`,
  key1002703: `认证工厂`,
  key1002704: `当日发货`,
  key1002705: `24小时发货`,
  key1002706: `48小时发货`,
  key1002707: `7天无理由`,
  key1002708: `一件代发`,
  key1002709: `支持包邮`,
  key1002710: `按价格`,
  key1002711: `按月销量`,
  key1002712: `价格开始区间不能大于价格结束区间`,
  key1002713: `中文名称不能为空！`,
  key1002714: `尺码表模板名称`,
  key1002715: `新增部位`,
  key1002716: `新增单位`,
  key1002717: `同一列部位的中文名称不能重复！`,
  key1002718: `部位1`,
  key1002719: `英文名称`,
  key1002720: ` 荷兰语名称`,
  key1002721: `同一列的部位多语种名称必须填写完整或全为空！`,
  key1002722: `单位名称不能为空！`,
  key1002723: `新增模板成功！`,
  key1002724: `更新模板成功！`,
  key1002725: `上移`,
  key1002726: `下移`,
  key1002727: `不能选择重复的单位值！`,
  key1002728: `属性池管理`,
  key1002729: `请输入属性中文名称`,
  key1002730: `商品规格中只能有一个是尺码属性！`,
  key1002731: `请先选择属性池数据！`,
  key1002732: `输入关键词搜索`,
  key1002733: `分类ID：`,
  key1002734: `父级类目已配置不展示，此类目不展示于商城。`,
  key1002735: `报关编码：`,
  key1002736: `中文报关名称：`,
  key1002737: `英文报关名称：`,
  key1002738: `尺码表模板：`,
  key1002739: `新增子分类`,
  key1002740: `导入映射`,
  key1002741: `分类信息`,
  key1002742: `商城展示配置：`,
  key1002743: `展示`,
  key1002744: `不展示`,
  key1002745: `平台信息`,
  key1002746: `报关信息`,
  key1002747: `从属性池选择`,
  key1002748: `您确认`,
  key1002749: `这个平台吗？`,
  key1002750: `商品总数：`,
  key1002751: `您确认删除这条内容吗？`,
  key1002752: `报关编码不能为空`,
  key1002753: `中文报关名称不能为空`,
  key1002754: `英文报关名称不能为空`,
  key1002755: `尺码表模板不能为空`,
  key1002756: `平台名称`,
  key1002757: `站点`,
  key1002758: `商品分类`,
  key1002759: `修改`,
  key1002760: `是否将其他物品属性listing关键词的生成规则更新为当前生成规则`,
  key1002761: `同步`,
  key1002762: `主属性设置`,
  key1002763: `主属性`,
  key1002764: `设为主属性`,
  key1002765: `只能同级拖动`,
  key1002766: `请先设置商品规格的主属性！`,
  key1002767: `是否要删除当前分类？`,
  key1002768: `服装`,
  key1002769: `不能包含->特殊字符`,
  key1002770: `文件格式有误, 仅支持上传xls、xlsx格式文件`,
  key1002771: `请输入打印数量`,
  key1002772: `打印面单`,
  key1002773: `供应商名称/供应商商户号`,
  key1002774: `批量驳回`,
  key1002775: `供应商货号/SPU`,
  key1002776: `中文品名`,
  key1002777: `供应商商户号 / 名称`,
  key1002778: `审核状态`,
  key1002779: `请勾选需要批量审核的数据!`,
  key1002780: `请先勾选需要批量驳回的数据！`,
  key1002781: `功能设置`,
  key1002782: `功能描述`,
  key1002783: `商品标签是否在商城展示`,
  key1002784: `是否启用商品可售卖国设置`,
  key1002785: `账单打款确认`,
  key1002786: `打款密码：`,
  key1002787: `请输入打款密码`,
  key1002788: `是否已核对账单无误，确认打款后款项将直接通过支付宝转账至供应商账户中，请谨慎操作。`,
  key1002789: `账单时间：`,
  key1002790: `客户商户号：`,
  key1002791: `请输入客户商户号`,
  key1002792: `导出客户账单`,
  key1002793: `年`,
  key1002794: `订单金额`,
  key1002795: `订单币种`,
  key1002796: `账单号`,
  key1002797: `账单周期`,
  key1002798: `客户商户号`,
  key1002799: `月累计充值金额`,
  key1002800: `支付宝充值金额`,
  key1002801: `手工充值金额`,
  key1002802: `累计消费金额`,
  key1002803: `账单生成时间`,
  key1002804: `账单信息`,
  key1002805: `所属商户`,
  key1002806: `所属账单`,
  key1002807: `充值流水号`,
  key1002808: `第三方交易号`,
  key1002809: `充值金额`,
  key1002810: `付款方式`,
  key1002811: `充值成功时间`,
  key1002812: `消费记录`,
  key1002813: `第三方单号`,
  key1002814: `货品总数`,
  key1002815: `物流重量(g)`,
  key1002816: `商品金额`,
  key1002817: `物流费`,
  key1002818: `订单完成时间`,
  key1002819: `账单维度`,
  key1002820: `明细维度`,
  key1002821: `物流费用为供应商应付项，货品金额及补贴金额为应收项，计算时请注意`,
  key1002822: `费用明细`,
  key1002823: `审核确认`,
  key1002824: `线下已打款`,
  key1002825: `货品结算金额`,
  key1002826: `货品金额小计`,
  key1002827: `供应商账单号：`,
  key1002828: `供应商名称：`,
  key1002829: `供应商商户号：`,
  key1002830: `账单生成时间：`,
  key1002831: `账单结算状态：`,
  key1002832: `账单状态更新时间：`,
  key1002833: `账单金额：`,
  key1002834: `货品金额（应付）：`,
  key1002835: `物流费用（应收）：`,
  key1002836: `补贴金额（应付）：`,
  key1002837: `账单周期：`,
  key1002838: `物流费用`,
  key1002839: `账单审核确认`,
  key1002840: `是否已审核账单无误，审核确认后将变为可打款状态。`,
  key1002841: `账单线下已打款确认`,
  key1002842: `请确认是否已进行线下打款。`,
  key1002843: `确认线下已打款`,
  key1002844: `费用类型`,
  key1002845: `货品数量`,
  key1002846: `SPU近30天累计销量`,
  key1002847: `提单编号`,
  key1002848: `结束装箱时间`,
  key1002849: `揽收时间`,
  key1002850: `云卖网发货时间`,
  key1002851: `应付物流费：`,
  key1002852: `应付其他费用：`,
  key1002853: `物流实重(g)：`,
  key1002854: `未对账订单数量：`,
  key1002855: `物流商币种：`,
  key1002856: `账单明细`,
  key1002857: `仅看亏损订单`,
  key1002858: `仅看未对账订单`,
  key1002859: `盈亏(当前筛选条件)：`,
  key1002860: `账单编号：`,
  key1002861: `账单更新时间：`,
  key1002862: `客户实付物流费：`,
  key1002863: `其他应付费用：`,
  key1002864: `总费用：`,
  key1002865: `单据号`,
  key1002866: `单据状态`,
  key1002867: `系统计算物流重量(g)`,
  key1002868: `运单号`,
  key1002869: `物流实重(g)`,
  key1002870: `客户实付物流费`,
  key1002871: `应付物流费`,
  key1002872: `未对账`,
  key1002873: `应付其他费用`,
  key1002874: `总费用`,
  key1002875: `盈亏`,
  key1002876: `包裹出库时间`,
  key1002877: `对账数据来源`,
  key1002878: `导入账单`,
  key1002879: `应付物流费不能为空`,
  key1002880: `备注不能为空`,
  key1002881: `账单确认结算`,
  key1002882: `正在操作账单结算，结算后将无法再对账单进行修改。请确认是否结算？`,
  key1002883: `结算成功!`,
  key1002884: `修改币种成功！`,
  key1002885: `分销商商户号：`,
  key1002886: `充值时间：`,
  key1002887: `充值状态：`,
  key1002888: `充值流水号：`,
  key1002889: `请输入充值流水号,支持多个按行分隔`,
  key1002890: `导出充值记录`,
  key1002891: `充值`,
  key1002892: `充值中`,
  key1002893: `充值成功`,
  key1002894: `充值失败`,
  key1002895: `第三方凭证号`,
  key1002896: `第三方凭证`,
  key1002897: `充值前余额`,
  key1002898: `充值后余额`,
  key1002899: `初始化额度`,
  key1002900: `剩余额度`,
  key1002901: `充值状态`,
  key1002902: `订单处理费：`,
  key1002903: `订单耗材费：`,
  key1002904: `附加服务费：`,
  key1002905: `退货处理费：`,
  key1002906: `费用名称：`,
  key1002907: `费用金额：`,
  key1002908: `+添加费用`,
  key1002909: `月处理订单数：`,
  key1002910: `总金额：`,
  key1002911: `客户实付金额：`,
  key1002912: `其他费用：`,
  key1002913: `费用名称`,
  key1002914: `金额`,
  key1002915: `关联单据号`,
  key1002916: `订单内货品数量`,
  key1002917: `订单处理费`,
  key1002918: `订单耗材费`,
  key1002919: `附加服务费`,
  key1002920: `退货处理费`,
  key1002921: `合计`,
  key1002922: `客户实付金额`,
  key1002923: `费用产生时间`,
  key1002924: `修改费用`,
  key1002925: `单据费用`,
  key1002926: `其他费用`,
  key1002927: `订单处理费不能为空`,
  key1002928: `费用金额不能为空`,
  key1002929: `费用名称不能为空`,
  key1002930: `添加费用`,
  key1002931: `添加费用成功`,
  key1002932: `修改费用成功`,
  key1002933: `添加备注成功`,
  key1002934: `充值币种：`,
  key1002935: `若充值币种与分销商币种不一致时，系统将按汇率转为分销商币种进行充值。`,
  key1002936: `充值面额：`,
  key1002937: `付款方式：`,
  key1002938: `支付流水号：`,
  key1002939: `支付流水图片：`,
  key1002940: `上传附件`,
  key1002941: `分销商货币为`,
  key1002942: `，实际到账`,
  key1002943: `系统汇率：1`,
  key1002944: `现金`,
  key1002945: `分销商商户号不能为空`,
  key1002946: `付款方式不能为空`,
  key1002947: `支付流水号不能为空`,
  key1002948: `充值币种不能为空`,
  key1002949: `自定义的充值面额不能为空！`,
  key1002950: `请上传支付流水图片！`,
  key1002951: `充值成功！`,
  key1002952: `最多支持上传五个附件！`,
  key1002953: `请输入分销商商户号`,
  key1002954: `（分销商币种）`,
  key1002955: `账单状态：`,
  key1002956: `可搜索账单号/供应商名称`,
  key1002957: `导出供应商账单`,
  key1002958: `批量打款`,
  key1002959: `批量确认线下打款`,
  key1002960: `供应商账单号`,
  key1002961: `请输入供应商账单号，支持多个逗号或换行分隔`,
  key1002962: `账单金额`,
  key1002963: `账单币种`,
  key1002964: `订单数量`,
  key1002965: `账单结算状态`,
  key1002966: `供应商确认时间`,
  key1002967: `审核时间`,
  key1002968: `打款时间`,
  key1002969: `打款人`,
  key1002970: `打款方式`,
  key1002971: `收款账户信息`,
  key1002972: `收款方式`,
  key1002973: `开户人姓名`,
  key1002974: `开户行`,
  key1002975: `账户`,
  key1002976: `身份证号`,
  key1002977: `账单明细信息`,
  key1002978: `供货价(CNY)`,
  key1002979: `货品金额小计(CNY)`,
  key1002980: `产生费用时间`,
  key1002981: `面单类型`,
  key1002982: `订单创建时间`,
  key1002983: `中心仓发货时间`,
  key1002984: `是否中心仓备货`,
  key1002985: `总账单`,
  key1002986: `仅收款账户为支付宝的才能批量打款！`,
  key1002987: `请先选择账单！`,
  key1002988: `支付宝订单号`,
  key1002989: `线下支付`,
  key1002990: `补贴规则：`,
  key1002991: `请选择补贴规则`,
  key1002992: `物流`,
  key1002993: `物流方式`,
  key1002994: `补贴规则`,
  key1002995: `提单重量`,
  key1002996: `提货单内货品数量`,
  key1002997: `准时发货率`,
  key1002998: `入库时间`,
  key1002999: `分拣时间`,
  key1003000: `最后更新人`,
  key1003001: `请输入提单号,支持多个逗号或换行分隔`,
  key1003002: `请输入运单号,支持多个逗号或换行分隔`,
  key1003003: `请输入供应商商户号,支持多个逗号或换行分隔`,
  key1003004: `仅待审核的订单可批量审核！`,
  key1003005: `请先勾选需要审核的数据！`,
  key1003006: `上门揽收`,
  key1003007: `说明`,
  key1003008: `已审核`,
  key1003009: `提单内货品数量`,
  key1003010: `重新审核`,
  key1003011: `补贴说明`,
  key1003012: `总计：`,
  key1003013: `物流-快递`,
  key1003014: `物流-上门揽收`,
  key1003015: `.截止`,
  key1003016: `前的`,
  key1003017: `单`,
  key1003018: `按提单个数补贴`,
  key1003019: `按提单重量补贴`,
  key1003020: `无补贴`,
  key1003021: `发货方式`,
  key1003022: `订单发货率`,
  key1003023: `补贴对象`,
  key1003024: `补贴类型`,
  key1003025: `补贴条件`,
  key1003026: `使用`,
  key1003027: `实际数据`,
  key1003028: `补贴计算`,
  key1003029: `1.单个提单补贴：`,
  key1003030: `1.0-1公斤补贴：`,
  key1003031: `2.续重每公斤补贴：`,
  key1003032: `3.单个提单补贴上限：`,
  key1003033: `符合子规则：`,
  key1003034: `符合规则：`,
  key1003035: `补贴手动调整为：`,
  key1003036: `导出仓储账单`,
  key1003037: `更新账单`,
  key1003038: `结算状态`,
  key1003039: `结算中`,
  key1003040: `已结算`,
  key1003041: `总金额(CNY)`,
  key1003042: `订单处理量`,
  key1003043: `仓库租金`,
  key1003044: `账单更新时间`,
  key1003045: `月订单处理量(CNY)`,
  key1003046: `订单耗材费用(CNY)`,
  key1003047: `附加费用(CNY)`,
  key1003048: `退货处理费用(CNY)`,
  key1003049: `仓库租金(CNY)`,
  key1003050: `订单耗材费用`,
  key1003051: `附加服务费用`,
  key1003052: `退货处理费用`,
  key1003053: `合计总金额`,
  key1003054: `仓储费用重新计算中，请稍候刷新列表查看！`,
  key1003055: `请选勾选需要更新的数据！`,
  key1003056: `规则计算补贴：`,
  key1003057: `调整后补贴：`,
  key1003058: `请输入调整后补贴金额`,
  key1003059: `调整说明：`,
  key1003060: `请输入调整说明`,
  key1003061: `调整后补贴金额不能为空`,
  key1003062: `调整说明不能为空`,
  key1003063: `费用流水状态：`,
  key1003064: `费用类型：`,
  key1003065: `费用产生时间：`,
  key1003066: `分销商`,
  key1003067: `单据编号`,
  key1003068: `账单所属月份`,
  key1003069: `费用流水状态`,
  key1003070: `已生成账单`,
  key1003071: `未生成账单`,
  key1003072: `导出物流账单`,
  key1003073: `导入物流商账单`,
  key1003074: `导入物流账单`,
  key1003075: `1.已结算的账单数据导入后不再进行更新，将显示为失败;`,
  key1003076: `2.支持扩展名:`,
  key1003077: `3.当导入数据存在异常时，数据导入失败;`,
  key1003078: `账号`,
  key1003079: `月包裹数`,
  key1003080: `账单匹配数量`,
  key1003081: `请选择补贴审核结果：`,
  key1003082: `审核不通过原因：`,
  key1003083: `审核原因不能为空`,
  key1003084: `删除打款密码`,
  key1003085: `请输入打款密码：`,
  key1003086: `新打款密码：`,
  key1003087: `请再次输入新密码：`,
  key1003088: `请再次输入打款密码`,
  key1003089: `请再次输入密码：`,
  key1003090: `账单打款密码`,
  key1003091: `删除密码`,
  key1003092: `删除打款密码后,账单线上打款确认时将不再需要输入密码,请谨慎操作。`,
  key1003093: `未设置`,
  key1003094: `设置打款密码`,
  key1003095: `打款密码不能为空`,
  key1003096: `更换成功!`,
  key1003097: `保存成功!`,
  key1003098: `两次输入的密码不一致`,
  key1003099: `已设置`,
  key1003100: `修改打款密码`,
  key1003101: `消费记录（订单仅计算已取消及已出库部分）`,
  key1003102: `月累计充值：`,
  key1003103: `月支付宝累计充值：`,
  key1003104: `月手工累计充值：`,
  key1003105: `月累计消费：`,
  key1003106: `第三方交易凭证`,
  key1003107: `多个`,
  key1003108: `SKU请换行分隔`,
  key1003109: `提单SKU详情`,
  key1003110: `当前提单内SKU如下图所示，共`,
  key1003111: `种，合计`,
  key1003112: `件。`,
  key1003113: `货品上架`,
  key1003114: `待上架数量`,
  key1003115: `上架数量`,
  key1003116: `仓库名称不能为空!`,
  key1003117: `库区名称不能为空!`,
  key1003118: `库位名称不能为空!`,
  key1003119: `上架数量不能为空!`,
  key1003120: `上架数量必须是大于0的整数`,
  key1003121: `上架数量不能大于待上架数量!`,
  key1003122: `上架数量必须大于0`,
  key1003123: `扫描或输入供应商SKU`,
  key1003124: `批量打印`,
  key1003125: `正在贴单作业...`,
  key1003126: `结束贴单作业`,
  key1003127: `应贴数量`,
  key1003128: `扫描成功！`,
  key1003129: `请输入正确的供应商SKU！`,
  key1003130: `供应商SKU不能为空!`,
  key1003131: `请先勾选要打印的数据！`,
  key1003132: `打印数量不能为空!`,
  key1003133: `常规分拣`,
  key1003134: `备货分拣`,
  key1003135: `扫描：`,
  key1003136: `可扫描出库单号、运单号、物流商单号、容器编号`,
  key1003137: `称重：`,
  key1003138: `请保持系统重量单位和电子秤重量单位一致`,
  key1003139: `实际称重(`,
  key1003140: `称重`,
  key1003141: `物流渠道：`,
  key1003142: `结束容器分拣`,
  key1003143: `结袋`,
  key1003144: `打印预约交货面单提示`,
  key1003145: `速卖通线上发货需打印预交面单，请结袋后在物流交接单中打印预交面单。`,
  key1003146: `暂不结袋`,
  key1003147: `直接结袋`,
  key1003148: `预约并结袋`,
  key1003149: `打印揽收面单提示`,
  key1003150: `速卖通全托管JIT订单需打印揽收面单，若未打印将无法交付速卖通揽收。`,
  key1003151: `打印并结袋`,
  key1003152: `移除`,
  key1003153: `移除包裹提示`,
  key1003154: `正在操作移除包裹，移除后可重新扫描装箱`,
  key1003155: `还需`,
  key1003156: `完成`,
  key1003157: `移除成功`,
  key1003158: `克`,
  key1003159: `千克`,
  key1003160: `称重单位为：`,
  key1003161: `请输入出库单号或运单号！`,
  key1003162: `称重必须是大于0的整数！`,
  key1003163: `称重不能为空！`,
  key1003164: `获取面单地址失败！`,
  key1003165: `你还有包裹未完成分拣，你是否结束分拣？`,
  key1003166: `物流分拣异常提示`,
  key1003167: `强制结束`,
  key1003168: `交接人：`,
  key1003169: `请输入交接人`,
  key1003170: `标记出库`,
  key1003171: `交接单号`,
  key1003172: `包裹数量`,
  key1003173: `交货方式`,
  key1003174: `快递至揽收点`,
  key1003175: `物流下发方式`,
  key1003176: `系统下发`,
  key1003177: `手动下发`,
  key1003178: `国内物流单号`,
  key1003179: `交接时间`,
  key1003180: `交接人`,
  key1003181: `继续装箱`,
  key1003182: `打印预交面单`,
  key1003183: `打印快递面单`,
  key1003184: `获取快递面单地址失败`,
  key1003185: `预约揽收`,
  key1003186: `打印揽收面单`,
  key1003187: `请输入交接单号,支持多个逗号或换行分隔`,
  key1003188: `请输入出库单号,支持多个逗号或换行分隔`,
  key1003189: `请先勾选要标记出库的数据！`,
  key1003190: `获取预交面单地址失败`,
  key1003191: `预约成功！`,
  key1003192: `获取揽收面单地址失败`,
  key1003193: `请扫描或录入提单号/快递单号/三方提单编号`,
  key1003194: `三方提单编号`,
  key1003195: `箱/袋内出库单数量`,
  key1003196: `入库成功！`,
  key1003197: `请输入提单号！`,
  key1003198: `上架作业：`,
  key1003199: `扫描或输入容器编号`,
  key1003200: `支持多个容器编号，用逗号或换行分隔`,
  key1003201: `导出上架数据`,
  key1003202: `容器状态：`,
  key1003203: `容器状态`,
  key1003204: `商品数量`,
  key1003205: `作业开始时间`,
  key1003206: `继续上架`,
  key1003207: `实际上架数量`,
  key1003208: `作业结束时间`,
  key1003209: `作业时长`,
  key1003210: `按容器维度导出`,
  key1003211: `按SKU维度导出`,
  key1003212: `容器内SKU数量`,
  key1003213: `上架库位`,
  key1003214: `提货单号不能为空！`,
  key1003215: `商品上架作业提醒`,
  key1003216: `你有商品上架作业未完成，是否继续作业？`,
  key1003217: `贴单作业：`,
  key1003218: `导出贴单数据`,
  key1003219: `贴单作业`,
  key1003220: `继续贴单`,
  key1003221: `已贴单`,
  key1003222: `容器编号不能为空！`,
  key1003223: `商品贴单作业提醒`,
  key1003224: `你有商品贴单作业未完成，是否继续作业？`,
  key1003225: `分拣异常：`,
  key1003226: `揽收时间：`,
  key1003227: `导出提单列表`,
  key1003228: `批量标记已揽收`,
  key1003229: `缺失包裹`,
  key1003230: `物流轨迹`,
  key1003231: `应收货品数量`,
  key1003232: `应收部分实收数量`,
  key1003233: `供应商多发数量`,
  key1003234: `异常状态`,
  key1003235: `数量异常，请点击查看`,
  key1003236: `发货地址`,
  key1003237: `标记已揽收`,
  key1003238: `作废`,
  key1003239: `请输入SKU,支持多个逗号或换行分隔`,
  key1003240: `请输入三方提单编号,支持多个逗号或换行分隔`,
  key1003241: `提货单号`,
  key1003242: `供应商商户名`,
  key1003243: `快递单号`,
  key1003244: `运输方式`,
  key1003245: `按提货单维度导出`,
  key1003246: `请先勾选要标记已揽收的数据！`,
  key1003247: `出库时间：`,
  key1003248: `明细信息`,
  key1003249: `(已取消)`,
  key1003250: `包裹实重（g）`,
  key1003251: `数据来源`,
  key1003252: `称重员`,
  key1003253: `移除成功！`,
  key1003254: `发货设置`,
  key1003255: `单位：`,
  key1003256: `截去：`,
  key1003257: `部分物流商面单扫描的运单号会带前缀或后缀，这样系统就无法识别运单号，勾选可截去相对应的前缀或后缀。`,
  key1003258: `开启(必填)`,
  key1003259: `开启(非必填)`,
  key1003260: `不开启`,
  key1003261: `前`,
  key1003262: `后`,
  key1003263: `请扫描TEMU标签号`,
  key1003264: `TEMU标签号`,
  key1003265: `是否已扫描`,
  key1003266: `重量（g）`,
  key1003267: `打印打包标签`,
  key1003268: `请扫描TEMU标签号！`,
  key1003269: `扫描成功`,
  key1003270: `请扫描所有TEMU小包后再进行结袋操作。`,
  key1003271: `未获取到面单地址！`,
  key1003272: `作废提单确认`,
  key1003273: `作废原因：`,
  key1003274: `请输入作废原因`,
  key1003275: `作废提单后，未发货订单将重新流转至供应商待发货列表中。`,
  key1003276: `确认作废`,
  key1003277: `作废成功！`,
  key1003278: `扫描记录`,
  key1003279: `撤销操作`,
  key1003280: `容器已结束不可撤销`,
  key1003281: `分拣容器：`,
  key1003282: `提单编号：`,
  key1003283: `分拣时间：`,
  key1003284: `供应商SKU：`,
  key1003285: `请先扫描提单号！`,
  key1003286: `撤销操作提示`,
  key1003287: `撤销后，对应容器中的SKU将移除，请谨慎操作。`,
  key1003288: `撤销`,
  key1003289: `提单中存在多发货品，`,
  key1003290: `将作为备货进行处理，并已自动创建相应备货单号，类型为多发补单`,
  key1003291: `提单号：`,
  key1003292: `三方提单编号：`,
  key1003293: `提单申明数量及实际到仓数量`,
  key1003294: `销售订单：`,
  key1003295: `备货单：`,
  key1003296: `多发货品数量`,
  key1003297: `实收数量`,
  key1003298: `目的仓库`,
  key1003299: `已包装`,
  key1003300: `操作内容/留言`,
  key1003301: `请输入备注内容！`,
  key1003302: `分拣作业：`,
  key1003303: `扫描或输入提单号/快递单号/三方提单编号`,
  key1003304: `导出分拣数据`,
  key1003305: `进入作业界面`,
  key1003306: `提货单状态：`,
  key1003307: `提货单状态`,
  key1003308: `提货单类型`,
  key1003309: `出库单数量`,
  key1003310: `分拣作业`,
  key1003311: `提货单货品数`,
  key1003312: `实际分拣数量`,
  key1003313: `sku数量`,
  key1003314: `入库操作时间`,
  key1003315: `分拣开始时间`,
  key1003316: `分拣结束时间`,
  key1003317: `分拣结束操作人`,
  key1003318: `入库分拣作业提醒`,
  key1003319: `你有入库分拣作业未完成，是否继续作业？`,
  key1003320: `上架异常提示`,
  key1003321: `上架数量不能超出待上架数量`,
  key1003322: `库区库位未填写`,
  key1003323: `部分上架`,
  key1003324: `结束上架`,
  key1003325: `SKU上架数量与待上架数量不一致，详情如下，请确认是否结束上架？`,
  key1003326: `继续作业`,
  key1003327: `应上架数量`,
  key1003328: `存在异常SKU，请检查后重试`,
  key1003329: `上架数据未填写完整`,
  key1003330: `扫描或输入`,
  key1003331: `SKU/供应商SKU/物流面单`,
  key1003332: `扫描或输入提货单号`,
  key1003333: `请扫描或输入容器编号`,
  key1003334: `正在分拣作业...`,
  key1003335: `结束分拣作业`,
  key1003336: `结束本单分拣`,
  key1003337: `逐件扫描`,
  key1003338: `超出应发数量是否提示`,
  key1003339: `启用语音播报`,
  key1003340: `需贴单`,
  key1003341: `请添加容器`,
  key1003342: `容器完拣`,
  key1003343: `找到多种类型面单，请正确选择！`,
  key1003344: `暂不处理`,
  key1003345: `绑定容器`,
  key1003346: `结束分拣`,
  key1003347: `已在`,
  key1003348: `容器中存在相同SKU，请结束`,
  key1003349: `容器后重新扫描提单`,
  key1003350: `SKU/供应商SKU/物流面单不能不为空！`,
  key1003351: `应收数量`,
  key1003352: `分拣数量`,
  key1003353: `还需分拣`,
  key1003354: `已扫数量`,
  key1003355: `请扫描商品`,
  key1003356: `已分拣数量`,
  key1003357: `未分拣`,
  key1003358: `提货单号不能为空!`,
  key1003359: `提货单中没有此商品，请仔细确认！`,
  key1003360: `此包裹已被分拣，请勿重复扫描`,
  key1003361: `容器完拣提示`,
  key1003362: `此容器拣货数量已达上限，系统将自动将容器完拣，请绑定新容器继续作业。`,
  key1003363: `此容器包装数量已达上限，系统将自动将容器完拣，请绑定新容器继续作业。`,
  key1003364: `将同时结束提货单与容器分拣，你确定要结束？`,
  key1003365: `绑定成功！`,
  key1003366: `容器颜色重复提示`,
  key1003367: `此颜色容器已存在，请选择不同颜色的容器。`,
  key1003368: `设置容器颜色提示`,
  key1003369: `此容器无颜色，请选择具有颜色属性的容器。`,
  key1003370: `请先扫描提单号!`,
  key1003371: `容器编号不能为空!`,
  key1003372: `请选择面单类型！`,
  key1003373: `SKU超出应收提示`,
  key1003374: `正在进行作业的SKU已超出应收数量，分拣完成后若存在超出应收数量的SKU，系统将自动进行补单并向供应商发送提示，无需人工操作。`,
  key1003375: `此SKU需进行上架，暂未绑定贴单+上架容器，请绑定贴单+上架容器：`,
  key1003376: `此SKU需进行上架，暂未绑定上架容器，请绑定上架容器：`,
  key1003377: `SKU数量异常提示`,
  key1003378: `以下SKU数量与应发数量有差异，结束分拣后实际多收的SKU系统将自动进行补单并向供应商发送提示。`,
  key1003379: `国内快递选择`,
  key1003380: `下单方式：`,
  key1003381: `国内快递：`,
  key1003382: `此物流渠道需发快递至揽收点，结袋后请将此渠道下的所有包裹再次打包，最外层贴国内快递面单！`,
  key1003383: `系统下单`,
  key1003384: `线下下单`,
  key1003385: `请选择国内快递`,
  key1003386: `请输入取消`,
  key1003387: `订单原因，此原因分销商可见。`,
  key1003388: `取消订单`,
  key1003389: `订单取消原因：`,
  key1003390: `确认取消`,
  key1003391: `订单原因`,
  key1003392: `取消成功!`,
  key1003393: `供应商订单总金额：`,
  key1003394: `分销商订单总金额：`,
  key1003395: `分销商应付金额：`,
  key1003396: `供应商应收金额：`,
  key1003397: `保存并下单`,
  key1003398: `的供应商订单总金额和分销商订单总金额不能为空`,
  key1003399: `保存并下单成功`,
  key1003400: `备货单状态：`,
  key1003401: `备货单号：`,
  key1003402: `请输入备货单号,支持多个按行分隔`,
  key1003403: `下单时间：`,
  key1003404: `付款时间：`,
  key1003405: `完结时间：`,
  key1003406: `订单金额：`,
  key1003407: `订单原金额：`,
  key1003408: `调价后金额：`,
  key1003409: `已取消金额：`,
  key1003410: `已退款金额：`,
  key1003411: `分销商：`,
  key1003412: `供应商子单单号：`,
  key1003413: `发货时间：`,
  key1003414: `入库时间：`,
  key1003415: `金额：`,
  key1003416: `调价：`,
  key1003417: `结算：`,
  key1003418: `原单价：`,
  key1003419: `调价后：`,
  key1003420: `单价：`,
  key1003421: `订单数量：`,
  key1003422: `收货数量：`,
  key1003423: `上架数量：`,
  key1003424: `取消数量：`,
  key1003425: `退款数量：`,
  key1003426: `缺货数量：`,
  key1003427: `预计到货时间：`,
  key1003428: `单号：`,
  key1003429: `异常状态：`,
  key1003430: `补货中`,
  key1003431: `收货数量`,
  key1003432: `取消数量`,
  key1003433: `退款数量`,
  key1003434: `待调价`,
  key1003435: `待供应商接单`,
  key1003436: `待分销商付款`,
  key1003437: `待入库`,
  key1003438: `已完成`,
  key1003439: `请输入SPU，支持多个逗号或换行分隔`,
  key1003440: `请输入SKU，支持多个逗号或换行分隔`,
  key1003441: `请输入分销商商户号，支持多个逗号或换行分隔`,
  key1003442: `请输入供应商商户号，支持多个逗号或换行分隔`,
  key1003443: `查询中...`,
  key1003444: `待重发`,
  key1003445: `部分到货`,
  key1003446: `已完结`,
  key1003447: `下单确认`,
  key1003448: `正在操作下单。请确认是否下单？`,
  key1003449: `确认下单`,
  key1003450: `下单成功！`,
  key1003451: `主信息`,
  key1003452: `收货人信息`,
  key1003453: `物流商信息`,
  key1003454: `订单详情：`,
  key1003455: `订单SKU`,
  key1003456: `打包信息`,
  key1003457: `已发货（`,
  key1003458: `行号`,
  key1003459: `供应商订单号`,
  key1003460: `缺货状态`,
  key1003461: `缺货中，请查看详情`,
  key1003462: `订单状态更新时间`,
  key1003463: `是否作废`,
  key1003464: `未作废`,
  key1003465: `详情`,
  key1003466: `中文申报名`,
  key1003467: `英文申报名`,
  key1003468: `申报价值`,
  key1003469: `申报重量`,
  key1003470: `申报数量`,
  key1003471: `海关编码`,
  key1003472: `销售链接`,
  key1003473: `申报信息`,
  key1003474: `日志内容描述`,
  key1003475: `面单类型：`,
  key1003476: `分销商订单号：`,
  key1003477: `发货仓库：`,
  key1003478: `收货人名称：`,
  key1003479: `国家：`,
  key1003480: `买家识别号：`,
  key1003481: `详细地址1：`,
  key1003482: `详细地址2：`,
  key1003483: `物流商单号：`,
  key1003484: `邮寄方式：`,
  key1003485: `运单号1：`,
  key1003486: `运单号2：`,
  key1003487: `物流商称重（g）：`,
  key1003488: `物流商运费：`,
  key1003489: `Temu标签号`,
  key1003490: `SKU*数量`,
  key1003491: `包裹重量(g)`,
  key1003492: `暂无打包标签`,
  key1003493: `已配货`,
  key1003494: `已备货`,
  key1003495: `新增行请填写完整`,
  key1003496: `请先添加申报信息！`,
  key1003497: `当前小包未进行物流分拣，无法打印箱唛`,
  key1003498: `sku信息`,
  key1003499: `出库单类型：`,
  key1003500: `到仓时间：`,
  key1003501: `所有SKU`,
  key1003502: `到仓异常数量SKU`,
  key1003503: `发起备货数量`,
  key1003504: `实际到仓数量`,
  key1003505: `修改物流方式`,
  key1003506: `选择修改后仓库：`,
  key1003507: `选择修改后物流方式：`,
  key1003508: `账号：`,
  key1003509: `修改失败数据`,
  key1003510: `物流动态参数`,
  key1003511: `确认修改`,
  key1003512: `原物流商`,
  key1003513: `原物流方式`,
  key1003514: `目的地`,
  key1003515: `原因`,
  key1003516: `未选择数据`,
  key1003517: `请选择物流渠道`,
  key1003518: `账号不能为空`,
  key1003519: `SKU，支持多个逗号或换行分隔`,
  key1003520: `订单状态：`,
  key1003521: `订单标签：`,
  key1003522: `下发状态：`,
  key1003523: `物流方式：`,
  key1003524: `订单类型：`,
  key1003525: `来源：`,
  key1003526: `履约方：`,
  key1003527: `来源为自有渠道的数据，才能修改物流方式！`,
  key1003528: `下发物流`,
  key1003529: `订单号，支持多个逗号或换行分隔`,
  key1003530: `状态更新时间：`,
  key1003531: `订单创建时间：`,
  key1003532: `订单发货时间：`,
  key1003533: `订单取消时间：`,
  key1003534: `个供应商子单`,
  key1003535: `物品数量：`,
  key1003536: `订单信息`,
  key1003537: `订单SKU信息`,
  key1003538: `订单发货时间`,
  key1003539: `发货时效`,
  key1003540: `请输入分销商订单号，支持多个逗号或换行分隔`,
  key1003541: `请输入供应商订单号，支持多个逗号或换行分隔`,
  key1003542: `请输入运单号，支持多个逗号或换行分隔`,
  key1003543: `请输入Temu标签号，支持多个逗号或换行分隔`,
  key1003544: `物流三方单号`,
  key1003545: `请输入物流三方单号，支持多个逗号或换行分隔`,
  key1003546: `（待执行发货）`,
  key1003547: `待下发`,
  key1003548: `下发中`,
  key1003549: `下发成功`,
  key1003550: `下发失败`,
  key1003551: `国内快递费：`,
  key1003552: `货品金额：`,
  key1003553: `物流费：`,
  key1003554: `处理费：`,
  key1003555: `需通过快递发至揽收点的包裹将加收国内快递费。`,
  key1003556: `清关费：`,
  key1003557: `更新承运商`,
  key1003558: `添加承运商`,
  key1003559: `物流三方单号：`,
  key1003560: `国内快递单号：`,
  key1003561: `发货信息`,
  key1003562: `客选物流：`,
  key1003563: `供应商子单信息`,
  key1003564: `部分补货中`,
  key1003565: `部分已停售`,
  key1003566: `查看子单`,
  key1003567: `货品信息`,
  key1003568: `通知分销商`,
  key1003569: `允许取消`,
  key1003570: `自有渠道`,
  key1003571: `三方渠道`,
  key1003572: `商品费用(CNY)`,
  key1003573: `总费用(CNY)`,
  key1003574: `处理费(CNY)`,
  key1003575: `物流费(CNY)`,
  key1003576: `物流名称`,
  key1003577: `物流商单号`,
  key1003578: `运单号1`,
  key1003579: `运单号2`,
  key1003580: `供应商出库时间`,
  key1003581: `提单揽收时间`,
  key1003582: `订单取消时间`,
  key1003583: `订单取消原因`,
  key1003584: `订单类型`,
  key1003585: `订单标签`,
  key1003586: `平台订单信息`,
  key1003587: `平台站点`,
  key1003588: `店铺名`,
  key1003589: `平台订单号`,
  key1003590: `平台订单金额`,
  key1003591: `平台订单状态`,
  key1003592: `平台订单SKU信息`,
  key1003593: `下单时间`,
  key1003594: `付款时间`,
  key1003595: `订单维度`,
  key1003596: `SKU维度`,
  key1003597: `(列)`,
  key1003598: `(行)`,
  key1003599: `按行展示时，通用信息将进行合并，统计时请注意。`,
  key1003600: `状态更新时间`,
  key1003601: `按更新时间`,
  key1003602: `供应商仓直发`,
  key1003603: `分销集货仓发`,
  key1003604: `分销集货仓发(未贴标)`,
  key1003605: `单次最多查询1000条，请调整后重试。`,
  key1003606: `更新成功！`,
  key1003607: `请先勾选要下发物流的数据！`,
  key1003608: `请先勾选要修改的数据！`,
  key1003609: `请先勾选要添加标签的数据！`,
  key1003610: `删除标签确认`,
  key1003611: `正在操作删除订单标签。请确认是否删除?`,
  key1003612: `确认删除`,
  key1003613: `已装箱状态允许取消订单确认`,
  key1003614: `取消处理费：`,
  key1003615: `此订单已装箱，请务必确认现场是否未发出，取消后需及时联系相关人员将包裹进行取出。`,
  key1003616: `允许取消订单`,
  key1003617: `取消处理费不能为空`,
  key1003618: `供应商订单号：`,
  key1003619: `出库单状态：`,
  key1003620: `货品数量：`,
  key1003621: `缺货信息`,
  key1003622: `请选择备货单状态`,
  key1003623: `供应商出库单状态：`,
  key1003624: `请选择出库单状态`,
  key1003625: `供应商出库单类型：`,
  key1003626: `请选择供应商出库单类型`,
  key1003627: `导出订单`,
  key1003628: `已收货`,
  key1003629: `Sku,支持多个逗号或换行分隔`,
  key1003630: `备货单号`,
  key1003631: `请输入备货单号,支持多个逗号或换行分隔`,
  key1003632: `备货单状态`,
  key1003633: `供应商出库单状态`,
  key1003634: `供应商出库单类型`,
  key1003635: `发货时间`,
  key1003636: `取消时间`,
  key1003637: `请选择订单状态`,
  key1003638: `结算状态：`,
  key1003639: `请选择结算状态`,
  key1003640: `超时时间：`,
  key1003641: `，仓库实收数量不足，请至发货记录中查看详情`,
  key1003642: `已作废，需重新发货。`,
  key1003643: `剩余发货时间`,
  key1003644: `已超时时间`,
  key1003645: `条码：`,
  key1003646: `到仓数量：`,
  key1003647: `超时时间`,
  key1003648: `完结时间`,
  key1003649: `完结时间指此订单流程已全部结束时间节点，订单将已此节点时间计入对应账期。`,
  key1003650: `标记到货`,
  key1003651: `更新标记`,
  key1003652: `超时未发`,
  key1003653: `请输入订单号，支持多个逗号或换行分隔`,
  key1003654: `请输入供应商SKU，支持多个逗号或换行分隔`,
  key1003655: `请输入供应商名称，支持多个逗号或换行分隔`,
  key1003656: `供应商商户编号`,
  key1003657: `订单总金额`,
  key1003658: `子单类型`,
  key1003659: `未入账`,
  key1003660: `超时时间开始时长不能大于结束时长`,
  key1003661: `获取标记缺货详情数据失败！`,
  key1003662: `已超时`,
  key1003663: `秒`,
  key1003664: `添加备注：`,
  key1003665: `修改运单号`,
  key1003666: `请输入运单号`,
  key1003667: `请输入物流商单号`,
  key1003668: `请选择物流方式`,
  key1003669: `运单号不能为空`,
  key1003670: `SKU，用逗号或换行分隔`,
  key1003671: `SPU，用逗号或换行分隔`,
  key1003672: `多个供应商商户号，用逗号或换行分隔`,
  key1003673: `库位：`,
  key1003674: `请输入库位代码`,
  key1003675: `货主：`,
  key1003676: `请输入商户号`,
  key1003677: `总库存：`,
  key1003678: `导出库存余量`,
  key1003679: `导出库存明细`,
  key1003680: `导出库存余量数据`,
  key1003681: `导出库存明细数据`,
  key1003682: `1.导入库存后将覆盖原来库存，请谨慎导入;`,
  key1003683: `商品导入`,
  key1003684: `按SKU`,
  key1003685: `规格属性值`,
  key1003686: `货主角色`,
  key1003687: `货主商户号`,
  key1003688: `按产品查询`,
  key1003689: `按产品/库位查询`,
  key1003690: `可用数量必须是大于等于0的正整数`,
  key1003691: `可用库存开始区间不能大于可用库存结束区间`,
  key1003692: `明细`,
  key1003693: `库存详情`,
  key1003694: `显示库存为0的数据`,
  key1003695: `常规包装`,
  key1003696: `备货包装`,
  key1003697: `包装人：`,
  key1003698: `请输入拣货单号，支持多个按行分隔`,
  key1003699: `导出多品分拣`,
  key1003700: `扫描拣货单单号/容器编号：`,
  key1003701: `你有包装中作业未完成，拣货单号：`,
  key1003702: `，是否继续作业?`,
  key1003703: `拣货单号`,
  key1003704: `拣货单状态`,
  key1003705: `出库单数`,
  key1003706: `拣货/分拣完成时间`,
  key1003707: `拣货/分拣人`,
  key1003708: `包装人`,
  key1003709: `开始作业`,
  key1003710: `作业操作人`,
  key1003711: `开始作业时间`,
  key1003712: `结束作业时间`,
  key1003713: `常规包装作业提醒`,
  key1003714: `备货包装作业提醒`,
  key1003715: `处理中....`,
  key1003716: `请输入拣货单号！`,
  key1003717: `单品拣货单打印时货品列表输入顺序：`,
  key1003718: `多品拣货单打印时货品列表输入顺序：`,
  key1003719: `集货超时：`,
  key1003720: `分拣时打印地址面单：`,
  key1003721: `拣货单设置`,
  key1003722: `集货超时设置`,
  key1003723: `包装作业设置`,
  key1003724: `常规分拣架设置(仅在多品多件包裹自动分拣功能开启时可用)`,
  key1003725: `启用左侧分拣货架`,
  key1003726: `货架矩阵`,
  key1003727: `行`,
  key1003728: `列`,
  key1003729: `启用中间分拣货架`,
  key1003730: `启用右侧分拣货架`,
  key1003731: `备货分拣架设置(仅在多品多件包裹自动分拣功能开启时可用)`,
  key1003732: `保存见货出单设置`,
  key1003733: `仅按货品库位字符升序`,
  key1003734: `仅按货品库位降序`,
  key1003735: `请至少选择一个常规拣货架！`,
  key1003736: `请至少选择一个备货拣货架！`,
  key1003737: `分拣人：`,
  key1003738: `你有分拣中作业未完成，拣货单号:`,
  key1003739: `分拣人`,
  key1003740: `扫描/录入拣货单单号开始多品分拣：`,
  key1003741: `常规分拣作业提醒`,
  key1003742: `备货分拣作业提醒`,
  key1003743: `包裹重量不能为空`,
  key1003744: `小包结袋`,
  key1003745: `包裹重量(g)：`,
  key1003746: `当前小包内SKU如下图所示，共`,
  key1003747: `件，确认数量后请先进行称重，再进行结袋操作。`,
  key1003748: `打印标签并结袋`,
  key1003749: `待盘点`,
  key1003750: `盘点中`,
  key1003751: `盘点单:`,
  key1003752: `盘点仓库：`,
  key1003753: `拣货提醒：`,
  key1003754: `盘点单状态：`,
  key1003755: `动态盘点：`,
  key1003756: `盲盘：`,
  key1003757: `盘点人员：`,
  key1003758: `库存数`,
  key1003759: `盘点数`,
  key1003760: `差异数`,
  key1003761: `成功生成`,
  key1003762: `张拣货单`,
  key1003763: `拣货单单号`,
  key1003764: `拣货单生成规则：`,
  key1003765: `去拣货单列表`,
  key1003766: `每张备货拣货单最大包裹数不能为空！`,
  key1003767: `每张常规拣货单最大包裹数不能为空！`,
  key1003768: `拣货单`,
  key1003769: `商品标签选择`,
  key1003770: `拣货单号：{pickingGoodsNo} 已经分拣完成，点击【结束本次分拣】结束本次作业`,
  key1003771: `您扫描的货品{sku}，当前拣货单中的包裹均不需要或需求数量已经满足。`,
  key1003772: `当前分拣作业拣货单：`,
  key1003773: `结束本次分拣`,
  key1003774: `SKU/货品身份码`,
  key1003775: `全部分拣完成`,
  key1003776: `货品异常警告`,
  key1003777: `可能的原因：`,
  key1003778: `1.您拣货的时候错误拣出该货品或该货品拣货过多。`,
  key1003779: `2.该货品条码粘贴错误。`,
  key1003780: `请将该货品交由异常处理部门处理。`,
  key1003781: `继续扫描其他货品（回车）`,
  key1003782: `拣货单信息：`,
  key1003783: `出库单信息：`,
  key1003784: `将该包裹重置为未开始分拣`,
  key1003785: `将该包裹从当前拣货单中移除等待生成新拣货单`,
  key1003786: `打印Temu中间标签`,
  key1003787: `请选择商品标签：`,
  key1003788: `见货货单`,
  key1003789: `分拣单`,
  key1003790: `Temu发货订单`,
  key1003791: `sku/名称/规格`,
  key1003792: `总货品数量`,
  key1003793: `已分拣货品数量`,
  key1003794: `包裹`,
  key1003795: `拣货单下出库单均已换单`,
  key1003796: `请扫描正确的SKU/货品身份码`,
  key1003797: `没有可用拣货篮`,
  key1003798: `当前货品无标签地址`,
  key1003799: `多品备货分拣—结束分拣`,
  key1003800: `拣货单取消提示`,
  key1003801: `拣货单内已无出库单，拣货单已取消，点击按钮后将自动结束本次作业。`,
  key1003802: `打印失败`,
  key1003803: `打印标签成功！`,
  key1003804: `分拣打印完成`,
  key1003805: `应发数量`,
  key1003806: `容器货品数量`,
  key1003807: `待分拣数量`,
  key1003808: `打印标签`,
  key1003809: `打印Temu中间标签成功！`,
  key1003810: `获取Temu中间标签地址失败！`,
  key1003811: `未获取到Temu中间标签面单地址`,
  key1003812: `拣货复核-结束作业`,
  key1003813: `您即将结束拣货单 {pickingGoodsNo} 的拣货复核`,
  key1003814: `共 {scannedCount} 人参与本次拣货复核`,
  key1003815: `包装总时间 {scannedTime} 分钟`,
  key1003816: `总计(人.时) {userScannedTime} 分钟`,
  key1003817: `您即将结束拣货单 {pickingGoodsNo} 的拣货复核，但系统检测到以下异常`,
  key1003818: `以下包裹货品已扫描但包裹正在打印中`,
  key1003819: `保存为Excel`,
  key1003820: `通常因打印机信息返回异常导致，需要人工确认地址标签是否已经正常打印。`,
  key1003821: `如果您标记为已打印，强制结束拣货复核时将认为这些包裹已经正常包装，包裹无法再生成新的拣货单。`,
  key1003822: `如果您标记为未打印，强制结束拣货复核时将认为这些包裹未打印包装，系统将把这些包裹从拣货单中移除，您可以重新生成新的拣货单。`,
  key1003823: `以下包裹尚未打印或包裹打印前被作废`,
  key1003824: `强制结束拣货复核时未打印包裹将从拣货单中移除，再次生成新的拣货单。`,
  key1003825: `拣货单打印之后，地址标签打印之前，包裹被作废，将会导致拣货的实物货品剩余。`,
  key1003826: `正常情况下，本项货品汇总应与您手中的货品实物一致。`,
  key1003827: `以下包裹打印之后被作废`,
  key1003828: `地址标签打印后，包裹被作废，会导致包裹被正常包装，建议立即拦截，并在[回收包裹]中进行确认。`,
  key1003829: `结束作业`,
  key1003830: `返回继续包装`,
  key1003831: `已扫描数量`,
  key1003832: `未拣数量`,
  key1003833: `已完成包装包裹总数`,
  key1003834: `已完成包装货品总数`,
  key1003835: `统计项目`,
  key1003836: `拣货单数量`,
  key1003837: `实际完成数量`,
  key1003838: `作业人`,
  key1003839: `包裹总数`,
  key1003840: `作业时长(分钟)`,
  key1003841: `包裹速度(每分钟)`,
  key1003842: `货品速度(每分钟)`,
  key1003843: `收件人`,
  key1003844: `扫描时间`,
  key1003845: `已打`,
  key1003846: `未打`,
  key1003847: `货品小计`,
  key1003848: `扫描货品`,
  key1003849: `获取包裹信息`,
  key1003850: `校对货品数量`,
  key1003851: `发送至打印机`,
  key1003852: `标记已打印`,
  key1003853: `SKU异常`,
  key1003854: `您扫描或输入的SKU【 {warnSku} 】在当前拣货单中未找到`,
  key1003855: `未匹配到指定`,
  key1003856: `当前拣货复核拣货单：`,
  key1003857: `当前操作员：`,
  key1003858: `退出当前作业`,
  key1003859: `自动匹配包裹：`,
  key1003860: `清空已扫描货品并重新扫描`,
  key1003861: `拣货单的出库单`,
  key1003862: `您需要先处理当前未打印的包裹`,
  key1003863: `您提交的扫描的结果，在当前拣货单中未能找到包裹信息，可能原因：`,
  key1003864: `*当前包裹拣货货品存在错误`,
  key1003865: `*您扫描的过程中存在遗漏或者重复。`,
  key1003866: `拣货单不存在或状态已改变,请刷新后重试`,
  key1003867: `拣货复核扫描的出库单不存在`,
  key1003868: `拣货复核扫描出库单已作废`,
  key1003869: `SKU未匹配合适出库单`,
  key1003870: `拣货复核已结束`,
  key1003871: `存在未完成包装的出库单`,
  key1003872: `等待提交后获取包裹信息`,
  key1003873: `物流商/邮寄方式`,
  key1003874: `SKU及数量`,
  key1003875: `打印货品标签`,
  key1003876: `获取速卖通全托管货品条码失败！`,
  key1003877: `名称 / 规格`,
  key1003878: `校对`,
  key1003879: `当前SKU对应订单为速卖通全托管JIT订单，需打印对应货品条码。请注意纸张大小需为60mm*30mm。`,
  key1003880: `打印成功`,
  key1003881: `打印Temu条码`,
  key1003882: `当前出库单：`,
  key1003883: `已装货品数量：`,
  key1003884: `整单完结`,
  key1003885: `分拣容器及对应出库单`,
  key1003886: `名称/规格`,
  key1003887: `已扫描数量不能为空！`,
  key1003888: `已扫 / 未扫`,
  key1003889: `已扫`,
  key1003890: `未扫`,
  key1003891: `打印Temu标签`,
  key1003892: `（部分包装）`,
  key1003893: `（已包装）`,
  key1003894: `（已完结）`,
  key1003895: `超出拣货单数量，请检查实际货品数量!`,
  key1003896: `SKU非此订单所需货品，请确认货品所属订单！`,
  key1003897: `SKU不能为空!`,
  key1003898: `存在未完结出库单，请标记完结后再结束作业。`,
  key1003899: `所有货品均已扫描，请结束作业`,
  key1003900: `超出拣货单数量，请检查实际货品数量`,
  key1003901: `此出库单已标记完结，请勿重复标记！`,
  key1003902: `整单编辑完结后不可进行变更，请确认无误后再进行标记。`,
  key1003903: `标记完结`,
  key1003904: `完结异常提示`,
  key1003905: `当前出库单末进行包装作业，标记完结后状态将流转至待生成拣货单中，请确认无误后再进行标记。`,
  key1003906: `请结束小包后再标记整单完结。`,
  key1003907: `您即将结束拣货单 {pickingGoodsNo} 的分拣作业，但系统检测到以下异常`,
  key1003908: `以下包裹未完成所有货品的分拣`,
  key1003909: `1.拣货货品小于拣货单要求数量。`,
  key1003910: `2.之前的分拣过程在某个拣货框中放入了过多的货品。`,
  key1003911: `3.等待分拣区尚有剩余货品未扫描。`,
  key1003912: `拣货与分拣过程均确保数量准确的情况下，待拣部分货品应该与等待分拣区的剩余货品数量一致。`,
  key1003913: `如果您觉得错误过多，无法追溯，您可以将所有拣货框中的货品全部放回等待分拣区，`,
  key1003914: `并`,
  key1003915: `撤销本次分拣`,
  key1003916: `拣货单可以再次开启分拣作业。`,
  key1003917: `返回继续分拣`,
  key1003918: `拣货框`,
  key1003919: `未分配`,
  key1003920: `货品（已拣/待拣）`,
  key1003921: `异常原因`,
  key1003922: `未开始分拣`,
  key1003923: `分拣未完成`,
  key1003924: `已拣`,
  key1003925: `待拣`,
  key1003926: `货品数量异常提示`,
  key1003927: `本次作业中存在以下SKU仍未扫描，请再次核对货品数量，若存在丢货情况请及时与管理人员联系。`,
  key1003928: `返回作业`,
  key1003929: `应扫数量`,
  key1003930: `实扫数量`,
  key1003931: `打印货品条码`,
  key1003932: `应发货品数量`,
  key1003933: `拣货框货品数量`,
  key1003934: `获取打印面单地址失败！`,
  key1003935: `重置成功！`,
  key1003936: `多品常规分拣—结束分拣`,
  key1003937: `获取货品条码失败！`,
  key1003938: `(Temu样品订单)`,
  key1003939: `(Temu货品订单)`,
  key1003940: `不存在拣货单明细`,
  key1003941: `打印SKU`,
  key1003942: `删除选中`,
  key1003943: `不自动关闭`,
  key1003944: `请下载通途打印控件`,
  key1003945: `条形码未生成成功，请重新点击打印。`,
  key1003946: `展示更多`,
  key1003947: `超出输入限制长度`,
  key1003948: `添加备注成功！`,
  key1003949: `添加备注失败！`,
  key1003950: `新增失败`,
  key1003951: `SKU，支持多个按行分隔`,
  key1003952: `导出出库明细`,
  key1003953: `订单号，支持多个按行分隔`,
  key1003954: `请输入分销商商户号，支持多个按行分隔`,
  key1003955: `请输入分销商订单号，支持多个按行分隔`,
  key1003956: `请输入运单号，支持多个按行分隔`,
  key1003957: `出库人`,
  key1003958: `生成拣货单人`,
  key1003959: `物流分拣人`,
  key1003960: `发货数量（件）`,
  key1003961: `分配库存时间`,
  key1003962: `生成拣货单时间`,
  key1003963: `完成拣货时间`,
  key1003964: `完成分拣时间`,
  key1003965: `完成包装时间`,
  key1003966: `完成物流分拣时间`,
  key1003967: `出库时间`,
  key1003968: `物流商运单号`,
  key1003969: `发货数量`,
  key1003970: `操作人员`,
  key1003971: `活动状态：`,
  key1003972: `活动名称：`,
  key1003973: `请输入活动名称关键字`,
  key1003974: `新增活动`,
  key1003975: `未开始`,
  key1003976: `进行中`,
  key1003977: `已结束`,
  key1003978: `活动图片`,
  key1003979: `活动名称`,
  key1003980: `活动状态`,
  key1003981: `活动开始时间`,
  key1003982: `活动结束时间`,
  key1003983: `长期有效`,
  key1003984: `立即开始`,
  key1003985: `立即开始活动确认`,
  key1003986: `确认后将活动将立即开始并展示在商城端，请确认是否开始？`,
  key1003987: `立即结束`,
  key1003988: `立即结束活动确认`,
  key1003989: `确认后将活动将立即结束，请确认是否结束？`,
  key1003990: `请输入入口名称`,
  key1003991: `图片：`,
  key1003992: `点击跳转：`,
  key1003993: `更换`,
  key1003994: `效果图`,
  key1003995: `金刚区`,
  key1003996: `图片上传`,
  key1003997: `(建议尺寸：60*60)`,
  key1003998: `未填写`,
  key1003999: `效果预览`,
  key1004000: `入口`,
  key1004001: `金刚区图片为空，请上传后重试。`,
  key1004002: `名称不能为空！`,
  key1004003: `请先上传`,
  key1004004: `的图片！`,
  key1004005: `的点击跳转地址不能为空！`,
  key1004006: `图片成功`,
  key1004007: `图片失败`,
  key1004008: `点击跳转`,
  key1004009: `跳转目标类型：`,
  key1004010: `跳转目标：`,
  key1004011: `活动`,
  key1004012: `指定链接`,
  key1004013: `不跳转`,
  key1004014: `跳转目标类型不能为空`,
  key1004015: `指定链接地址必须是https://或者http://开头的链接！`,
  key1004016: `跳转目标不能为空!`,
  key1004017: `请输入站点名称`,
  key1004018: `站点域名：`,
  key1004019: `请输入站点域名`,
  key1004020: `站点说明：`,
  key1004021: `请输入站点说明`,
  key1004022: `站点名称`,
  key1004023: `站点域名`,
  key1004024: `站点说明`,
  key1004025: `查看站点信息`,
  key1004026: `编辑站点信息`,
  key1004027: `banner图：`,
  key1004028: `首页轮播图`,
  key1004029: `顶部广告位`,
  key1004030: `侧边广告位`,
  key1004031: `首页-个人信息下方广告位`,
  key1004032: `更改启用状态提示`,
  key1004033: `此操作将更改该广告位在商城端是否可见，请确认是否继续更改。`,
  key1004034: `(建议尺寸：70*228)`,
  key1004035: `广告图为空，请上传后重试。`,
  key1004036: `点击跳转地址不能为空！`,
  key1004037: `(建议高度：80px)`,
  key1004038: `banner图为空，请上传后重试。`,
  key1004039: `(建议尺寸：228*70)`,
  key1004040: `统一定价(`,
  key1004041: `是否新增为首页tab：`,
  key1004042: `是否展示供应商信息：`,
  key1004043: `活动开始时间：`,
  key1004044: `选择活动开始时间`,
  key1004045: `活动结束时间：`,
  key1004046: `选择活动结束时间`,
  key1004047: `是否统一设置价格：`,
  key1004048: `配置匹配规则`,
  key1004049: `匹配规则：`,
  key1004050: `上新天数：`,
  key1004051: `移除商品确认`,
  key1004052: `活动信息`,
  key1004053: `(建议尺寸：1200*160)`,
  key1004054: `活动定价`,
  key1004055: `活动商品`,
  key1004056: `指定商品`,
  key1004057: `规则匹配`,
  key1004058: `配置规则`,
  key1004059: `批量移除`,
  key1004060: `规则说明：`,
  key1004061: `1.系统将自动匹配满足一定上新天数内的商品，如：填写7天，则系统将按审核通过时间匹配与今天相差7天内的商品添加至活动中。`,
  key1004062: `移除后商品将不再参加此活动，价格可能发生改变，系统将提醒已订阅的分销商。`,
  key1004063: `移除商品`,
  key1004064: `活动开始时间不能为空`,
  key1004065: `按上新天数匹配`,
  key1004066: `匹配规则不能为空`,
  key1004067: `上新天数不能为空`,
  key1004068: `标语：`,
  key1004069: `商品活动`,
  key1004070: `活动结束时间不能为空`,
  key1004071: `请先勾选参与活动的商品！`,
  key1004072: `请先配置匹配规则！`,
  key1004073: `统一定价不能为空！`,
  key1004074: `活动结束时间不能小于活动开始时间！`,
  key1004075: `费用配置`,
  key1004076: `发货仓：`,
  key1004077: `单件处理费：`,
  key1004078: `续件处理费：`,
  key1004079: `仓库处理费 = `,
  key1004080: `（件数-1）`,
  key1004081: `发货仓不能为空`,
  key1004082: `单件处理费不能为空`,
  key1004083: `续件处理费不能为空`,
  key1004084: `(建议尺寸：644*324)`,
  key1004085: `删除轮播项`,
  key1004086: `+添加轮播项`,
  key1004087: `轮播项`,
  key1004088: `轮播图为空，请上传后重试。`,
  key1004089: `轮播项1`,
  key1004090: `创建补贴规则`,
  key1004091: `补贴规则名称`,
  key1004092: `规则状态`,
  key1004093: `添加子规则`,
  key1004094: `运费补贴`,
  key1004095: `添加费用配置`,
  key1004096: `订单续件`,
  key1004097: `件`,
  key1004098: `发货仓`,
  key1004099: `费用规则`,
  key1004100: `补贴对象：`,
  key1004101: `补贴类型：`,
  key1004102: `选择时间`,
  key1004103: `多个商户号请用英文逗号，或换行分隔`,
  key1004104: `条件`,
  key1004105: `此名称客户可见，请合理命名`,
  key1004106: `补贴条件：`,
  key1004107: `添加条件`,
  key1004108: `补贴金额：`,
  key1004109: `子规则补贴将与主规则叠加，请合理配置`,
  key1004110: `单个提单补贴：`,
  key1004111: `0-1公斤补贴：`,
  key1004112: `续重每公斤补贴：`,
  key1004113: `单个提单补贴上限：`,
  key1004114: `补贴对象不能为空`,
  key1004115: `补贴类型不能为空`,
  key1004116: `创建子规则`,
  key1004117: `编辑补贴规则`,
  key1004118: `编辑子规则`,
  key1004119: `补贴规则未完善，请完整填写后重试。`,
  key1004120: `提单内货品数量必须是大于等于0整数`,
  key1004121: `订单发货率必须是大于等于0`,
  key1004122: `补贴金额未完善，请完整填写后重试。`,
  key1004123: `输入商户号`,
  key1004124: `服务器选择`,
  key1004125: `搜索：`,
  key1004126: `云卖刊登`,
  key1004127: `入驻成功`,
  key1004128: `更多角色`,
  key1004129: `可输入员工姓名、邮箱、手机号`,
  key1004130: `账号状态：`,
  key1004131: `管理员工角色`,
  key1004132: `添加新员工`,
  key1004133: `按姓名`,
  key1004134: `按邮箱`,
  key1004135: `最后登录时间`,
  key1004136: `是否要删除当前角色？`,
  key1004137: `角色名称：`,
  key1004138: `请输入角色名称`,
  key1004139: `组装加工 - `,
  key1004140: `拣货 - `,
  key1004141: `模块`,
  key1004142: `添加新角色`,
  key1004143: `角色名称不能为空`,
  key1004144: `编辑角色`,
  key1004145: `基本资料`,
  key1004146: `所属角色`,
  key1004147: `数据权限`,
  key1004148: `仓库权限`,
  key1004149: `仓库所有数据权限`,
  key1004150: `拓客数据权限`,
  key1004151: `所有数据权限`,
  key1004152: `新增员工`,
  key1004153: `邮箱不能为空`,
  key1004154: `查看员工`,
  key1004155: `编辑员工`,
  key1004156: `规则名：`,
  key1004157: `实际发货物流方式：`,
  key1004158: `帐号：`,
  key1004159: `选择指定物流`,
  key1004160: `选择符合条件物流`,
  key1004161: `已停用，请修改后重新保存`,
  key1004162: `物流相关设置`,
  key1004163: `时效：小于`,
  key1004164: `无`,
  key1004165: `有`,
  key1004166: `请输入规则名`,
  key1004167: `请添加物流方式`,
  key1004168: `时效和运单号请至少选择一个`,
  key1004169: `实际发货物流方式不能为空`,
  key1004170: `账户不能为空`,
  key1004171: `新增物流`,
  key1004172: `选择物流：`,
  key1004173: `请选择自定义物流类型：`,
  key1004174: `自定义物流商代码：`,
  key1004175: `自定义物流商名称：`,
  key1004176: `API对接`,
  key1004177: `类型不能为空`,
  key1004178: `自定义物流商代码不能为空`,
  key1004179: `自定义物流商名称不能为空`,
  key1004180: `无需数据对接`,
  key1004181: `Excel对接`,
  key1004182: `输入名称搜索`,
  key1004183: `物流渠道名称/代码：`,
  key1004184: `未启用物流方式`,
  key1004185: `新建自定义物流`,
  key1004186: `物流方案代码`,
  key1004187: `接收方指定提交代码`,
  key1004188: `物流时效`,
  key1004189: `发件地址`,
  key1004190: `揽收地址`,
  key1004191: `退件地址`,
  key1004192: `请勾选要启用的物流方式`,
  key1004193: `关联物流`,
  key1004194: `物流渠道名称：`,
  key1004195: `添加关联`,
  key1004196: `物流仅支持绑定一条渠道！`,
  key1004197: `(天)`,
  key1004198: `确定要取消关联？`,
  key1004199: `取消关联`,
  key1004200: `请先添加关联物流！`,
  key1004201: `添加成功!`,
  key1004202: `请先选择完整的物流方式!`,
  key1004203: `编辑自定义物流`,
  key1004204: `交运方式不能为空`,
  key1004205: `揽收时间不能为空`,
  key1004206: `交货方式：`,
  key1004207: `国内快递费用：`,
  key1004208: `物流服务商(Carrier)：`,
  key1004209: `发货国家：`,
  key1004210: `揽收方式：`,
  key1004211: `配送服务：`,
  key1004212: `交运方式：`,
  key1004213: `面单模板`,
  key1004214: `'面单尺寸'`,
  key1004215: `发货说明：`,
  key1004216: `全球可达(运费相同)`,
  key1004217: `全球可达(运费不同)`,
  key1004218: `非全球可达`,
  key1004219: ` 运费折扣： `,
  key1004220: ` 通折： `,
  key1004221: `渠道基础信息`,
  key1004222: `物流商类型：`,
  key1004223: `无对接`,
  key1004224: `物流方案代码：`,
  key1004225: `参考时效：`,
  key1004226: `保险服务：`,
  key1004227: `尺寸限制(cm)：`,
  key1004228: `适用重量`,
  key1004229: `周长限制(cm)：`,
  key1004230: `最长边限制(cm)：`,
  key1004231: `体积限制(cm³)：`,
  key1004232: `追踪信息查询网址：`,
  key1004233: `揽收点地址信息`,
  key1004234: `物流商可选设置(默认设置)`,
  key1004235: `派送范围及运费`,
  key1004236: `派送范围`,
  key1004237: `计费模型`,
  key1004238: `从其他渠道引用`,
  key1004239: `批量导入`,
  key1004240: `新增分区`,
  key1004241: `运费币种：`,
  key1004242: `运费折扣`,
  key1004243: `通折`,
  key1004244: `请注意，无折扣时，请输入1，八折时，请输入0.8`,
  key1004245: `适用仓库`,
  key1004246: `全部自营仓库`,
  key1004247: `平台标发货设置`,
  key1004248: `不填运单号`,
  key1004249: `（默认）`,
  key1004250: `添加特例国家`,
  key1004251: `面单打印设置`,
  key1004252: `打印配货清单`,
  key1004253: `标准报价`,
  key1004254: `操作费（元/票）`,
  key1004255: `首重（g）`,
  key1004256: `首重运费`,
  key1004257: `续重单位重量（g）`,
  key1004258: `续重单价（元）`,
  key1004259: `特例国家/地区不能为空`,
  key1004260: `物流服务商(Carrier)不能为空`,
  key1004261: `不能添加相同名称的配送服务！`,
  key1004262: `分区`,
  key1004263: `默认分区`,
  key1004264: `通达国家`,
  key1004265: `所有国家`,
  key1004266: `计费方式`,
  key1004267: `计重方式`,
  key1004268: `计费公式`,
  key1004269: `手动同步`,
  key1004270: `下发物流商成功后同步`,
  key1004271: `获取运单号成功后同步`,
  key1004272: `出库成功后同步`,
  key1004273: `折扣价`,
  key1004274: `寄件人地址`,
  key1004275: `揽收人地址`,
  key1004276: `退件人地址`,
  key1004277: `买家自送`,
  key1004278: `国内快递费用不能为空`,
  key1004279: `物流渠道代码不能为空`,
  key1004280: `默认分区外计费模型国家不能为空`,
  key1004281: `分段计费表格参数不匹配或不完整`,
  key1004282: `编辑物流渠道`,
  key1004283: `查看物流渠道`,
  key1004284: `新增物流渠道`,
  key1004285: `首重`,
  key1004286: `元`,
  key1004287: `续重`,
  key1004288: `不能添加相同名称的物流商服务！`,
  key1004289: `规则指定订单来源`,
  key1004290: `规则指定订单目的国家/地区包括`,
  key1004291: `规则指定发货指定方式`,
  key1004292: `适用范围`,
  key1004293: `适用平台：`,
  key1004294: `适用店铺：`,
  key1004295: `适用包裹金额($)：`,
  key1004296: `买家支付的运费范围($)：`,
  key1004297: `包裹包含SKU：`,
  key1004298: `支持多个SKU，请用逗号或空格分开`,
  key1004299: `商品总数量范围：`,
  key1004300: `运费最低`,
  key1004301: `时效最快`,
  key1004302: `上网时间最短`,
  key1004303: `妥投率最高`,
  key1004304: `智能物流匹配逻辑说明`,
  key1004305: `匹配物流渠道基础属性`,
  key1004306: `必要流程：找出可发物流商`,
  key1004307: `订单匹配物流渠道基础属性中的：`,
  key1004308: `1.尺寸限制`,
  key1004309: `2.重量限制`,
  key1004310: `3.接收类型限制`,
  key1004311: `4.配送范围限制`,
  key1004312: `匹配结果=1结束，匹配结果>1进行下一步`,
  key1004313: `5.发货仓库支持的物流渠道`,
  key1004314: `匹配物流规则`,
  key1004315: `上一步匹配成功的结果集跑物流匹配规则，通过规则引擎匹配，从上到下匹配`,
  key1004316: `匹配物流适用范围`,
  key1004317: `可选流程：适用范围`,
  key1004318: `用户可自定义物流渠道的适用范围，匹配所有渠道的适用范围`,
  key1004319: `运费/时效优先级`,
  key1004320: `必要流程：选择运费最低或时效最快`,
  key1004321: `计算所有符合条件物流渠道的运费/时效，根据设置的优先级选择运费最低或时效最快的`,
  key1004322: `物流规则`,
  key1004323: `新增物流规则`,
  key1004324: `已选发货指定方式：`,
  key1004325: `物流渠道适用范围`,
  key1004326: `更多仓库`,
  key1004327: `拖拽`,
  key1004328: `规格名称`,
  key1004329: `最后修改人`,
  key1004330: `物流商/物流渠道`,
  key1004331: `平台/账号`,
  key1004332: `运费范围`,
  key1004333: `包含sku`,
  key1004334: `数量范围`,
  key1004335: `所有`,
  key1004336: `新建规则`,
  key1004337: `物流名称：`,
  key1004338: `关联物流渠道：`,
  key1004339: `关联物流渠道`,
  key1004340: `关联`,
  key1004341: `分销商物流`,
  key1004342: `供应商物流`,
  key1004343: `适用范围：`,
  key1004344: `适用重量 (g)：`,
  key1004345: `体积限制(cm)：`,
  key1004346: `省州：`,
  key1004347: `街道：`,
  key1004348: `旺旺：`,
  key1004349: `揽件时间：`,
  key1004350: `代理人`,
  key1004351: `国内`,
  key1004352: `国外`,
  key1004353: `方案基础信息`,
  key1004354: `重量单位：`,
  key1004355: `速卖通线上发货设置`,
  key1004356: `eDIS物流设置`,
  key1004357: `Logistics设置`,
  key1004358: `选择发件地址：`,
  key1004359: `选择揽收地址：`,
  key1004360: `交运地址：`,
  key1004361: `选择退件地址：`,
  key1004362: `交运方式与揽件时间`,
  key1004363: `卖家自送`,
  key1004364: `清关费用配置`,
  key1004365: `是否收取清关费用：`,
  key1004366: `收取`,
  key1004367: `不收取`,
  key1004368: `比例配置：申报价值在`,
  key1004369: `至150EUR时，按如下税率计算后`,
  key1004370: `超出150欧的包裹将下发物流失败。`,
  key1004371: `续重单价`,
  key1004372: `税率`,
  key1004373: `税率必须是大于等于0且最多允许2位小数`,
  key1004374: `默认分区外计费模型国家不能为空!`,
  key1004375: `必填项不能为空!`,
  key1004376: `内容不能为空`,
  key1004377: `物流商名称：`,
  key1004378: `是否可用：`,
  key1004379: `适用平台不能为空`,
  key1004380: `线下物流`,
  key1004381: `线上物流`,
  key1004382: `物流商名称`,
  key1004383: `分销商是否可用`,
  key1004384: `物流账户`,
  key1004385: `适用平台`,
  key1004386: `对接类型`,
  key1004387: `编辑账号`,
  key1004388: `修改适用范围`,
  key1004389: `分销商是否可用：`,
  key1004390: `账号名称不能为空`,
  key1004391: `个人姓名/公司名称不能为空！`,
  key1004392: `地址信息不能为空！`,
  key1004393: `账号名称：`,
  key1004394: `授权状态：`,
  key1004395: `个人姓名/公司名称：`,
  key1004396: `联系电话：`,
  key1004397: `地址信息：`,
  key1004398: `重新授权`,
  key1004399: `去授权`,
  key1004400: `已授权`,
  key1004401: `未授权`,
  key1004402: `EU代理人`,
  key1004403: `获取申请授权回调地址失败！`,
  key1004404: `使用的IOSS：`,
  key1004405: `条件类型：`,
  key1004406: `平台：`,
  key1004407: `店铺：`,
  key1004408: `此名称仅用于更好区分不同IOSS`,
  key1004409: `按店铺`,
  key1004410: `按平台`,
  key1004411: `请选择使用的IOSS`,
  key1004412: `请选择店铺`,
  key1004413: `添加规则`,
  key1004414: `添加IOSS`,
  key1004415: `申报说明：`,
  key1004416: `YMS将按照产品在YMS中的销售价格，即对应YMS订单的商品价格进行申报。`,
  key1004417: `查看税率`,
  key1004418: `当平台订单中存在IOSS号时，YMS将优先使用平台返回的IOSS编号，若无法匹配到IOSS号时系统将拦截此订单。`,
  key1004419: `手工订单`,
  key1004420: `IOSS管理`,
  key1004421: `IOSS规则配置`,
  key1004422: `IOSS名称`,
  key1004423: `IOSS编号`,
  key1004424: `使用IOSS名称`,
  key1004425: `使用IOSS编号`,
  key1004426: `适用平台/店铺`,
  key1004427: `适用店铺`,
  key1004428: `启用IOSS确认`,
  key1004429: `停用IOSS确认`,
  key1004430: `启用IOSS后，相关规则将可被匹配，请确认是否启用IOSS？`,
  key1004431: `停用IOSS后，使用此IOSS编号的规则将同时停用，请确认是否停用IOSS?`,
  key1004432: `启用IOSS规则`,
  key1004433: `停用IOSS规则`,
  key1004434: `请确认是否启用规则？`,
  key1004435: `请确认是否停用规则？`,
  key1004436: `原价`,
  key1004437: `调整后价格`,
  key1004438: `费率`,
  key1004439: `验证身份`,
  key1004440: `设置新邮箱`,
  key1004441: `设置成功`,
  key1004442: `验证码：`,
  key1004443: `手机验证码：`,
  key1004444: `请输入手机验证码`,
  key1004445: `新邮箱：`,
  key1004446: `邮箱验证码：`,
  key1004447: `请输入邮箱验证码`,
  key1004448: `下一步`,
  key1004449: `提`,
  key1004450: `交`,
  key1004451: `图片验证码不能为空`,
  key1004452: `手机号格式不正确!`,
  key1004453: `手机号和图片验证码不能为空！`,
  key1004454: `邮箱格式不正确!`,
  key1004455: `邮箱不能为空！`,
  key1004456: `验证码已发送！`,
  key1004457: `手机号不能为空`,
  key1004458: `邮箱格式不正确`,
  key1004459: `手机验证`,
  key1004460: `邮箱验证`,
  key1004461: `登录密码`,
  key1004462: `定期更换密码有助于账户安全`,
  key1004463: `上新日期：`,
  key1004464: `上新SPU：`,
  key1004465: `全部标记已读`,
  key1004466: `标记已读`,
  key1004467: `仅看未读`,
  key1004468: `订单异常通知`,
  key1004469: `停售通知`,
  key1004470: `调价通知`,
  key1004471: `店铺上新通知`,
  key1004472: `自动规则通知`,
  key1004473: `请先勾选要标记的数据！`,
  key1004474: `分销商名称：`,
  key1004475: `币种：`,
  key1004476: `修改币种`,
  key1004477: `修改手机`,
  key1004478: `电话：`,
  key1004479: `修改邮箱`,
  key1004480: `IOSS名称：`,
  key1004481: `IOSS编号：`,
  key1004482: `IOSS名称不能为空`,
  key1004483: `编辑IOSS`,
  key1004484: `IOSS编号不能为空`,
  key1004485: `请输入IM+10位数字`,
  key1004486: `原密码：`,
  key1004487: `新密码：`,
  key1004488: `再次确认新密码：`,
  key1004489: `原密码不能为空`,
  key1004490: `新密码不能为空`,
  key1004491: `更新成功!`,
  key1004492: `新密码与确认新密码不一致`,
  key1004493: `设置新手机`,
  key1004494: `新手机号：`,
  key1004495: `请输入新手机号`,
  key1004496: `邮箱和图片验证码不能为空！`,
  key1004497: `手机号码格式不正确!`,
  key1004498: `手机号不能为空！`,
  key1004499: `最小库存`,
  key1004500: `最大库存`,
  key1004501: `¥最低价`,
  key1004502: `¥最高价`,
  key1004503: `价格：`,
  key1004504: `一键刊登`,
  key1004505: `下载资料`,
  key1004506: `综合`,
  key1004507: `销量`,
  key1004508: `上新时间`,
  key1004509: `总库存`,
  key1004510: `请勾选需要取消收藏的商品`,
  key1004511: `取消收藏成功!`,
  key1004512: `请勾选需要收藏的商品`,
  key1004513: `商品收藏成功`,
  key1004514: `请勾选需要下载资料的商品`,
  key1004515: `YMS订单`,
  key1004516: `平台订单`,
  key1004517: `备货单`,
  key1004518: `订单规则`,
  key1004519: `每隔`,
  key1004520: `分钟一次`,
  key1004521: `可用数量=`,
  key1004522: `订单超过`,
  key1004523: `小时未装箱时自动取消`,
  key1004524: `库存同步规则`,
  key1004525: `商品状态同步规则`,
  key1004526: `店铺`,
  key1004527: `刊登模板`,
  key1004528: `同步时间`,
  key1004529: `启动`,
  key1004530: `库存同步`,
  key1004531: `商品状态同步`,
  key1004532: `价格同步`,
  key1004533: `供应商仓库`,
  key1004534: `商品停售时库存自动调整为0`,
  key1004535: `商品停售时自动同步状态至上架工具`,
  key1004536: `删除后将不可恢复，你确定要删除？`,
  key1004537: `请选择适用平台`,
  key1004538: `此规则适用于商品价格发生变动时自动通过`,
  key1004539: `调整价格`,
  key1004540: `请选择适用店铺`,
  key1004541: `添加价格同步规则`,
  key1004542: `修改价格同步规则`,
  key1004543: `价格同步规则`,
  key1004544: `可能会因为库存数量基数较小，浮动后使库存为0，也可能因为库存基数较大，浮动后与浮动前库存没有变化，请谨慎设置浮动比例。`,
  key1004545: `公式计算结果四舍五入，比如：计算结果为9.5，实际取值为10。`,
  key1004546: `向下浮动：`,
  key1004547: `同步时间：`,
  key1004548: `请选择仓库`,
  key1004549: `添加库存规则`,
  key1004550: `编辑库存规则`,
  key1004551: `个平台`,
  key1004552: `订单超时不能为空`,
  key1004553: `订单同步规则`,
  key1004554: `此规则适用于商品停售时库存自动调0及与上架工具状态同步。`,
  key1004555: `当任一规则中勾选此项后，商品停售状态将同步至上架工具中。`,
  key1004556: `添加商品状态同步规则`,
  key1004557: `修改商品状态同步规则`,
  key1004558: `变动类型`,
  key1004559: `采购到货`,
  key1004560: `分配库存`,
  key1004561: `库存盘点`,
  key1004562: `变动时间`,
  key1004563: `Temu店铺：`,
  key1004564: `上传状态`,
  key1004565: `已上传`,
  key1004566: `未上传`,
  key1004567: `重新上传`,
  key1004568: `上传文件格式有误，请上传条码PDF文件或PNG图片`,
  key1004569: `刊登店铺`,
  key1004570: `刊登状态：`,
  key1004571: `刊登平台：`,
  key1004572: `已刊登`,
  key1004573: `未刊登`,
  key1004574: `刊登数量（item）`,
  key1004575: `刊登状态`,
  key1004576: `设置别名`,
  key1004577: `请输入别名`,
  key1004578: `该别名已与 YMSSKU`,
  key1004579: `相同`,
  key1004580: `不允许出现相同别名`,
  key1004581: `平台提示：`,
  key1004582: `设置商品别名后，出现平台订单，订单商品SKU与YMS商品SKU匹配失败时，订单商品SKU`,
  key1004583: `可再与YMS商品的别名进行匹配，匹配成功后订单自动匹配该商品，无需分销商手动选择匹配;`,
  key1004584: `收藏状态：`,
  key1004585: `收藏时间：`,
  key1004586: `售卖资料：`,
  key1004587: `库存：`,
  key1004588: `请输入整数`,
  key1004589: `导出商品资料`,
  key1004590: `生成售卖资料提示`,
  key1004591: `批量生成售卖资料`,
  key1004592: `批量导出商品资料`,
  key1004593: `忽略已生成`,
  key1004594: `生成`,
  key1004595: `勾选的`,
  key1004596: `条商品中有`,
  key1004597: `条已生成售卖记录，请选择是否重新生成?`,
  key1004598: `库存（件）`,
  key1004599: `享`,
  key1004600: `折`,
  key1004601: `减`,
  key1004602: `件以上`,
  key1004603: `折扣价：`,
  key1004604: `优惠：`,
  key1004605: `下一阶段优惠：`,
  key1004606: `近30天内销量`,
  key1004607: `上架时间`,
  key1004608: `收藏时间`,
  key1004609: `售卖资料`,
  key1004610: `生成售卖时间`,
  key1004611: `生成售卖资料`,
  key1004612: `取消收藏`,
  key1004613: `重新刊登`,
  key1004614: `设置关联关系`,
  key1004615: `商品SPU`,
  key1004616: `请输入商品SPU,支持多个按行分隔`,
  key1004617: `商品SKU`,
  key1004618: `请输入商品SKU,支持多个按行分隔`,
  key1004619: `请输入商品标题,支持多个按行分隔`,
  key1004620: `请输入供应商名称,支持多个按行分隔`,
  key1004621: `请输入供应商编号,支持多个按行分隔`,
  key1004622: `商品基本信息`,
  key1004623: `商品基本信息表`,
  key1004624: `商品图片`,
  key1004625: `轮播图`,
  key1004626: `属性图`,
  key1004627: `未生成`,
  key1004628: `已生成`,
  key1004629: `超出单次下载数量`,
  key1004630: `一次最多可下载10个商品，请重新选择后下载。`,
  key1004631: `请输入SKU`,
  key1004632: `此SKU已与 YMSSKU`,
  key1004633: `形成关联关系`,
  key1004634: `平台商品SKU：`,
  key1004635: `一个平台商品仅允许形成一个绑定关系`,
  key1004636: `设置商品关联关系后，出现平台订单，订单商品SKU与YMS商品SKU匹配失败时，订单商品可通过关联关`,
  key1004637: `系与YMS商品进行匹配，匹配成功后订单自动匹配该商品，无需分销商手动选择匹配；当YMS商品同时设`,
  key1004638: `置了`,
  key1004639: `别名`,
  key1004640: `关联关系”时，优先使用`,
  key1004641: `关联关系”进行匹配;`,
  key1004642: `需要将“平台`,
  key1004643: `、“店铺”、“平台商品SKU”填写完整，才会创建对应的关联关系;`,
  key1004644: `请输入商品SPU，支持多个按行分隔`,
  key1004645: `商品标题：`,
  key1004646: `发起生成时间：`,
  key1004647: `发起人：`,
  key1004648: `剩余`,
  key1004649: `条预计`,
  key1004650: `分钟后生成完毕`,
  key1004651: `批量停止生成售卖资料`,
  key1004652: `生成中`,
  key1004653: `生成成功`,
  key1004654: `生成失败`,
  key1004655: `生成状态`,
  key1004656: `待生成`,
  key1004657: `发起生成售卖时间`,
  key1004658: `失败原因`,
  key1004659: `发起人`,
  key1004660: `停止生成`,
  key1004661: `只有待生成的状态可以停止生成售卖资料`,
  key1004662: `请先勾选要停止生成的数据！`,
  key1004663: `停止生成售卖资料确认`,
  key1004664: `正在操作停止生成售卖资料，确认后将取消所有未生成的任务，如需重新生成需重新在收藏的商品中进行操作，请确认是否停止生成？`,
  key1004665: `库存优先级配置`,
  key1004666: `使用库存优先级`,
  key1004667: `是否等待在途库存`,
  key1004668: `优先使用本人货权的库存`,
  key1004669: `优先使用其他在YMS仓的库存`,
  key1004670: `等待`,
  key1004671: `不等待`,
  key1004672: `SKU库存区间：`,
  key1004673: `导出库存`,
  key1004674: `7日：`,
  key1004675: `14日：`,
  key1004676: `最近销量`,
  key1004677: `我的可用库存`,
  key1004678: `我的在途库存`,
  key1004679: `平均货品成本`,
  key1004680: `SKU库存开始区间不能大于SKU库存结束区间`,
  key1004681: `选择时长`,
  key1004682: `店铺时长`,
  key1004683: `价格CNY`,
  key1004684: `1个月`,
  key1004685: `3个月`,
  key1004686: `6个月`,
  key1004687: `12个月`,
  key1004688: `开通店铺`,
  key1004689: `续费确认`,
  key1004690: `确认续费`,
  key1004691: `正在充值中...`,
  key1004692: `开通成功`,
  key1004693: `续费成功`,
  key1004694: `开通失败`,
  key1004695: `续费失败`,
  key1004696: `请输入代号、账户名`,
  key1004697: `未添加店铺时，如果需要eDIS发货，请先在eDIS绑定eBayID后再添加店铺。`,
  key1004698: `已添加店铺时，如果需要eDIS发货，请在eDIS绑定eBayID后再重新授权店铺。`,
  key1004699: `授权状态`,
  key1004700: `前往授权`,
  key1004701: `首次授权时间`,
  key1004702: `授权更新时间`,
  key1004703: `通途状态`,
  key1004704: `到期时间`,
  key1004705: `永久有效`,
  key1004706: `未开通`,
  key1004707: `已过期`,
  key1004708: `开通`,
  key1004709: `续费`,
  key1004710: `授权成功！`,
  key1004711: `添加账号成功！`,
  key1004712: `角色描述：`,
  key1004713: `请输入角色描述`,
  key1004714: `权限分配`,
  key1004715: `新增角色`,
  key1004716: `查看角色`,
  key1004717: `添加角色`,
  key1004718: `角色名称`,
  key1004719: `角色描述`,
  key1004720: `用户数量（已启用）`,
  key1004721: `角色下已关联用户时，不能删除角色。`,
  key1004722: `确认要删除该角色？`,
  key1004723: `删除该角色后，将不可恢复，你确认要删除？`,
  key1004724: `删除角色成功！`,
  key1004725: `查看用户`,
  key1004726: `账号类型`,
  key1004727: `主账号`,
  key1004728: `子账号`,
  key1004729: `账号角色`,
  key1004730: `请输入邮箱地址`,
  key1004731: `生成密码`,
  key1004732: `店铺权限`,
  key1004733: `添加用户`,
  key1004734: `编辑用户`,
  key1004735: `新增成功，上架工具用户角色授权更新失败！`,
  key1004736: `请输入手机号码、邮箱或姓名`,
  key1004737: `修改密码`,
  key1004738: `修改密码后，原密码将不可使用，请谨慎修改！`,
  key1004739: `请输入供应商名称`,
  key1004740: `粉丝数：`,
  key1004741: `进入店铺`,
  key1004742: `是否确定取消关注？`,
  key1004743: `货品费用退费`,
  key1004744: `包裹处理退费`,
  key1004745: `物流退费`,
  key1004746: `国内快递退费`,
  key1004747: `货品费用`,
  key1004748: `活动说明`,
  key1004749: `您当前可用余额为`,
  key1004750: `继续支付`,
  key1004751: `支付成功，后台入账中`,
  key1004752: `,第三方凭证号:`,
  key1004753: `导出费用明细`,
  key1004754: `扣款`,
  key1004755: `退款`,
  key1004756: `平台订单币种`,
  key1004757: `商品数量(件)`,
  key1004758: `物流重量`,
  key1004759: `sku销售价`,
  key1004760: `sku优惠价`,
  key1004761: `请输入平台订单号`,
  key1004762: `余额充值`,
  key1004763: `充值金额：`,
  key1004764: `支付方式：`,
  key1004765: `其他金额`,
  key1004766: `当前汇率：1`,
  key1004767: `账户货币为`,
  key1004768: `充值金额不能超过100000000`,
  key1004769: `充值金额不能小于等于0`,
  key1004770: `（已取消）`,
  key1004771: `备货至 `,
  key1004772: `取消金额：`,
  key1004773: `退款金额：`,
  key1004774: `订单商品`,
  key1004775: `商品单价`,
  key1004776: `实付单价`,
  key1004777: `下单数量`,
  key1004778: `到仓数量`,
  key1004779: `部分货品未到仓，未到仓部分已退款。`,
  key1004780: `用户取消货品数量`,
  key1004781: `商品总重量(g)`,
  key1004782: `货品总值`,
  key1004783: `已付款`,
  key1004784: `供应商已发货`,
  key1004785: `待付款`,
  key1004786: `待备货`,
  key1004787: `未完结`,
  key1004788: `请选择Temu店铺`,
  key1004789: `请填写Temu订单号`,
  key1004790: `你还未添加商品`,
  key1004791: `处理费=3+(商品数量-1)*0.5`,
  key1004792: `应付总额会在提交订单时进行预扣，请确保账户有足够的余额。`,
  key1004793: `收起地址`,
  key1004794: `更多地址`,
  key1004795: `发货订单`,
  key1004796: `寄样订单`,
  key1004797: `删除商品`,
  key1004798: `请注意：`,
  key1004799: `1.寄样订单，若一个SPU下需发多个SKU，请务必将SKU置于一个订单内进行下单。`,
  key1004800: `2.发货订单，请务必按照TEMU后台备货单进行下单，不可将多张备货单置于同一YMS订单下。`,
  key1004801: `商品数量：`,
  key1004802: `商品总重量(g)：`,
  key1004803: `商品总金额：`,
  key1004804: `收货地址`,
  key1004805: `设为默认地址`,
  key1004806: `新增收货地址`,
  key1004807: `应付总额：`,
  key1004808: `数量（件）`,
  key1004809: `小计`,
  key1004810: `Temu店铺不能为空`,
  key1004811: `Temu订单号不能为空`,
  key1004812: `请先勾选要移除的商品！`,
  key1004813: `编辑收货地址`,
  key1004814: `是否要删除当前收货地址？`,
  key1004815: `取消提示`,
  key1004816: `取消后将不会保存已填写的内容，你确定要取消？`,
  key1004817: `继续填写`,
  key1004818: `订单信息填写不完整，请填写完订单信息再提交!`,
  key1004819: `配送方式`,
  key1004820: `运费：`,
  key1004821: `修改配送方式`,
  key1004822: `选择配送方式`,
  key1004823: `使用IOSS：`,
  key1004824: `前往配置`,
  key1004825: `物流代缴时将产生额外费用，YMS将代为收取。`,
  key1004826: `本单将额外收取`,
  key1004827: `请先选择物流`,
  key1004828: `仓库存`,
  key1004829: `当前的收货地址不支持配送！`,
  key1004830: `请选择使用的IOSS！`,
  key1004831: `请输入IOSS编号！`,
  key1004832: `请输入IM+10位数字！`,
  key1004833: `分销商余额不足`,
  key1004834: `录入IOSS`,
  key1004835: `匹配物流：`,
  key1004836: `更换物流后，清关费用将重新计算，原先收取的费用将退回本单将收取`,
  key1004837: `清关费用`,
  key1004838: `匹配物流`,
  key1004839: `指定仓库物流方式`,
  key1004840: `修改物流地址`,
  key1004841: `买家修改地址，修改前后信息如下，请确认是否修改阿里国际订单地址？`,
  key1004842: `原地址：`,
  key1004843: `收件人姓名`,
  key1004844: `联系方式`,
  key1004845: `新地址：`,
  key1004846: `失败原因：`,
  key1004847: `地址更新失败提示`,
  key1004848: `标记已处理`,
  key1004849: `重试`,
  key1004850: `物流渠道名称`,
  key1004851: `请先勾选要配送方式的数据！`,
  key1004852: `不在重量区间之内！`,
  key1004853: `订单原因，此原因仅`,
  key1004854: `可见。`,
  key1004855: `取消范围：`,
  key1004856: `取消商品：`,
  key1004857: `请注意，部分取消后此订单将被作废，将会生成新的YMS订单!`,
  key1004858: `当前`,
  key1004859: `订单中暂无商品`,
  key1004860: `取消订单后，订单不再发货，已装箱订单取消需收取手续费，取消后本单将收取`,
  key1004861: `费用，确定取消订单?`,
  key1004862: `请选择取消范围`,
  key1004863: `整单取消`,
  key1004864: `取消部分商品`,
  key1004865: `取消部分商品时，取消数量不能全部为空！`,
  key1004866: `取消部分商品时，取消总数量不能大于等于订单总数量！`,
  key1004867: `确认取消订单`,
  key1004868: `订单轨迹`,
  key1004869: `揽收中，正送往ymsWarehouseName仓`,
  key1004870: ` 订单轨迹`,
  key1004871: `订单已确认，待商家拣货`,
  key1004872: `订单已拣货完成，待商家复核`,
  key1004873: `订单已复核完成，待商家打包`,
  key1004874: `快递运输中，正送往ymsWarehouseName,快递单号：expressageNo`,
  key1004875: `ymsWarehouseName已收货，待仓库处理`,
  key1004876: `ymsWarehouseName配货中`,
  key1004877: `货品拣货完成，待仓库包装`,
  key1004878: `货品包装完成，待仓库装箱`,
  key1004879: `仓库已发货，已通知shippingMethodName物流取件`,
  key1004880: `订单已出库，需要尾程物流轨迹，请自行到物流官方网站或第三方平台查询`,
  key1004881: `订单已取消`,
  key1004882: `当前配送至：`,
  key1004883: `请输入省/州`,
  key1004884: `请输入城市`,
  key1004885: `请输入区县`,
  key1004886: `详细地址：`,
  key1004887: `请输入详细地址信息，如：道路、门牌号、小区、楼栋号、单元等信息`,
  key1004888: `请输入邮政编码`,
  key1004889: `收货人姓名：`,
  key1004890: `长度不超过25个字符`,
  key1004891: `请输入固定电话号码`,
  key1004892: `邮箱地址：`,
  key1004893: `设置为默认收货地址`,
  key1004894: `省/州不能为空`,
  key1004895: `收货人姓名不能为空`,
  key1004896: `添加成功！`,
  key1004897: `取消原因：`,
  key1004898: `取消类型：`,
  key1004899: `请选择平台订单取消原因，此原因买家可见。`,
  key1004900: `取消平台订单时，系统将自动调用平台接口取消订单,并同时给买家退款!`,
  key1004901: `已装箱订单取消需收取手续费，取消后本单将收取`,
  key1004902: `费用`,
  key1004903: `请选择取消类型`,
  key1004904: `请选择取消原因`,
  key1004905: `取消平台订单`,
  key1004906: `平台订单交易号`,
  key1004907: `取消部分商品时，取消商品不能全部勾选！`,
  key1004908: `请先勾选需要取消的数据！`,
  key1004909: `当前平台订单中暂无商品`,
  key1004910: `买家提供地址存在问题`,
  key1004911: `应买家要求取消订单`,
  key1004912: `买家未付款`,
  key1004913: `商品缺货或存在其他履约问题`,
  key1004914: `买家不再想要该商品`,
  key1004915: `买家买错商品`,
  key1004916: `买卖双方对此商品存在争议`,
  key1004917: `商品缺货`,
  key1004918: `仅剩次品`,
  key1004919: `仲裁取消`,
  key1004920: `其他原因`,
  key1004921: `买家未领取订单`,
  key1004922: `无法送达该地区`,
  key1004923: `订单被运输途中丢失`,
  key1004924: `客户要求取消`,
  key1004925: `定价错误`,
  key1004926: `欺诈交易，取消订单`,
  key1004927: `买家地址不可用`,
  key1004928: `无法送达`,
  key1004929: `物品已损坏`,
  key1004930: `物品退回发件人`,
  key1004931: `付款`,
  key1004932: `由于此订单由阿里国际多商家履约，已自动拆分为不同订单，需按订单进行支付。`,
  key1004933: `付款状态`,
  key1004934: `去付款`,
  key1004935: `更新状态`,
  key1004936: `付款确认`,
  key1004937: `是否付款成功？`,
  key1004938: `状态未更新提示`,
  key1004939: `获取到阿里国际订单信息状态仍为未付款，请稍后重试或手动标记为已成功。`,
  key1004940: `手动标记`,
  key1004941: `更换订单商品`,
  key1004942: `确认更换`,
  key1004943: `请选择需更换的商品及数量`,
  key1004944: `请注意，部分取消后此订单将被作废，将会生成新的YMS订单！`,
  key1004945: `需更换数量`,
  key1004946: `需小于订单数量`,
  key1004947: `原订单内SKU信息`,
  key1004948: `替换SKU信息`,
  key1004949: `选择商品`,
  key1004950: `替换商品为当前商品`,
  key1004951: `更换商品数量需小于订单数量`,
  key1004952: `未选择更换订单商品`,
  key1004953: `未输入选择商品的替换数量`,
  key1004954: `请选择替换商品`,
  key1004955: `提交中`,
  key1004956: `修改收货人信息`,
  key1004957: `买家姓名：`,
  key1004958: `地址1：`,
  key1004959: `地址2：`,
  key1004960: `州/省：`,
  key1004961: `电话号码：`,
  key1004962: `当前所选目的国家需要录入IOSS相关信息`,
  key1004963: `请注意，修改地址信息后此订单将作废并生成新的订单!`,
  key1004964: `校验中...`,
  key1004965: `地址变更提示`,
  key1004966: `当前物流方式不支持此地区，更换地址后物流方式将被置空，请在待处理状态中重新匹配物流。请确认是否变更地址?`,
  key1004967: `确定变更`,
  key1004968: `修改中...`,
  key1004969: `订单详情`,
  key1004970: `计费重量(`,
  key1004971: `已处理`,
  key1004972: `预计 `,
  key1004973: ` 到货`,
  key1004974: `此时间为供应商仓预计到货时间，距尾程包裹发出时间仍需2~3天。`,
  key1004975: `出库单编号`,
  key1004976: `收货人名称`,
  key1004977: `买家识别号`,
  key1004978: `详细地址1`,
  key1004979: `详细地址2`,
  key1004980: `邮寄方式`,
  key1004981: `物流商称重（g）`,
  key1004982: `物流商运费`,
  key1004983: `待物流揽收`,
  key1004984: `供应商备货中`,
  key1004985: `物流已揽收`,
  key1004986: `平台订单自动创建`,
  key1004987: `取消数量不能为空`,
  key1004988: `由于价格调整待供应商接单暂无法付款`,
  key1004989: `商品已停售`,
  key1004990: `部分取消成功！`,
  key1004991: `第三方订单：`,
  key1004992: `请输入第三方订单,支持多个按行分隔`,
  key1004993: `订单备注：`,
  key1004994: `添加订单`,
  key1004995: `批量导入订单`,
  key1004996: `批量下单`,
  key1004997: `订单商品信息`,
  key1004998: `是否确认下单，下单后会根据订单金额自动付款，请确保您余额充足！`,
  key1004999: `订单号,支持多个按行分隔`,
  key1005000: `已选中：`,
  key1005001: `条订单信息。`,
  key1005002: `订单编号：`,
  key1005003: `第三方单号：`,
  key1005004: `国内快递费单号：`,
  key1005005: `需通过快递发至揽收点的包裹将加收国内快递费。具体费用可在自有物流模块中点击对应渠道查看。`,
  key1005006: `订单备注`,
  key1005007: `目的地及买家信息`,
  key1005008: `目的地：`,
  key1005009: `买家ID：`,
  key1005010: `商品费：`,
  key1005011: `清关费用：`,
  key1005012: `部分缺货，商品已停售`,
  key1005013: `取消订单确认`,
  key1005014: `是否确认取消该订单，取消后的订单将会移入“已取消”列表，订单信息无法直接恢复，请谨慎操作!`,
  key1005015: `订单下单确认`,
  key1005016: `全部 `,
  key1005017: `下发物流失败`,
  key1005018: `请输入第三方单号,支持多个按行分隔`,
  key1005019: `请输入运单号,支持多个按行分隔`,
  key1005020: `待处理`,
  key1005021: `请先勾选需要操作的数据！`,
  key1005022: `批量下单确认`,
  key1005023: `所属平台：`,
  key1005024: `分销订单状态：`,
  key1005025: `所属店铺：`,
  key1005026: `是否有退款：`,
  key1005027: `平台订单异常状态：`,
  key1005028: `分销订单异常状态：`,
  key1005029: `执行发货状态：`,
  key1005030: `导出平台订单`,
  key1005031: `批量标记非`,
  key1005032: `执行发货`,
  key1005033: `批量接单`,
  key1005034: `标发货`,
  key1005035: `标发货设置`,
  key1005036: `非`,
  key1005037: `执行发货后，`,
  key1005038: `将自动流转Temu订单状态，勾选的订单将作为一个物流包裹发出。`,
  key1005039: `正在确认通过`,
  key1005040: `履约速卖通全托管JIT订单，由于JIT订单限制较多，若出现平台处罚，`,
  key1005041: `将按照售后条款进行处理，无额外赔付，请确认是否接单？`,
  key1005042: ` 平台订单信息`,
  key1005043: `PO单号：`,
  key1005044: `平台订单商品信息`,
  key1005045: `是否标发货：`,
  key1005046: `下单数量：`,
  key1005047: `买家备注：`,
  key1005048: `平台订单异常`,
  key1005049: `分销订单信息`,
  key1005050: `阿里国际站订单`,
  key1005051: `发货物流：`,
  key1005052: `分销订单商品信息`,
  key1005053: `分销订单状态`,
  key1005054: `分销订单金额`,
  key1005055: `分销订单异常`,
  key1005056: `缺货，商品已停售`,
  key1005057: `匹配商品`,
  key1005058: `处理订单`,
  key1005059: `确认等待`,
  key1005060: `接单`,
  key1005061: `修改物流`,
  key1005062: `集货中`,
  key1005063: `待执行发货`,
  key1005064: `已执行发货`,
  key1005065: `已标发货`,
  key1005066: `平台订单已取消`,
  key1005067: `待匹配商品`,
  key1005068: `匹配物流失败`,
  key1005069: `PO单号`,
  key1005070: `所属平台`,
  key1005071: `店铺名称`,
  key1005072: `平台订单标发货状态`,
  key1005073: `订单原始SKU*数量`,
  key1005074: `平台订单是否有退款`,
  key1005075: `平台订单异常状态`,
  key1005076: `买家ID`,
  key1005077: `买家姓名`,
  key1005078: `买家备注`,
  key1005079: `分销订单号`,
  key1005080: `分销订单SKU信息`,
  key1005081: `分销订单创建时间`,
  key1005082: `分销订单发货时间`,
  key1005083: `分销订单发货时效`,
  key1005084: `待接单`,
  key1005085: `待匹配物流`,
  key1005086: `标发货失败`,
  key1005087: `待入录IOSS`,
  key1005088: `客户更换地址`,
  key1005089: `客户标记取消`,
  key1005090: `可输入平台订单号、PO单号、分销订单号、包裹号、买家ID、 买家姓名、SKU、平台产品编码`,
  key1005091: `可输入平台订单号、分销订单号、包裹号、买家ID、 买家姓名、SKU、平台产品编码`,
  key1005092: `请输入平台订单号，支持多个逗号或换行分隔`,
  key1005093: `请输入PO单号，支持多个逗号或换行分隔`,
  key1005094: `请先勾选要标发货的数据！`,
  key1005095: `标记发货中，请稍后刷新页面查看。`,
  key1005096: `请先勾选要下单的数据！`,
  key1005097: `部分下单异常`,
  key1005098: `不同店铺的订单不可同时执行发货！`,
  key1005099: `仅待执行发货的订单可执行发货，请重新选择！`,
  key1005100: `执行发货成功!`,
  key1005101: `手工订单和平台订单不可同时执行发货，请分批操作！`,
  key1005102: `样品订单与发货订单不可同时执行发货，请分批操作！`,
  key1005103: `请先勾选要执行发货的数据！`,
  key1005104: `确认等待订单`,
  key1005105: `正在操作确认等待订单，若订单未按时间到达，仍会进行二次提醒，请确认是否等待订单到货？`,
  key1005106: `手动标记发货`,
  key1005107: `系统向平台标记发货失败，请前往平台后台进行标记发货，在平台后台标记发货后可在此处点击确认已标记发货按钮将此订单异常状态消除。`,
  key1005108: `确认已标记发货`,
  key1005109: `只有待接单的订单才能进行接单！`,
  key1005110: `请先勾选需要接单的数据！`,
  key1005111: `确认接单`,
  key1005112: `接单成功！`,
  key1005113: `部分接单异常`,
  key1005114: `样品订单`,
  key1005115: `货品订单`,
  key1005116: `常规订单`,
  key1005117: `平台订单已取消，请自行前往阿里国际中取消订单，取消后请手动标记已处理。`,
  key1005118: `可搜索商品名称、`,
  key1005119: `关键词搜索：`,
  key1005120: `导出备货单`,
  key1005121: `备货单信息`,
  key1005122: `采购状态：`,
  key1005123: `备货仓：`,
  key1005124: `采购商品信息`,
  key1005125: `货品总值：`,
  key1005126: `时间信息`,
  key1005127: `取消时间：`,
  key1005128: `部分停售`,
  key1005129: `取消交易`,
  key1005130: `缺货-补货中`,
  key1005131: `缺货-已停售`,
  key1005132: `采购状态`,
  key1005133: `备货仓`,
  key1005134: `已取消金额`,
  key1005135: `已退款金额`,
  key1005136: `SKU单价`,
  key1005137: `SKU实付单价`,
  key1005138: `采购单付款确认`,
  key1005139: `正在操作采购单付款。请确认是否付款？`,
  key1005140: `确认付款`,
  key1005141: `取消交易提示`,
  key1005142: `正在操作取消备货单，请确认是否取消？`,
  key1005143: `取消交易成功！`,
  key1005144: `条码缺失提示`,
  key1005145: `以下商品无`,
  key1005146: `信息，请在`,
  key1005147: `我的商品`,
  key1005148: `中上传后重试。`,
  key1005149: `商品条码`,
  key1005150: `样品条码`,
  key1005151: `请输入订单备注`,
  key1005152: `1、请确保文件内容中订单商品信息以及文件表头字段对应关系的准确无误，若文件中存在无`,
  key1005153: `效内容，会导致文件上传失败;`,
  key1005154: `2、当前仅支持xls、xlsx格式文件`,
  key1005155: `3、文件上传成功后，解析对应的订单内容会存入“YMS订单-待下单”状态列表，需用户二次`,
  key1005156: `确认下单后才会创建YMS订单。`,
  key1005157: `来源备注只能输入英文！`,
  key1005158: `文件格式有误,仅支持 xls、xlsx格式文件`,
  key1005159: `到仓详情`,
  key1005160: `匹配SKU成功`,
  key1005161: `匹配SKU成功(人工匹配)`,
  key1005162: `不匹配`,
  key1005163: `阿里国际单号：`,
  key1005164: `分销单号：`,
  key1005165: `展开`,
  key1005166: `原始SKU：`,
  key1005167: `物流服务：`,
  key1005168: `价格及数量`,
  key1005169: `已解码SKU：`,
  key1005170: `匹配SKU失败`,
  key1005171: `手动匹配`,
  key1005172: `重新匹配`,
  key1005173: `SKU已停售，请取消或更换商品`,
  key1005174: `商品为空，无法关联`,
  key1005175: `总费用=商品+处理费+物流费`,
  key1005176: `处理费=3+（商品数量-1）*0.5`,
  key1005177: `总费用(`,
  key1005178: `商品费(`,
  key1005179: `处理费(`,
  key1005180: `物流费(`,
  key1005181: `国内快递费(`,
  key1005182: `清关费用(`,
  key1005183: `阿里国际订单信息`,
  key1005184: `收货地址信息`,
  key1005185: `平台订单地址信息`,
  key1005186: `暂无更多数据...`,
  key1005187: `国内快递单号`,
  key1005188: `下载订单`,
  key1005189: `已标记发货`,
  key1005190: `待标记发货`,
  key1005191: `订单已整单取消`,
  key1005192: `订单已部分取消`,
  key1005193: `阿里国际商家已发货`,
  key1005194: `，预计到货时间：`,
  key1005195: `，已停售`,
  key1005196: `创建日期：`,
  key1005197: `新建模板`,
  key1005198: `创建日期`,
  key1005199: `删除模板确认`,
  key1005200: `是否确认删除模板，删除后信息不可恢复，请谨慎操作！`,
  key1005201: `订单多SKU导入方式：`,
  key1005202: `每行一个`,
  key1005203: `同一行多列`,
  key1005204: `模板字段映射`,
  key1005205: `多商品时，第二个商品SKU列标题以第一个商品SKU列标题按数字递增；`,
  key1005206: `如：商品SKU原列标题名称为“商品SKU”，如果存在多个商品，那么第一`,
  key1005207: `个商品SKU列标题名称则设为“商品SKU1”，`,
  key1005208: `第二个商品SKU列标题名称`,
  key1005209: `设为“商品SKU2”，以此递增；`,
  key1005210: `注:输入框内仅需输入第一个商品SKU列标题即可，“数量`,
  key1005211: `内容同理;`,
  key1005212: `文件中出现相同订单号时：`,
  key1005213: `认为是包含多SKU的订单`,
  key1005214: `报错`,
  key1005215: `文件中订单号在系统相同备注中已存在时：`,
  key1005216: `忽略并导入其他订单`,
  key1005217: `第三方订单号`,
  key1005218: `配送至（国家）`,
  key1005219: `详细地址`,
  key1005220: `收货人姓名`,
  key1005221: `邮箱地址`,
  key1005222: `IOSS号`,
  key1005223: `YMS订单信息`,
  key1005224: `内容性质`,
  key1005225: `处理方式`,
  key1005226: `映射`,
  key1005227: `Excel文件列标题`,
  key1005228: `映射的Excel文件列标题不能为空！`,
  key1005229: `备货仓库`,
  key1005230: `YMS仓库：`,
  key1005231: `下单后自动付款`,
  key1005232: `请选择备货仓库!`,
  key1005233: `配置方式：`,
  key1005234: `全局统一配置`,
  key1005235: `按平台配置`,
  key1005236: `可选择以下任一YMS订单节点作为标发货节点：`,
  key1005237: `物流：`,
  key1005238: `此信息需与买手确认，未填写时将不在包裹上额外粘贴收货信息`,
  key1005239: `收货人：`,
  key1005240: `请填写收货人`,
  key1005241: `收货组：`,
  key1005242: `请填写收货组`,
  key1005243: `运费将附加在执行发货的首个订单中`,
  key1005244: `第一步：选择物流`,
  key1005245: `第二步：填写收件信息`,
  key1005246: `物流不能为空`,
  key1005247: `请填写物流单号`,
  key1005248: `第一步：上传打包标签`,
  key1005249: `第二步：填写物流单号`,
  key1005250: `包裹编号`,
  key1005251: `包裹重量（g）`,
  key1005252: `包裹货品总数`,
  key1005253: `上传打包标签`,
  key1005254: `无需上传标签`,
  key1005255: `请先上传打包标签!`,
  key1005256: `物流单号不能为空!`,
  key1005257: `派送范围：`,
  key1005258: `帐号名称不能为空`,
  key1005259: `账户授权`,
  key1005260: `帐号名称：`,
  key1005261: `关联店铺`,
  key1005262: `平台店铺：`,
  key1005263: `未启用`,
  key1005264: `正在启用`,
  key1005265: `账户，请确认后操作。`,
  key1005266: `停用该`,
  key1005267: `账户后，相关联的店铺将无法下发物流，请确认是否停用账户？`,
  key1005268: `物流渠道代码`,
  key1005269: `物流类型`,
  key1005270: `停用物流提示`,
  key1005271: `停用该物流后，将无法适用该物流进行下发，请确认是否停用？`,
  key1005272: `启用物流提示`,
  key1005273: `正在启用物流，请确认后操作。`,
  key1005274: `启用渠道提示`,
  key1005275: `正在启用物流渠道，请确认后操作。`,
  key1005276: `启用账户提示`,
  key1005277: `停用渠道提示`,
  key1005278: `停用该物流渠道后，将无法适用该物流渠道进行下发，请确认是否停用？`,
  key1005279: `停用账户提示`,
  key1005280: `帐号`,
  key1005281: `请您按照以下要求上传营业执照，如您没有按要求上传，您的申请可能会被拒绝`,
  key1005282: `请确保营业执照图片字体清晰，边角完整，无任何无关水印，不可故意遮盖二维码或信息。若拍摄模糊建议上传扫描件。`,
  key1005283: `请您先自行在国家企业信用信息公示系统查询您填写的统一社会信用代码/注册号，确保能查询到您的主体信息，且登记状态为“存续（在营、开业、在册）”。`,
  key1005284: `若公司名称或法定代表人等信息发生过变更，请上传最新核准日期的营业执照图片。`,
  key1005285: `示例图`,
  key1005286: `请您按照以下要求上传，不符合以下规范将会被审核驳回`,
  key1005287: `请确保您所上传法定代表人身份证上的姓名与营业执照上的法定代表人姓名一致。`,
  key1005288: `请确认法定代表人身份证字体清晰无反光、边角完整，无任何无关水印及故意遮盖。`,
  key1005289: `YMS平台隐私政策`,
  key1005290: `请填写营业执照上公司全称`,
  key1005291: `请选择[jpg、jpeg、png]`,
  key1005292: `公司名称不能为空`,
  key1005293: `法人身份证国徽面`,
  key1005294: `法人身份证人像面`,
  key1005295: `请先上传法人身份证国徽面`,
  key1005296: `请先上传法人身份证人像面`,
  key1005297: `法定代表人身份证照规范`,
  key1005298: `营业执照规范`,
  key1005299: `图形验证码：`,
  key1005300: `请输入短信验证码`,
  key1005301: `设置密码：`,
  key1005302: `8~26位（建议使用两种或两种以上字符组合）`,
  key1005303: `确认密码：`,
  key1005304: `请再次输入密码`,
  key1005305: `此手机号已注册分销商，继续则视为以此账户入驻供应商`,
  key1005306: `我已阅读并同意`,
  key1005307: `密码长度必须为8~26位`,
  key1005308: `请先填写手机号和图形验证码！`,
  key1005309: `账号注册成功`,
  key1005310: `你的账号已注册成功，请继续完善入驻信息，完成入驻！`,
  key1005311: `请先勾选我已阅读并同意`,
  key1005312: `设置密码不能为空`,
  key1005313: `设置密码与确认密码不一致`,
  key1005314: `填写账号信息`,
  key1005315: `填写企业信息`,
  key1005316: `入驻资料审核`,
  key1005317: `注册成功`,
  key1005318: `已有账号？`,
  key1005319: `立即登录`,
  key1005320: `提交成功`,
  key1005321: `你的申请材料已提交，通常我们会在3个工作日`,
  key1005322: `内完成审核，请耐心等待，如你有任何疑问，请`,
  key1005323: `联系在线客服！`,
  key1005324: `入驻信息审核中，请耐心等待~`,
  key1005325: `审核通过后，即可正常使用供应商后台，如你有`,
  key1005326: `任何疑问，请联系在线客服！`,
  key1005327: `你的申请，审核未通过`,
  key1005328: `请核对并修改以下信息后，再重新提交。`,
  key1005329: `重新修改`,
  key1005330: `一切就绪`,
  key1005331: `你的申请，已经审核通过！`,
  key1005332: `去发布商品`,
  key1005333: `正在退出`,
  key1005334: `账号登录`,
  key1005335: `手机号码/邮箱/通途账号`,
  key1005336: `密码`,
  key1005337: `验证码`,
  key1005338: `短信登录`,
  key1005339: `短信验证码`,
  key1005340: `立即授权`,
  key1005341: `找回密码`,
  key1005342: `手机号码/邮箱/通途账号不能为空`,
  key1005343: `短信验证码不能为空`,
  key1005344: `手机号/邮箱格式不正确`,
  key1005345: `手机号注册`,
  key1005346: `邮箱注册`,
  key1005347: `邮箱（可不填）`,
  key1005348: `通途登录`,
  key1005349: `欢迎注册`,
  key1005350: `注册`,
  key1005351: `已有账号，请登录`,
  key1005352: `通途账号登录`,
  key1005353: `如果您已有通途账号，可直接使用通途账号登录。`,
  key1005354: `登录将为您开通YMS商城分销权限。`,
  key1005355: `注册成功！`,
  key1005356: `设置密码`,
  key1005357: `请输入邮箱 / 已验证手机号`,
  key1005358: `去登录`,
  key1005359: `手机号/邮箱不能为空`,
  key1005360: `确认密码不能为空`,
  key1005361: `提 交`,
  key1005362: `首页`,
  key1005363: `供应商入驻指南`,
  key1005364: `立即入驻`,
  key1005365: `欢迎加入YMS分销平台`,
  key1005366: `YMS是中国领先的跨境服装一站式分销平台，创始团队成员深耕跨境行业十多年，有着丰富且优质的跨境电商供应链资源，`,
  key1005367: `可提供集货、仓储、物流、分销等一体化服务，可帮助源头工厂、品牌代理商、工贸一体企业等类型供应商拓展海外销路，`,
  key1005368: `秉承着“合作共赢、共同发展”的经营理念，现诚邀优质跨境电商货源供应商加入，助力您的海外市场。`,
  key1005369: `我们的优势`,
  key1005370: `招商品类`,
  key1005371: `供应商资质要求`,
  key1005372: `企业资料`,
  key1005373: `入驻材料`,
  key1005374: `材料要求`,
  key1005375: `三证合一的企业营业执照；`,
  key1005376: `确保申请入驻的企业不在《经营异常名录》中，且所销售的`,
  key1005377: `商品在其经营范围内；`,
  key1005378: `距离有效期截止时间应大于3个月。`,
  key1005379: `身份证`,
  key1005380: `法人身份证；`,
  key1005381: `距离有效期截止时间应大于1个月。`,
  key1005382: `支持银行卡或支付宝。`,
  key1005383: `产品要求`,
  key1005384: `产品资质`,
  key1005385: `商品合法合规`,
  key1005386: `承诺提供的商品合法合规，符合销售目的地的法律法规、质`,
  key1005387: `量标准及YMS上架规范；`,
  key1005388: `专利产品，需提供销售区域专利证书；`,
  key1005389: `有品牌的供应商，需提供品牌授权书；`,
  key1005390: `具备稳定的供货渠道，愿意遵循平台规则。`,
  key1005391: `真诚长远合作`,
  key1005392: `保证商品价格全网最低；`,
  key1005393: `认同YMS发展理念，抱有真诚长远的合作态度。`,
  key1005394: `订单履约`,
  key1005395: `保证商品在发货期内到达YMS仓库。`,
  key1005396: `入驻流程`,
  key1005397: `即刻入驻YMS供应商，即享快速代发新模式`,
  key1005398: `YMS诚邀你加入我们，成为我们的优质伙伴，赶快点击入驻！`,
  key1005399: `专`,
  key1005400: `专注跨境电商`,
  key1005401: `10余年专业跨境电商服装产品团队`,
  key1005402: `广`,
  key1005403: `覆盖主流电商平台`,
  key1005404: `分销店铺覆盖Amazon、eBay等主流电商平台`,
  key1005405: `集`,
  key1005406: `订单集中处理`,
  key1005407: `供应商只需发货至YMS集运仓库`,
  key1005408: `免`,
  key1005409: `免仓储服务费`,
  key1005410: `入驻即获得仓储免费服务`,
  key1005411: `快速代发`,
  key1005412: `一站式代发系统托管，节省仓储精力，控制人工成本`,
  key1005413: `女 装`,
  key1005414: `男 装`,
  key1005415: `女 童`,
  key1005416: `男 童`,
  key1005417: `婴 童`,
  key1005418: `产品调研`,
  key1005419: `YMS协助进行产品调研，确定产品符合市场需求，且具备价格竞争优势。`,
  key1005420: `注册账号`,
  key1005421: `按要求填写账号信息，注册企业账号。`,
  key1005422: `填写入驻资料`,
  key1005423: `提交营业执照、法定代表人身份证，填写店铺相关信息。`,
  key1005424: `入驻资料提交后，YMS将在3个工作日内完成审核。`,
  key1005425: `入驻完成`,
  key1005426: `审核通过后，供应商登录后台，发布商品开始销售。`,
  key1005427: `请描述你的问题，如：物流`,
  key1005428: `视频教程`,
  key1005429: `云卖供应链`,
  key1005430: `功能介绍`,
  key1005431: `云卖供应链功能介绍`,
  key1005432: `注册云卖供应链`,
  key1005433: `店铺授权`,
  key1005434: `自助服务`,
  key1005435: `定期更换密码有助于保障账户安全`,
  key1005436: `修改手机号码`,
  key1005437: `手机号涉及登录、认证等关键业务，保障账户安全`,
  key1005438: `邮箱涉及登录、消息通知等关键业务，并保障账户安全`,
  key1005439: `搜索“`,
  key1005440: `”的相关结果，共`,
  key1005441: `无匹配搜索结果`,
  key1005442: `帮助首页`,
  key1005443: `登录`,
  key1005444: `登录查看价格`,
  key1005445: `每日上新`,
  key1005446: `潮流女装`,
  key1005447: `时尚男装`,
  key1005448: `精品童装`,
  key1005449: `HOT 热销商品`,
  key1005450: `好货推荐`,
  key1005451: `YMS商城（www.yms.com）是深圳云卖供应链管理有限公司建立的一个专注跨境电商服装品类的供应链服务平台。平台集成源头工厂服装商城、通途Listing刊登平台、通途ERP平台和通途物流平台，为跨境电商卖家与源头工厂打通了一条高效的订单履约链路，国外销售终端的顾客订单直接由源头工厂一件代发。`,
  key1005452: `只需3步，让跨境生意更简单`,
  key1005453: `你只需专注于运营，其他的，我们来帮你解决`,
  key1005454: `选品`,
  key1005455: `免费注册账号，在YMS商城订阅商品`,
  key1005456: `刊登`,
  key1005457: `直连通途Listing刊登系统，一键快速上架到eBay、Amazon等多平台店铺`,
  key1005458: `店铺出单后，即可在YMS商城下单，支持一件代发`,
  key1005459: `YMS商城跨境电商，专注于服装分销`,
  key1005460: `现在你可以把成千上万的服装商品从YMS商城刊登到海外各大平台，从而赢得更多的销售机会`,
  key1005461: `海量商品选购`,
  key1005462: `40000+服装SKU，1000+每月上新`,
  key1005463: `0库存风险`,
  key1005464: `无需国货备货，0库存风险`,
  key1005465: `无资金压力`,
  key1005466: `先出单后付款，提升资金周转率`,
  key1005467: `你只需专注于商品销售，就算只卖出一件商品，我们也会贴心地帮你发货`,
  key1005468: `快速上架到多平台`,
  key1005469: `自有品牌标识，让客户更信赖`,
  key1005470: `可以通过包裹、包装标识自有品牌，让客户更信赖`,
  key1005471: `订单自动跟踪`,
  key1005472: `销售订单系统自动跟踪，无需复制和粘贴客户订单详细信息`,
  key1005473: `发货快，出货时效有保障`,
  key1005474: `开放API接口`,
  key1005475: `YMS商城提供开放接口，可实现商品管理、订单管理、仓储管理等诸多功能`,
  key1005476: `分销商业务`,
  key1005477: `1）服务内容：为跨境电商服装卖家的eBay、Walmart、速卖通、Wish、Shopify等平台订单提供源头工厂现货一件代发服务。`,
  key1005478: `2）服务流程：`,
  key1005479: `注册成为YMS商城分销商`,
  key1005480: `方案1：通过通途ERP账户（http:`,
  key1005481: `方案2：直接手机号注册开通。`,
  key1005482: `刊登销售`,
  key1005483: `在YMS商城选品收藏商品，通途Listing系统快速刊登到eBay、Walmart、速卖通等平台销售。`,
  key1005484: `订单发货`,
  key1005485: `通途ERP抓取平台订单，匹配分销商设定的国际物流方式，下发至YMS商城。订单即可履约一件代发给国外顾客。`,
  key1005486: `供应商业务`,
  key1005487: `1）服务内容：跨境电商服装品类的工厂入驻YMS商城开设直营店铺。`,
  key1005488: `服装工厂联系供应商招商经理注册进驻。`,
  key1005489: `经营店铺，规范上架商品，管理库存，操作订单发货。`,
  key1005490: `中心仓仓储业务`,
  key1005491: `1）服务内容：为YMS商城供应商提供仓储服务。`,
  key1005492: `YMS商城供应商开通云仓。`,
  key1005493: `中心仓备货和发货，供应商创建备货计划，贴YMS商城商品条码入仓，中心仓自动匹配订单发货。`,
  key1005494: `平台业务介绍`,
  key1005495: `联系地址：深圳市南山区学苑大道南山智园C1栋6楼`,
  key1005496: `联系电话：400-0600-405`,
  key1005497: `分销商在线服务QQ：2853913746`,
  key1005498: `达到上一销量后可查看`,
  key1005499: `地址：`,
  key1005500: `当前位置：`,
  key1005501: `上架时间：`,
  key1005502: `优惠`,
  key1005503: `30天内累计分销`,
  key1005504: `收藏`,
  key1005505: `一键刊登支持以下平台`,
  key1005506: `优质供应商`,
  key1005507: `价格：登录后可见`,
  key1005508: `商品详细信息`,
  key1005509: `物品参数`,
  key1005510: `供应商建议`,
  key1005511: `暂无权限查看，请联系客服开通权限。`,
  key1005512: `关注店铺`,
  key1005513: `包装尺寸(cm)`,
  key1005514: `美西仓库存`,
  key1005515: `取消收藏成功`,
  key1005516: `★ 已关注`,
  key1005517: `入门视频`,
  key1005518: `新用户入门操作`,
  key1005519: `新用户注册`,
  key1005520: `授权店铺`,
  key1005521: `一键刊登流程`,
  key1005522: `一键刊登成功前提：成功授权店铺`,
  key1005523: `设置店铺刊登模板`,
  key1005524: `一键刊登规则`,
  key1005525: `第一步：刊登模板设置`,
  key1005526: `刊登模板设置`,
  key1005527: `第二步：刊登规则设置`,
  key1005528: `刊登规则设置`,
  key1005529: `第三步：一键刊登`,
  key1005530: `公司档案`,
  key1005531: `图片类型`,
  key1005532: `一键刊登支持平台：`,
  key1005533: `是否收藏：`,
  key1005534: `联系人姓名不能为空`,
  key1005535: `结算币种：`,
  key1005536: `省/市/区：`,
  key1005537: `仓库配置`,
  key1005538: `开启仓储管理`,
  key1005539: `发货地址信息`,
  key1005540: `+添加发货地址`,
  key1005541: `您更改提交的商户工商信息，平台正在审核中......`,
  key1005542: `您更改提交的商户工商信息已被平台驳回！请查看驳回原因后重新提交审核。`,
  key1005543: `查看驳回原因>>>`,
  key1005544: `您更改提交的商户工商信息平台已审核通过！`,
  key1005545: `未开启仓储管理`,
  key1005546: `工商信息已经在审核中，暂不支持修改！`,
  key1005547: `确认后不保存已编辑信息`,
  key1005548: `是否关闭仓储功能？`,
  key1005549: `当前仓储功能关闭后，暂不支持重新开启，是否确认关闭？`,
  key1005550: `已开启仓储管理`,
  key1005551: `请输入开户人姓名`,
  key1005552: `请输入开户行`,
  key1005553: `请输入银行卡号`,
  key1005554: `请输入身份证号`,
  key1005555: `请输入支付宝登录号，支持邮箱和手机号格式`,
  key1005556: `请输入该支付宝账户的开户人姓名`,
  key1005557: `请输入该支付宝账户的开户人证件号`,
  key1005558: `绑定`,
  key1005559: `绑定收款账户`,
  key1005560: `（推荐）`,
  key1005561: `更换成功！`,
  key1005562: `请输入支付宝登录号`,
  key1005563: `(推荐)`,
  key1005564: `变更日志`,
  key1005565: `绑定账户`,
  key1005566: `更换账户`,
  key1005567: `更换绑定`,
  key1005568: `原记录`,
  key1005569: `变更后记录`,
  key1005570: `变更时间`,
  key1005571: `暂未添加`,
  key1005572: `添加收款账户`,
  key1005573: `暂未绑定`,
  key1005574: `更换收款账户`,
  key1005575: `修改失败`,
  key1005576: `操作失败`,
  key1005577: `角色`,
  key1005578: `审核中,审核后将展示补贴金额。`,
  key1005579: `符合`,
  key1005580: `不满足现有补贴规则、如有疑问请联系您的`,
  key1005581: `对接人员`,
  key1005582: `收货时间`,
  key1005583: `应发部分实到数量`,
  key1005584: `多发数量`,
  key1005585: `发往仓库`,
  key1005586: `追加货品`,
  key1005587: `作废提单`,
  key1005588: `作废提单提示`,
  key1005589: `提单作废后，提单内所有订单将流转至待重发状态，出库单将流转至待拣货状态，请确认是否作废提单?`,
  key1005590: `正在装箱`,
  key1005591: `三方提单号`,
  key1005592: `物流单号`,
  key1005593: `请输入提单编号,支持多个逗号或换行分隔`,
  key1005594: `作废成功!`,
  key1005595: `运营商操作`,
  key1005596: `修改备货数量`,
  key1005597: `修改后数量`,
  key1005598: `未输入时视为不修改`,
  key1005599: `请输入需修改的商品数量`,
  key1005600: `修改备货数量成功!`,
  key1005601: `备货单导入`,
  key1005602: `目的仓库：`,
  key1005603: `1、一次性上传过多备货信息容易读取超时失败，建议单批次上传不超过100条备货信息;`,
  key1005604: `2、文件内容SKU若无商品SKU匹配项，或SKU对应数量内容非正整数，则视为无效内容;`,
  key1005605: `3、仅支持xls、xlsx格式文件;`,
  key1005606: `4、若文件中存在无效内容，则会上传失败;`,
  key1005607: `出库单类型不能为空！`,
  key1005608: `目的仓库不能为空！`,
  key1005609: `发起备货`,
  key1005610: `批量发货`,
  key1005611: `已选订单`,
  key1005612: `筛选结果订单`,
  key1005613: `仅出库单类型为自有仓备货的备货单可打印`,
  key1005614: `条码！`,
  key1005615: `扫描SKU打印`,
  key1005616: `打印所选订单条码`,
  key1005617: `请先勾选需要标记发货的数据！`,
  key1005618: `当前筛选结果暂无数据，无法标记发货！`,
  key1005619: `当前订单中出库状态为待发货状态的订单，才可以执行发货！`,
  key1005620: `当前订单中备货状态为待发货状态的订单，才可以执行发货！`,
  key1005621: `发货台内当前无商品，无法打印配货清单。`,
  key1005622: `请先勾选需要打印的订单条码数据！`,
  key1005623: `当前筛选结果暂无数据！无法打印订单条码`,
  key1005624: `取消备货`,
  key1005625: `请确认是否取消备货？`,
  key1005626: `配货清单格式`,
  key1005627: `清单格式：`,
  key1005628: `纸质清单`,
  key1005629: `表格文件`,
  key1005630: `扫描换单`,
  key1005631: `请扫描/输入SKU`,
  key1005632: `请扫描/输入SKU：`,
  key1005633: `批量打印：`,
  key1005634: `关闭弹窗`,
  key1005635: `sku不能为空！`,
  key1005636: `打印数量不能为空！`,
  key1005637: `获取打印地址失败！`,
  key1005638: `商品加工状态：`,
  key1005639: `工厂名称：`,
  key1005640: `请输入工厂名称`,
  key1005641: `结算金额：`,
  key1005642: `商品加工状态`,
  key1005643: `工厂名称`,
  key1005644: `拒绝`,
  key1005645: `待客户付款`,
  key1005646: `请输入订单号,支持多个逗号或换行分隔`,
  key1005647: `请输入供应商SKU,支持多个逗号或换行分隔`,
  key1005648: `待加工`,
  key1005649: `加工中`,
  key1005650: `加工完成待发货`,
  key1005651: `自发货订单`,
  key1005652: `POD订单`,
  key1005653: `缺货订单需标记到货后再进行发货！`,
  key1005654: `当前订单中出库单为待发货状态的订单，才可以执行发货！`,
  key1005655: `当前筛选结果暂无数据，无法操作！`,
  key1005656: `请先勾选需要标记的数据！`,
  key1005657: `标记到货提示`,
  key1005658: `标记到货后订单将流转为待发货，请确认是否标记到货？`,
  key1005659: `仅可对缺货订单标记到货，请重新选择！`,
  key1005660: `仅可对非缺货订单标记缺货，请重新选择！`,
  key1005661: `接单确认`,
  key1005662: `拒绝接单确认`,
  key1005663: `正在操作接单，确认接单后将按照此订单金额进行结算，需等待客户付款，请确认是否接单？`,
  key1005664: `拒绝接单后，订单将可能被取消，请确认是否拒绝接单？`,
  key1005665: `拒绝接单`,
  key1005666: `接单成功`,
  key1005667: `拒绝接单成功`,
  key1005668: `发货提醒`,
  key1005669: `商品数量不能为空或为0`,
  key1005670: `数量必须是大于0的整数`,
  key1005671: `请先添加商品！`,
  key1005672: `扫描`,
  key1005673: `请扫描或输入扫描`,
  key1005674: `提货单号：`,
  key1005675: `请输入提货单号`,
  key1005676: `完成追加`,
  key1005677: `已扫描出库单`,
  key1005678: `编码信息`,
  key1005679: `装箱提示`,
  key1005680: `你扫描的出库单号，与当前已装箱的出库单目的仓库不同，请扫描同一仓库的出库单号装箱。`,
  key1005681: `请输入sku`,
  key1005682: `打印数量不能为空`,
  key1005683: `商品管理`,
  key1005684: `发布新商品`,
  key1005685: `销售趋势图`,
  key1005686: `热销商品排行榜`,
  key1005687: `统计本月（自然月）店铺已完成`,
  key1005688: `仓已发出`,
  key1005689: `的订单总金额。`,
  key1005690: `统计本月（自然月）已完成`,
  key1005691: `的订单总数。`,
  key1005692: `上月销售额`,
  key1005693: `本月订单量（单）`,
  key1005694: `上月订单量`,
  key1005695: `昨日订单量`,
  key1005696: `今日订单量`,
  key1005697: `在售SPU总数`,
  key1005698: `统计在售SPU总数量，不包含、停售、暂存状态的SPU。`,
  key1005699: `在售SKU总数`,
  key1005700: `统计在售SKU总数量，不包含、停售、暂存状态的SKU。`,
  key1005701: `订阅商品数（SPU）`,
  key1005702: `统计分销商订阅商品的总数，同一个商品多个分销商订阅只计算1次。`,
  key1005703: `本月订阅数`,
  key1005704: `昨日订阅数`,
  key1005705: `今日订阅数`,
  key1005706: `统计分销商已上架到平台的SPU总数量，同一个SPU不同的Item只计算一次。`,
  key1005707: `统计分销商已发布刊登SPU的总数量，按Item数量统计。`,
  key1005708: `近30天发货时效（小时）`,
  key1005709: `统计近30天所有订单从待拣货到已出库（快递揽收）的平均耗时`,
  key1005710: `近7天发货时效`,
  key1005711: `近3天发货时效`,
  key1005712: `在售中`,
  key1005713: `已售罄`,
  key1005714: `已驳回`,
  key1005715: `近7天`,
  key1005716: `近30天`,
  key1005717: `销售额(CNY)： ¥ `,
  key1005718: `订单量(单)：`,
  key1005719: `中心仓`,
  key1005720: `库存明细日志`,
  key1005721: `账单`,
  key1005722: `提单`,
  key1005723: `上架/停售`,
  key1005724: `请输入中文标题`,
  key1005725: `审核状态：`,
  key1005726: `批量改价`,
  key1005727: `批量上架/停售`,
  key1005728: `导出商品`,
  key1005729: `获取1688商品`,
  key1005730: `采集1688商品`,
  key1005731: `打印条形码`,
  key1005732: `1.导入销售价后将覆盖原来价格，请谨慎导入;`,
  key1005733: `4.导入操作后，请稍后在“导入/导出任务-导入查看”中查看导入结果。`,
  key1005734: `1.按照SKU维度进行上架/停售，停售后分销商将无法继续下单;`,
  key1005735: `sku,支持多个按行分隔`,
  key1005736: `更新时间：`,
  key1005737: `请输入供应商sku,支持多个按行分隔`,
  key1005738: `修改价格`,
  key1005739: `导入商品`,
  key1005740: `中心仓名称`,
  key1005741: `中心仓分配数量`,
  key1005742: `中心仓可用数量`,
  key1005743: `自有仓分配数量`,
  key1005744: `自有仓可用数量`,
  key1005745: `供货价（元）`,
  key1005746: `，该商品正在被映射商品使用，不能删除`,
  key1005747: `，SKU不存在`,
  key1005748: `，该SKU有库存不允许删除`,
  key1005749: `，系统繁忙，请重新尝试`,
  key1005750: `选择分类：`,
  key1005751: `模板：`,
  key1005752: `第1步：下载模板`,
  key1005753: `第2步：上传资料`,
  key1005754: `导入数据发现相同SPU时：`,
  key1005755: `1.请按照模板中的导入说明文档进行商品信息上传。`,
  key1005756: `2.仅支持拓展名：.zip。`,
  key1005757: `3.当导入数据存在异常时，数据导入失败。`,
  key1005758: `5.暂无法对组装商品进行导入或覆盖。`,
  key1005759: `文件大小超出限制，最大为500M`,
  key1005760: `请先选择分类！`,
  key1005761: `下载模板成功！`,
  key1005762: `获取类型：`,
  key1005763: `选择店铺：`,
  key1005764: `商品链接：`,
  key1005765: `请输入1688商品链接，如:https://detail.1688.com/offer/673838787879.html支持多个按行分隔`,
  key1005766: `请选择1688商品创建时间`,
  key1005767: `获取`,
  key1005768: `将尽快补充映射。`,
  key1005769: `匹配映射失败，`,
  key1005770: `SKU重复`,
  key1005771: `时间范围不能为空`,
  key1005772: `店铺不能为空`,
  key1005773: `1688店铺未绑定`,
  key1005774: `暂未绑定1688店铺，请绑定后重试。`,
  key1005775: `获取商品失败`,
  key1005776: `获取商品部分失败`,
  key1005777: `复制失败SPU`,
  key1005778: `匹配映射失败，可点击`,
  key1005779: `获取商品成功`,
  key1005780: `查看商品`,
  key1005781: `获取商品成功，可在商品页面`,
  key1005782: `商品获取中，请稍等!`,
  key1005783: `商品链接不能为空!`,
  key1005784: `指定链接地址必须是https://或者http://开头的链接!`,
  key1005785: `1688类目：`,
  key1005786: `批量采集`,
  key1005787: `近90天成交：`,
  key1005788: `商品采集中，请稍等!`,
  key1005789: `品牌名称不能为空`,
  key1005790: `品牌名称：`,
  key1005791: `请输入品牌名称`,
  key1005792: `品牌描述：`,
  key1005793: `请输入品牌描述`,
  key1005794: `品牌状态：`,
  key1005795: `品牌网址：`,
  key1005796: `请输入品牌网址`,
  key1005797: `品牌LOGO：`,
  key1005798: `新增品牌`,
  key1005799: `单击或拖动此处上传文件`,
  key1005800: `按品牌状态`,
  key1005801: `按品牌名称`,
  key1005802: `品牌LOGO`,
  key1005803: `品牌名称`,
  key1005804: `品牌描述`,
  key1005805: `品牌网址`,
  key1005806: `品牌状态`,
  key1005807: `是否要删除当前品牌？`,
  key1005808: `编辑品牌`,
  key1005809: `价格不能为0或为空！`,
  key1005810: `等待收款`,
  key1005811: `商品原价`,
  key1005812: `货品金额（应收）：`,
  key1005813: `补贴金额（应收）：`,
  key1005814: `物流费用（应付）：`,
  key1005815: `请输入账单编号，支持多个逗号或换行分隔`,
  key1005816: `导出账单`,
  key1005817: `确认账单即表示已对账单内容、金额核对无误，请确认账单后操作，确认账单后`,
  key1005818: `后台将发起打款流程，资金将转入所绑定的收款账户。`,
  key1005819: `货品结算金额(CNY)`,
  key1005820: `收款账户未绑定`,
  key1005821: `当前账户未绑定收款账户，无法完成收款流程，请绑定后重试。`,
  key1005822: `去绑定`,
  key1005823: `账单编号`,
  key1005824: `账单状态更新时间`,
  key1005825: `中文描述：`,
  key1005826: `导出库存(自有仓库)`,
  key1005827: `(已删除)`,
  key1005828: `SKU、商品名称`,
  key1005829: `模糊搜索`,
  key1005830: `SKU属性`,
  key1005831: `库存数量`,
  key1005832: `冻结数量`,
  key1005833: `不良品数量`,
  key1005834: `请选择库区`,
  key1005835: `导出库存(`,
  key1005836: `中心仓)`,
  key1005837: `库区：`,
  key1005838: `自有仓库`,
  key1005839: `开启拣货复核异常`,
  key1005840: `您扫描或输入的拣货单【 {pickList} 】已完成`,
  key1005841: `您扫描或输入的拣货单【 {pickList} 】不存在或状态已改变,请刷新后重试`,
  key1005842: `您扫描或输入的拣货单【 {pickList} 】下不存在出库单`,
  key1005843: `您扫描或输入的拣货单【 {pickList} 】下不存在有效出库单`,
  key1005844: `您扫描或输入的拣货单【 {pickList} 】下存在普通打印出库单`,
  key1005845: `您扫描或输入的拣货单【 {pickList} 】未进行多品分拣`,
  key1005846: `您扫描或输入的拣货单【 {pickList} 】下存在出库单未下发物流商成功`,
  key1005847: `您扫描或输入的拣货单【 {pickList} 】拣货未完成`,
  key1005848: `您扫描或输入的拣货单【 {pickList} 】在系统中未找到，请确认拣货单是否已被删除`,
  key1005849: `扫描/录入拣货单单号开始拣货复核`,
  key1005850: `正在进行的拣货复核`,
  key1005851: `混合`,
  key1005852: `时长`,
  key1005853: `包裹进度`,
  key1005854: `货品进度`,
  key1005855: `小组成员`,
  key1005856: `单品单件`,
  key1005857: `单品多件`,
  key1005858: `存在正在进行的拣货复核，是否立即进入`,
  key1005859: `存在正在进行的拣货复核，是否立即跳转`,
  key1005860: `已上传物流商`,
  key1005861: `请先选择物流商`,
  key1005862: `出库单详情：`,
  key1005863: `出库单编号：`,
  key1005864: `参考编号：`,
  key1005865: `拣货状态：`,
  key1005866: `装箱状态：`,
  key1005867: `最后修改时间：`,
  key1005868: `最后修改人：`,
  key1005869: `通途估算重量：`,
  key1005870: `通途称重重量：`,
  key1005871: `通途估算运费：`,
  key1005872: `通途称重后运费：`,
  key1005873: `物流商称重：`,
  key1005874: `分配列表`,
  key1005875: `其他出库`,
  key1005876: `组装加工`,
  key1005877: `行状态`,
  key1005878: `分配异常原因`,
  key1005879: `产品sku`,
  key1005880: `填入计划调拨数量`,
  key1005881: `已发货数量`,
  key1005882: `身份码`,
  key1005883: `已分配数量`,
  key1005884: `未分配数量`,
  key1005885: `分配库位`,
  key1005886: `分配批次`,
  key1005887: `分配完成时间`,
  key1005888: `订单创建`,
  key1005889: `部分分配`,
  key1005890: `分配完成`,
  key1005891: `完全发货`,
  key1005892: `订单完成`,
  key1005893: `未装箱`,
  key1005894: `详情加载失败`,
  key1005895: `中心仓备货时进行包装作业：`,
  key1005896: `多品多件包裹自动分拣功能：`,
  key1005897: `拣货复核时进行包装：`,
  key1005898: `此设置对供应商备货流程无效`,
  key1005899: `是否要在拣货复核时对包裹称重？`,
  key1005900: `对包装作业中的已包装的包裹标记为已发货？`,
  key1005901: `开启`,
  key1005902: `分拣时打印地址面单`,
  key1005903: `不称重`,
  key1005904: `分拣架设置(仅在多品多件包裹自动分拣功能开启时可用)`,
  key1005905: `仅按货品SKU字符升序`,
  key1005906: `请至少选择一个拣货架`,
  key1005907: `可扫描出库单号、运单号、物流商单号`,
  key1005908: `有些物流商面单扫描的运单号会带前缀或后缀，这样系统就无法识别运单号，勾选可截去相对应的前缀或后缀`,
  key1005909: `尺寸：`,
  key1005910: `称重异常提醒`,
  key1005911: `直发`,
  key1005912: `扫描出库`,
  key1005913: `切换为批量出库`,
  key1005914: `量体积`,
  key1005915: `称重单位为:`,
  key1005916: `自动执行发货`,
  key1005917: `实际称重：`,
  key1005918: `截去`,
  key1005919: `已发`,
  key1005920: `批量标记发货`,
  key1005921: `清空全部`,
  key1005922: `需要称重`,
  key1005923: `需要量尺寸`,
  key1005924: `称重异常提醒设置`,
  key1005925: `当包裹中的商品为：`,
  key1005926: `且包裹称重与商品资料重量误差大于等于`,
  key1005927: `时系统将提示`,
  key1005928: `发货运费异常设置`,
  key1005929: `逐单扫描发货计算运费大于`,
  key1005930: `产品重量小计(g)：`,
  key1005931: `重量总计(g)：`,
  key1005932: `继续发货`,
  key1005933: `取消发货`,
  key1005934: `个包裹`,
  key1005935: `总重`,
  key1005936: `导出物流确认excel`,
  key1005937: `产品重量`,
  key1005938: `已扫描`,
  key1005939: `标发货成功`,
  key1005940: `此包裹已存在`,
  key1005941: `量尺寸`,
  key1005942: `包裹号不能为空`,
  key1005943: `无权限`,
  key1005944: `您当前筛选的包裹汇总信息：`,
  key1005945: `常用标签：`,
  key1005946: `订单数`,
  key1005947: `SKU品种数`,
  key1005948: `开启多品分拣作业异常`,
  key1005949: `您扫描或输入的拣货单【 {pickList} 】在系统中未找到，请确认拣货单是否已被删除或拣货单号输入错误。`,
  key1005950: `您扫描或输入的拣货单【 {pickList} 】不是多品多件拣货单，无法开启分拣作业。`,
  key1005951: `系统每个操作员同一时间只允许开启一个分拣作业，所以，很抱歉，你无法对拣货单【 {pickList} 】开启分拣作业。`,
  key1005952: `您扫描或输入的拣货单【 {pickList} 】正在进行分拣作业，每张拣货单同一时间只允许一位操作员进行分拣作业，无法再次开启。`,
  key1005953: `您扫描或输入的拣货单【 {pickList} 】已经分拣完成，您可以对该拣货单重新开启分拣作业。`,
  key1005954: `您扫描或输入的拣货单【 {pickList} 】正在进行拣货复核，无法开启分拣作业。`,
  key1005955: `您扫描或输入的拣货单【 {pickList} 】已经完成拣货复核，无法开启分拣作业。`,
  key1005956: `您扫描或输入的拣货单【 {pickList} 】下存在已进行普通打印出库单`,
  key1005957: `扫描/录入拣货单单号开始多品分拣`,
  key1005958: `正在进行的分拣作业`,
  key1005959: `拣货篮不能找到唯一没包装完成的拣货单`,
  key1005960: `拣货篮没有待完成的拣货单`,
  key1005961: `当前系统中存在由您开启的、尚未完成的分拣作业，`,
  key1005962: `分拣作业开始时间：`,
  key1005963: `我搞错了，我不需要重新分拣`,
  key1005964: `是的，我需要对这个拣货单进行重新分拣`,
  key1005965: `分拣状态`,
  key1005966: `正在分拣`,
  key1005967: `分拣完成`,
  key1005968: `操作员`,
  key1005969: `没有权限`,
  key1005970: `部分拣货`,
  key1005971: `等待分拣`,
  key1005972: `等待复核`,
  key1005973: `正在复核`,
  key1005974: `已复核`,
  key1005975: `普通打印`,
  key1005976: `见货出单`,
  key1005977: `拣货提醒`,
  key1005978: `请确认是否取消`,
  key1005979: `导出成功！`,
  key1005980: `拣货单生成时间：`,
  key1005981: `单品规则保存`,
  key1005982: `多品规则保存`,
  key1005983: `单品规则`,
  key1005984: `多品规则`,
  key1005985: `合并规则`,
  key1005986: `时间设置`,
  key1005987: `固定周期`,
  key1005988: `每过：`,
  key1005989: `小时生成拣货单`,
  key1005990: `每天定时`,
  key1005991: `每天：`,
  key1005992: `是否开启`,
  key1005993: `请填写时间`,
  key1005994: `正常拣货单`,
  key1005995: `自动拣货波次`,
  key1005996: `请输入提单编号`,
  key1005997: `批量添加出库单`,
  key1005998: `输入多个请用逗号或者换行分隔`,
  key1005999: `导出提单数据`,
  key1006000: `新增箱/袋`,
  key1006001: `添加包裹`,
  key1006002: `是否要删除当前提单编号？`,
  key1006003: `请输入出库单!`,
  key1006004: `包裹不存在`,
  key1006005: `包裹不是出库状态`,
  key1006006: `该包裹已提单`,
  key1006007: `未拣货/打印出库单明细`,
  key1006008: `同一个出库单中未拣数量至少要有一个是大于0`,
  key1006009: `打印时间：`,
  key1006010: `请输入拣货单号`,
  key1006011: `请输入运单号，多个运单号可以英文逗号隔开`,
  key1006012: `请输入出库单号`,
  key1006013: `请输入订单号`,
  key1006014: `请输入买家姓名`,
  key1006015: `请输入买家ID`,
  key1006016: `可输入订单号、出库单号、买家ID、买家姓名、SKU`,
  key1006017: `多个库区，用逗号或换行分隔`,
  key1006018: `是否有运单号：`,
  key1006019: `是否有物流商号：`,
  key1006020: `是否有物流商重量：`,
  key1006021: `是否有物流商运费：`,
  key1006022: `之前`,
  key1006023: `以下SKU列表是所选包裹中所包含的SKU的汇总，请选择缺货的SKU，标记缺货后，系统将自动将包裹还原到缺货状态，并还原所选SKU的库存，同时建立所选SKU的盘点单，请进行盘点修正库存。`,
  key1006024: `按打印时间`,
  key1006025: `按付款时间`,
  key1006026: `按出库单号`,
  key1006027: `买家ID/姓名`,
  key1006028: `标记为已发货`,
  key1006029: `请选择包裹`,
  key1006030: `标出库`,
  key1006031: `标出库，系统将符合您选择的包裹标记为已出库。`,
  key1006032: `系统将符合您选择的包裹标记为已发货。`,
  key1006033: `标出库（所有结果集）`,
  key1006034: `所有结果集标出库，系统将符合您当前查询条件的所有包裹标记为已出库。`,
  key1006035: `请选择标记的订单`,
  key1006036: `个拣货篮：`,
  key1006037: `打印选中面单`,
  key1006038: `包材`,
  key1006039: `重打面单`,
  key1006040: `拣货复核`,
  key1006041: `没有正在扫描的拣货单`,
  key1006042: `当前勾选中的数据都已打印！`,
  key1006043: `箱/袋管理`,
  key1006044: `扫描装箱`,
  key1006045: `冻结分配`,
  key1006046: `解冻并分配`,
  key1006047: `入库单`,
  key1006048: `冻结单`,
  key1006049: `产品ID`,
  key1006050: `库位使用`,
  key1006051: `收货库位`,
  key1006052: `拣货库位`,
  key1006053: `来源商户：`,
  key1006054: `身份码：`,
  key1006055: `导出出库单数据`,
  key1006056: `作废出库单异常`,
  key1006057: `新增出库单`,
  key1006058: `请输入运单号，多个运单号可用英文逗号分隔`,
  key1006059: `请输入物流商号，多个物流商号可用英文逗号分隔`,
  key1006060: `按出库时间`,
  key1006061: `此出库单为其他出库单实际发货数量超出应发数量所产生的补单。`,
  key1006062: `来源渠道`,
  key1006063: `来源商户`,
  key1006064: `运单号/物流商单号`,
  key1006065: `物流商重量/物流商运费`,
  key1006066: `下载面单`,
  key1006067: `关联订单号`,
  key1006068: `买家名称`,
  key1006069: `地址`,
  key1006070: `邮编`,
  key1006071: `打印时间`,
  key1006072: `打印人`,
  key1006073: `实际重量`,
  key1006074: `物流商重量(g)`,
  key1006075: `组织`,
  key1006076: `物品SKU`,
  key1006077: `物品身份码`,
  key1006078: `多品分拣—结束分拣`,
  key1006079: `已出库数量`,
  key1006080: `货品(已拣/待拣)`,
  key1006081: `新增订单成功!`,
  key1006082: `扫描拣货单号/出库单号/运单号/`,
  key1006083: `面单：`,
  key1006084: `出库单为空，无法操作结束装箱！`,
  key1006085: `请先扫描提单编号！`,
  key1006086: `请输入提单编号！`,
  key1006087: `请输入拣货单号/出库单号/运单号！`,
  key1006088: `是否要删除当前出库单号？`,
  key1006089: `(作废)`,
  key1006090: `库区组`,
  key1006091: `拣货数量`,
  key1006092: `识别号：`,
  key1006093: `扫描SKU：`,
  key1006094: `可扫描SKU`,
  key1006095: `扫描身份码：`,
  key1006096: `可扫描身份码`,
  key1006097: `标记异常`,
  key1006098: `包装设置`,
  key1006099: `异常`,
  key1006100: `校对SKU`,
  key1006101: `已扫描包裹`,
  key1006102: `取消包装`,
  key1006103: `继续包装`,
  key1006104: `包材名称`,
  key1006105: `应包装数量`,
  key1006106: `克（g）`,
  key1006107: `千克（Kg）`,
  key1006108: `待包装数量`,
  key1006109: `产品重量(g)`,
  key1006110: `当前包裹中还在sku还未扫描完成！`,
  key1006111: `当前包裹中还有身份码未扫描完成！`,
  key1006112: `当前扫描的出库单号或运单号或物流商单号，已经扫描出库！`,
  key1006113: `识别号不能为空！`,
  key1006114: `当前扫描的数量不能大于应包装数量！`,
  key1006115: `当前扫描的sku不存在！`,
  key1006116: `SKU不能为空！`,
  key1006117: `称重不能为空且要大于0！`,
  key1006118: `请先扫描识别号！`,
  key1006119: `已扫描数量不能全等于待包装数量，不然无法标记异常！`,
  key1006120: `标记异常成功！`,
  key1006121: `已扫描包裹成功！`,
  key1006122: `重打数量`,
  key1006123: `已打印数量`,
  key1006124: `已扫描完成`,
  key1006125: `匹配失败`,
  key1006126: `多个订单号请用逗号分隔`,
  key1006127: `SKU/数量：`,
  key1006128: `品牌：`,
  key1006129: `发货提醒：`,
  key1006130: `拣货单生成设置`,
  key1006131: `品类`,
  key1006132: `拣货库区组`,
  key1006133: `拣货库区`,
  key1006134: `标签名不能为空`,
  key1006135: `选择导入文件：`,
  key1006136: `选择文件`,
  key1006137: `下载模板`,
  key1006138: `存在空的通途sku`,
  key1006139: `存在空的skuCode`,
  key1006140: `通途sku系统不存在`,
  key1006141: `skuCode 系统不存在`,
  key1006142: `头程成本必须是数字`,
  key1006143: `作废出库单`,
  key1006144: `勾选重置出库单，会重新生成一个出库单`,
  key1006145: `重置出库单`,
  key1006146: `条出库单被重置状态，成功执行后的记录，将回到【等待分配】状态,同时出库单会执行以下操作：`,
  key1006147: `1.出库单如果已经下发物流成功，会尝试调用物流商接口取消下单`,
  key1006148: `2.出库单如果已经分配库存成功，会归还已分配库存`,
  key1006149: `代号：`,
  key1006150: `请输入代号`,
  key1006151: `账号名：`,
  key1006152: `请输入授权的平台账号`,
  key1006153: `到期时间：`,
  key1006154: `账号名`,
  key1006155: `授权时间`,
  key1006156: `数据同步`,
  key1006157: `选择仓库`,
  key1006158: `选择多个仓库将同步所有仓库总库存。`,
  key1006159: `同步仓库`,
  key1006160: `授权`,
  key1006161: `新增商品`,
  key1006162: `请先勾选仓库！`,
  key1006163: `库存 =`,
  key1006164: `主仓实际库存`,
  key1006165: `订单占有数`,
  key1006166: `仓库待发数`,
  key1006167: `虚拟库存`,
  key1006168: `采购在途数`,
  key1006169: `销退仓库存`,
  key1006170: `进货仓库存`,
  key1006171: `次品库存`,
  key1006172: `库存 = `,
  key1006173: `规则项不能为空!`,
  key1006174: `您没有权限访问该资源,请与管理员联系`,
  key1006175: `系统繁忙，请重新尝试（接口报错了，请在控制台查看接口错误）`,
  key1006176: `账号已被停用`,
  key1006177: `供应商结算状态需为等待结算且`,
  key1006178: `出库状态需为已出库`,
  key1006179: `客户结算状态需为等待结算且`,
  key1006180: `确认支付账单失败，该账单已作废`,
  key1006181: `分类ID已存在`,
  key1006182: `父节点不存在`,
  key1006183: `分类名称不能包含->`,
  key1006184: `名称不能包含空格`,
  key1006185: `已到最大分类层级`,
  key1006186: `同级分类名已存在`,
  key1006187: `邮箱已经被占用`,
  key1006188: `手机号已经被占用`,
  key1006189: `包裹非已揽收状态`,
  key1006190: `查询不到相关出库单`,
  key1006191: `提单不为已分拣状态`,
  key1006192: `存在多个包裹`,
  key1006193: `√`,
  key1006194: `存在非分拣中状态`,
  key1006195: `出库单非已拣货状态`,
  key1006196: `交接单非已装箱状态`,
  key1006197: `已是客户`,
  key1006198: `图形验证码错误`,
  key1006199: `验证码错误`,
  key1006200: `邮箱未注册`,
  key1006201: `页面重复提交`,
  key1006202: `发送短信失败`,
  key1006203: `查询用户失败`,
  key1006204: `手机号为空`,
  key1006205: `密码错误`,
  key1006206: `短信验证码校验错误`,
  key1006207: `图形验证码失效`,
  key1006208: `超过错误次数,请输入图形验证码再重试`,
  key1006209: `重复提交`,
  key1006210: `非当前用户邮箱`,
  key1006211: `非当前用户手机`,
  key1006212: `登录失败,用户不存在`,
  key1006213: `请使用通途管理员账号登录注册分销账户`,
  key1006214: `通途帐号登录自动创建客户帐号成功,审核通过后再访问`,
  key1006215: `当前用户请审核通过之后登录`,
  key1006216: `商品不存在`,
  key1006217: `客户等级已绑定供应商等级`,
  key1006218: `供应商已设置该供应商等级`,
  key1006219: `查询的拣货单编号不存在`,
  key1006220: `品牌名已存在`,
  key1006221: `非草稿状态不能删除`,
  key1006222: `待审核不能修改中文名称,英文名称,标题,图片`,
  key1006223: `不能包含逗号`,
  key1006224: `存在相同属性SKU`,
  key1006225: `SPU或SKU存在重复`,
  key1006226: `变参商品SKU不能与SPU重复`,
  key1006227: `该商品正在被映射商品使用，不能删除`,
  key1006228: `包裹存在待完成的补拣单`,
  key1006229: `sku不存在`,
  key1006230: `异常包裹中存在正常拣货包裹`,
  key1006231: `包裹已作废`,
  key1006232: `标出库失败，此包裹已作废待回收`,
  key1006233: `完成包装失败，包装状态已变更!`,
  key1006234: `不是多品多件拣货单，无法开启分拣作业。`,
  key1006235: `系统每个操作员同一时间只允许开启一个分拣作业`,
  key1006236: `拣货单正在被别人分拣中`,
  key1006237: `已经分拣完成，您可以对该拣货单重新开启分拣作业。`,
  key1006238: `存在已进行普通打印出库单`,
  key1006239: `拣货单未打印，不能开始拣货复核`,
  key1006240: `拣货单已完成`,
  key1006241: `拣货单下不存在出库单`,
  key1006242: `拣货单下不存在有效出库单`,
  key1006243: `拣货单下存在商品备注`,
  key1006244: `拣货单下存在普通打印出库单`,
  key1006245: `拣货单未进行多品分拣`,
  key1006246: `拣货单下存在出库单未下发物流商成功`,
  key1006247: `拣货单拣货未完成`,
  key1006248: `分拣仅支持销售出库拣货单`,
  key1006249: `sku与upc不可重复`,
  key1006250: `upc已存在`,
  key1006251: `UPC存在重复`,
  key1006252: `存在重复SKU`,
  key1006253: `非销售出库单拣货单不支持拣货复核`,
  key1006254: `包裹已截留`,
  key1006255: `用户正在包装其他拣货单`,
  key1006256: `未找到可以分配的库存，无法分配`,
  key1006257: `包裹已出库`,
  key1006258: `包裹信息不存在`,
  key1006259: `箱号不能为空`,
  key1006260: `sku不能为空`,
  key1006261: `未找到对应出库单`,
  key1006262: `出库单号错误`,
  key1006263: `SKU不存在在`,
  key1006264: `拣货单不存在`,
  key1006265: `清空扫描失败，该包裹已经扫描完成`,
  key1006266: `装箱数量错误`,
  key1006267: `该拣货单下只有一个包裹，不能移除`,
  key1006268: `拣货单已完成拣货复核,无法重新分拣`,
  key1006269: `仓库正在配货中，请稍后再试`,
  key1006270: `包裹没有待补拣的货品`,
  key1006271: `入库单已关闭,不能进行收货!`,
  key1006272: `当前包裹不是已打印状态`,
  key1006273: `扫描的sku数量与应包装数量不一致`,
  key1006274: `未匹配合适出库单`,
  key1006275: `无效key`,
  key1006276: `扫描sku数量与身份码数量不一致`,
  key1006277: `当前提单状态不是装箱中不可以继续装箱`,
  key1006278: `结束分拣的拣货单分拣状态不是分拣中,不能结束分拣`,
  key1006279: `已拣货的拣货单不能再次标记为已拣货`,
  key1006280: `包裹状态已变更,操作异常`,
  key1006281: `提单号不存在`,
  key1006282: `提单非等待揽收/正在装箱状态,`,
  key1006283: `不能删除`,
  key1006284: `当前属性正在被引用，不可删除`,
  key1006285: `同名属性已存在`,
  key1006286: `请停售被删除的SKU后重试`,
  key1006287: `该SKU有库存不允许删除`,
  key1006288: `SKU已存在`,
  key1006289: `订单已作废`,
  key1006290: `存在非等待揽收状态`,
  key1006291: `提单揽收包裹数量不统一`,
  key1006292: `属性不合法`,
  key1006293: `颜色的中文名称已存在`,
  key1006294: `库区编码重复`,
  key1006295: `库区名称重复`,
  key1006296: `仓库名称重复`,
  key1006297: `仓库编码重复`,
  key1006298: `该`,
  key1006299: `订单已换单无法取消`,
  key1006300: `操作人已完成拣货，请勿重复提交`,
  key1006301: `已分拣完成处于带包装状态，请勿重复提交`,
  key1006302: `已经撤销成功，请勿重复提交`,
  key1006303: `库位编号重复`,
  key1006304: `拣货单正在分拣`,
  key1006305: `没有匹配到待包装`,
  key1006306: `当前拣货单状态为非待拣货/拣货中状态`,
  key1006307: `拣货单不是待包装或者包装中`,
  key1006308: `状态已变更,请刷新后重试!`,
  key1006309: `只有已换单的包裹才可以扫描`,
  key1006310: `分类下存在商品`,
  key1006311: `分类下存在子分类`,
  key1006312: `重量不能为空`,
  key1006313: `剩下一个sku时`,
  key1006314: `不可以删除`,
  key1006315: `获取分类信息异常`,
  key1006316: `非物流面单类型不允许标记出库`,
  key1006317: `面单异常`,
  key1006318: `不能映射被删除的属性`,
  key1006319: `存在映射属性，不允许删除`,
  key1006320: `提单已删除`,
  key1006321: `变更的类别下存在映射属性，请先进行映射属性删除`,
  key1006322: `当前充值的客户编号不存在`,
  key1006323: `同一平台下不能存在相同站点/无站点则不能存在相同分类`,
  key1006324: `货品没有图片信息，请及时上传`,
  key1006325: `货品没有颜色图片信息，请及时上传`,
  key1006326: `物流商不存在`,
  key1006327: `物流商账号名称已存在`,
  key1006328: `平台帐号已停用`,
  key1006329: `服务名称或者编号重复`,
  key1006330: `物流商代码已存在`,
  key1006331: `存在相同名称的自动规则`,
  key1006332: `货代物理关联重复`,
  key1006333: `店铺编号已存在`,
  key1006334: `店铺名称已存在`,
  key1006335: `店铺名称和编号已存在`,
  key1006336: `已授权店铺不能切换店铺`,
  key1006337: `订单状态异常`,
  key1006338: `没有满足`,
  key1006339: `物流特殊规则的`,
  key1006340: `物流范围规则的`,
  key1006341: `取消订单异常`,
  key1006342: `更新用户信息异常`,
  key1006343: `更新物流信息异常`,
  key1006344: `创建订单失败`,
  key1006345: `订单已下单`,
  key1006346: `包裹已经出库`,
  key1006347: `正在处理下发物流操作`,
  key1006348: `取消物流失败`,
  key1006349: `供应商系统作废子单失败`,
  key1006350: `查询指定货品信息为空`,
  key1006351: `手工下单失败`,
  key1006352: `生成发货信息失败`,
  key1006353: `物流渠道停用失败，已关联`,
  key1006354: `物流无法停用`,
  key1006355: `物流渠道代码不可重复`,
  key1006356: `该包裹不处于等待出库状态`,
  key1006357: `指定拣货单列表为空`,
  key1006358: `中心仓备货的出库单已经生成拣货单，不允许作废`,
  key1006359: `面单正在拣货复核中`,
  key1006360: `出库单不是`,
  key1006361: `中心仓备货的出库单`,
  key1006362: `存在已出库的供应商子单`,
  key1006363: `没有找到sku对应的货品信息`,
  key1006364: `中心仓备货拣货单正在被扫描`,
  key1006365: `同类型通知消息模板已存在`,
  key1006366: `商户未设置刊登地址`,
  key1006367: `角色名称已存在`,
  key1006368: `角色在使用中`,
  key1006369: `未设置默认客户等级`,
  key1006370: `出库单已标缺货`,
  key1006371: `供应商未进行直接发货设置，不允许直接发货`,
  key1006372: `当前容器入库状态不是待换单`,
  key1006373: `同名容器类型已存在`,
  key1006374: `当前容器类型已被绑定`,
  key1006375: `容器与提单不在同一个仓库`,
  key1006376: `分拣容器中不存在该`,
  key1006377: `容器不存在`,
  key1006378: `容器已被绑定`,
  key1006379: `容器状态异常或已变更`,
  key1006380: `容器为空，不可结束分拣`,
  key1006381: `提单分拣数量异常，存在未完成分拣的sku或运单`,
  key1006382: `当前容器入库状态不是换单中`,
  key1006383: `订单已被分拣`,
  key1006384: `提单正在分拣`,
  key1006385: `容器状态不存在`,
  key1006386: `提单状态需为已入库或分拣中`,
  key1006387: `存在分拣中提单`,
  key1006388: `该提单其他用户正在分拣中`,
  key1006389: `已绑定同类型容器`,
  key1006390: `请完成当前的容器贴单作业`,
  key1006391: `容器正在贴单`,
  key1006392: `请完成当前的容器上架作业`,
  key1006393: `容器正在上架`,
  key1006394: `存在处理中的容器`,
  key1006395: `分拣交接单中已存在该`,
  key1006396: `订单需要先走商品分拣`,
  key1006397: `订单已发货，不可被移除`,
  key1006398: `正在处理下发物流,请稍后再试`,
  key1006399: `当前尺码类型正在被使用`,
  key1006400: `模板已被使用，不允许删除`,
  key1006401: `部位中文名称已被使用，不允许修改`,
  key1006402: `尺码值已存在`,
  key1006403: `规格属性已被使用，不允许删除`,
  key1006404: `规格属性已被使用，不允许修改`,
  key1006405: `当前尺码值,正在被使用,不允许删除`,
  key1006406: `主属性的规格属性已被使用，不允许修改`,
  key1006407: `导出模板异常`,
  key1006408: `聚水潭商户未授权`,
  key1006409: `未添加聚水潭商户`,
  key1006410: `未找到已勾选同步库存并且已启用的聚水潭商户`,
  key1006411: `Sku值为空`,
  key1006412: `不是在售的spu不能停售`,
  key1006413: `上架数量与拣货数量不一致`,
  key1006414: `导出账单信息为空`,
  key1006415: `手机未注册`,
  key1006416: `SPU不能包含中文`,
  key1006417: `当前尺码属性模板正在被使用`,
  key1006418: `已存在相同名称的属性，请修改属性名称后再提交`,
  key1006419: `没有创建仓库，请先创建仓库`,
  key1006420: `该订单已取消，请在线下补货到中心仓`,
  key1006421: `已装箱的出库单不能作废`,
  key1006422: `存在多个主属性`,
  key1006423: `未满足重复刊登条件`,
  key1006424: `入驻商户不存在`,
  key1006425: `密码校验错误`,
  key1006426: `未查询到收款人信息`,
  key1006427: `拣货单不是待分拣或者分拣中`,
  key1006428: `拣货单已取消`,
  key1006429: `拣货单不处于包装中`,
  key1006430: `正在装箱的出库单不能作废`,
  key1006431: `活动结束时间不能小于活动开始时间`,
  key1006432: `该商品已经存在其他活动中`,
  key1006433: `未找到商品对应类型的容器，请绑定后重新扫描。`,
  key1006434: `暂未授权WishPost，请在自有物流管理中授权并关联店铺后重试`,
  key1006435: `订单对应店铺暂未关联WishPost账户，请在自有物流管理中关联后重试`,
  key1006436: `WishPost授权无效，请在自有物流管理中重新授权后重试`,
  key1006437: `店铺已关联其他账号`,
  key1006438: `关联店铺未启用`,
  key1006439: `同名问卷已存在`,
  key1006440: `当前问卷下同名问题已存在`,
  key1006441: `仓库已关联供应商`,
  key1006442: `扫描包裹与交接单发货仓库不相同`,
  key1006443: `1688平台未授权店铺`,
  key1006444: `链接不存在`,
  key1006445: `币种配置已存在`,
  key1006446: `此拣货单为常规单，请至常规包装模块进行作业`,
  key1006447: `此拣货单为备货拣货单，请至备货分拣进行作业`,
  key1006448: `客户暂未执行发货，请下轮作业重试`,
  key1006449: `未查到相关小包信息`,
  key1006450: `当前小包已经被分拣扫描`,
  key1006451: `存在未包装完成小包`,
  key1006452: `存在未被物流分拣小包,请分拣完成后再结袋`,
  key1006453: `修改订单同步状态为同步中失败`,
  key1006454: `物流交接单所在仓未授权,请申请对应仓库权限`,
  key1006455: `交接单不存在`,
  key1006456: `请先开通Temu虚拟物流`,
  key1006457: `平台订单已存在`,
  key1006458: `物流商单号已存在`,
  key1006459: `已充值币种不可修改`,
  key1006460: `创建商品后币种不可修改`,
  key1006461: `正在进行中的作业不是分拣中或包装中`,
  key1006462: `扫描的sku数量超出拣货单的应拣数量`,
  key1006463: `已存在商品使用此属性作为规格属性，不可删除`,
  key1006464: `还原库存失败`,
  key1006465: `账单已经生成，补贴审核失败`,
  key1006466: `物流仅支持绑定一条渠道`,
  key1006467: `上传包裹失败`,
  key1006468: `获取面单失败`,
  key1006469: `仓库地址不存在`,
  key1006470: `物流未关联基础物流`,
  key1006471: `物流商获取token失败`,
  key1006472: `不允许扫描temu出库单`,
  key1006473: `速卖通oaid不存在`,
  key1006474: `解密失败`,
  key1006475: `账单已经生成，补贴重新审核失败`,
  key1006476: `打印所选订单条码异常`,
  key1006477: `打印sku条码异常`,
  key1006478: `存在非缺货中的货品无法标记到货`,
  key1006479: `无此商品订单，请核对订单`,
  key1006480: `提单非等待揽收状态`,
  key1006481: `出库单已出库,不可进行其他操作`,
  key1006482: `当前订单中存在出库单为非待发货状态的订单`,
  key1006483: `此拣货单内只有一个订单，此拣货单无需分拣，请进行备货包装作业`,
  key1006484: `此拣货单为常规单，请至常规分拣进行作业`,
  key1006485: `此拣货单为备货单，请至备货分拣进行作业`,
  key1006486: `追加货品，同步运营提单失败`,
  key1006487: `当前订单中存在出库单为缺货状态的订单`,
  key1006488: `中间标签码不可为空`,
  key1006489: `无发货订单`,
  key1006490: `该拣货单存在在未整单完结的`,
  key1006491: `手工订单和平台订单不可同时执行发货`,
  key1006492: `货品订单和样品订单不可同时执行发货`,
  key1006493: `不同店铺的订单不可同时执行发货`,
  key1006494: `不同收货地址的订单不可同时执行发货`,
  key1006495: `该订单存在未结袋的小包`,
  key1006496: `订单已经完结,不可以再次扫描`,
  key1006497: `手动修改的扫描数必须是大于0的正整数`,
  key1006498: `所选出库单批次下出库单超出最大限制，请调整最大包裏数`,
  key1006499: `每张拣货单最大出库单数不能为空或小于1`,
  key1006500: `拣货单正在被其他用户作业，不可再次作业`,
  key1006501: `temu_s拣货单存在未打印`,
  key1006502: `提单与容器所属仓库不同,请检查后重试`,
  key1006503: `非待匹配商品或匹配物流失败或下单失败状态，无法标记为非`,
  key1006504: `订单操作`,
  key1006505: `同系统同模块下已存在该标题的标题指引，请重新定义标题`,
  key1006506: `物流/基础物流不能同时传`,
  key1006507: `物流/基础物流必传其一`,
  key1006508: `基础物流trackingNumber,labelPath必传`,
  key1006509: `sku已停售`,
  key1006510: `商品发货仓库匹配到多个，请拆分平台订单后，再下单`,
  key1006511: `匹配仓库失败`,
  key1006512: `商品可用库存为空或不合法`,
  key1006513: `账号未绑定店铺，或者账号已过期`,
  key1006514: `同名客户标签已存在`,
  key1006515: `该客户分组名已存在`,
  key1006516: `该用户已开启其他拣货作业`,
  key1006517: `手机已绑定,不支持更新手机`,
  key1006518: `商品所属类目未映射平台`,
  key1006519: `获取供应商币种接口失败`,
  key1006520: `SPU存在重复，请修改`,
  key1006521: `查询三方平台订单失败`,
  key1006522: `支付宝支付订单失败`,
  key1006523: `新增充值记录失败`,
  key1006524: `获取分销商币种失败`,
  key1006525: `未查询到可操作订单`,
  key1006526: `回传发货状态失败`,
  key1006527: `店铺地址为空`,
  key1006528: `物流交接单内订单为空`,
  key1006529: `已换单后不允许多品分拣移除拣货单`,
  key1006530: `未申请预约单`,
  key1006531: `容器类型已被使用，请删除已使用此类型的容器后，再编辑此容器类型`,
  key1006532: `未找到物流追踪记录`,
  key1006533: `未匹配到有效物流渠道`,
  key1006534: `存在下单失败的`,
  key1006535: `订单,请完成后再更换商品`,
  key1006536: `客户等级名称已存在,请更换后重试`,
  key1006537: `已装箱后不允许修改买家收件地址`,
  key1006538: `邮箱注册方式邮箱不可为空`,
  key1006539: `邮箱和手机至少需要填写一个`,
  key1006540: `此物流渠道暂不可用，详情请咨询客服`,
  key1006541: `已选商品正在生成资料中`,
  key1006542: `生成售卖资料失败`,
  key1006543: `已存在供应商账号`,
  key1006544: `请重新登录`,
  key1006545: `已存在分销商账号，请重新登录`,
  key1006546: `暂未开通供应商账号权限，请联系主账号开通，如有疑问，请联系在线客服咨询！`,
  key1006547: `html类型面单只允许单个打印`,
  key1006548: `销售价小于或等于0`,
  key1006549: `标记缺货的时候，缺货数量必须大于1`,
  key1006550: `该账号为运营商系统账号，请更换账号再试`,
  key1006551: `此发货仓已有此类型费用配置，请检查后重试`,
  key1006552: `未设置仓库处理费`,
  key1006553: `固定计费未设置常规或多品订单处理费`,
  key1006554: `分层计费未设置分层价或多频订单处理费`,
  key1006555: `当前勾选的账单存在已被确认过的账单`,
  key1006556: `此规格属性已被使用，不可修改为普通属性`,
  key1006557: `存在相同模板名称的地址薄`,
  key1006558: `备注内容过长,添加失败`,
  key1006559: `上架工具权限授权更新失败`,
  key1006560: `备货单所在提单已装箱`,
  key1006561: `同平台配置已存在`,
  key1006562: `未配置指定平台`,
  key1006563: `未配置指定店铺`,
  key1006564: `未配置刊登模板`,
  key1006565: `店铺token失效`,
  key1006566: `未设置超时时间`,
  key1006567: `请先开通速卖通全托管物流`,
  key1006568: `IOSS编码格式不正确`,
  key1006569: `目的国税率设置已存在`,
  key1006570: `同名IOSS配置已存在`,
  key1006571: `其中有原料正在被组装商品使用，不能删除`,
  key1006572: `平台订单IOSS已录入`,
  key1006573: `物流代缴，清关费用不可为空`,
  key1006574: `此物流渠道已停用,请重新选择物流渠道`,
  key1006575: `存在已经生成售卖资料的数据`,
  key1006576: `分销商备货单不是待支付状态`,
  key1006577: `分销商取消备货单货品数量校验异常`,
  key1006578: `分销商备货单不是待调价状态`,
  key1006579: `分销商备货单不是供应商待接价状态`,
  key1006580: `分配备货单上架库存失败`,
  key1006581: `设置组装商品中包含已删除货品`,
  key1006582: `设置组装商品中原材料包含组装货品`,
  key1006583: `组装商品原材料不能和当前商品一致`,
  key1006584: `存在相同名称的导出模板`,
  key1006585: `未配置组装商品虚拟库位`,
  key1006586: `组装商品原料及成品出入库失败`,
  key1006587: `物流交接单非分拣中状态`,
  key1006588: `可操作单数量与选择不一致`,
  key1006589: `没有匹配买家邮政编码的`,
  key1006590: `可操作单数量于选择不一致`,
  key1006591: `保存申报信息失败`,
  key1006592: `不支持的库存操作`,
  key1006593: `库存操作失败`,
  key1006594: `调接口获取供应商数据失败`,
  key1006595: `当前提单非自有物流且处在等待揽收状态，不可修改物流`,
  key1006596: `货品分配数失败`,
  key1006597: `撤回`,
  key1006598: `调速卖通创建揽收接口失败`,
  key1006599: `打印揽收面单图片访问失败`,
  key1006600: `调速卖通全托管打印揽收面单接口失败`,
  key1006601: `速卖通预约交货接口调用API异常`,
  key1006602: `超出此物流尺寸限制，请更换物流后重试`,
  key1006603: `超出此物流重量限制，请更换物流后重试`,
  key1006604: `超出此物流配送范围，请更换物流后重试`,
  key1006605: `待接单状态无法匹配商品`,
  key1006606: `不同目的仓订单请分开发货`,
  key1006607: `由于货品数量过多，请在详情中进行单个打印`,
  key1006608: `商品属性未填写属性占比`,
  key1006609: `当前交接单内存在已取消的包裹，请移除已取消包裹后重试`,
  key1006610: `存在非已装箱的订单`,
  key1006611: `此商品已停售，`,
  key1006612: `仓库存数量小于订单货品数量，暂无法下单`,
  key1006613: `生成拣货单失败`,
  key1006614: `存在分销商未取消订单，请稍后再试`,
  key1006615: `当前物流不可达，请取消订单后重新下单`,
  key1006616: `暂未开通分销商账号权限，请联系主账号开通，如有疑问，请联系在线客服咨询！`,
  key1006617: `更换收货地址取消`,
  key1006618: `订单异常`,
  key1006619: `更换收货地址失败，订单已取消`,
  key1006620: `存在重复快递单号，此包裹请通过提单号进行扫描`,
  key1006621: `非等待揽收状态不可作废`,
  key1006622: `作废供应商提单失败`,
  key1006623: `站点名称已经被使用，请更改其他站点名`,
  key1006624: `仅支持已创建、备货中订单进行取消，请刷新数据查看`,
  key1006625: `未设置云卖物流规则`,
  key1006626: `回传样品发货信息失败`,
  key1006627: `获取大仓收货地址失败`,
  key1006628: `商品条码查询失败`,
  key1006629: `查询寄样单列表`,
  key1006630: `未匹配到发货方式`,
  key1006631: `物流代缴，清关费不可为空`,
  key1006632: `无有效的发货信息`,
  key1006633: `平台单不存在交易信息`,
  key1006634: `订单已发货`,
  key1006635: `出库单非已出库状态`,
  key1006636: `商品非待审核状态`,
  key1006637: `已关注该店铺`,
  key1006638: `商品分类信息不存在`,
  key1006639: `已取消收藏`,
  key1006640: `当前待上架提单不是已入库状态`,
  key1006641: `重置包裹状态失败`,
  key1006642: `未匹配到仓库，请联系客服处理`,
  key1006643: `容器非货品分拣完成状态`,
  key1006644: `已经处于完成上架状态，请勿重复操作`,
  key1006645: `该货品已经分拣完成`,
  key1006646: `未设置刊登规则`,
  key1006647: `未匹配到库区，请联系客服处理`,
  key1006648: `SKU与货位号不匹配，请检查后重试`,
  key1006649: `SKU已完拣`,
  key1006650: `账单不是付款状态`,
  key1006651: `必传其一`,
  key1006652: `供应商未设置归属仓库`,
  key1006653: `未完全分配ymsWarehouseLocation`,
  key1006654: `速卖通线上发货UserNick必传`,
  key1006655: `包裹所在仓未授权`,
  key1006656: `物流交接单仓库与包裹仓库不匹配`,
  key1006657: `物流交接单仓库与包裹物流不一致`,
  key1006658: `缺少包裹`,
  key1006659: `物流商编号已存在`,
  key1006660: `三方平台不存在`,
  key1006661: `获取店铺的发货,退货地址失败`,
  key1006662: `当前云卖订单未发货`,
  key1006663: `当前订单物流方式不是云卖物流`,
  key1006664: `速卖通打印面单接口失败`,
  key1006665: `单次最多可处理200个包裹，请移出后再结袋`,
  key1006666: `分销商备货单已经支付，不可再次支付`,
  key1006667: `分类不存在`,
  key1006668: `订单编号查询订单Id集合异常`,
  key1006669: `包裹信息保存失败`,
  key1006670: `获取物流商异常`,
  key1006671: `打印面单异常`,
  key1006672: `订单已经存在包裹`,
  key1006673: `物流商帐号不存在`,
  key1006674: `存在未回收包裹`,
  key1006675: `包裹订单数据异常`,
  key1006676: `获取订单异常`,
  key1006677: `更新订单打印时间失败`,
  key1006678: `发货系统包裹数据与订单系统数据不一致`,
  key1006679: `出库单下发物流未成功`,
  key1006680: `出库单标记打印失败,部分包裹已经进行其他打印方式`,
  key1006681: `包裹未交运`,
  key1006682: `无效的可用库存数请输入大于0可用库存数`,
  key1006683: `发货台内当前无商品，无法打印配货清单`,
  key1006684: `修改备货单货品数量失败`,
  key1006685: `当前提单非等待揽收状态，不可修改物流`,
  key1006686: `当前提单非自有物流，不可修改物流`,
  key1006687: `获取仓库异常`,
  key1006688: `更新包裹包装状态异常`,
  key1006689: `用户没有开启分拣时打印地址面单`,
  key1006690: `存在商品在使用该品牌，不能删除!`,
  key1006691: `提交存在相同属性`,
  key1006692: `变参商品空属性`,
  key1006693: `属性key不一致`,
  key1006694: `删除SKU异常`,
  key1006695: `上传图片路径为空`,
  key1006696: `普通商品spu与sku必须一致`,
  key1006697: `变参商品属性不能为空`,
  key1006698: `特性标签包含逗号`,
  key1006699: `非变参不能添加格外sku`,
  key1006700: `spu不能为空`,
  key1006701: `cnName不能为空`,
  key1006702: `分类ID不能为空`,
  key1006703: `productGoodsList不能为空`,
  key1006704: `sku未匹配`,
  key1006705: `请求参数为空`,
  key1006706: `取消分配失败`,
  key1006707: `取消分配失败出库单不存在`,
  key1006708: `取消分配失败已发货、已取消、已生成拣货单或已拣货的不可取消`,
  key1006709: `取消分配失败删除拣货单失败`,
  key1006710: `取消分配失败还原原库存数据`,
  key1006711: `(分配之前`,
  key1006712: `)失败`,
  key1006713: `取消分配失败修改库存产品明细数据失败`,
  key1006714: `(已分配数量`,
  key1006715: `取消分配失败产品分配记录删除失败`,
  key1006716: `操作出库单失败`,
  key1006717: `明细数据为空`,
  key1006718: `未找到出库单`,
  key1006719: `操作货品失败`,
  key1006720: `拣货状态不正确`,
  key1006721: `发货状态状态不正确`,
  key1006722: `物流地址为空，请填写物流地址`,
  key1006723: `地址不匹配，请联系在线客服咨询`,
  key1006724: `调用支付宝转账异常`,
  key1006725: `同步平台地址失败`,
  key1006726: `仅待审核客户可进行审核，请重新选择`,
  key1006727: `速卖通接口异常`,
  key1006728: `pms物流账号token为空`,
  key1006729: `包裹号为空`,
  key1006730: `商品链接为空`,
  key1006731: `物流取消异常`,
  key1006732: `获取ioss税率配置失败`,
  key1006733: `当前订单不是手工单`,
  key1006734: `未查询到客户编号所对应的联系人`,
  key1006735: `未查询到库存统计表中要更新的数据YmsInventoryWarehouse`,
  key1006736: `修改alibabagj收货地址失败`,
  key1006737: `当前订单中存在出库单为非尾程物流的POD订单`,
  key1006738: `确认订单付款报错，接口异常`,
  key1006739: `阿里国际平台单非手工取消`,
  key1006740: `发货重试`,
  key1006741: `未找到已匹配商品的分销商平台订单`,
  key1006742: `wms发货失败`,
  key1006743: `未找到符合订单条件的云卖物流`,
  key1006744: `wms取消失败`,
  key1006745: `仓库为空`,
  key1006746: `当前拣货单中包含状态为非`,
  key1006747: `待拣货/拣货中`,
  key1006748: `包裹下的ordershipingid已经存在`,
  key1006749: `获取发货设置异常`,
  key1006750: `出库单列表数量无效`,
  key1006751: `分配库存异常`,
  key1006752: `未出库的包裹`,
  key1006753: `更新账单和出库单状态失败`,
  key1006754: `同一平台下不能存在相同站点`,
  key1006755: `请确认商户号是否正确`,
  key1006756: `店铺不存在`,
  key1006757: `拣货单正在包装中`,
  key1006758: `拣货单不是待拣货或拣货中`,
  key1006759: `当前云卖面单正在拣货复核中`,
  key1006760: `商品状态错误`,
  key1006761: `拣货单状态异常`,
  key1006762: `获取包装设置异常`,
  key1006763: `未匹配到指定云卖订单`,
  key1006764: `未获取面单异常`,
  key1006765: `库区名称name重复`,
  key1006766: `库区库位不能都为null`,
  key1006767: `可用库存不能小于0`,
  key1006768: `商品生成面单标签异常`,
  key1006769: `生成云卖面单异常`,
  key1006770: `无效的仓储库存信息ID`,
  key1006771: `仅支持对待审核状态账单进行批量审核，请检查后重试`,
  key1006772: `仅支持对待打款状态账单进行批量审核，请检查后重试`,
  key1006773: `无此API权限，请联系客服开通`,
  key1006774: `备货单拆单失败`,
  key1006775: `订单同名标签已存在`,
  key1006776: `此订单中含有特殊需加工商品，无法直接取消，若需取消请联系客服`,
  key1006777: `同名商品标签已存在`,
  key1006778: `同名订单导入模板已存在`,
  key1006779: `存在相同Excel文件标题`,
  key1006780: `编辑商品`,
  key1006781: `尺码表模板`,
  key1006782: `新增/编辑尺码表模板`,
  key1006783: `待审核列表`,
  key1006784: `审核/编辑商品`,
  key1006785: `商品设置`,
  key1006786: `供应商订单详情`,
  key1006787: `分销商备货单`,
  key1006788: `供应商备货单`,
  key1006789: `供应商备货单详情`,
  key1006790: `入库管理`,
  key1006791: `提单管理`,
  key1006792: `提单管理详情`,
  key1006793: `查看出库单详情`,
  key1006794: `拣货单列表`,
  key1006795: `见货出单设置`,
  key1006796: `物流商交接单管理`,
  key1006797: `物流商交接单管理详情`,
  key1006798: `供应商管理`,
  key1006799: `供应商列表`,
  key1006800: `供应商审核`,
  key1006801: `供应商等级管理`,
  key1006802: `客户管理`,
  key1006803: `客户列表`,
  key1006804: `拓客链接`,
  key1006805: `供应商账单`,
  key1006806: `供应商账单详情`,
  key1006807: `客户账单详情`,
  key1006808: `物流账单详情`,
  key1006809: `仓储账单详情`,
  key1006810: `密码管理`,
  key1006811: `物流管理`,
  key1006812: `基础物流`,
  key1006813: `智能物流规则`,
  key1006814: `仓库管理`,
  key1006815: `库区管理`,
  key1006816: `库位管理`,
  key1006817: `消息模板`,
  key1006818: `容器管理`,
  key1006819: `容器类型设置`,
  key1006820: `地址库管理`,
  key1006821: `出入库流程配置`,
  key1006822: `IOSS税率设置`,
  key1006823: `申报规则`,
  key1006824: `自动分仓规则`,
  key1006825: `内容管理`,
  key1006826: `问卷调查`,
  key1006827: `编辑问卷`,
  key1006828: `操作指引`,
  key1006829: `数据中心`,
  key1006830: `营销管理`,
  key1006831: `站点管理`,
  key1006832: `活动管理`,
  key1006833: `新增/编辑活动`,
  key1006834: `广告管理`,
  key1006835: `分销商费用配置`,
  key1006836: `消息、邮箱查看`,
  key1006837: `员工管理`,
  key1006838: `任务中心`,
  key1006839: `导入任务`,
  key1006840: `导出任务`,
  key1006841: `订单中心`,
  key1006842: `订单列表`,
  key1006843: `备货单详情`,
  key1006844: `发货记录`,
  key1006845: `发货记录详情`,
  key1006846: `商品中心`,
  key1006847: `新增/编辑商品`,
  key1006848: `品牌管理`,
  key1006849: `第三方平台`,
  key1006850: `平台授权`,
  key1006851: `对账管理`,
  key1006852: `账户管理`,
  key1006853: `收款账户`,
  key1006854: `出库单详情`,
  key1006855: `提货单`,
  key1006856: `拣货作业`,
  key1006857: `出库作业`,
  key1006858: `出库作业设置`,
  key1006859: `商城`,
  key1006860: `分销市场`,
  key1006861: `收藏的商品`,
  key1006862: `生成售卖记录`,
  key1006863: `自动规则`,
  key1006864: `店铺管理`,
  key1006865: `收藏的供应商`,
  key1006866: `平台订单详情`,
  key1006867: `添加Temu订单`,
  key1006868: `订单导入模板`,
  key1006869: `权限管理`,
  key1006870: `角色管理`,
  key1006871: `用户管理`,
  key1006872: `价格变动详情`,
  key1006873: `账号管理`,
  key1006874: `安全信息`,
  key1006875: `商品、店铺列表`
}
