
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';
import axios from 'axios';
import Vue from 'vue';
import {removeToken} from '@/utils/cookie';
import api from '@/api/';
import {Message, Modal} from 'view-design';
import {getToken} from '@/utils/cookie';
import supplierRouter from "@/views/supplier/router";
import {getHrefPath, errorUrlHandel, deleteTicket, setConfigCharacters} from "@/utils/common";

Vue.component('Message', Message);
Vue.component('Modal', Modal);

function logoutFun() {
  axios.get(api.get_logout + getToken()).then(response => {
    if (response.data.code === 0) {
      removeToken().then(() => {
        let url = deleteTicket(window.location.href);
        url = errorUrlHandel(url);
        window.location.href = response.data.others.logOutUrl + encodeURIComponent(url);
      });
    }
  });
}

export const showNoAuth = () => {
  document.getElementById('app').innerHTML = '';
  Modal.warning({
    title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001209'),
    transfer: true,
    zIndex: 1000,
    content: '<p>' + alias93cb5d9a0adb49f980c05a290e356da0.t('key1001210') + '</p><p>' + alias93cb5d9a0adb49f980c05a290e356da0.t('key1001211') + '</p>',
    okText: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000073'),
    onOk: () => {
      logoutFun();
    }
  });
};

// 待确认账单的提示框
export const showBillConfirmed = () => {
  Modal.confirm({
    title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001212'),
    transfer: true,
    zIndex: 1000,
    content: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001213'),
    okText: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001214'),
    cancelText: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001215'),
    onOk: () => {
      supplierRouter.push('/reconciliationManage');
    }
  });
};

// 供应商停用提示框

export const supplierDeactivationPrompt = () => {
  Modal.warning({
    title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001216'),
    transfer: true,
    zIndex: 1000,
    content: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001203')}${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001204')}`,
    okText: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000139'),
    onOk: () => {
      logoutFun();
    }
  });
};

// 校验当前登录到供应商系统之后判断当前商户是否是没有通过审核，如果没有通过审核自动跳转到商城--供应商入驻页面
export const auditSuppliers = (message) => {
  let merchantId = null;
  if (message) {
    const match = message.match(/\d+/); // 使用正则表达式匹配数字部分
    if (match) {
      merchantId = match[0];
    } else {
      console.log('获取供应商商户号失败！');
    }
    if (merchantId) {
      let url = `/login.html#/supplierSettlement?supplierMerchantId=${merchantId}`;
      let service = '/yms-shopping-service';
      window.location.href = getHrefPath(url, service);
    }
  }
};

/**
 * 当前登录商户不是分销商，弹出是否要开通分销商的确认弹窗
 * 当前登录分销商系统商户是通途商户，弹出是否要开通分销商的确认弹窗
 * 当前登录商户不是供应商，弹出是否要开通供应商的确认弹窗
 */

export const becomeDistributor = (type) => {
  let title = '';
  let okText = '';
  let content = '';
  switch (type) {
    case'supplier':
      title = alias93cb5d9a0adb49f980c05a290e356da0.t('key1001217');
      okText = alias93cb5d9a0adb49f980c05a290e356da0.t('key1001218');
      content = `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001205')}${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001206')}${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001207')}`;
      break;
    case 'distributor':
      title = alias93cb5d9a0adb49f980c05a290e356da0.t('key1001219');
      okText = alias93cb5d9a0adb49f980c05a290e356da0.t('key1001220');
      content = `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001205')}${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001206')}${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001208')}`;
      break;
    case 'tongTool':
      title = alias93cb5d9a0adb49f980c05a290e356da0.t('key1001219');
      okText = alias93cb5d9a0adb49f980c05a290e356da0.t('key1001220');
      content = alias93cb5d9a0adb49f980c05a290e356da0.t('key1001221');
      break;
    }

  Modal.confirm({
    title: title,
    transfer: true,
    zIndex: 1000,
    render: (h) => {
      return h('div', [
        h('becomingSupplierDistributorModal', {
          props: {
            systemType: type,
            content: content,
            okText: okText,
            $t: Vue.t,
          }
        })
      ]);
    }
  });
  setTimeout(() => {
    let confirmFooter = document.querySelectorAll('.ivu-modal-confirm-footer');
    confirmFooter[0].style.display = 'none';
  }, 500);
}

// 当前商户没有开通域名站点
export const noDomainNameSite = (message) => {
  Modal.warning({
    title: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000256'),
    transfer: true,
    zIndex: 1000,
    content: `${message}`,
    okText: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000073'),
    onOk: () => {
      logoutFun();
    }
  });
};
