
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 分销商后台管理系统--菜单
 * @author jing
 * @date 2021/1/19 9:49
 * @attribute name 名称
 * @attribute path 路径
 * @attribute icon 图标
 * @attribute children 子菜单
 * @attribute menuKey 菜单key(需要唯一，用于获取权限)
 * @attribute distributorType 分销商类型(ALIGJ: 阿里国际分销、YMS：云卖分销商)
 */
import {setConfigCharacters} from "@/utils/common";

export default {
  menu: [
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006860'),
      icon: '&#xe6b2;',
      path: '/distributionMarket',
      menuKey: 'distributor_distributionMarket',
      distributorType: ['ALIGJ']
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1005683'),
      icon: '&#xe618;',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000843'),
          path: '/myInventory',
          menuKey: 'distributor_myInventory',
          distributorType: ['YMS']
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006861'),
          path: '/commodityList',
          menuKey: 'distributor_commodityList',
          distributorType: ['YMS', 'ALIGJ']
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006862'),
          path: '/generateSalesRecords',
          menuKey: 'distributor_generateSalesRecords',
          distributorType: ['YMS', 'ALIGJ']
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000008'),
          type: 'toListing',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在角色管理中不展示此菜单
          externalLink: true, // 是否跳转到外部系统的标识
          menuKey: 'distributor_shelfTools',
          distributorType: ['YMS', 'ALIGJ']
        }
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006863'),
      icon: '&#xe6fe;',
      path: '/automaticRules',
      menuKey: 'distributor_automaticRules',
      distributorType: ['YMS', 'ALIGJ']
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006864'),
      icon: '&#xe604;',
      path: '/platformStoreList',
      menuKey: 'distributor_platformStoreList',
      distributorType: ['YMS', 'ALIGJ']
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000079'),
      icon: '&#xe63a;',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006865'),
          path: '/collectionSupplier',
          menuKey: 'distributor_collectionSupplier',
          distributorType: ['YMS']
        }
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001117'),
      icon: '&#xe668;',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004516'),
          path: '/platformOrder',
          menuKey: 'distributor_platformOrder',
          distributorType: ['YMS', 'ALIGJ'],
          children: [
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006866'),
              path: '/platformDetails',
              menuKey: 'distributor_platformDetails',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006867'),
              path: '/addTemuOrder',
              menuKey: 'distributor_addTemuOrder',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001406')}`,
          path: '/myOrder',
          menuKey: 'distributor_myOrder',
          distributorType: ['YMS'],
          children: [
            {
              name: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1004969')}`,
              path: '/myOrderDetails',
              menuKey: 'distributor_myOrderDetails',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004994'),
              path: '/addCloudSalesOrder',
              menuKey: 'distributor_addCloudSalesOrder',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004517'),
          path: '/distributorStockList',
          menuKey: 'distributor_distributorStockList',
          distributorType: ['YMS'],
          children: [
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001448'),
              path: '/createBackupOrder',
              menuKey: 'distributor_createBackupOrder',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006843'),
              path: '/distributorStockListDetails',
              menuKey: 'distributor_distributorStockListDetails',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006868'),
          path: '/orderImportTemplateList',
          menuKey: 'distributor_orderImportTemplateList',
          distributorType: ['YMS'],
        }
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001118'),
      icon: '&#xe645;',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002173'),
          path: '/rechargeRecord',
          menuKey: 'distributor_rechargeRecord',
          distributorType: ['YMS'],
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1002822'),
          path: '/financialJournal',
          menuKey: 'distributor_financialJournal',
          distributorType: ['YMS'],
        },
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006811'),
      icon: '&#xe68e;',
      children: [
        {
          name: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1002992')}`,
          path: '/logisticsSettings',
          menuKey: 'distributor_logisticsSettings',
          distributorType: ['YMS'],
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001155'),
          path: '/ownLogistics',
          menuKey: 'distributor_ownLogistics',
          distributorType: ['YMS'],
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006813'),
          path: '/smartRule',
          menuKey: 'distributor_smartRule',
          distributorType: ['YMS'],
        }
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006869'),
      icon: '&#xe61b;',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006870'),
          path: '/roleManage',
          menuKey: 'distributor_roleManage',
          distributorType: ['YMS', 'ALIGJ'],
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006871'),
          path: '/userManage',
          menuKey: 'distributor_userManage',
          distributorType: ['YMS', 'ALIGJ'],
        },
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000286'),
      path: '/newsList',
      menuKey: 'distributor_newsList',
      distributorType: ['YMS', 'ALIGJ'],
      menuHide: true,
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006872'),
          path: '/newsListDetails',
          menuKey: 'distributor_newsListDetails',
          distributorType: ['YMS', 'ALIGJ'],
          menuHide: true
        }
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006873'),
      icon: '&#xe627;',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000613'),
          path: '/basicInformation',
          menuKey: 'distributor_basicInformation',
          distributorType: ['YMS', 'ALIGJ'],
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006874'),
          path: '/securityInformation',
          menuKey: 'distributor_securityInformation',
          distributorType: ['YMS', 'ALIGJ'],
          children: [
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004477'),
              path: '/modifyMobile',
              menuKey: 'distributor_modifyMobile',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004479'),
              path: '/modifyMailbox',
              menuKey: 'distributor_modifyMailbox',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004737'),
              path: '/modifyPassword',
              menuKey: 'distributor_modifyPassword',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1004420'),
          path: '/iossManageList',
          menuKey: 'distributor_iossManageList',
          distributorType: ['YMS'],
        },
      ]
    },
    {
      name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006838'),
      icon: '&#xe6a9;',
      type: 'importTask',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006839'),
          path: '/importTask',
          menuKey: 'distributor_importTask',
          distributorType: ['YMS', 'ALIGJ'],
        },
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006840'),
          path: '/exportTask',
          menuKey: 'distributor_exportTask',
          distributorType: ['YMS', 'ALIGJ'],
        }
      ]
    },
    {
      name: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1006859')}`,
      type: 'ymsShopping',
      children: [
        {
          name: alias93cb5d9a0adb49f980c05a290e356da0.t('key1006875'),
          menuKey: 'shop_shopList',
          distributorType: ['YMS'],
        }
      ]
    }
  ]
};
