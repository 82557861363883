<template>
  <Modal v-model="sellingCountrySettingModal" :title="sellingCountrySettingTitle" :mask-closable="false"
    @on-visible-change="sellingCountrySettingChange">
    <Form :label-width="110" @submit.native.prevent>
      <Row type="flex">
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <FormItem :label="$t('key1000297')" class="required_style_label">
            <Select v-model="countryCodes" filterable multiple>
              <Option v-for="item in sellingCountrySettingList" :value="item.twoCode" :key="item.twoCode">{{ item.name }}</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
    </Form>
    <template #footer>
      <Button @click="sellingCountrySettingModal=false">{{ $t('key1000098') }}</Button>
      <Button type="primary" :disabled="countryCodes.length <=0" @click="sellingCountrySettingBtn">{{ $t('key1000097') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';

export default {
  name: 'sellingCountrySettingModal',
  mixins: [Mixin],
  props: {
    sellingCountrySettingData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    let v = this;
    return {
      sellingCountrySettingModal: false,
      sellingCountrySettingTitle: '',
      sellingCountrySettingList: [],
      countryCodes: [],
      ymsProductId: null,
      sellingCountrySettingType: null,
    }
  },
  methods: {
    // 初始化数据
    initSellingCountrySettingData(type, ymsProductId) {
      let obj = {
        setting: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000298'),
        edit: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000299')
      }
      this.sellingCountrySettingType = type;
      this.sellingCountrySettingTitle = obj[type];
      this.ymsProductId = ymsProductId;
      if (this.sellingCountrySettingData.length > 0) {
        this.sellingCountrySettingList = this.sellingCountrySettingData;
      } else {
        this.getCountrysData().then((data) => {
          this.sellingCountrySettingList = data;
        });
      }
      if (type === 'edit') {
        this.axios.get(api.get_ymsProductSaleCountry_queryYmsProductSaleCountry + ymsProductId).then((response) => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data.length > 0) {
              this.countryCodes = data.map((item) => item.countryCode);
            }
          }
        });
      }
      this.sellingCountrySettingModal = true;
    },
    // 确定
    sellingCountrySettingBtn() {
      let query = {
        countryCodes: this.countryCodes,
        ymsProductIds: Array.isArray(this.ymsProductId) ? this.ymsProductId : [this.ymsProductId]
      }
      this.axios.post(api.post_ymsProductSaleCountry_batchSetSaleCountry, query,
        {loading: true, loadingText: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000213')}).then(response => {
        if (response.data.code === 0) {
          this.sellingCountrySettingModal = false;
          if (this.sellingCountrySettingType !== 'edit') {
            this.$Message.success(alias93cb5d9a0adb49f980c05a290e356da0.t('key1000300'));
          } else {
            this.$Message.success(alias93cb5d9a0adb49f980c05a290e356da0.t('key1000301'));
          }
          this.$emit('updateData', true);
        }
      });
    },
    // 监听弹窗
    sellingCountrySettingChange(value) {
      if (!value) {
        this.countryCodes = [];
        this.ymsProductId = null;
        this.sellingCountrySettingList = [];
      }
    }
  },
  watch: {},
  components: {}
};
</script>

<style lang="less">

</style>
