
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 处理对应code码对应的动态错误信息
 * @author jing
 * @date 2023/7/13 15:52
 * @params:
 */
import {isJSON, isValueEmpty, setConfigCharacters} from "@/utils/common";

// 处理数组数据问题
function handleArrayData(data) {
  if (Array.isArray(data)) {
    return data.join('、');
  } else {
    return data;
  }
}

export const dynamicErrorCodeFun = (data, value) => {
  if (data) {
    let codeObj = {};
    let code = data.code;
    let message = data.others ? data.others.errorMessage : '';
    let jsonTalg = isJSON(message);
    let text = '';
    if (jsonTalg) {
      let obj = JSON.parse(message);
      let ymsWarehouseName = '';
      let originalYmsWarehouseData = localStorage.getItem('originalYmsWarehouseData') ? JSON.parse(localStorage.getItem('originalYmsWarehouseData')) : [];
      if (originalYmsWarehouseData.length > 0) {
        if (obj.ymsWarehouseId) {
          ymsWarehouseName = originalYmsWarehouseData.find(item => item.ymsWarehouseId === obj.ymsWarehouseId).name;
        }
      }
      if (obj.ymsSpus) {
        codeObj['112812'] = `${obj.ymsSpus.join('、')}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001565')}${ymsWarehouseName}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001566')}`
      }
      if (!isValueEmpty(obj.message)) {
        codeObj['1112810'] = `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001567')}${obj.message}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001568')}`
      }
      for (let key in codeObj) {
        if (Number(key) === code) {
          text = codeObj[key]
        }
      }
    } else {
      codeObj = {
        220029: `SKU: ${message} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001569')}`,
        220031: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001570')}${message} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001571')}`,
        220032: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001572')} ${message} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001573')}`,
        1112765: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001574')} ${message}`,
        1112772: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001575')} ${message}`,
        1112777: `${message}`,
        1112778: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001576')} ${message} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001577')}`,
        111294: `${message} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001578')}`,
        111297: `${message}`,
        1112759: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001579')}${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001580')} ${message}`,
        1112764: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001581')} ${message}`,
        1112803: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001582')}${message}`,
        111190: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001583')}${handleArrayData(message)}`,
        112834: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001584')}${message} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001585')}`,
        1112739: `${message}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001586')}`,
        122008: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001587')}${message}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001588')}`,
        1112760: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001589')}${message}`
      }
      for (let key in codeObj) {
        if (Number(key) === code) {
          text = codeObj[key]
        }
      }
    }
    return text;
  }
}