<template>
  <Modal v-model="messageNotificationModal" :closable="false" :title="$t('key1000530')" width="1000"
    :transfer="false" :mask-closable="false" class-name="messageNotificationStyle" @on-visible-change="messageNotificationChange">
    <div class="messageNotification_content" ref="scrollview">
      <div class="messageNotification_box">
        <div class="messageNotification_box_item" v-for="(item,index) in messageNotificationList" :key="index">
          <h2 class="title">{{ (index + 1) + '. ' + item.title }}</h2>
          <p class="text" v-html="item.content" @click="viewrOderDetails($event, item)"></p>
        </div>
      </div>
      <div class="noMoreData" v-if="!showMore">{{ $t('key1000531') }}</div>
    </div>
    <template #footer>
      <Button @click="messageNotificationModal = false">{{ $t('key1000532') }}</Button>
      <Button type="primary" @click="markReadBtn">{{ $t('key1000139') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from "@/components/mixin/common_mixin";
import {getMenuRoutingId, isJSON, pageJump, verifyLoginTicket} from "@/utils/common";

export default {
  name: "messageNotificationModal",
  mixins: [Mixin],
  data() {
    return {
      messageNotificationModal: false,
      messageNotificationList: [],
      pageParam: {
        nextToken: null,
        createdStartTime: null,
        createdEndTime: null,
        hasRead: 0, // 0 未读   1已读
        isPopup: 1, // 只获取设置弹窗提示的信息
        pageNum: 1,
        pageSize: 50
      },
      isScroll: true, //是否可以滚动
      showMore: true, // 是否有更多数据
      isPageVisible: true, // 默认是活跃状态
      messageNotificationTimer: null,
    }
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    }
  },
  mounted() {
    let v = this;
    const scrollview = v.$refs['scrollview'];
    scrollview.addEventListener('scroll', v.scrollChange, true);
    document.addEventListener('visibilitychange', v.handleVisibilityChange);
  },
  created() {
    this.handleMessageNotification();
    verifyLoginTicket().then((val) => {
      if (val) {
        this.getMessageNotificationData();
      }
    });
  },
  methods: {
    // 判断当前页面标签是否处于活跃状态
    handleVisibilityChange() {
      this.isPageVisible = !document.hidden;
    },
    // 获取消息通知的数据
    getMessageNotificationData() {
      let v = this;
      v.isScroll = false;
      let urlObj = {
        yms: api.post_ymsStationNotification_query,
        supplier: api.post_ymsSupplierStationNotification_query,
        distributor: api.post_ymsDistributorStationNotification_query
      }
      /**
       * 通知消息类型 1订单缺货 2备货超时 3拣货超时 4换单超时 5装箱超时 6发货超时 7订单取消 8停售通知 9商品信息修改通知 10价格变动通知
       *              11商品上新通知 12货品多发通知 13商品状态自动同步通知 14在线刊登自动调价通知  15YMS订单自动取消通知 16余额不足通知
       */

      let query = {
        yms: [2, 3, 4, 5, 6],
        supplier: [7, 12], // 订单取消、 多发补单
        distributor: [1, 8, 9, 10, 13, 16]
      }
      let url = urlObj[v.system];
      if (v.system === 'yms') {
        v.pageParam.informationTypeList = query[v.system];
      } else {
        v.pageParam.informationTypeList = query[v.system];
      }
      v.axios.post(url, v.pageParam).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          let newList = data.list || [];
          if (newList.length > 0) {
            if (v.system === 'distributor') {
              v.pageParam.nextToken = newList[newList.length - 1].ymsStationNotificationId;
            }
            newList.map((item) => {
              if (item.informationType === 17) {
                let obj = {};
                let content = item.content;
                if (isJSON(content)) {
                  obj = JSON.parse(content);
                }
                item.content = `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000533')}${obj.supplierSpu}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000534')}${obj.shelvedRejectReason}。`
              }
            });
          }
          v.messageNotificationList = [...v.messageNotificationList, ...newList];
          if (v.messageNotificationList.length > 0) {
            v.messageNotificationModal = true;
            if (v.messageNotificationList.length >= data.total) {
              v.showMore = false;
              v.isScroll = false;
            } else {
              v.showMore = true;
              v.isScroll = true;
            }
          }
        }
      });
    },
    // 定时任务，每隔10分钟调接口查询未读消息的数据
    handleMessageNotification() {
      let v = this;
      if (['/login', '/logout'].includes(v.$route.path)) {
        clearInterval(this.messageNotificationTimer);
      } else {
        v.messageNotificationTimer = setInterval(() => {
          if (v.isPageVisible) {
            let currentTime = v.$uDate.getCurrentTime('fulltime');
            let time = (new Date(currentTime).getTime()) - 10 * 60 * 1000;
            v.pageParam.createdStartTime = v.$uDate.getDateTime(time, 'fulltime');
            v.pageParam.createdEndTime = currentTime;
            v.getMessageNotificationData();
          }
        }, 10 * 60 * 1000)
      }
    },
    // 监听弹窗内的滚动条
    scrollChange(e) {
      let v = this;
      let distanceHeight = 50; // 距离底部50px时，加载更多数据
      let scrollTop = e.target.scrollTop;
      let windowHeight = e.target.clientHeight;
      let scrollHeight = e.target.scrollHeight;
      if ((scrollTop + windowHeight) + distanceHeight >= scrollHeight && v.isScroll) {
        if (v.showMore) {
          v.pageParam.pageNum += 1;
          v.getMessageNotificationData();
        }
      }
    },
    // 关闭弹窗时，移除监听滚动条的事件
    messageNotificationChange(value) {
      if (!value) {
        let v = this;
        const scrollview = this.$refs['scrollview'];
        scrollview.removeEventListener('scroll', v.scrollChange, true);
      }
    },
    // 查看订单详情
    viewrOderDetails(event, item) {
      let v = this;
      let id = null;
      let informationType = item.informationType;
      let targetId = event.target.id;
      switch (v.system) {
        // 运营系统
        case 'yms':
          // 2备货超时、3拣货超时、4换单超时、5装箱超时、6发货超时
          if ([2, 3, 4, 5, 6].includes(informationType)) {
            let id = getMenuRoutingId('/orderList');
            localStorage.setItem('activeName', id);
            let nowDate = new Date();
            let time = new Date();
            let tagTime = time.setMonth(nowDate.getMonth() - 6);
            let updatedStartTime = v.$uDate.getDateTime(tagTime, 'fulltime');
            let updatedEndTime = '';
            // 天
            if (item.triggerType === 'd') {
              let val = nowDate.getDate() - Number(item.triggerValue);
              updatedEndTime = v.$uDate.getDateTime(val, 'fulltime');
            }
            // 小时
            if (item.triggerType === 'h') {
              let val = nowDate.getTime() - (Number(item.triggerValue) * 60 * 60 * 1000);
              updatedEndTime = v.$uDate.getDateTime(val, 'fulltime');
            }
            let obj = {
              updatedStartTime: updatedStartTime,
              updatedEndTime: updatedEndTime,
              informationType: informationType
            };
            localStorage.setItem('orderListQuery', JSON.stringify(obj));
            v.$store.commit('updateSystemMenu', true);
            setTimeout(() => {
              v.$router.push({
                path: '/orderList',
              });
            }, 200);
          }
          break;
        // 供应商系统
        case 'supplier':
          if (targetId) {
            switch (informationType) {
              // 订单取消
              case 7:
                if (document.getElementById(targetId)) {
                  let id = getMenuRoutingId('/supplierOrderList');
                  localStorage.setItem('activeName', id);
                  let value = document.getElementById(targetId).innerText;
                  localStorage.setItem('supplierOrderListQuery', JSON.stringify(value));
                  v.$store.commit('updateSystemMenu', true);
                  setTimeout(() => {
                    v.$router.push({
                      path: '/supplierOrderList',
                    });
                  }, 500);
                }
                break;
              // 货品多发通知
              case 12:
                let val = targetId.split('-');
                let pickupOrderNumber = val[0];
                let wmsPickupOrderId = val[1];
                let id = getMenuRoutingId('/shipmentRecord');
                localStorage.setItem('activeName', id);
                v.$store.commit('updateSystemMenu', true);
                setTimeout(() => {
                  v.$router.push({
                    path: '/shipmentRecordDetails',
                    query: {
                      pickupOrderNumber: pickupOrderNumber,
                      wmsPickupOrderId: wmsPickupOrderId
                    }
                  })
                }, 500);
                break;
            }
          }
          break;
        // 分销商系统
        case 'distributor':
          if (targetId) {
            switch (informationType) {
              // 订单缺货、YMS订单自动取消通知
              case 1:
              case 15:
                id = getMenuRoutingId('/myOrder');
                localStorage.setItem('distributorActiveName', id);
                v.$store.commit('updateDistributorMenu', true);
                setTimeout(() => {
                  v.$router.push({
                    path: '/myOrderDetails',
                    query: {
                      ymsPackageId: targetId
                    }
                  });
                }, 500);
                break;
              // 停售通知、商品信息修改通知、商品调价通知、商品状态自动同步通知、在线刊登自动调价通知
              case 8:
              case 9:
              case 10:
              case 13:
              case 14:
                pageJump(`/index.html#/shopDetails/${item.businessId}`, '/yms-shopping-service', '_blank');
                break;
              // 余额不足通知
              case 16:
                id = getMenuRoutingId('/platformOrder');
                localStorage.setItem('distributorActiveName', id);
                let val = targetId.split('-');
                let obj = {
                  platformId: val[0],
                  platformOrderNos: val[1]
                };
                localStorage.setItem('platformOrderQuery', JSON.stringify(obj));
                v.$store.commit('updateDistributorMenu', true);
                setTimeout(() => {
                  v.$router.push({
                    path: '/platformOrder'
                  });
                }, 200);
                break;
            }
          }
          break;
      }
      v.messageNotificationModal = false;
    },
    // 全部标记已读
    markReadBtn() {
      let v = this;
      let urlObj = {
        yms: api.get_ymsStationNotification_markPopipRead + v.userInfo.merchantId,
        supplier: api.get_ymsSupplierStationNotification_markPopipRead + v.userInfo.merchantId,
        distributor: api.get_ymsDistributorStationNotification_markPopipRead + v.userInfo.merchantId
      }
      let url = urlObj[v.system];
      let selectNoticeList = v.messageNotificationList.map((item) => {
        return item.ymsStationNotificationId
      });
      if (selectNoticeList.length > 0) {
        v.axios.get(url, {loading: true, loadingText: alias93cb5d9a0adb49f980c05a290e356da0.t('key1000535')}).then((response) => {
          if (response.data.code === 0) {
            v.$Message.success(alias93cb5d9a0adb49f980c05a290e356da0.t('key1000536'));
            v.messageNotificationModal = false;
          }
        });
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .messageNotificationStyle {
  .ivu-modal-body {
    padding: 0;
  }

  .messageNotification_content {
    max-height: 550px;
    overflow-y: auto;
    padding: 8px 16px 0 16px;
    box-sizing: border-box;

    .messageNotification_box {

      .messageNotification_box_item {
        margin-bottom: 15px;

        .title {
          color: #333;
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 3px;
        }

        .text {
          color: #666;
          font-size: 13px;

          .blueColor {
            cursor: pointer;
            color: #FD5425 !important;
            margin: 0 4px;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .noMoreData {
      color: #666;
      font-size: 12px;
      padding: 10px 0;
      text-align: center;
      width: 100%;
    }
  }
}
</style>