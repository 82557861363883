
import { alias93cb5d9a0adb49f980c05a290e356da0 } from '@/customFolder/customVueAlias.js';
// 多语种动态日志-- 目前只处理分销商系统涉及的日志
import {setConfigCharacters} from "@/utils/common";

export const dynamicLog = (obj, contentType, system) => {
  // 动态类型值
  let typeObj = {
    LF: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001563'),
    FT: alias93cb5d9a0adb49f980c05a290e356da0.t('key1001564')
  }

  // 处理地址的动态日志数据问题
  function handleAddress(obj) {
    let newList = [];
    let addressLoglist = [
      {
        paramsKey: ['oldBuyerName', 'newBuyerName'],
        value: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001378')} [ ${obj.oldBuyerName} ] ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000451')} [ ${obj.newBuyerName} ]`
      },
      {
        paramsKey: ['oldBuyerEmail', 'newBuyerEmail'],
        value: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001379')} [ ${obj.oldBuyerEmail} ] ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000451')} [ ${obj.newBuyerEmail} ]`
      },
      {
        paramsKey: ['oldBuyerPhone', 'newBuyerPhone'],
        value: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001380')} [ ${obj.oldBuyerPhone} ] ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000451')} [ ${obj.newBuyerPhone} ]`
      },
      {
        paramsKey: ['oldBuyerAccountId', 'newBuyerAccountId'],
        value: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001381')} [ ${obj.oldBuyerAccountId} ] ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000451')} [ ${obj.newBuyerAccountId} ]`
      },
      {
        paramsKey: ['oldBuyerCountryCode', 'newBuyerCountryCode'],
        value: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001382')} [ ${obj.oldBuyerCountryCode} ] ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000451')} [ ${obj.newBuyerCountryCode} ]`
      },
      {
        paramsKey: ['oldBuyerMobile', 'newBuyerMobile'],
        value: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001383')} [ ${obj.oldBuyerMobile} ] ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000451')} [ ${obj.newBuyerMobile} ]`
      },
      {
        paramsKey: ['oldBuyerAddress1', 'newBuyerAddress1'],
        value: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001384')} [ ${obj.oldBuyerAddress1} ] ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000451')} [ ${obj.newBuyerAddress1} ]`
      },
      {
        paramsKey: ['oldBuyerAddress2', 'newBuyerAddress2'],
        value: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001385')} [ ${obj.oldBuyerAddress2} ] ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000451')} [ ${obj.newBuyerAddress2} ]`
      },
      {
        paramsKey: ['oldBuyerCity', 'newBuyerCity'],
        value: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001386')}  [ ${obj.oldBuyerCity} ] ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000451')} [ ${obj.newBuyerCity} ]`
      },
      {
        paramsKey: ['oldBuyerState', 'newBuyerState'],
        value: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001387')} [ ${obj.oldBuyerState} ] ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000451')} [ ${obj.newBuyerState} ]`
      },
      {
        paramsKey: ['oldBuyerPostalCode', 'newBuyerPostalCode'],
        value: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001388')} [ ${obj.oldBuyerPostalCode} ] ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000451')} [ ${obj.newBuyerPostalCode} ]`
      },
      {
        paramsKey: ['oldBuyerPassportCode', 'newBuyerPassportCode'],
        value: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001389')} [ ${obj.oldBuyerPassportCode} ] ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000451')} [ ${obj.newBuyerPassportCode} ]`
      },
    ];
    if (Object.keys(obj).length > 0) {
      for (let key in obj) {
        addressLoglist.map((item) => {
          if (item.paramsKey.includes(key)) {
            newList.push(item.value)
          }
        })
      }
    }
    newList.unshift(`${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001390')} ${obj.packageCode} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001391')}`);
    let text = [...new Set(newList)].join(';');
    return text;
  }

  // 处理有些动态日志不需要展示在分销商但是需要展示运营
  function handleDynamicLogs(obj, system) {
    let text = '';
    if (contentType === 40) {
      if (system === 'distributor') {
        text = `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001392')}${obj.currency} ${obj.amount}`;
      } else if (system === 'yms') {
        text = `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001393')}${obj.currency} ${obj.amount}`;
      }
    }
    if (contentType === 48) {
      if (system === 'distributor') {
        text = '';
      } else if (system === 'yms') {
        text = `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001394')}${obj.totalPriceCurrency}  ${obj.totalPrice}
         ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001395')}${obj.totalPriceCurrency}  ${obj.distributorStockCost}
         ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001396')}${obj.supplierTotalPriceCurrency}  ${obj.supplierTotalPrice}`
      }
    }
    if (contentType === 52) {
      if (system === 'distributor') {
        text = '';
      } else if (system === 'yms') {
        text = `${obj.subPackageCode} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001397')}`;
      }
    }
    return text;
  }

  /*;是用于页面显示的时候换行展示*/
  let logsObj = {
    1: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001398')}${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001399')}`,
    2: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001400')}`,
    3: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001401')}`,
    4: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001402')}`,
    5: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001403')}`,
    6: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001404')}`,
    7: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001405')}${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001406')} [ ${obj.packageCode} ]`,
    8: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001407')} ${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001406')} [ ${obj.packageCode} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001408')} ${obj.ruleName} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001409')} ${obj.ymsLogisName} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001410')} ${typeObj[obj.dynamicMessage]}`,
    9: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001411')}${obj.platformItemSku}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001412')}${setConfigCharacters}SKU：${obj.ymsSku}`,
    10: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001413')}${obj.platformItemSku}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001414')}${setConfigCharacters}SKU`,
    11: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001415')}${obj.platformItemSku}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001412')}${setConfigCharacters}SKU：${obj.ymsSku}`,
    12: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001416')}${obj.platformItemSku}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001412')}${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001417')}`,
    13: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001416')}${obj.platformItemSku}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001414')}${setConfigCharacters}SKU`,
    14: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001407')} ${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001406')} [ ${obj.packageCode} ]`,
    15: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001406')} [ ${obj.packageCode} ] ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001418')}`,
    16: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001419')}${obj.carrierShippingMethodName} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001420')}${obj.trackingNumber} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001421')}${obj.thirdPartyNo}`,
    17: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001422')}${obj.message}`,
    18: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001423')}${obj.trackingNumber}`,
    19: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001424')}${obj.message}`,
    20: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001406')} [ ${obj.packageCode} ] ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001425')}`,
    21: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001406')} [ ${obj.packageCode} ] ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001426')}`,
    22: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000934')} ${obj.packageCode} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001427')} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001428')})`,
    23: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001406')} [ ${obj.packageCode} ] ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001425')} ${obj.message}`,
    24: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001390')} ${obj.packageCode} ] ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001429')}`,
    25: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001390')} ${obj.packageCode} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001430')} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001431')} [ ${obj.oldCarrierContent} ] ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001432')} [ ${obj.newCarrierContent} ]`,
    26: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001390')} ${obj.packageCode} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001433')}`,
    27: handleAddress(obj),
    28: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001434')}`,
    29: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001435')}`,
    30: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000517')}`,
    31: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000516')}`,
    32: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001436')}`,
    33: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001437')}`,
    34: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001438')}${obj.iossName}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001439')}${obj.iossCode}`,
    35: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001440')}${obj.iossName}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001439')}${obj.iossCode}`,
    36: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001441')}${obj.iossName}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001442')}${obj.iossCode}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001443')}${obj.platformIds}`,
    37: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001441')}${obj.iossName}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001442')}${obj.iossCode}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001444')}${obj.shopIds}`,
    38: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001445')}${obj.iossName}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001442')}${obj.iossCode}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001443')}${obj.platformIds}`,
    39: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001445')}${obj.iossName}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001442')}${obj.iossCode}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001444')}${obj.shopIds}`,
    40: handleDynamicLogs(obj, system),
    41: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001446')}${obj.currency} ${obj.amount}`,
    42: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001447')}${obj.currency} ${obj.amount}`,
    43: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001448')}`,
    44: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001449')}`,
    45: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001450')}`,
    46: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001451')} ${obj.amount}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001452')}${obj.currency}`,
    47: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001453')}`,
    48: handleDynamicLogs(obj, system),
    49: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001454')}`,
    50: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001455')}${obj.estimateArrivalOfGoodsTime}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001456')}${obj.ymsSkuContent}`,
    51: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001457')}${obj.ymsSkuContent}`,
    52: handleDynamicLogs(obj, system),
    53: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001458')}`,
    54: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001459')}`,
    55: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000516')}${obj.cnValue}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000218')}`,
    56: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000517')}${obj.cnValue}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000218')}`,
    57: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001030')}${obj.cnValue}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000218')}`,
    58: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000227')}${obj.content}`,
    59: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001460')}`,
    60: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001461')}`,
    61: `${obj.subPackageCode}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001397')}`,
    62: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001462')}`,
    63: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001463')}`,
    64: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001464')}${obj.carrierShippingMethodName}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001465')}${obj.trackingNumber}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001466')}${obj.thirdPartyNo}`,
    65: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001467')}`,
    66: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001468')}`,
    67: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001469')}`,
    68: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001470')}`,
    69: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001471')}`,
    70: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001472')}`,
    71: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001473')}${obj.rate}`,
    72: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001474')}${obj.rate}`,
    73: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001475')}`,
    74: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001476')}`,
    75: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001477')}`,
    76: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001478')}${obj.ymsPickingOrderCode}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001479')}`,
    77: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001246')}`,
    78: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001480')}`,
    79: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001481')}`,
    80: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001482')}${obj.code}】`,
    81: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001483')}`,
    82: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001484')}`,
    83: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001485')}`,
    84: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001486')}`,
    85: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001487')}`,
    86: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001488')}`,
    87: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001489')}`,
    88: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000674')}`,
    89: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001490')}${obj.describe}`,
    90: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001491')}  ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001492')} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001493')}${obj.describe}`,
    91: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001494')} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001492')} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000674')}`,
    92: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001491')} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001492')} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000674')}`,
    93: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001494')} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001492')} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001495')} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001490')}${obj.describe}`,
    94: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001496')} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001497')}${obj.subsidyAmount}${obj.subsidyAmountCurrency}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001498')}${obj.modifySubsidyMoney}${obj.subsidyAmountCurrency}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001499')}${obj.describe}`,
    95: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001489')}`,
    96: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001500')}`,
    97: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001501')}`,
    98: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001502')}`,
    99: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001503')}`,
    100: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001504')}`,
    101: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001505')}`,
    102: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001506')}${obj.auditRemarks}`,
    103: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001507')}`,
    104: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001508')}`,
    105: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001509')}`,
    106: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001510')}${obj.carrierName}：${obj.carrierShippingMethodName}`,
    107: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001511')}`,
    108: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001244')}`,
    109: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001512')}${obj.packageCode}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001513')}${obj.warehouseName}`,
    110: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001515')}${obj.invalidReason || alias93cb5d9a0adb49f980c05a290e356da0.t('key1001514')}`,
    111: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001516')}${obj.oldName}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001517')}${obj.newName}]`,
    112: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000934')}${obj.packageCode}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001407')}${obj.carrierName}_${obj.carrierShippingMethodName}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001518')}(${obj.ruleName})`,
    113: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000934')}${obj.packageCode}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001407')}${obj.carrierName}_${obj.carrierShippingMethodName}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001519')}`,
    114: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1000934')}${obj.packageCode}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001407')}${obj.carrierName}_${obj.carrierShippingMethodName}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001520')}`,
    115: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001521')}`,
    116: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001522')}`,
    117: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001523')}`,
    118: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001524')}`,
    119: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001525')} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001526')}${obj.trackingNumber})`,
    120: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001522')} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001527')}${obj.carrierErrorMsg})`,
    121: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001528')}`,
    122: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001529')}`,
    123: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001530')}`,
    124: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001531')}${obj.businessId}】`,
    125: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001532')}`,
    126: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001533')}`,
    127: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001534')}`,
    128: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001535')}`,
    129: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001536')}`,
    130: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001537')}`,
    131: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001538')}`,
    132: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001539')}`,
    133: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001540')}`,
    134: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001541')}${obj.invalidReason}`,
    135: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001542')}`,
    136: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001543')}`,
    137: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001544')}`,
    138: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001545')}${obj.renewalPrice}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001546')}${obj.renewalMonth} ${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001547')}`,
    139: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001548')}`,
    140: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001549')}${obj.auditRemarks}`,
    141: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001550')}${obj.content}`,
    142: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001551')}`,
    143: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001552')}${obj.packageTagName}`,
    144: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001553')}${obj.packageTagName}`,
    145: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001554')}`,
    146: `${setConfigCharacters}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001555')}${obj.content}`,
    147: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001556')}`,
    148: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001557')}`,
    149: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001558')}`,
    150: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001559')}`,
    151: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001560')}${obj.ymsSpu}`,
    152: `${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001561')}${obj.ymsSku}`,
    153:`${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001416')}${obj.platformItemSku}${alias93cb5d9a0adb49f980c05a290e356da0.t('key1001562')}`
  };
  return logsObj[contentType];

}
